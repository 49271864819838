import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {logoutUser} from 'store/actions';

import * as types from './types';

const initialState = {
  isLoggedIn: false,
  loginInProgress: false,
  loginError: null,
  isRequestingPhoneCode: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    [types.LOGIN_SUCCESS]: (state) => {
      state.isLoggedIn = true;
      state.loginError = null;
      state.loginInProgress = false;
    },
    [types.LOGIN_FAILURE]: (state, action: PayloadAction<any>) => {
      state.isLoggedIn = false;
      state.loginError = action.payload;
      state.loginInProgress = false;
    },
    [types.LOGIN_INPROGRESS]: (state) => {
      state.isLoggedIn = false;
      state.loginInProgress = true;
      state.loginError = null;
    },
    [types.REQUESTING_PHONE_CODE_INPROGRESS]: (state) => {
      state.isRequestingPhoneCode = true;
    },
    [types.REQUESTING_PHONE_CODE_SUCCESS]: (state) => {
      state.isRequestingPhoneCode = false;
    },
    [types.REQUESTING_PHONE_CODE_FAILED]: (state) => {
      state.isRequestingPhoneCode = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      state.loginError = null;
      state.isLoggedIn = false;
      state.loginInProgress = false;
    });
  },
});

export const {
  [types.LOGIN_SUCCESS]: loginSuccess,
  [types.LOGIN_FAILURE]: loginFailure,
  [types.LOGIN_INPROGRESS]: loginInProgress,
  [types.REQUESTING_PHONE_CODE_INPROGRESS]: requestingPhoneCodeInProgress,
  [types.REQUESTING_PHONE_CODE_SUCCESS]: requestingPhoneCodeSuccess,
  [types.REQUESTING_PHONE_CODE_FAILED]: requestingPhoneCodeFailed,
} = authSlice.actions;

export default authSlice.reducer;
