import {ChangeEvent, FC, MouseEventHandler, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';

import Button from 'shared/components/Button';
import DragAndDrop from 'shared/components/DragAndDrop';
import Icon from 'shared/components/Icon';
import {TasksViewMode} from 'shared/constants/common';
import {ImportFileSchema} from 'shared/helpers/validationSchemas';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';

import {useTasksImportContext} from '../../TasksImportContext/TasksImportContext';
import {TasksImportSourceType} from '../../utils/constants';
import {getFileExtension} from '../../utils/functions';

type Props = {
  viewMode: TasksViewMode;
};

const TasksImportFileSelector: FC<Props> = ({viewMode}) => {
  const [{file, sourceType}, actions] = useTasksImportContext();
  const {t} = useTranslation('import');
  const inputRef = useRef<HTMLInputElement>(null);

  const {projectId} = useParams<{projectId: string}>();
  const {mixpanel} = useAnalyticsService({
    extraMeta: {projectId, viewMode},
  });

  const onFileSelected = (files: FileList) => {
    if (files.length) {
      const [file] = files;
      ImportFileSchema.validate({importFile: file})
        .then(() => {
          mixpanel.track(mixpanel.events.tasks.import.selectFile, {fileType: getFileExtension(file.name)});
          actions.setFileSource(file);
        })
        .catch(({errors}) => {
          toast.error(errors[0]);
          if (inputRef.current) {
            inputRef.current.value = '';
          }
        });
    }
  };

  const selectFile: MouseEventHandler<HTMLButtonElement> = () => {
    mixpanel.track(mixpanel.events.tasks.import.uploadCSVBtn);
    inputRef.current.click();
  };

  const resetFileSource = () => {
    actions.setFileSource(null);
  };

  const isActive = sourceType === TasksImportSourceType.file;
  const activeStyle = isActive ? {color: '#053fe3'} : null;

  return (
    <>
      <header className="form-compare__header" style={activeStyle}>
        <h2 className="form-compare__title">{t('file_selector.title', 'Upload Import File')}</h2>
      </header>
      {(!file && (
        <DragAndDrop
          showOnDrag={
            <div className="ctrl-upload-csv ctrl-upload-csv--dnd form-compare__upload">
              <Icon colorFill className="ctrl-upload-csv__icon-dnd" size={24} name="upload_on_cloud" />
              <div className="ctrl-upload-csv__text">{t('file_selector.drag.title', 'Drag here to upload')}</div>
            </div>
          }
          onDropHandle={onFileSelected}
        >
          <div className="ctrl-upload-csv ctrl-upload-csv--start form-compare__upload">
            <div className="ctrl-upload-csv__text">
              {t('file_selector.drag.text', 'Drag CSV, MS Project (.mpp), or P6 (.xer) file here to upload')}
            </div>
            <input
              accept=".csv,.xer,.mpp"
              data-cy="ctrlImportFile"
              ref={inputRef}
              type="file"
              style={{position: 'absolute', left: -1, top: -1, width: 1, height: 1}}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onFileSelected(e.target.files)}
            />
            <div className="ctrl-upload-csv__actions">
              <Button
                suffix="z"
                buttonSize="xl"
                className="ctrl-upload-csv__button"
                onClick={selectFile}
                icon={<Icon colorFill className="z-ctrl-btn__icon" size={24} name="upload_on_cloud" />}
              >
                {t('file_selector.buttons.upload', 'Upload')}
              </Button>
            </div>
          </div>
        </DragAndDrop>
      )) || (
        <div className="ctrl-upload-csv ctrl-upload-csv--file form-compare__upload">
          <div className="ctrl-upload-csv__text">{t('file_selector.upload_title', 'Your file:')}</div>
          <div className="ctrl-file ctrl-upload-csv__file">
            <span className="ctrl-file__name">{file.name}</span>
            <Button
              iconOnly
              className="ctrl-file__button-remove"
              onClick={resetFileSource}
              icon={<Icon colorFill className="ctrl-btn-clear__icon" size={24} name="clear_circle" />}
            >
              {t('file_selector.buttons.remove', 'Remove this File')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default TasksImportFileSelector;
