import cn from 'classnames';
import {Children, cloneElement, isValidElement, ReactNode} from 'react';

import s from './Comments.module.scss';
import CommentsAction from './components/CommentsAction/CommentsAction';
import CommentsClear from './components/CommentsClear/CommentsClear';
import CommentsDate from './components/CommentsDate/CommentsDate';
import CommentsNew from './components/CommentsNew/CommentsNew';

type BaseProps = {
  className?: string;
};

type CommentsProps = {
  className?: string;
  children?: ReactNode | ReactNode[];
};

const Comments = ({className, children}: CommentsProps) => {
  return (
    <div className={cn(s.Comments, className)}>
      {Children.toArray(children).map((child) =>
        isValidElement<BaseProps>(child)
          ? cloneElement<BaseProps>(child, {
              ...child.props,
              className: cn(child.props.className, s.Comments__item),
            })
          : child,
      )}
    </div>
  );
};

Comments.Action = CommentsAction;
Comments.Clear = CommentsClear;
Comments.Date = CommentsDate;
Comments.CommentsNew = CommentsNew;

export {Comments};
