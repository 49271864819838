import {useQuery, useQueryClient} from '@tanstack/react-query';
import {KeyboardEvent, forwardRef, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {ActionMeta, GroupBase, type SelectInstance, StylesConfig, createFilter} from 'react-select';
import {toast, ToastContent} from 'react-toastify';

import ProjectsApi from 'api/projects';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {sortAlphabetically} from 'shared/helpers/common';
import {CompanyOrgs} from 'shared/models/company';

import {CreatableSelect} from '../CreatableSelect/CreatableSelect';
import {CoreSelect} from '../Select';

const filterOption = createFilter({
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: 'any',
  stringify: (option) => option.label,
  trim: true,
});

interface BaseProps {
  _projectId?: string;
  className?: string;
  classNamePrefix?: string;
  closeMenuOnSelect?: boolean;
  isDisabled?: boolean;
  loadingPlaceholder?: string;
  menuIsOpen?: boolean;
  name?: string;
  onAfterCreateOption?: (subcontractor: CompanyOrgs) => void;
  onChange?: (value: string, actionMeta?: ActionMeta<CoreOptionType>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLDivElement>) => void;
  onMenuClose?: () => void;
  options?: CoreOptionType[];
  placeholder?: string;
  projectId?: string;
  size?: 'xs';
  styles?: StylesConfig<CoreOptionType, false, GroupBase<CoreOptionType>>;
  tabSelectsValue?: boolean;
  value: string;
}

interface CreatableProps extends BaseProps {
  isCreatable: true;
  onCreateOption?: (inputValue: string) => void;
}

interface NonCreatableProps extends BaseProps {
  isCreatable?: false;
}

type Props = CreatableProps | NonCreatableProps;

export const AsyncProjectSubcontractorSelect = forwardRef<SelectInstance<CoreOptionType, false>, Props>(
  (
    {
      name,
      value,
      onChange,
      loadingPlaceholder = 'Wait...',
      isDisabled,
      isCreatable,
      placeholder,
      size,
      _projectId,
      onAfterCreateOption,
      ...props
    },
    ref,
  ) => {
    const {projectId: paramsProjectId} = useParams<{projectId: string; id: string}>();
    const [selected, setSelected] = useState<string | null>(null);
    const queryClient = useQueryClient();
    const projectId = _projectId || paramsProjectId;
    const {t} = useTranslation('common');
    const [isCreating, setIsCreating] = useState(false);

    const {data: options, isLoading: isFetching} = useQuery({
      queryKey: QUERY_CACHE_KEYS.projectSubcontractors(projectId),
      queryFn: () => ProjectsApi.getOrgs(projectId),
      enabled: !!projectId,
      refetchOnWindowFocus: false,
      select: (orgs) => {
        const preparedOrgs = orgs.map(
          (sub): CoreOptionType => ({
            value: sub.id,
            label: sub.group.name,
          }),
        );
        return sortAlphabetically(preparedOrgs, 'label');
      },
    });

    const isLoading = isFetching || isCreating;

    useEffect(() => {
      const defaultSelectedValue = options?.find((sub) => sub.value === value)?.value;
      setSelected(defaultSelectedValue || null);
    }, [value, options]);

    const onSelectChange = (value: string) => {
      setSelected(value);
      onChange(value || null);
    };

    const onCreateOption = async (value: string) => {
      setIsCreating(true);
      try {
        const createdSubcontractor = await ProjectsApi.createOrg(projectId, {name: value});
        onAfterCreateOption?.(createdSubcontractor);
        await queryClient.invalidateQueries({
          queryKey: QUERY_CACHE_KEYS.projectSubcontractors(projectId),
        });
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(extractAxiosError(error) as ToastContent);
        }
      } finally {
        setIsCreating(false);
      }
    };

    const commonProps = {
      filterOption,
      ref,
      isSearchable: true,
      isClearable: true,
      menuPlacement: 'auto' as const,
      isDisabled: isLoading || isDisabled,
      isLoading,
      options: options,
      name,
      placeholder: isLoading ? loadingPlaceholder : placeholder,
      className: `react-select ${size ? 'react-select--size-xs' : ''}`,
      classNamePrefix: 'react-select',
      onChange: onSelectChange,
      value: selected,
      ...props,
    };

    if (isCreatable) {
      return (
        <CreatableSelect
          {...commonProps}
          onCreateOption={onCreateOption}
          noOptionsMessage={() => t('dropdown.no_options_create', 'No options. You can create one.')}
        />
      );
    }

    return <CoreSelect {...commonProps} noOptionsMessage={() => t('dropdown.no_options', 'No options')} />;
  },
);

AsyncProjectSubcontractorSelect.displayName = 'AsyncProjectSubcontractorSelect';

export default AsyncProjectSubcontractorSelect;
