import {useMemo} from 'react';

import {useRootSelector} from 'store';
import {getProjectResponsibleParties, getProjectLocations} from 'store/projects/selectors';

export function useOptionsForSelect(projectId: string) {
  const responsibleParties = useRootSelector((state) => getProjectResponsibleParties(state, projectId));
  const projectLocations = useRootSelector((state) => getProjectLocations(state, projectId));

  const responsibleOptions = useMemo(
    () => responsibleParties?.map((v) => ({value: v, label: v})) ?? [],
    [responsibleParties],
  );

  const locationOptions = useMemo(
    () =>
      projectLocations
        .filter(Boolean)
        .map((v) => ({value: v, label: v}))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [projectLocations],
  );

  return {responsibleOptions, locationOptions};
}
