import {GanttStatic} from 'dhtmlx-gantt';
import {FormikProps} from 'formik';
import {TFunction} from 'i18next';
import {MutableRefObject} from 'react';
import {toast, ToastContent} from 'react-toastify';

import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {GANTT_COLUMNS_NAMES} from 'modules/Tasks/components/Gantt/utils/constants';
import {isPlaceholderTask} from 'modules/Tasks/components/Gantt/utils/gantt';
import {refreshTask} from 'modules/Tasks/utils/functions';
import {GanttNames} from 'shared/constants/gantt';
import {isSame, startOf, subtract, toShortIso} from 'shared/helpers/dates';
import {debounce} from 'shared/helpers/debounce';
import {useMixpanel} from 'shared/hooks/analytics/useMixpanel';
import {TaskGanttModel} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';

import {FormValues} from '../../SidebarPanel/utils/types';

export function focusEditor(gantt: GanttStatic) {
  const inlineState = gantt.ext.inlineEditors.getState();
  if (inlineState.editor?.get_input && inlineState.placeholder) {
    const input: HTMLInputElement | null = inlineState.editor.get_input(inlineState.placeholder);
    input && input.focus();
  }
  return false;
}
export function onTaskCreated(gantt: GanttStatic, task: GanttTask, projectId: string) {
  task.name = task.name || '';
  task.datesIsPristine = true;
  if (!isPlaceholderTask(gantt, task)) {
    gantt.addTask(
      {
        ...task,
        projectId,
        readonly: false,
        focus: GANTT_COLUMNS_NAMES.name,
        taskStatus: TaskStatusType.assigned,
        start_date: startOf(new Date(), 'day').toDate(),
        taskDuration: 1,
      } as Partial<TaskGanttModel>,
      task.parent,
      task.relativeToId && gantt.isTaskExists(task.relativeToId)
        ? gantt.getTaskIndex(task.relativeToId) + 1
        : undefined,
    );
    return false;
  } else {
    task.projectId = projectId;
  }
  return true;
}

export function onAfterTaskDrag(
  gantt: GanttStatic,
  taskId: string,
  mode: string,
  old: TaskGanttModel,
  mixpanel: ReturnType<typeof useMixpanel>,
): void {
  const newTask: TaskGanttModel = gantt.getTask(taskId);
  const startChanged = !isSame(old.start_date, newTask.start_date);
  const endChanged = !isSame(old.end_date, newTask.end_date);
  const isGanttView = gantt.name === GanttNames.gantt;
  if (startChanged || mode === 'resize') {
    newTask.lastChangedFields.startDate = {newValue: newTask.start_date};
    if (!endChanged && isGanttView) {
      mixpanel.track(mixpanel.events.gantt.dragBarStartTaskDate);
    }
  }
  if (endChanged || mode === 'resize') {
    const inclusiveEnd = toShortIso(subtract(newTask.end_date, 1, 'day'));
    newTask.lastChangedFields.endDate = {newValue: inclusiveEnd};
    if (!startChanged && isGanttView) {
      mixpanel.track(mixpanel.events.gantt.dragBarDueTaskDate);
    }
  }
}

export function watchFrozenColumnOffset(gantt: GanttStatic) {
  let mainGridScrollbar = document.querySelector<HTMLElement>('.gridScroll_cell .gantt_hor_scroll');
  const ganttContainer: HTMLElement = document.querySelector('.gantt-container');
  const scrollArea: HTMLElement = document.querySelector('.gantt_grid_data');
  const gridArea: HTMLElement = document.querySelector('.gantt_grid');
  const taskNameCell: HTMLElement = document.querySelector('.gantt_grid_head_cell[data-column-name="name"]');
  const initialScrollWidth = mainGridScrollbar?.scrollWidth;
  let gridDiff = 0;
  let changedGridWidth: number;
  const ganttEventsIds = [];

  function calculateOffset(changedGrid) {
    return Math.abs(initialScrollWidth + changedGrid);
  }

  function getOffset(newGridWidth: number) {
    changedGridWidth = newGridWidth;
    const isScrollHidden = mainGridScrollbar?.scrollWidth === 0;
    const isGridLargeThanScroll = mainGridScrollbar.scrollWidth < newGridWidth;
    const isGridChangesRange = newGridWidth > initialScrollWidth + gridDiff && newGridWidth < gridArea.clientWidth;

    const plusOffset =
      mainGridScrollbar?.scrollWidth - (newGridWidth ?? mainGridScrollbar?.clientWidth) - mainGridScrollbar?.scrollLeft;

    if (isGridLargeThanScroll) {
      if ((!!gridDiff && isScrollHidden && isGridChangesRange) || taskNameCell.clientWidth <= newGridWidth) {
        return 0 + 'px';
      }
      if (!!gridDiff && isScrollHidden && newGridWidth < initialScrollWidth + gridDiff && !isGridChangesRange) {
        return calculateOffset(gridDiff - newGridWidth) + 'px';
      }
      if (!gridDiff && isScrollHidden && newGridWidth < initialScrollWidth && !isGridChangesRange) {
        return calculateOffset(-newGridWidth) + 'px';
      }

      return 0 + 'px';
    }

    return plusOffset + 'px';
  }

  function setOffset(gridWidth?: number) {
    if (mainGridScrollbar) {
      ganttContainer.style.setProperty('--gantt-frozen-column-offset', getOffset(gridWidth));
    } else {
      ganttContainer.style.setProperty('--gantt-frozen-column-offset', '0px');
    }
  }

  function onScrollHandler() {
    setOffset();
  }

  function handleColumnChange() {
    if (gridArea?.clientWidth !== changedGridWidth) {
      gridDiff = scrollArea.clientWidth - initialScrollWidth + gridDiff;
      setOffset();
    } else {
      setOffset(0);
    }
  }

  ganttEventsIds.push(
    gantt.attachEvent(
      'onDataRender',
      debounce(function () {
        mainGridScrollbar = document.querySelector<HTMLElement>('.gridScroll_cell .gantt_hor_scroll');
        setOffset();
        if (mainGridScrollbar) {
          mainGridScrollbar.addEventListener('scroll', onScrollHandler);
        } else {
          mainGridScrollbar?.removeEventListener('scroll', onScrollHandler);
        }
      }, 0),
      undefined,
    ),
  );

  ganttEventsIds.push(
    gantt.attachEvent(
      'onAfterTaskAdd',
      () => {
        mainGridScrollbar?.scrollTo({left: 0});
      },
      undefined,
    ),
  );

  ganttEventsIds.push(gantt.attachEvent('onGanttRender', handleColumnChange, undefined));

  return () => {
    mainGridScrollbar?.removeEventListener('scroll', onScrollHandler);
    ganttEventsIds.forEach((id) => gantt.detachEvent(id));
  };
}

export function disableDateEditorsWhenActualized(gantt: GanttStatic, t: TFunction) {
  const ganttEventsIds: string[] = [];
  ganttEventsIds.push(
    gantt.attachEvent(
      'onBeforeTaskDrag',
      (id, mode) => {
        const task = gantt.getTask(id);
        if (task.actual_start && mode === 'move') {
          toast.warning(
            t(
              'gantt:toast.warning.activity_actual_both',
              'Dates cannot be changed for actualized activities!',
            ) as ToastContent,
          );
          return false;
        } else if (task.actual_end && mode === 'resize') {
          toast.warning(
            t(
              'gantt:toast.warning.activity_actual_duration',
              'Duration cannot be changed for actualized activities!',
            ) as ToastContent,
          );
          return false;
        }
        return true;
      },
      undefined,
    ),
  );

  // Disabling edit clicks on the nonEditable task date-cells in the grid
  const inlineEditors = gantt.ext.inlineEditors;
  ganttEventsIds.push(
    inlineEditors.attachEvent(
      'onBeforeEditStart',
      function (state) {
        const task = gantt.getTask(state.id);
        if (task.actual_end && state.columnName === 'taskDuration') {
          return false;
        }
        if (task.actual_start && state.columnName === 'start_date') {
          return false;
        }
        if (task.actual_end && state.columnName === 'end_date') {
          return false;
        }
        return true;
      },
      undefined,
    ),
  );

  return () => {
    ganttEventsIds.forEach((id) => gantt.detachEvent(id));
  };
}

const getClickedTaskNameNode = (e: MouseEvent): null | HTMLElement => {
  const {target} = e;

  // TODO: for what reason we check closest('.react-datepicker')
  if (!(target instanceof Element) || target.closest('.react-datepicker')) return null;

  if (target.closest('.gantt__task-name_link')) {
    const taskId = target.closest<HTMLElement>('[data-task-id]').dataset.taskId;
    const taskNode = document.querySelector(`[data-task-id=${taskId}]`);
    const [taskName] = Array.from(
      taskNode.getElementsByClassName('gantt__task-name_link') as HTMLCollectionOf<HTMLElement>,
    );

    return taskName;
  }

  return null;
};

export const startEdit = (gantt: GanttStatic, taskId: string | number, columnName) => {
  refreshTask(gantt, taskId, {isReadyEdit: true});
  gantt.ext.inlineEditors.startEdit(taskId, columnName);
};
export const handleConfirmOnOutsideClick = (
  gantt: GanttStatic,
  formik: MutableRefObject<FormikProps<FormValues>>,
  taskId: string,
  askToSaveBeforeLeave,
) => {
  return async (e: MouseEvent | TouchEvent, saveTask, onReject) => {
    if (!gantt.isTaskExists(taskId)) return;
    const task = gantt.getTask(taskId);
    const clickTarget = getClickedTaskNameNode(e as MouseEvent);

    const click = (onConfirmAsyncHandler: (...args: unknown[]) => Promise<unknown> | void, values?: FormValues) => {
      return async () => {
        if (values) {
          await onConfirmAsyncHandler(values);
        } else {
          onConfirmAsyncHandler();
          task.openedEditPanel = false;
          gantt.unselectTask(taskId);
        }

        clickTarget && clickTarget.click();
      };
    };

    if (formik.current?.dirty && clickTarget) {
      await askToSaveBeforeLeave(click(onReject), click(saveTask, formik.current?.values))();
    }
  };
};
