import classnames from 'classnames';
import {useField} from 'formik';
import {cloneElement, FC, isValidElement, PropsWithChildren} from 'react';

interface CoreFormControlProps {
  name: string;
  label?: string;
  className?: string;
  baseClassName?: string;
  classPrefix?: string;
  color?: string;
  labelOff?: boolean;
  withAddition?: boolean;
  showError?: boolean;
  error?: string;
}

const CoreFormControlBase: FC<PropsWithChildren<CoreFormControlProps>> = ({
  name,
  label,
  className,
  baseClassName = 'form-control',
  classPrefix = 'z',
  color,
  labelOff = false,
  withAddition = false,
  error,
  showError,
  children,
}) => {
  return (
    <div
      className={classnames(
        `${classPrefix}-form-control`,
        className,
        {
          'is-error': showError,
        },
        color && `${classPrefix}-${baseClassName}--${color}`,
        labelOff && `${baseClassName}--label-off`,
        withAddition && `${classPrefix}-${baseClassName}--addition`,
      )}
    >
      <div className={`${classPrefix}-${baseClassName}__header`}>
        {label && (
          <label
            className={`${classPrefix}-${baseClassName}__label`}
            htmlFor=""
            dangerouslySetInnerHTML={{__html: label}}
          />
        )}
      </div>
      <div className={`${classPrefix}-${baseClassName}__body`}>
        {isValidElement<{name: string}>(children)
          ? cloneElement(children, {
              name: name,
            })
          : children}
        {showError && <span className={`${classPrefix}-${baseClassName}__validation`}>{error}</span>}
      </div>
    </div>
  );
};

const CoreFormControl: FC<PropsWithChildren<Omit<CoreFormControlProps, 'showError' | 'error'>>> & {
  Base: typeof CoreFormControlBase;
} = (props) => {
  const [, meta] = useField(props.name);
  return <CoreFormControlBase showError={!!meta && meta.error && meta.touched} error={meta.error} {...props} />;
};

CoreFormControl.Base = CoreFormControlBase;

export default CoreFormControl;
