/* eslint-disable react/display-name */
import React, {forwardRef, useMemo} from 'react';
import ReactSelect, {
  components,
  SingleValue,
  ActionMeta,
  GroupBase,
  Props as SelectProps,
  MultiValue,
} from 'react-select';

import DropdownIndicator from './DropdownIndicator/DropdownIndicator';
import {createGenericStyles} from './styles';
import {SelectOption, SingleSelectRef} from './types';

export const MenuPortal = (props: any) => {
  return <components.MenuPortal {...props} className={props.selectProps.className} />;
};

const CustomOption = (props: any) => {
  const handleMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    // call the select method
    props.selectOption(props.data);
  };

  return (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        onMouseDown: handleMouseDown,
      }}
    />
  );
};

interface SingleSelectProps<Option extends SelectOption>
  extends Omit<SelectProps<Option, false, GroupBase<Option>>, 'value' | 'onChange'> {
  size?: boolean;
  className?: string;
  value?: string;
  onChange?: (value: string | SingleValue<Option> | MultiValue<Option>, actionMeta: ActionMeta<Option>) => void;
  options?: Option[];
  [key: string]: any;
}

const createOption = <Option extends SelectOption>(value: string): Option =>
  ({
    label: value,
    value: value,
  } as Option);

export const CoreSelect = forwardRef(
  <Option extends SelectOption = SelectOption>(
    props: SingleSelectProps<Option>,
    ref: React.Ref<SingleSelectRef<Option>>,
  ) => {
    const {size, className, value, onChange, options, components: customComponents, ...rest} = props;

    const selectedOption = useMemo((): Option | Option[] | null => {
      if (props.isMulti) {
        const selectedValues = Array.isArray(value) ? value : value ? [value] : [];
        if (selectedValues.length === 0) return [];

        return selectedValues.map((item) => {
          const existingOption = options?.find((opt) => opt.value === (typeof item === 'string' ? item : item.value));
          return existingOption || createOption<Option>(typeof item === 'string' ? item : item.value);
        });
      }

      if (!value) return null;
      const existingOption = options?.find((opt) => opt.value === value);
      return existingOption || createOption<Option>(value);
    }, [value, options, props.isMulti]);

    const handleChange = (option: SingleValue<Option> | MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
      if (props.isMulti) {
        const multiOption = option as MultiValue<Option>;
        onChange?.(multiOption, actionMeta);
      } else {
        const singleOption = option as SingleValue<Option>;
        onChange?.(singleOption?.value ?? '', actionMeta);
      }
    };

    return (
      <ReactSelect<Option, false>
        ref={ref}
        className={`${className ?? ''} ${size ? 'react-select--size-xs' : ''}`}
        classNamePrefix="react-select"
        components={{
          DropdownIndicator,
          MenuPortal,
          Option: CustomOption,
          ...customComponents,
        }}
        styles={props.styles || createGenericStyles<Option>()}
        value={selectedOption}
        options={options}
        onChange={handleChange}
        inputId={props.name}
        menuPortalTarget={document.body}
        {...rest}
      />
    );
  },
);
