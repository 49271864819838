import * as Sentry from '@sentry/react';
import {useQueryClient} from '@tanstack/react-query';
import {Formik, FormikConfig, FormikProps} from 'formik';
import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {batch} from 'react-redux';
import {toast} from 'react-toastify';

import CompanyApi from 'api/company';
import Button from 'shared/components/Button';
import {useConfirm} from 'shared/components/Confirmation';
import Icon from 'shared/components/Icon';
import {CreateCompanyProfileSchema, EditCompanyProfileSchema} from 'shared/helpers/validationSchemas';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompany} from 'shared/hooks/useCompany';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {useProfile} from 'shared/hooks/useProfile';
import {SlidePanel} from 'shared/layout/admin';
import {CompanyModel} from 'shared/models/company';
import {useRootDispatch, useRootSelector} from 'store';
import {createCompany, updateCompany} from 'store/company/actions';
import {fullLogout} from 'store/ducks/auth/actions';
import {profileActions} from 'store/profile';
import {loadWorkerCompanies, loadWorkerCompanyWorkers} from 'store/profile/actions';
import {profileDataLoaded} from 'store/selectors';

import CompanyForm from './CompanyForm/CompanyForm';
import CompanyPreview from './CompanyPreview/CompanyPreview';
import {CreateCompanyDesc} from './CreateCompanyDesc/CreateCompanyDesc';
import {CompanyFormValues} from './types';

type CompanyProfileProps = {visible: boolean; onClose: () => void};

function getDefaultValues(company: CompanyModel): CompanyFormValues {
  return {
    companyAddress: company?.companyAddress || '',
    companyName: company?.companyName || '',
    businessUrl: company?.businessUrl || '',
    pitchText: company?.pitchText || '',
    logo: null,
    companySize: company?.companySize || undefined,
    companyState: undefined,
    companyCountry: undefined,
    companyCity: undefined,
  };
}

const ConfirmSaveChangesPayload = {
  description: 'Do you want to save your changes?',
  acceptButton: 'Save Company',
  cancelButton: "Don't Save",
};

const CompanyProfile: FC<CompanyProfileProps> = ({visible, onClose}) => {
  const company = useCompany();
  const profile = useProfile();
  const {t} = useTranslation('company');
  const formik = useRef<FormikProps<CompanyFormValues>>(null);
  const dispatch = useRootDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const {createNewCompany, profileReady, companies} = useRootSelector((state) => ({
    createNewCompany: state.profile?.createNewCompany ?? false,
    profileReady: profileDataLoaded(state),
    companies: state.profile?.companies ?? [],
  }));
  const queryClient = useQueryClient();
  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.company;
  const doesntHaveCompany = !companies?.length;
  const needCreateCompany = createNewCompany || doesntHaveCompany;

  const {isCompanyAdmin} = useCompanyWorkerRoles();

  const getValidationSchema = useMemo(() => {
    return needCreateCompany ? CreateCompanyProfileSchema : EditCompanyProfileSchema;
  }, [needCreateCompany]);

  const {confirmAction} = useConfirm();
  const submit: FormikConfig<CompanyFormValues>['onSubmit'] = async (values: CompanyFormValues) => {
    if (needCreateCompany) {
      return await create(values);
    }

    const {logo, ...restFields} = values;
    const model = {...company, ...restFields} as CompanyModel;
    if (logo) {
      try {
        model.logoUrl = await uploadAvatar(logo);
        dispatch(profileActions.updateCompany({...company, logoUrl: model.logoUrl}));
      } catch (error) {
        toast.error(t('errors.avatar.update', 'Failed to update avatar'));
      }
    } else {
      model.logoUrl = null;
    }
    try {
      await dispatch(
        updateCompany({
          company: {
            ...model,
            pitchText: model.pitchText.trim(),
            companyName: model.companyName.trim(),
          },
          workerId: profile.id,
        }),
      ).unwrap();

      toast.success(t('notifications.company_profile.update.success', 'Profile updated successfully'));
      setIsEditing(false);
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          name: 'updateCompany thunk',
        },
      });
      toast.error(t('errors.company_profile.update', 'Failed to update company'));
    }
  };

  const loadCompaniesAndWorkers = (workerId: string) => {
    return Promise.all([dispatch(loadWorkerCompanies(workerId)), dispatch(loadWorkerCompanyWorkers(workerId))]);
  };

  const create = async (values: CompanyFormValues) => {
    const preparedValues = {
      businessUrl: values.businessUrl,
      companyName: values.companyName.trim(),
      pitchText: values.pitchText?.trim(),
      companyAddress: `${values.companyCity.trim()}, ${values.companyState.trim()}, ${values.companyCountry.trim()}`,
      companySize: values.companySize,
    };

    const res = await dispatch(createCompany({companyObject: preparedValues, workerId: profile.id}));
    if (createCompany.fulfilled.match(res)) {
      const createdCompany = res.payload;
      if (values.logo) {
        try {
          createdCompany.logoUrl = await uploadAvatar(values.logo);
        } catch (error) {
          toast.error(t('errors.avatar.update', 'Failed to update avatar'));
        }
        const res = await dispatch(
          updateCompany({
            company: {
              logoUrl: createdCompany.logoUrl,
              id: createdCompany.id,
            },
            workerId: profile.id,
          }),
        );
        if (updateCompany.rejected.match(res)) {
          toast.error(t('errors.avatar.update', 'Failed to update avatar'));
        }
      }
      if (doesntHaveCompany) {
        dispatch(profileActions.showCongratsPopup(true));
      }
      await loadCompaniesAndWorkers(profile.id);
      queryClient.clear();
      batch(() => {
        dispatch(profileActions.setActiveCompany(createdCompany.id));
        dispatch(profileActions.setCreateNewCompany(false));
      });
      setIsEditing(false);
      toast.success(t('notifications.company.create.successfully', 'Company has been created successfully'));
    } else {
      toast.error(t('errors.company.create', 'Failed to create company. Please try again later.'));
    }
  };

  function handleClose() {
    setIsEditing(false);
    if (createNewCompany) {
      dispatch(profileActions.setCreateNewCompany(false));
    }
    onClose();
  }

  function handleBackToCompany() {
    setIsEditing(false);
    if (createNewCompany) {
      dispatch(profileActions.setCreateNewCompany(false));
    }
  }

  useEffect(() => {
    if (company && formik.current && !needCreateCompany) {
      formik.current.resetForm({values: getDefaultValues(company)});
    }
  }, [company, isEditing]);

  const uploadAvatar = async (logo: File) => {
    return (await CompanyApi.uploadCompanyLogo(logo, company.id))?.url;
  };

  const askToSaveBeforeLeave = (onReject: () => void) => {
    return confirmAction(ConfirmSaveChangesPayload, formik.current?.dirty, formik.current?.submitForm, onReject);
  };

  function getBody() {
    if (profileReady) {
      return isEditing ? (
        <CompanyForm logoUrl={!needCreateCompany ? company?.logoUrl : null} isNewCompany={needCreateCompany} />
      ) : needCreateCompany ? (
        <CreateCompanyDesc
          onCreate={() => setIsEditing(true)}
          onLogout={() => {
            dispatch(fullLogout());
          }}
        />
      ) : (
        <CompanyPreview values={company} onEdit={() => setIsEditing(true)} />
      );
    }
    return (
      <div style={{flex: 1, minWidth: 0}}>
        <Skeleton height={180} />
      </div>
    );
  }

  function getCreateEditButton() {
    if (isCompanyAdmin) {
      return company && !needCreateCompany ? (
        <Button
          className="slide-panel__button-main ctrl-btn--view-border"
          type="button"
          onClick={() => setIsEditing(true)}
          icon={<Icon className="ctrl-btn__icon" colorFill size={24} name="edit" />}
        >
          {t('slide_panel.header.buttons.start_edit', 'Edit Profile')}
        </Button>
      ) : (
        <Button
          className="slide-panel__button-main ctrl-btn--view-border"
          type="button"
          onClick={() => mixpanel.trackWithAction(() => setIsEditing(true), mixpanelEvents.createProfile)}
        >
          {t('slide_panel.header.buttons.create_company', 'Create Profile')}
        </Button>
      );
    }
    return null;
  }

  function getHeader(isSubmitting: boolean, submitForm: () => Promise<void>) {
    if (!profileReady)
      return <h2 className="slide-panel__title">{t('slide_panel.header.title', 'Company Profile')}</h2>;
    return isEditing ? (
      <>
        <button
          className="y-ctrl-btn slide-panel__button-back"
          type="button"
          onClick={() => askToSaveBeforeLeave(handleBackToCompany)}
        >
          <Icon className="y-ctrl-btn__icon" colorFill name="arrow_backward" />
          <span className="y-ctrl-btn__text">{t('slide_panel.header.edit_mode.buttons.back', 'Back to Company')}</span>
        </button>
        <Button
          className="slide-panel__button-main ctrl-btn--view-border"
          type="button"
          disabled={isSubmitting}
          onClick={() => mixpanel.trackWithAction(submitForm, mixpanelEvents.saveNewCompany)}
          icon={<Icon className="ctrl-btn__icon" colorFill size={24} name="checkmark-outlined" />}
        >
          {t('slide_panel.header.edit_mode.buttons.save', 'Save')}
        </Button>
      </>
    ) : (
      <>
        <h2 className="slide-panel__title">{t('slide_panel.header.title', 'Company Profile')}</h2>
        {getCreateEditButton()}
      </>
    );
  }

  return (
    visible && (
      <Formik<CompanyFormValues>
        initialValues={getDefaultValues(!needCreateCompany ? company : null)}
        validationSchema={getValidationSchema}
        validateOnChange={true}
        onSubmit={submit}
        innerRef={formik}
      >
        {({submitForm, isSubmitting}) => {
          return (
            <SlidePanel
              onClose={() => askToSaveBeforeLeave(handleClose)}
              content={
                <>
                  <SlidePanel.Header>{getHeader(isSubmitting, submitForm)}</SlidePanel.Header>
                  <SlidePanel.Body>{getBody()}</SlidePanel.Body>
                </>
              }
              aside={<div />}
            />
          );
        }}
      </Formik>
    )
  );
};
export default CompanyProfile;
