import cn from 'classnames';
import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';
import {forwardRef, useCallback, useImperativeHandle, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import WorkersApi from 'api/workers';
import {useInviteWorker} from 'modules/Worker/mutations';
import {useCompanyOrgs} from 'modules/Worker/queries';
import {getValidationSchema} from 'modules/Worker/WorkerForm/validation';
import PhoneControl from 'shared/components/CoreForm/PhoneControl';
import AsyncOrgsSelect from 'shared/components/CoreForm/Select/AsyncCompanyOrgsSelect';
import {CoreSelect} from 'shared/components/CoreForm/Select/Select';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import CtrlCheck from 'shared/components/CoreNewUI/CtrlCheck/CtrlCheck';
import CtrlCheckGroup from 'shared/components/CoreNewUI/CtrlCheckGroup/CtrlCheckGroup';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import {defaultValues} from 'shared/constants/worker';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompany} from 'shared/hooks/useCompany';
import {useLoadWorkerTrades} from 'shared/hooks/useLoadWorkerTrades';
import {InviteBy, WorkerFormValues} from 'shared/models/worker';
import {getInviteMemberHandler, getWorkerMixpanelLabel} from 'shared/utils/inviteWorker';

import {useAssigneesTabContext} from '../AssigneesTab/components/AssigneesTabContext';
import {AssignmentState} from '../AssigneesTab/utils/constants';

import s from './MemberInvite.module.scss';

type MemberInviteProps = {
  className?: string;
};

const inviteMember = WorkersApi.inviteToProject.bind(WorkersApi);

const MemberInvite = forwardRef(({className}: MemberInviteProps, ref) => {
  const company = useCompany();
  const orgsOptions = useCompanyOrgs(company?.id);
  const formik = useRef<FormikProps<WorkerFormValues>>(null);
  const {projectId} = useParams<{projectId: string}>();
  const {t} = useTranslation('worker');
  const {localizedTrades} = useLoadWorkerTrades();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId}});
  const mixpanelEvents = mixpanel.events.worker;
  const {setAssignmentState, prevAssignmentState} = useAssigneesTabContext();

  const openPrevState = () => {
    setAssignmentState(
      prevAssignmentState === AssignmentState.inviteMember ? AssignmentState.empty : prevAssignmentState,
    );
  };
  const inviteWorker = useInviteWorker(inviteMember, formik, openPrevState);
  const {handleSubmit, checkRole} = getInviteMemberHandler(formik, projectId, addMember);

  async function addMember(values: WorkerFormValues) {
    mixpanel.track(mixpanelEvents.workerSubmitInviteBtn(getWorkerMixpanelLabel(!!projectId)));
    await inviteWorker.mutateAsync({values});
    openPrevState();
  }

  const validationSchema = useCallback(() => getValidationSchema(t), [t]);

  useImperativeHandle(ref, () => formik.current);

  const getEmailFields = (setFieldValue, setValues, values, error) => (
    <>
      <div className={s.MemberInvite__formItem}>
        <FormControl label={t('form.email.label', 'Email Address')} name="workerEmail" error={error}>
          <Field
            as="input"
            className="ctrl-textfield"
            placeholder={t('form.email.placeholder', 'example@email.com')}
            onChange={(e) => {
              setValues({
                ...values,
                workerEmail: e.target.value,
                sendEmail: true,
              });
            }}
          />
        </FormControl>
      </div>

      <div className={s.MemberInvite__formItem}>
        <Field name="sendEmail">
          {({field}: FieldProps) => (
            <CtrlCheck
              fieldType="checkbox"
              label={`${t('form.email.send', 'Send invitation to')} ${
                values.workerEmail || t('form.email.placeholder', 'example@email.com')
              }`}
              labelSize="s"
            >
              <input
                {...field}
                checked={!!field.value}
                type="checkbox"
                onChange={(e) =>
                  mixpanel.trackWithAction(
                    () => setFieldValue(field.name, e.target.checked),
                    mixpanelEvents.formFields.notifyEmail(getWorkerMixpanelLabel(!!projectId)),
                  )
                }
              />
            </CtrlCheck>
          )}
        </Field>
      </div>
    </>
  );

  const getPhoneFields = (setFieldValue, setValues, values) => (
    <>
      <div className={s.MemberInvite__formItem}>
        <FormControl label="Phone number">
          <PhoneControl
            className={s.MemberInvite__phoneCode}
            disabled={values.roles.includes('company_admin')}
            onChange={(changes) => {
              setValues({
                ...values,
                phoneCode: changes.code,
                phoneNumber: changes.phone,
                sendSms: true,
              });
            }}
            value={{phone: values.phoneNumber, code: values.phoneCode}}
          />
        </FormControl>
      </div>

      <div className={s.MemberInvite__formItem}>
        <Field name="sendSms">
          {({field}: FieldProps) => (
            <CtrlCheck
              fieldType="checkbox"
              label={`${t('form.phone.send', 'Send SMS invitation to')} ${values.phoneCode} ${
                values.phoneNumber || t('form.phone.placeholder', '(XXX) XXX-XXXX')
              }`}
              labelSize="s"
            >
              <input
                {...field}
                checked={!!field.value}
                type="checkbox"
                name="sendToPhone"
                onChange={(e) =>
                  mixpanel.trackWithAction(
                    () => setFieldValue(field.name, e.target.checked),
                    mixpanelEvents.formFields.notifySMS(getWorkerMixpanelLabel(!!projectId)),
                  )
                }
              />
            </CtrlCheck>
          )}
        </Field>
      </div>
    </>
  );

  return (
    <Formik<WorkerFormValues>
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={defaultValues}
      innerRef={formik}
    >
      {({setFieldValue, values, setValues, errors}) => (
        <Form>
          <section className={cn(s.MemberInvite, className)}>
            <header className={s.MemberInvite__header}>
              <h2 className={s.MemberInvite__title}>{t('title_invite', 'Inviting Member')}</h2>
              <div className={s.MemberInvite__actions}>
                <CtrlButton icon="arrow_2_left" color="second" view="link" onClick={openPrevState}>
                  {t('buttons.back', 'Back')}
                </CtrlButton>
              </div>
            </header>
            <div className={s.MemberInvite__form}>
              <div className={`${s.MemberInvite__formPart} ${s.MemberInvite__formPart_lastEl_field}`}>
                <div className={s.MemberInvite__formItem}>
                  <FormControl label={t('form.name.label', 'Full Name')} name="fullName" error={errors.fullName}>
                    <Field
                      as="input"
                      className="ctrl-textfield"
                      placeholder={t('form.name.placeholder', 'Full Name')}
                    />
                  </FormControl>
                </div>
                <div className={s.MemberInvite__formItem}>
                  <FormControl label={t('form.trade.label', 'Select Trade')} name="trade">
                    <Field>
                      {({field}: FieldProps) => (
                        <CoreSelect
                          options={localizedTrades}
                          isLoading={!localizedTrades}
                          value={field.value}
                          onChange={(value) => setFieldValue('trade', value)}
                        />
                      )}
                    </Field>
                  </FormControl>
                </div>
                <div className={s.MemberInvite__formItem}>
                  <FormControl label={t('form.organization.label', 'Organization')} name="orgMappingIds">
                    <Field>
                      {({field}: FieldProps) => (
                        <AsyncOrgsSelect
                          isMulti={true}
                          name={field.name}
                          value={field.value}
                          onChange={(value) => setFieldValue(field.name, value)}
                          options={orgsOptions}
                        />
                      )}
                    </Field>
                  </FormControl>
                </div>
              </div>
              <div className={s.MemberInvite__formPart}>
                <CtrlCheckGroup title={t('form.title_role', "Member's Role")}>
                  <Field name="roles" type="radio" value="company_admin">
                    {({field}: FieldProps) => (
                      <CtrlCheck
                        {...field}
                        className={s.MemberInvite__roles}
                        fieldType="radio"
                        label={t('form.admin.label_short', 'Admin')}
                        description={t('form.admin.tip', 'Admin users can create activities and manage workers')}
                      >
                        <input
                          type="radio"
                          name="membersRole"
                          checked={!!values.roles.includes(field.value)}
                          onChange={() =>
                            mixpanel.trackWithAction(
                              () => checkRole(field.name, field.value),
                              mixpanelEvents.formFields.makeAdmin(getWorkerMixpanelLabel(!!projectId)),
                            )
                          }
                        />
                      </CtrlCheck>
                    )}
                  </Field>
                  <Field name="roles" type="radio" value="foreman">
                    {({field}: FieldProps) => (
                      <CtrlCheck
                        {...field}
                        className={s.MemberInvite__roles}
                        fieldType="radio"
                        label={t('form.foreman.label', 'Make Foreman')}
                        description={t(
                          'form.foreman.tip',
                          'Foreman users can add new workers by scanning QR code using the app, and create/edit activities',
                        )}
                      >
                        <input
                          type="radio"
                          name="membersRole"
                          checked={!!values.roles.includes(field.value)}
                          onChange={() =>
                            mixpanel.trackWithAction(
                              () => checkRole(field.name, field.value),
                              mixpanelEvents.formFields.makeForeman(getWorkerMixpanelLabel(!!projectId)),
                            )
                          }
                        />
                      </CtrlCheck>
                    )}
                  </Field>
                  <Field name="roles" type="radio" value="worker">
                    {({field}: FieldProps) => (
                      <CtrlCheck
                        {...field}
                        className={s.MemberInvite__roles_last}
                        fieldType="radio"
                        label={t('form.worker.label', 'Make Worker')}
                        description={t(
                          'form.worker.tip',
                          'Worker users can use the app to update task status, add photos/ comments',
                        )}
                      >
                        <input
                          type="radio"
                          name="membersRole"
                          checked={!!values.roles.includes(field.value)}
                          onChange={() =>
                            mixpanel.trackWithAction(
                              () => checkRole(field.name, field.value),
                              mixpanelEvents.formFields.makeForeman(getWorkerMixpanelLabel(!!projectId)),
                            )
                          }
                        />
                      </CtrlCheck>
                    )}
                  </Field>
                </CtrlCheckGroup>
              </div>
              <div className={s.MemberInvite__formPart}>
                <div className={s.MemberInvite__formItem}>
                  <CtrlCheckGroup title={t('form.invited.title', 'Invited By')} direction="line">
                    <CtrlCheck fieldType="radio" label={t('form.invited.email', 'Email')}>
                      <Field
                        as="input"
                        className="ctrl-radios__field"
                        type="radio"
                        name="inviteBy"
                        value={InviteBy.Email}
                      />
                    </CtrlCheck>
                    <CtrlCheck
                      fieldType="radio"
                      label={t('form.invited.phone', 'Phone Number')}
                      disabled={values.roles.includes('company_admin')}
                    >
                      <Field
                        as="input"
                        className="ctrl-radios__field"
                        type="radio"
                        name="inviteBy"
                        value={InviteBy.Phone}
                        disabled={values.roles.includes('company_admin')}
                      />
                    </CtrlCheck>
                  </CtrlCheckGroup>
                </div>
                {(values.inviteBy === InviteBy.Email &&
                  getEmailFields(setFieldValue, setValues, values, errors.workerEmail)) ||
                  getPhoneFields(setFieldValue, setValues, values)}

                <div className={s.MemberInvite__formItem}>
                  <CtrlButton className={s.MemberInvite__formButton} size="s" type="submit">
                    {t('buttons.member_add', 'Add Member')}
                  </CtrlButton>
                </div>
              </div>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
});

MemberInvite.displayName = 'MemberInvite';

export default MemberInvite;
