import classNames from 'classnames';
import {ComponentProps, FC, PropsWithChildren} from 'react';
import Skeleton from 'react-loading-skeleton';

import css from './index.module.scss';

export interface SkeletonPreloaderProps extends ComponentProps<typeof Skeleton> {
  when: boolean;
  flexGrow?: boolean;
  className?: string;
}
const SkeletonPreloader: FC<PropsWithChildren<SkeletonPreloaderProps>> = ({
  when,
  flexGrow,
  className,
  children,
  ...props
}) => {
  return when ? (
    <Skeleton containerClassName={classNames(flexGrow && css['react-loading-skeleton--flex'], className)} {...props} />
  ) : (
    <>{children}</>
  );
};
export default SkeletonPreloader;
