import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ProPlan, ProSubscription} from 'shared/models/checkout';
import {CompanyModel} from 'shared/models/company';
import {CompanyWorker, CompanyWorkerRole, Worker} from 'shared/models/worker';
import {RootState} from 'store';
import {logoutUser} from 'store/actions';
import {updateCompany} from 'store/company/actions';
import {setActiveCompanyToLocalStorage} from 'store/profile/utils';

import {getCompanySubscriptions, getProPlans, loadWorkerCompanyWorkers} from './actions';

type ProfileStore = {
  worker: Worker;
  companies: CompanyModel[];
  companyWorkers: CompanyWorker[];
  isLoading: boolean;
  activeCompanyId: string;
  companyProfileOpened: boolean;
  proPlan: ProPlan;
  companySubscriptions: ProSubscription[];
  createNewCompany: boolean;
  showCongratsPopup: boolean;
  showNewProjectLookaheadTutorial: boolean;
  myProfileOpened: boolean;
  showAccessDeniedPopup: boolean;
};

const initialState: ProfileStore = {
  worker: null,
  companies: null,
  companyWorkers: null,
  proPlan: null,
  companySubscriptions: null,
  isLoading: false,
  activeCompanyId: undefined,
  companyProfileOpened: false,
  createNewCompany: false,
  myProfileOpened: false,
  showCongratsPopup: false,
  showNewProjectLookaheadTutorial: false,
  showAccessDeniedPopup: false,
};

const slice = createSlice({
  initialState,
  name: 'profile',
  reducers: {
    setWorker(state, action: PayloadAction<Worker>) {
      state.worker = action.payload;
    },
    setWorkerCompanies(state, {payload}: PayloadAction<CompanyModel[]>) {
      state.companies = payload;
    },
    updateCompany(state, {payload}: PayloadAction<CompanyModel>) {
      const index = state.companies?.findIndex((company) => company.id === payload.id);
      if (index !== -1) {
        state.companies[index] = {...state.companies[index], ...payload};
      }
    },
    setWorkerIsLoading(state, {payload}: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setWorkerResumeIsSaving(state, {payload}: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setActiveCompany(state, {payload}: PayloadAction<string>) {
      state.activeCompanyId = payload;
      setActiveCompanyToLocalStorage(state.worker.id, payload);
    },
    reset() {
      return initialState;
    },
    setCompanyProfileOpened(state, action: PayloadAction<boolean>) {
      state.companyProfileOpened = action.payload;
    },
    setMyProfileOpened(state, {payload}: PayloadAction<boolean>) {
      state.myProfileOpened = payload;
    },
    cancelSubscription(state, {payload}: PayloadAction<string>) {
      state.companySubscriptions = state.companySubscriptions.filter((sub) => sub.id !== payload);
    },
    updateSubscription(state, {payload}: PayloadAction<ProSubscription[]>) {
      const companySubscriptions = [...state.companySubscriptions];
      for (const subscription of payload) {
        const existIndex = companySubscriptions.findIndex((sub) => sub.id === subscription.id);
        if (existIndex === -1) {
          companySubscriptions.push(subscription);
        } else {
          companySubscriptions.splice(existIndex, 1, Object.assign(companySubscriptions[existIndex], subscription));
        }
      }
      state.companySubscriptions = companySubscriptions;
    },
    setCreateNewCompany(state, {payload}: PayloadAction<boolean>) {
      state.createNewCompany = payload;
      if (payload === true) {
        state.companyProfileOpened = true;
      }
    },
    showCongratsPopup(state, {payload = true}: PayloadAction<boolean>) {
      state.showCongratsPopup = payload;
    },
    showTutorialPopup(state, {payload = true}: PayloadAction<boolean>) {
      state.showNewProjectLookaheadTutorial = payload;
    },
    showAccessDeniedPopup(state, {payload = true}: PayloadAction<boolean>) {
      state.showAccessDeniedPopup = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadWorkerCompanyWorkers.fulfilled, (state, {payload}) => {
      state.companyWorkers = payload;
    });
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      if (state.companies && Array.isArray(state.companies)) {
        const index = state.companies.findIndex((company) => company.id === action.payload.id);
        if (index !== -1) {
          state.companies[index] = {
            ...action.payload,
            currentSeatCount: state.companies[index].currentSeatCount,
          };
        }
      }
    });
    builder.addCase(getProPlans.fulfilled, (state, {payload}) => {
      state.proPlan = payload;
    });
    builder.addCase(getCompanySubscriptions.fulfilled, (state, {payload}) => {
      state.companySubscriptions = payload;
    });
    builder.addCase(logoutUser, () => {
      return initialState;
    });
  },
});

export default slice.reducer;
export const profileActions = slice.actions;

export const hasAdminRoleSelector = createSelector(
  (state: RootState) => state.profile.companyWorkers,
  (workers) => workers?.some((worker) => worker.roles.includes('company_admin')),
);

export const companyWorkersLoaded = createSelector(
  (state: RootState) => state.profile.companyWorkers,
  (state: RootState) => state.profile.activeCompanyId,
  (companyWorkers, activeCompanyId) => {
    if (!activeCompanyId) {
      return false;
    }
    return !!companyWorkers?.filter((cw) => cw.companyId === activeCompanyId).length;
  },
);

export const activeCompanyWorkerRolesSelector = createSelector(
  (state: RootState) => state.profile.companyWorkers,
  (state: RootState) => state.profile.activeCompanyId,
  (workers, activeCompanyId) => {
    if (!workers) return [];
    const activeCompanyWorkers = workers.filter((worker) => worker.companyId === activeCompanyId);
    return Array.from(
      new Set(activeCompanyWorkers.reduce((roles, worker) => roles.concat(worker.roles), [] as CompanyWorkerRole[])),
    );
  },
);

export const companyWorkersRolesSelector = createSelector(
  (state: RootState) => state.profile.companyWorkers,
  (workers) => {
    if (!workers) return [];
    return Array.from(
      new Set(workers.reduce((roles, worker) => roles.concat(worker.roles), [] as CompanyWorkerRole[])),
    );
  },
);

export const getWorkerRolesCurrentCompany = createSelector(
  (state: RootState) => state.profile.companyWorkers,
  (state: RootState) => state.profile.activeCompanyId,
  (workers, activeCompanyId) => {
    if (!workers || !activeCompanyId) return null;
    const filteredWorkers = workers.filter((w) => w.companyId === activeCompanyId);
    return Array.from(new Set(filteredWorkers.reduce((acc, cur) => acc.concat(cur.roles), [])));
  },
);

export const selectActiveCompany = createSelector(
  (state: RootState) => state.profile.companies,
  (state: RootState) => state.profile.activeCompanyId,
  (companies, activeCompanyId) => {
    return companies && activeCompanyId ? companies.find((company) => company.id === activeCompanyId) : null;
  },
);

export const selectCurrentSubscription = createSelector(
  (state: RootState) => state.profile.companySubscriptions,
  (subs) => {
    if (!subs) return null;

    const activeSubs = subs.filter((sub) => sub.status === 'active');
    if (activeSubs.length === 0) return null;
    if (activeSubs.length === 1) return activeSubs[0];

    return activeSubs[activeSubs.length - 1];
  },
);

export const selectCompanyWorkerProfileSelector = createSelector(
  (state: RootState) => state.profile.worker,
  (state: RootState) => state.profile.companyWorkers,
  (worker, companyWorkers) => {
    return companyWorkers?.find((cw) => cw.workerId === worker?.id);
  },
);

export const getActiveCompanyWorkersSelector = createSelector(
  (state: RootState) => state.profile.companyWorkers,
  (state: RootState) => state.profile.activeCompanyId,
  (workers, activeCompanyId) => workers.filter((cworker) => cworker.companyId === activeCompanyId),
);

export const getAdminProjectsSelector = createSelector(getActiveCompanyWorkersSelector, (activeWorkers) => {
  return Array.from(
    new Set(
      activeWorkers?.filter((workers) => workers.roles.includes('project_admin')).map((workers) => workers.projectId),
    ),
  );
});

export const isCompanyAdminSelector = createSelector(
  getActiveCompanyWorkersSelector,
  selectActiveCompany,
  (workers, activeCompany) =>
    workers?.some((workers) => workers.companyId === activeCompany?.id && workers.roles.includes('company_admin')),
);
