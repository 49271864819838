import cn from 'classnames';
import {FC, useState} from 'react';
import Select from 'react-select';

import {createCountryCodeStyles} from 'shared/components/CoreForm/Select/styles';
import {CountryCodeOption, DEFAULT_COUNTRY_CODES} from 'shared/constants/common';
import {useMount} from 'shared/hooks/core/useMount';

type Props = {
  name: string;
  onChange: (value: string) => void;
  initialValue?: string;
  disabled?: boolean;
  width?: number;
  className?: string;
};

const CountryCodeSelector: FC<Props> = ({name, onChange, initialValue, disabled, width, className}) => {
  const [selected, setSelected] = useState<CountryCodeOption>(DEFAULT_COUNTRY_CODES[0]);

  useMount(() => {
    if (initialValue) {
      const option = DEFAULT_COUNTRY_CODES.find((countryCode) => countryCode.value === initialValue);
      if (option) {
        setSelected(option);
      }
    }
  });

  const handleChange = (option: CountryCodeOption | null) => {
    if (option) {
      setSelected(option);
      onChange(option.value);
    }
  };

  return (
    <Select<CountryCodeOption, false>
      isSearchable={false}
      options={DEFAULT_COUNTRY_CODES}
      name={name}
      className={cn('react-select react-select--phone-codes', className)}
      classNamePrefix="react-select"
      formatOptionLabel={({label, value}, {context}) =>
        context === 'menu' ? (
          <>
            <span>{value}</span> {label}
          </>
        ) : (
          <>{value}</>
        )
      }
      onChange={handleChange}
      styles={createCountryCodeStyles(width)}
      value={selected}
      isDisabled={disabled}
      menuPortalTarget={width ? document.body : null}
    />
  );
};

export default CountryCodeSelector;
