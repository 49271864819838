import {CellContext} from '@tanstack/react-table';
import {ReactNode} from 'react';

import {CompanyWorker} from 'shared/models/worker';

type CenteredColumnProps = {
  nbWorkers: number;
  onClick: () => void;
};

export const CenteredContainer: React.FC<React.HTMLProps<HTMLDivElement>> = ({children, ...props}) => {
  const centerStyles = {display: 'flex', alignItems: 'center', height: '100%'};
  return (
    <div style={centerStyles} {...props}>
      {children}
    </div>
  );
};

export const CenteredColumn = ({getValue}: CellContext<CompanyWorker, string | number | ReactNode>) => {
  const value = getValue();
  return <CenteredContainer>{value}</CenteredContainer>;
};

export const CenteredColumnSubcontractors = ({getValue}: CellContext<CompanyWorker, CenteredColumnProps>) => {
  const {nbWorkers = 0, onClick} = getValue();
  return <CenteredContainer onClick={onClick}>{nbWorkers}</CenteredContainer>;
};
