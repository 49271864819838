import {Formik, Field} from 'formik';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';

import CoreFormControl from '../CoreForm/CoreFormControl';
import {CoreSelect} from '../CoreForm/Select/Select';
import {CoreOptionType} from '../CoreForm/Select/types';

type TasksExportFormProps = {
  onSubmit: (formatDate: string) => void;
};

const dateFormatOptions: CoreOptionType[] = [
  {value: 'MM/DD/YYYY', label: 'MM/DD/YYYY'},
  {value: 'DD/MM/YYYY', label: 'DD/MM/YYYY'},
  {value: 'YYYY-MM-DD', label: 'YYYY-MM-DD'},
];

type FormValues = {dateFormat: string};

const getDefaultValues = () =>
  ({
    dateFormat: dateFormatOptions[0].value,
  } as FormValues);

const TasksExportForm: FC<TasksExportFormProps> = ({onSubmit}) => {
  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.tasks.export;
  const {t} = useTranslation('project');
  return (
    <Formik<FormValues> initialValues={getDefaultValues()} onSubmit={(values) => onSubmit(values.dateFormat)}>
      {({submitForm}) => {
        return (
          <div className="form-export-csv">
            <div className="form-export-csv__item">
              <CoreFormControl name="dateFormat">
                <Field>
                  {({field: {value, ...fieldProps}, form: {setFieldValue}}) => (
                    <CoreSelect
                      {...fieldProps}
                      value={value}
                      options={dateFormatOptions}
                      onChange={(value) =>
                        mixpanel.trackWithAction(() => setFieldValue(fieldProps.name, value), mixpanelEvents.dateFormat)
                      }
                    />
                  )}
                </Field>
              </CoreFormControl>
            </div>
            <div className="form-export-csv__item">
              <button className="ctrl-btn form-export-csv__button" type="submit" onClick={submitForm}>
                <span className="ctrl-btn__text">{t('preview.buttons.export_start', 'Start Export')}</span>
              </button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default TasksExportForm;
