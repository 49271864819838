import {TFunction} from 'i18next';
import {Ref, useCallback, useMemo, useState, KeyboardEvent} from 'react';
import Select, {SelectInstance, GroupBase, MultiValue} from 'react-select';
import CreatableSelect from 'react-select/creatable';

import {filterDuplicatedCompanyWorkers} from 'shared/helpers/worker';
import {useProjectWorkers} from 'shared/hooks/useProjectWorkers';
import {CompanyWorker} from 'shared/models/worker';

import {createProjectWorkerStyles} from '../styles';
import {SelectOption} from '../types';

import {CustomMenuList, CustomOption, CustomOptionForMulti, CustomSingleValue} from './components';

export interface ProjectWorkerSelectOption extends SelectOption<CompanyWorker> {
  label: string;
  value: CompanyWorker;
  workerData: CompanyWorker;
}

type AsyncProjectWorkerSelectProps = {
  onSelectChange?: (value: CompanyWorker[]) => void;
  placeholder: string;
  loadingPlaceholder: string;
  projectId: string;
  taskId?: string;
  t?: TFunction;
  withInviteButton?: boolean;
  className?: string;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLDivElement>) => void;
  value?: ProjectWorkerSelectOption[];
  isCreatable?: boolean;
  formatCreateLabel?: (inputValue: string) => string;
  onMenuClose?: () => void;
  menuIsOpen?: boolean;
  isValidNewOption?: (inputValue: string) => boolean;
  onCreateOption?: (inputValue: string) => void;
  isMulti?: true;
  selectRef?: Ref<SelectInstance<ProjectWorkerSelectOption, true, GroupBase<ProjectWorkerSelectOption>>>;
  [key: string]: any;
};

const AsyncProjectWorkerSelect = ({
  onSelectChange,
  placeholder,
  loadingPlaceholder,
  value,
  projectId,
  selectRef,
  withInviteButton = false,
  isCreatable = false,
  ...props
}: AsyncProjectWorkerSelectProps) => {
  const [selected, setSelected] = useState<ProjectWorkerSelectOption[]>([]);
  const {projectWorkers, isLoading} = useProjectWorkers(projectId);

  const options = useMemo(() => {
    if (projectWorkers) {
      return filterDuplicatedCompanyWorkers(projectWorkers).map((cworker) => ({
        label: cworker.workerFull.fullName || cworker.workerFull.email || cworker.workerFull.mobileNumber,
        value: cworker,
        workerData: cworker,
      }));
    }
    return [];
  }, [projectWorkers]);

  const handleChange = (newValue: MultiValue<ProjectWorkerSelectOption>) => {
    const normalizedOptions = Array.from(newValue);
    setSelected(normalizedOptions);
    onSelectChange?.(normalizedOptions.map((opt) => opt.value));
  };

  const getCustomComponents = useCallback(() => {
    const components = {
      Option: props.isMulti ? CustomOptionForMulti : CustomOption,
      SingleValue: CustomSingleValue,
    };
    if (withInviteButton) {
      Object.assign(components, {MenuList: CustomMenuList});
    }
    return components;
  }, [props.isMulti, withInviteButton]);

  const baseProps = {
    ref: selectRef,
    defaultInputValue: '',
    isSearchable: true,
    isDisabled: isLoading,
    isLoading,
    menuPlacement: 'auto' as const,
    options,
    className: 'react-select override-menu-height',
    styles: createProjectWorkerStyles(),
    classNamePrefix: 'react-select',
    onChange: handleChange,
    components: getCustomComponents(),
    isMulti: true as const,
    value: value || selected,
    ...props,
  };

  const SelectComponent = isCreatable ? CreatableSelect : Select;
  return (
    <SelectComponent<ProjectWorkerSelectOption, true>
      closeMenuOnSelect={false}
      closeMenuOnScroll={false}
      {...baseProps}
    />
  );
};

export default AsyncProjectWorkerSelect;
