import {InfiniteData, QueryKey, QueryObserverResult} from '@tanstack/react-query';
import {FormikProps} from 'formik';
import {FC, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory, useParams, useRouteMatch} from 'react-router';
import {toast} from 'react-toastify';

import {UpdateCompanyWorkerResponse} from 'api/workers';
import {WorkerCardProps} from 'modules/Worker/WorkerCard/WorkerCard';
import Button from 'shared/components/Button';
import {useConfirm} from 'shared/components/Confirmation';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import Icon from 'shared/components/Icon';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {extractAxiosError} from 'shared/helpers/axios';
import {getConfirmSaveChangesPayload} from 'shared/helpers/common';
import {getCompanyWorkerStatus} from 'shared/helpers/statuses';
import {mapOrgsIds} from 'shared/helpers/worker';
import {useUnmount} from 'shared/hooks/core/useUnmount';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompany} from 'shared/hooks/useCompany';
import {useQueryCache} from 'shared/hooks/useQueryCache/useQueryCache';
import {SlidePanel} from 'shared/layout/admin';
import {ProjectModel} from 'shared/models/project';
import {TasksResponseType} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';
import {CompanyWorker, CompanyWorkerRole, InviteBy, WorkerFormValues} from 'shared/models/worker';
import {getWorkerMixpanelLabel, needShowEmailContact} from 'shared/utils/inviteWorker';
import {useRootDispatch} from 'store';
import {profileActions} from 'store/profile';

import {useWorkerPath} from './hooks/useWorkerPath';
import {useInviteWorker, useRemoveCompanyWorker, useUpdateWorker} from './mutations';
import WorkerCard from './WorkerCard';
import WorkerForm from './WorkerForm';
import {checkErrorVisibility} from './WorkerForm/validation';
import WorkerProfile from './WorkerProfile';
import WorkerTasks from './WorkerTasks';

const apiErrorToFormMapper = {
  mobile_number: 'phoneNumber',
  worker_phone_number: 'phoneNumber',
  email: 'workerEmail',
};

export type WorkerHistoryType = {from: string; queryKey: QueryKey};

type Props = {
  worker: CompanyWorker;
  projects: ProjectModel[];
  orgsOptions: CoreOptionType[];
  refetchProfile: () => {data?: CompanyWorker} | Promise<QueryObserverResult<CompanyWorker, unknown>>;
  workerTasks: TasksResponseType;
  isFetchingTasks: boolean;
  inviteMember: (payload, queryParams) => Promise<CompanyWorker>;
  updateMember: (payload) => Promise<UpdateCompanyWorkerResponse>;
};

const WorkerDetails: FC<Props> = ({
  worker,
  projects,
  orgsOptions,
  refetchProfile,
  workerTasks,
  isFetchingTasks,
  inviteMember,
  updateMember,
}: Props) => {
  const history = useHistory<WorkerHistoryType>();
  const routes = useLocalizedRoutes();
  const match = useRouteMatch<{id: string}>([routes.projectWorker, routes.worker]);
  const dispatch = useRootDispatch();
  const company = useCompany();
  const [isEditing, setIsEditing] = useState(false);
  const [formikValues, setFormikValues] = useState<WorkerFormValues>();
  const [saving, setSaving] = useState(false);
  const formik = useRef<FormikProps<WorkerFormValues>>(null);
  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.worker;
  const {cacheHelper, queryClient} = useQueryCache();
  const {confirmAction, confirm} = useConfirm();
  const {projectId} = useParams<{projectId: string}>();
  const {workerPath, workersPath} = useWorkerPath();
  const {t} = useTranslation('worker');
  const isProjectWorkersPage = match?.path === routes.projectWorker;

  const inviteWorker = useInviteWorker(inviteMember);
  const updateWorker = useUpdateWorker();
  const removeWorker = useRemoveCompanyWorker();

  const isNewWorker = match.params.id === 'new';
  const isInvitedWorker = worker?.workercard?.status === 'invited';
  const isClosedWorker = worker?.status === 'active' && worker?.workercard?.status === TaskStatusType.closed;
  const canEditWorker = worker?.status !== 'removed' || isClosedWorker;

  useUnmount(() =>
    queryClient.resetQueries({queryKey: ['tasks', {workerId: match.params.id, companyId: company?.id}]}),
  );

  const workerCardModel: WorkerCardProps['profile'] = useMemo(() => {
    return {
      profileImage: worker?.workercard?.profilePicUrl,
      status: worker ? getCompanyWorkerStatus(worker.status, worker.workercard.status) : 'new',
      mobileNumber: formikValues?.phoneCode + formikValues?.phoneNumber,
      fullName: formikValues?.fullName,
      trade: formikValues?.trade,
      orgMappingIds: mapOrgsIds(formikValues?.orgMappingIds, orgsOptions),
      workerId: worker?.workerId || 'new',
      workerEmail: formikValues?.workerEmail,
      roles: worker?.roles,
      showEmailAsContact: formikValues?.inviteBy === InviteBy.Email || needShowEmailContact(worker),
    };
  }, [worker, formikValues]);

  const updateCompanyOrgsList = (orgs: string[]) => {
    if (!Array.isArray(orgs)) return;
    const cachedOrgsList = (queryClient.getQueryData(['orgs']) || []) as CoreOptionType[];
    if ((!cachedOrgsList.length && orgs.length) || cachedOrgsList.find((org) => !orgs.includes(org.value)))
      return queryClient.refetchQueries({
        queryKey: ['orgs'],
      });
  };

  const updateWorkerHandler = async (values: Partial<WorkerFormValues>) => {
    try {
      mixpanel.track(mixpanelEvents.saveInfo(getWorkerMixpanelLabel(!!projectId)));

      const isAdmin = values.roles.includes('company_admin');

      await updateWorker.mutateAsync({
        values,
        companyWorker: worker,
        projectId: isAdmin ? undefined : projectId,
        updateMember,
      });

      const {data: fetchedProfile} = await refetchProfile();

      /* mostly when status has changed from invited -> active */
      if (worker.workerFull.status !== fetchedProfile.workerFull.status || isProjectWorkersPage) {
        const query = cacheHelper.findRecentPagedQuery<CompanyWorker>(['workers']);

        if (query) {
          queryClient.setQueryData<InfiniteData<CompanyWorker[]>>(query.queryKey, (data) => {
            if (!data) return data;

            const preparedRoles = Array.from(
              new Set([...fetchedProfile.roles, ...values.roles]),
            ) as CompanyWorkerRole[];

            const preparedProfile = {
              ...fetchedProfile,
              workercard: {
                ...fetchedProfile.workercard,
                trade: values?.trade || fetchedProfile.workercard.trade,
              },
              roles: Array.isArray(values?.roles) ? preparedRoles : fetchedProfile.roles,
            };

            return cacheHelper.updatePagedData(data, preparedProfile);
          });
        }

        if (isProjectWorkersPage) {
          await refetchProfile();
        }
      }

      setIsEditing(false);
      toast.success(t('toast.success.update', 'Worker successfully updated'));
    } catch (error) {
      handleApiError(error);
    }
  };

  const updateCacheAfterCreating = (createdWorker: CompanyWorker, isCreatingProjectWorker: boolean): void => {
    const isAutoCreatingProjectWorker = match.params.id !== 'new' && isCreatingProjectWorker;
    const query = cacheHelper.findRecentPagedQuery<CompanyWorker>(['workers']);

    if (!query) return;

    queryClient.setQueryData<InfiniteData<CompanyWorker[]>>(query.queryKey, (oldData) => {
      if (!oldData) return oldData;

      if (isAutoCreatingProjectWorker) {
        const cachedWorker = cacheHelper.findInPagedData(oldData, (worker) => match.params.id === worker.id);

        const updatedWorker: CompanyWorker = {
          ...createdWorker,
          roles: createdWorker.roles.concat(cachedWorker?.roles || []),
        };

        const updatedPages = cacheHelper.updatePagedData(oldData, updatedWorker);

        return updatedPages ? cacheHelper.removeItemFromPagedData(updatedPages, match.params.id) : updatedPages;
      }

      return cacheHelper.updatePagedData(oldData, createdWorker);
    });
  };

  const createWorkerHandler = async (values: Partial<WorkerFormValues>, isCreatingProjectWorker?: boolean) => {
    try {
      const inviteValues = Object.assign(values, {
        projectId,
      });
      const createdWorker = await inviteWorker.mutateAsync({values: inviteValues});
      mixpanel.track(mixpanelEvents.workerSubmitInviteBtn(getWorkerMixpanelLabel(!!projectId)));
      updateCacheAfterCreating(createdWorker, isCreatingProjectWorker);
      if (!isCreatingProjectWorker) toast.success(t('toast.success.invite', 'New worker has been invited'));
      setIsEditing(false);
      dispatch(profileActions.updateCompany({...company, currentSeatCount: company.currentSeatCount + 1}));
      history.push(generatePath(workerPath, {id: createdWorker.id, projectId}));
    } catch (error) {
      handleApiError(error);
    }
  };

  async function onSubmit(values: Partial<WorkerFormValues>) {
    setSaving(true);
    if (worker && isProjectWorkersPage && !worker.projectId && !isNewWorker) {
      const preparedValues = Object.assign(values, {
        roles: values.roles.includes('company_admin') ? ['project_admin'] : values.roles,
        fullName: values?.fullName || worker.workerFull.fullName,
        workerEmail: values?.workerEmail || worker.workerFull.email,
        trade: values?.trade || worker.workerFull.trade,
        orgMappingIds: values.orgMappingIds || worker.orgMappingIds,
      });
      await createWorkerHandler(preparedValues, true);
      refetchProfile();
    } else {
      if (!worker) {
        await createWorkerHandler(values);
      } else {
        await updateWorkerHandler(values);
      }
    }
    setSaving(false);
    updateCompanyOrgsList(values.orgMappingIds);
  }

  async function handleRemoveCompanyWorker() {
    if (
      await confirm({
        description: t('remove.description', 'Are you sure you want to remove worker?'),
        cancelButton: t('buttons.cancel', 'Cancel'),
        acceptButton: t('buttons.accept', 'Remove'),
      })
    ) {
      setSaving(true);
      try {
        mixpanel.track(mixpanelEvents.removeWorker(getWorkerMixpanelLabel(!!projectId)));
        await removeWorker.mutateAsync({id: worker.id, workerId: worker.workerId});
        toast.success(t('toast.success.remove', 'Worker successfully removed'));
        history.push(generatePath(workersPath, {projectId}));
        dispatch(profileActions.updateCompany({...company, currentSeatCount: company.currentSeatCount - 1}));
      } catch (error) {
        handleApiError(error);
      } finally {
        setSaving(false);
      }
    }
  }

  function handleApiError(error: unknown) {
    const resultError = extractAxiosError(error, apiErrorToFormMapper);
    typeof resultError === 'string' ? toast.error(resultError) : formik.current.setErrors(resultError);
  }

  function back() {
    return history.location.state?.from ? history.goBack() : history.push(generatePath(workersPath, {projectId}));
  }

  function getHeader() {
    if (isNewWorker) {
      return (
        <>
          <button
            className="y-ctrl-btn slide-panel__button-back"
            type="button"
            onClick={() =>
              askToSaveBeforeLeave(() =>
                mixpanel.trackWithAction(back, mixpanelEvents.backToWorkers(getWorkerMixpanelLabel(!!projectId))),
              )
            }
          >
            <Icon size={24} colorFill name="arrow_backward" />
            <span className="y-ctrl-btn__text">{t('buttons.back_workers', 'Back to Workers')}</span>
          </button>
          <Button
            onClick={() => {
              checkErrorVisibility(formik.current);
              formik.current.handleSubmit();
            }}
            data-cy="editing_add_worker_bt"
            className="slide-panel__button-main"
            icon={<Icon colorFill size={24} name="checkmark-outlined" />}
          >
            {t('buttons.add', 'Add')}
          </Button>
        </>
      );
    }

    if (isEditing) {
      return (
        <>
          <button
            className="y-ctrl-btn slide-panel__button-back"
            type="button"
            onClick={() =>
              askToSaveBeforeLeave(() =>
                mixpanel.trackWithAction(
                  () => setIsEditing(false),
                  mixpanelEvents.backToProfile(getWorkerMixpanelLabel(!!projectId)),
                ),
              )
            }
          >
            <Icon size={24} colorFill name="arrow_backward" className="y-ctrl-btn__icon" />
            <span className="y-ctrl-btn__text">{t('buttons.back_profile', 'Back to Profile')}</span>
          </button>

          <Button
            disabled={saving}
            data-cy="editing_save_worker_bt"
            onClick={() => formik.current.handleSubmit()}
            className="slide-panel__button-main"
            icon={<Icon colorFill size={24} name="checkmark-outlined" />}
          >
            {t('buttons.save', 'Save Info')}
          </Button>
        </>
      );
    }
    return (
      <>
        <h2 className="slide-panel__title">Worker&apos;s Profile</h2>
        {canEditWorker && worker && (
          <Button
            disabled={saving}
            data-cy="edit_worker_bt"
            onClick={() =>
              mixpanel.trackWithAction(
                () => setIsEditing(true),
                mixpanelEvents.editProfile(getWorkerMixpanelLabel(!!projectId)),
              )
            }
            className="ctrl-btn--view-border slide-panel__button-main"
            icon={<Icon colorFill size={24} name="edit" />}
          >
            {t('buttons.edit', 'Edit Profile')}
          </Button>
        )}
      </>
    );
  }

  function askToSaveBeforeLeave(onReject: () => void) {
    return confirmAction(
      getConfirmSaveChangesPayload(t),
      formik.current?.dirty,
      formik.current?.handleSubmit,
      onReject,
    );
  }

  if (worker && isInvitedWorker && !worker.workerFull) {
    return (
      <SlidePanel
        onClose={back}
        content={
          <>
            <SlidePanel.Header>{t('error.title', 'Ooops')}</SlidePanel.Header>
            <SlidePanel.Body>
              <div>{t('error.description', 'Server did not return enough data to properly show worker info')}</div>
            </SlidePanel.Body>
          </>
        }
      />
    );
  }

  return (
    <SlidePanel
      onClose={() =>
        askToSaveBeforeLeave(() =>
          mixpanel.trackWithAction(back, mixpanelEvents.exit(getWorkerMixpanelLabel(!!projectId)), {}, !!isNewWorker),
        )
      }
      content={
        <>
          <SlidePanel.Header>{getHeader()}</SlidePanel.Header>
          <SlidePanel.Body>
            {isEditing || isNewWorker ? (
              <WorkerForm
                profile={worker}
                onSubmit={onSubmit}
                ref={formik}
                onValuesChange={setFormikValues}
                onRemove={handleRemoveCompanyWorker}
                options={orgsOptions}
              />
            ) : (
              <WorkerProfile profile={worker} projects={projects} options={orgsOptions} />
            )}
          </SlidePanel.Body>
        </>
      }
      aside={
        isEditing || isNewWorker ? (
          <WorkerCard projects={projects} profile={workerCardModel} />
        ) : (
          <WorkerTasks tasks={workerTasks} isFetching={isFetchingTasks} />
        )
      }
      disablePaddings
    />
  );
};
export default WorkerDetails;
