import {FieldArray, useFormikContext} from 'formik';
import {FieldInputProps} from 'formik/dist/types';
import {FC, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

import {parseDateWith2DigitYear} from 'modules/Tasks/components/Gantt/utils/date';
import CoreNativeDatePicker from 'shared/components/CoreForm/CoreNativeDatePicker';
import {CoreSelect} from 'shared/components/CoreForm/Select/Select';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import SkeletonPreloader from 'shared/components/SkeletonPreloader';
import {toShortIso} from 'shared/helpers/dates';
import {getProjectCustomField} from 'shared/helpers/project';
import {useProject} from 'shared/hooks/useProject';
import {ProjectCustomFieldDef, ProjectCustomFieldType} from 'shared/models/project';

import {FormValues} from '../../utils/types';
import s from '../ActivityForm/ActivityForm.module.scss';

type Props = {
  projectId: string;
  loading: boolean;
  isReadOnly: boolean;
};

export const CustomFieldSection: FC<Props> = ({projectId, loading, isReadOnly}: Props) => {
  const {project} = useProject(projectId);
  const {t} = useTranslation(['task']);
  const {values, setFieldValue} = useFormikContext<FormValues>();

  const getCustomFieldValue = (internalFieldName: string): string => {
    return values.customFields.find((field) => field.internalFieldName === internalFieldName)?.value ?? '';
  };

  const onChangeCustomField = (internalFieldName: string, value: string) => {
    const fieldIndex = values.customFields.findIndex((f) => f.internalFieldName === internalFieldName);
    if (fieldIndex !== -1) {
      setFieldValue(`customFields[${fieldIndex}].value`, value);
    } else {
      setFieldValue('customFields', values.customFields.concat([{internalFieldName, value}]));
    }
  };

  const getInputField = (projectField: ProjectCustomFieldDef, props: FieldInputProps<FormValues>) => {
    const {internalFieldName, fieldType} = projectField;
    const isNumberField = fieldType === ProjectCustomFieldType.number;
    const commonProps = {
      value: getCustomFieldValue(internalFieldName),
      onChange: (e) => onChangeCustomField(internalFieldName, e.target.value),
      className: 'ctrl-textfield',
      type: isNumberField ? 'number' : 'text',
      disabled: isReadOnly,
    };
    if (isNumberField) {
      Object.assign(commonProps, {
        min: 0,
        step: 1,
      });
    }

    return <input {...props} {...commonProps} />;
  };

  const getSelectField = (projectField: ProjectCustomFieldDef) => {
    const {fieldType, fieldData, internalFieldName} = projectField;
    const isMultiselect = fieldType === ProjectCustomFieldType.multiselect;

    const parsedData = fieldData ? JSON.parse(fieldData) : [];
    const options: CoreOptionType[] = parsedData?.map((value: string) => ({
      value,
      label: value,
    }));

    let parsedValue: string | string[] = [];
    const rawValue = getCustomFieldValue(internalFieldName);
    if (rawValue) {
      try {
        parsedValue = JSON.parse(rawValue);
      } catch (e) {
        console.error('Error parsing value:', e);
      }
    }

    const commonProps = {
      isDisabled: isReadOnly,
      options,
      className: 'react-select',
      menuPlacement: 'auto' as const,
    };

    if (isMultiselect) {
      return (
        <CoreSelect
          {...commonProps}
          isMulti
          value={Array.isArray(parsedValue) ? parsedValue : []}
          onChange={(values) => {
            // react-select internals changed on upgrade
            // extract just the values from the selected options
            const selectedValues = values.map((option) => option.value);
            onChangeCustomField(internalFieldName, JSON.stringify(selectedValues));
          }}
        />
      );
    }

    return (
      <CoreSelect
        {...commonProps}
        value={Array.isArray(parsedValue) ? parsedValue[0] || '' : parsedValue}
        onChange={(value) => {
          onChangeCustomField(internalFieldName, JSON.stringify([value]));
        }}
      />
    );
  };

  const getDateField = (projectField: ProjectCustomFieldDef, props: FieldInputProps<FormValues>) => {
    const {internalFieldName} = projectField;
    return (
      <CoreNativeDatePicker
        {...props}
        disabled={isReadOnly}
        minDate={new Date('1925-01-01')}
        value={getCustomFieldValue(internalFieldName)}
        onChange={({target}) => {
          onChangeCustomField(internalFieldName, toShortIso(parseDateWith2DigitYear(target.value)));
        }}
      />
    );
  };

  const getCustomFieldComponent = (fieldName: string, props: FieldInputProps<FormValues>): ReactNode => {
    const projectField = getProjectCustomField(project, fieldName);
    const {fieldType} = projectField;

    switch (fieldType) {
      case ProjectCustomFieldType.string:
      case ProjectCustomFieldType.number:
        return getInputField(projectField, props);
      case ProjectCustomFieldType.select:
      case ProjectCustomFieldType.multiselect:
        return getSelectField(projectField);
      case ProjectCustomFieldType.date:
        return getDateField(projectField, props);
    }
  };

  return (
    <div className={s.activityForm__part}>
      <div className={s.activityForm__partHeader}>
        <h3 className={s.activityForm__partTitle}>{t('task:task_form.parts.custom_fields.title', 'Custom Fields')}</h3>
      </div>
      <div className={s.activityForm__partBody}>
        <FieldArray
          name="customFields"
          render={() => (
            <>
              {project.customFieldDef.map(({internalFieldName, fieldName}, i) => (
                <div className={s.activityForm__partItem} key={internalFieldName}>
                  <FormControl.Formik name={`customFields.${i}.internalFieldName`} label={fieldName}>
                    {({field: fieldProps}) => (
                      <SkeletonPreloader when={loading}>
                        {getCustomFieldComponent(internalFieldName, fieldProps)}
                      </SkeletonPreloader>
                    )}
                  </FormControl.Formik>
                </div>
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};
