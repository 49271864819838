import {type StylesConfig, type GroupBase, type CSSObjectWithLabel} from 'react-select';

import {TaskOptionType} from 'modules/Tasks/components/SidebarPanel/components/TaskAsyncSelect';
import {BulkOption} from 'modules/Tasks/components/TasksActionsBar/bulkPopups/BulkWatcher/BulkWatcher';
import {CountryCodeOption} from 'shared/constants/common';

import {ProjectWorkerSelectOption} from './AsyncProjectWorkerSelect/AsyncProjectWorkerSelect';
import type {SelectOption} from './types';

function createGenericStyles<Option extends SelectOption>(
  overrides: Partial<StylesConfig<Option, boolean, GroupBase<Option>>> = {},
): StylesConfig<Option, boolean, GroupBase<Option>> {
  return {
    clearIndicator: () => ({cursor: 'pointer'}),
    container: () => ({}),
    control: () => ({}),
    dropdownIndicator: () => ({cursor: 'pointer'}),
    group: () => ({}),
    groupHeading: () => ({}),
    indicatorsContainer: () => ({}),
    indicatorSeparator: () => ({}),
    input: () => ({}),
    loadingIndicator: () => ({}),
    loadingMessage: () => ({}),
    menu: (base: CSSObjectWithLabel) => ({
      ...base,
      top: 'unset !important',
    }),
    menuList: () => ({}),
    menuPortal: (base: CSSObjectWithLabel) => {
      const isDropDown = document.body.querySelector('[data-popup-role="confirmation"]');
      const dropDownZIndex = 42002;
      const zIndex = 9999;
      return {
        ...base,
        zIndex: (isDropDown ? dropDownZIndex : zIndex) ?? base.zIndex,
      };
    },
    multiValue: () => ({}),
    multiValueLabel: () => ({}),
    multiValueRemove: () => ({}),
    noOptionsMessage: () => ({}),
    option: () => ({}),
    placeholder: () => ({}),
    singleValue: () => ({}),
    valueContainer: () => ({}),
    ...overrides,
  };
}

export const createCountryCodeStyles = (
  width?: number,
): StylesConfig<CountryCodeOption, false, GroupBase<CountryCodeOption>> =>
  createGenericStyles<CountryCodeOption>({
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      width: width,
    }),
  });

export const taskSelectStyles = createGenericStyles<TaskOptionType>();
export const bulkWatcherStyles = createGenericStyles<BulkOption>();

export const createProjectWorkerStyles = (): StylesConfig<
  ProjectWorkerSelectOption,
  true,
  GroupBase<ProjectWorkerSelectOption>
> => {
  return {
    container: (base) => ({
      ...base,
      '&': {
        '& .react-select__input': {
          lineHeight: '32px',
        },
        '& .react-select__option': {
          paddingTop: '4px',
          paddingBottom: '4px',
          minHeight: '36px',
          display: 'flex',
          overflow: 'hidden',
          alignItems: 'center',
        },
        '& .worker-accepted__info': {
          alignItems: 'center',
          display: 'flex',
          gap: '8px',
        },
        '& .worker-accepted__info-part--avatar': {
          width: '24px',
          height: '24px',
          flexShrink: 0,
        },
        '& .worker-accepted__info-part--copy': {
          flex: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        '& .react-select__menu-list--is-multi': {
          display: 'block',
          padding: 0,
          '& .react-select__option': {
            WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
            boxSizing: 'border-box',
            color: 'inherit',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            letterSpacing: '-0.5px',
            lineHeight: '20px',
            outline: 'none',
            padding: '0 11px',
            WebkitUserSelect: 'none',
            userSelect: 'none',
            '&:last-of-type': {
              border: '1px solid transparent',
            },
            '&--is-focused': {
              backgroundColor: '#235dff',
              color: '#fff',
            },
          },
        },
      },
    }),
    menu: (base) => ({
      ...base,
      top: 'unset !important',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    clearIndicator: (base) => ({
      ...base,
      cursor: 'pointer',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      cursor: 'pointer',
    }),
  };
};

export type SelectStyles<Option extends SelectOption, IsMulti extends boolean = false> = StylesConfig<
  Option,
  IsMulti,
  GroupBase<Option>
>;

export {createGenericStyles};
