import {injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import {RefObject} from 'react';

type PrivateProps = 'dailiesPrintNodeRef';
export type UIStoreType = Pick<UIStore, keyof Omit<UIStore, PrivateProps>>;

@injectable()
export class UIStore implements UIStoreType {
  private dailiesPrintNodeRef: RefObject<HTMLDivElement> | null = null;
  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setDailiesPrintNodeRef(ref: RefObject<HTMLDivElement>) {
    this.dailiesPrintNodeRef = ref;
  }

  get getDailiesPrintNodeRef() {
    return this.dailiesPrintNodeRef;
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}
