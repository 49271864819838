import {createEntityAdapter, createSelector, createSlice, PayloadAction, Update} from '@reduxjs/toolkit';

import {ProjectModel} from 'shared/models/project';
import {RootState} from 'store';
import {logoutUser} from 'store/actions';

import {
  createProject,
  deleteProject,
  getAllProjects,
  getAllProjectsForWorker,
  getProject,
  updateCurrentProject,
  updateProject,
} from './actions';

const projectsAdapter = createEntityAdapter<ProjectModel>();

const initialState = projectsAdapter.getInitialState({
  loaded: false as boolean,
  currentProject: null as ProjectModel,
  responsibleParties: [] as string[],
  userProjects: [] as string[], // New field to store user-specific project IDs
  userProjectsLoaded: false as boolean, // New field to track if user projects have been loaded
});

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<ProjectModel>) => {
      state.currentProject = action.payload;
    },
    updateProject: (state, action: PayloadAction<Update<ProjectModel, string>>) => {
      projectsAdapter.updateOne(state, action.payload);
    },
    remove: projectsAdapter.removeOne,
    addResponsibleParties: (state, {payload}: PayloadAction<{projectId: string; values: string | string[]}>) => {
      state.entities[payload.projectId].responsibleParties = Array.from(
        new Set(state.entities[payload.projectId].responsibleParties.concat(payload.values)),
      );
    },
    setLoaded: (state, {payload}: PayloadAction<boolean>) => {
      state.loaded = payload;
    },
    updateCustomColumn: (
      state,
      {payload}: PayloadAction<{projectId: string; internalFieldName: string; fieldData: string; fieldName: string}>,
    ) => {
      const {projectId, internalFieldName, fieldData, fieldName} = payload;
      const newCustomFieldDef = state.entities[projectId].customFieldDef.map((field) =>
        field.internalFieldName === internalFieldName ? {...field, fieldData, fieldName} : field,
      );
      state.entities[projectId] = {
        ...state.entities[projectId],
        customFieldDef: newCustomFieldDef,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProject.fulfilled, (state, action) => {
      projectsAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(getAllProjects.fulfilled, (state, action) => {
      projectsAdapter.setAll(state, action.payload);
      if (!state.loaded) {
        state.loaded = true;
      }
    });
    builder.addCase(updateCurrentProject, (state, action) => {
      if (state.entities[action.payload.id]) {
        projectsAdapter.upsertOne(state, {...state.entities[action.payload.id], baselines: action.payload.baselines});
      }
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      if (state.entities[action.payload.id]) {
        projectsAdapter.upsertOne(state, action.payload);
      }
      state.currentProject =
        state.currentProject?.id === action.payload.id
          ? Object.assign(state.currentProject, action.payload)
          : action.payload;
    });
    builder.addCase(createProject.fulfilled, (state, action) => {
      projectsAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      projectsAdapter.removeOne(state, action.meta.arg);
    });
    builder.addCase(logoutUser, () => initialState);
    builder.addCase(getAllProjectsForWorker.pending, (state) => {
      state.userProjectsLoaded = false;
    });
    builder.addCase(getAllProjectsForWorker.fulfilled, (state, action) => {
      const userProjectIds = action.payload.map((project: ProjectModel) => project.id);
      state.userProjects = userProjectIds;
      projectsAdapter.upsertMany(state, action.payload);
      state.userProjectsLoaded = true;
    });
    builder.addCase(getAllProjectsForWorker.rejected, (state) => {
      state.userProjectsLoaded = false;
    });
  },
});

export default projectsSlice.reducer;
export const projectActions = projectsSlice.actions;
export const {
  selectById: selectProjectById,
  selectEntities: selectAllProjectEntities,
  selectAll: selectAllProjects,
} = projectsAdapter.getSelectors<RootState>((state) => state.projects);

export const selectProjectOptions = createSelector(selectAllProjects, (projects) =>
  projects.map((project) => ({label: project.name, value: project.id})),
);

export const selectUserProjects = createSelector(
  [selectAllProjects, (state: RootState) => state.projects.userProjects],
  (allProjects, userProjectIds) => allProjects.filter((project) => userProjectIds.includes(project.id)),
);

export const selectUserProjectsLoaded = (state: RootState) => state.projects.userProjectsLoaded;
