import {Cell, flexRender} from '@tanstack/react-table';
import cn from 'classnames';
import {JSX} from 'react';
import Skeleton from 'react-loading-skeleton';

import s from 'shared/components/Table/Table.module.scss';

type RecordableData = Record<string, unknown>;

// Define custom meta type for columns
interface ColumnMeta {
  className?: string;
}

type TableColumnProps<D extends RecordableData> = {
  cell: Cell<D, unknown>;
  columnIndex: number;
  renderProps: Record<string, unknown>;
  className?: string;
  columnId?: string;
};

const DEFAULT_COLUMN_PADDING = 8;

export const TableColumn = <D extends RecordableData>({
  cell,
  renderProps,
  columnIndex,
  className,
  columnId,
}: TableColumnProps<D>): JSX.Element => {
  const isFirstColumn = columnIndex === 0;
  const columnMeta = cell.column.columnDef.meta as ColumnMeta;

  function calculateColumnPadding() {
    if (!isFirstColumn) return undefined;
    return DEFAULT_COLUMN_PADDING;
  }

  return (
    <div
      className={cn(s.tableWorkers__cell, s[`tableWorkers__cell_${columnId}`], className, columnMeta?.className)}
      style={{
        width: cell.column.getSize(),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
        paddingLeft: calculateColumnPadding(),
      }}
    >
      {renderProps?.loading ? (
        <Skeleton height={42} />
      ) : (
        flexRender(cell.column.columnDef.cell, {
          ...cell.getContext(),
          ...renderProps,
        })
      )}
    </div>
  );
};
