import classNames from 'classnames';
import {useCallback, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {useQueryClient} from '@tanstack/react-query';

import Icon from 'shared/components/Icon';
import {useOutsideClick} from 'shared/hooks/core/useOusideClick';
import {useCompany} from 'shared/hooks/useCompany';
import {CompanyModel} from 'shared/models/company';
import {useRootDispatch, useRootSelector} from 'store';
import {profileActions} from 'store/profile';

import css from './workspaceSwitcher.module.scss';

const WorkspaceSwitcher = ({menuOpened, onOpenMenu}: {menuOpened: boolean; onOpenMenu: (open: boolean) => void}) => {
  const companies = useRootSelector((state) => state.profile.companies);
  const sorted = useMemo(
    () => (companies ? [...companies]?.sort((a, b) => a.companyName.localeCompare(b.companyName)) : []),
    [companies],
  );
  const activeCompany = useCompany();
  const dispatch = useRootDispatch();
  const dropdown = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const {t} = useTranslation('left_menu');

  const onOutsideClick = useCallback(() => {
    onOpenMenu(false);
  }, [onOpenMenu]);

  useOutsideClick({ref: dropdown, callback: onOutsideClick});

  const onWorkspaceSelect = useCallback(
    (company: CompanyModel) => {
      queryClient.clear();
      dispatch(profileActions.setActiveCompany(company.id));
      onOpenMenu(false);
    },
    [dispatch, queryClient],
  );

  return (
    <div className="workspace-switch header__workspace">
      {activeCompany ? (
        <>
          <button
            className="workspace-switch__button workspace-switch__button--toggle"
            data-cy="workspace-switch-bt"
            type="button"
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={() => {
              onOpenMenu(!menuOpened);
            }}
          >
            <span className="workspace-switch__button-abbr">{activeCompany.companyName.slice(0, 3).toUpperCase()}</span>
            <span className="workspace-switch__button-name">{activeCompany.companyName}</span>
            <Icon colorFill name="chevron-bottom" className="workspace-switch__button-icon" size={24} />
          </button>
          <div className="workspace-switch__dropdown" ref={dropdown} style={{display: menuOpened ? 'block' : 'none'}}>
            <div className="workspace-switch__title">{t('switch.title', 'Switch Workspace')}</div>

            <ul className={classNames('workspace-switch__list', css.workspaceSwitch__list)}>
              {sorted?.map((company) => (
                <li key={company.id}>
                  <button
                    data-cy-workspace-id={company.id}
                    className={`workspace-switch__button ${company.id === activeCompany.id ? 'is-active' : ''}`}
                    disabled={company.id === activeCompany.id}
                    onClick={() => onWorkspaceSelect(company)}
                    type="button"
                  >
                    <span className="workspace-switch__button-abbr">
                      {company.companyName.slice(0, 2).toUpperCase()}
                    </span>
                    <span className="workspace-switch__button-name">{company.companyName}</span>
                    <Icon
                      colorFill
                      name={company.id === activeCompany.id ? 'checkmark-outlined' : 'arrow-right'}
                      className="workspace-switch__button-icon"
                      size={24}
                    />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : (
        <Skeleton width={60} height={30} />
      )}
    </div>
  );
};
export default WorkspaceSwitcher;
