import {useQuery} from '@tanstack/react-query';

import ProjectsApi from 'api/projects';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';

export function useTaskTypeOptions(projectId: string) {
  return useQuery({
    queryKey: QUERY_CACHE_KEYS.taskTypes(projectId),

    queryFn: async () => {
      const options: CoreOptionType[] = [];
      const res = await ProjectsApi.getProjectTaskTypes(projectId);
      res?.forEach((label) => {
        if (label.length) options.push({value: label, label: label});
      });
      return options;
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  });
}
