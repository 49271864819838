import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router';

import FeedbackService from 'api/feedback';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';

import {groupGeneralComments} from './utils/groupGeneralComments';

export function useDailiesGeneralComments() {
  const {projectId} = useParams<{projectId: string}>();
  const {queryParams} = useFilterContext();
  const query = useQuery({
    queryKey: [QUERY_CACHE_KEYS.dailiesGeneralComments, queryParams.schedEndFirst],

    queryFn: () => FeedbackService.getAllFeedBackForProject(projectId, queryParams.schedEndFirst),
  });

  if (query.error) {
    Sentry.captureException(query.error);
  }

  if (query.data?.length) {
    const response = query.data.filter(
      (res) => !res.taskId && dayjs(res.date).isSame(dayjs(queryParams.schedEndFirst), 'day'),
    );
    const data = groupGeneralComments(response);
    return {...query, data};
  }
  return {...query, data: []};
}
