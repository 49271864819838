import * as Sentry from '@sentry/react';

import {routerHistory} from 'routerHistory';
import env from 'shared/constants/env';

export function initSentry() {
  if (env.reactAppEnv !== 'local') {
    Sentry.init({
      dsn: window.Cypress ? null : process.env.REACT_APP_SENTRY_DSN,
      replaysSessionSampleRate: process.env.SENTRY_ORG !== 'bycore' ? 1 : 0.1,
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: [
        '/^ResizeObserver loop limit exceeded$/',
        '/^Non-Error promise rejection captured with value: Timeout$/',
      ],
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.reactRouterV5BrowserTracingIntegration({history: routerHistory}),
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      tracesSampleRate: 0.2,
      release: process.env.REACT_APP_SENTRY_RELEASE,
    });
  }
}
