import * as Sentry from '@sentry/browser';
import {ConfirmationResult} from 'firebase/auth';
import {Field, FieldProps, useFormikContext} from 'formik';
import {ChangeEvent, FC, useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IMaskInput} from 'react-imask';
import {toast} from 'react-toastify';

import {SupportedLoginTypes} from 'api/worker';
import {InviteSignUpValues} from 'modules/InviteSignup/utils';
import FirebaseService from 'services/Firebase/Firebase';
import Button from 'shared/components/Button';
import FormControl from 'shared/components/CoreForm/FormControl';
import {usePasswordVisibility} from 'shared/components/CoreForm/hooks/usePasswordVisibility';
import PhoneControl from 'shared/components/CoreForm/PhoneControl';
import RadiosControl from 'shared/components/CoreForm/Radios/Radios';
import Loader from 'shared/components/Loader';
import {getHumanizedFbError} from 'shared/constants/firebase';
import useCountDown from 'shared/helpers/hooks/useCountDown';
import {useRecaptcha} from 'shared/helpers/hooks/useRecaptcha';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';

type Props = {
  isAdmin?: boolean;
  loginType: SupportedLoginTypes;
  initialLoginType: SupportedLoginTypes | null;
  onLoginTypeChanged: (SupportedLoginTypes) => void;
  onCodeSent: (result: ConfirmationResult) => void;
};

const RECAPTCHA_CONTAINER_ID = 'onboarding-recaptcha-container';

const InviteSignupForm: FC<Props> = ({isAdmin, loginType, initialLoginType, onCodeSent, onLoginTypeChanged}) => {
  const {
    values: formValues,
    errors,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = useFormikContext<InviteSignUpValues>();
  const {t} = useTranslation(['invite_signup', 'errors']);

  const [isLoading, setIsLoading] = useState(false);
  const passwordVisibility = usePasswordVisibility();
  const confirmVisibility = usePasswordVisibility();

  // send code helpers
  const recaptcha = useRecaptcha();
  const verifyCodeInputRef = useRef(null);
  const [isSentCode, setIsSentCode] = useState(false);
  const {counter, start, reset} = useCountDown(15);

  const reloadTimer = () => {
    reset();
    start();
  };

  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.inviteSignup;

  const sendVerificationCode = async (phoneNumber: string) => {
    FirebaseService.toggleRecapchaAutoVerificationForTesting(phoneNumber);
    const recaptchaVerifier = recaptcha.create(RECAPTCHA_CONTAINER_ID, phoneNumber);
    try {
      const result = await FirebaseService.signInWithPhone(phoneNumber, recaptchaVerifier);
      onCodeSent(result);
      reloadTimer();
      setIsSentCode(true);
      verifyCodeInputRef.current.focus();
    } catch (error) {
      setIsSentCode(false);
      Sentry.captureException(error);
      toast(getHumanizedFbError(error, t));
    } finally {
      recaptcha.destroy(recaptchaVerifier, RECAPTCHA_CONTAINER_ID);
    }
  };

  const sendOrResendCode = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    mixpanel.track(mixpanelEvents.buttons.send);

    if (!isSentCode || !counter) {
      setIsLoading(true);
      await sendVerificationCode(formValues.countryCode + formValues.phoneNumber);
      setFieldValue('verificationCode', '');
      setFieldTouched('verificationCode', false);
      setIsLoading(false);
    }
  };

  const onChangePhoneControl = useCallback((value) => {
    setValues({...formValues, countryCode: value.code, phoneNumber: value.phone});
  }, []);

  const onLoginTypeChange = (e: ChangeEvent) => {
    onLoginTypeChanged((e.target as HTMLInputElement).value);
  };

  const getPhoneFields = () => {
    return (
      <>
        <div className="form-onboarding-z__item">
          <PhoneControl
            disabled={initialLoginType === 'phone'}
            onChange={onChangePhoneControl}
            value={{phone: formValues.phoneNumber, code: formValues.countryCode}}
            showTooltip={true}
          >
            <div className="ctrl-phone__item ctrl-phone__item--button">
              <Button
                className="ctrl-phone__button-send"
                onClick={sendOrResendCode}
                disabled={!!errors.phoneNumber || (isSentCode && !!counter)}
                colorStyle={formValues.verificationCode ? 'success' : null}
              >
                {!isSentCode
                  ? t('invite_signup:actions.confirmation.send', 'Send')
                  : !counter
                  ? t('invite_signup:actions.confirmation.resend', 'Resend')
                  : `${t('invite_signup:actions.confirmation.info', 'Resend in')} ${counter}${t(
                      'invite_signup:actions.confirmation.seconds_acronim',
                      's',
                    )}`}
              </Button>
            </div>
          </PhoneControl>
        </div>
        <div className="form-onboarding-z__item">
          <FormControl
            name="verificationCode"
            label={t('invite_signup:fields.verificationCode.label', 'Enter the code we`ve sent to your number')}
            className="ctrl-form--button-big"
          >
            <Field name="verificationCode">
              {({field}: FieldProps) => (
                <>
                  <IMaskInput
                    {...field}
                    style={!isSentCode ? {pointerEvents: 'none', opacity: '.25'} : null}
                    className="ctrl-textfield"
                    mask="000000"
                    placeholder={t('invite_signup:fields.verificationCode.placeholder', 'Enter the code')}
                    inputRef={verifyCodeInputRef}
                  />
                </>
              )}
            </Field>
          </FormControl>
        </div>
      </>
    );
  };

  const getEmailFields = () => {
    return (
      <div className="form-onboarding-z__item">
        <div className="form-onboarding-z__item">
          <FormControl name="emailAddress" label={t('invite_signup:fields.emailAddress.label', 'Email Address')}>
            <Field
              className="ctrl-textfield"
              placeholder={t('invite_signup:fields.emailAddress.placeholder', 'Enter Email Address')}
              autoComplete="off"
              readOnly={initialLoginType === 'password'}
            />
          </FormControl>
        </div>
        <div className="form-onboarding-z__item">
          <FormControl
            name="password"
            label={t('invite_signup:fields.password.label', 'Password')}
            iconName={passwordVisibility.visible ? 'visible_outlined' : 'hidden_outlined'}
            onIconClick={passwordVisibility.toggleVisibility}
          >
            <Field
              type={passwordVisibility.inputType}
              className="ctrl-textfield"
              placeholder={t('invite_signup:fields.password.placeholder', 'Enter password')}
              autoComplete="new-password"
            />
          </FormControl>
        </div>
        <div className="form-onboarding-z__item">
          <FormControl
            name="repeatPassword"
            label={t('invite_signup:fields.passwordRepeat.label', 'Repeat Password')}
            iconName={confirmVisibility.visible ? 'visible_outlined' : 'hidden_outlined'}
            onIconClick={confirmVisibility.toggleVisibility}
          >
            <Field
              type={confirmVisibility.inputType}
              className="ctrl-textfield"
              placeholder={t('invite_signup:fields.passwordRepeat.placeholder', 'Repeat password')}
              autoComplete="new-password"
            />
          </FormControl>
        </div>
      </div>
    );
  };

  return (
    <div className="form-onboarding-z__grid loader-container">
      <div className="form-onboarding-z__item">
        <RadiosControl>
          <RadiosControl.Field name="loginType" label={t('invite_signup:fields.loginType.email.label', 'Email')}>
            <input type="radio" value="password" checked={loginType === 'password'} onChange={onLoginTypeChange} />
          </RadiosControl.Field>
          {!isAdmin && (
            <RadiosControl.Field name="loginType" label={t('invite_signup:fields.loginType.phone.label', 'Phone')}>
              <input type="radio" value="phone" checked={loginType === 'phone'} onChange={onLoginTypeChange} />
            </RadiosControl.Field>
          )}
        </RadiosControl>
      </div>
      {loginType === 'phone' ? getPhoneFields() : getEmailFields()}
      {isLoading && <Loader />}
    </div>
  );
};
export default InviteSignupForm;
