import cn from 'classnames';
import dayjs from 'dayjs';
import {Field, useFormikContext} from 'formik';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';

import {Activity} from 'modules/Tasks/components/Gantt/types';
import {TasksLocationState} from 'modules/Tasks/types/location';
import CoreNativeDatePicker from 'shared/components/CoreForm/CoreNativeDatePicker';
import AsyncProjectSubcontractorSelect from 'shared/components/CoreForm/Select/AsyncProjectSubcontractorSelect';
import {CoreSelect} from 'shared/components/CoreForm/Select/Select';
import CoreStatusSelect from 'shared/components/CoreForm/Select/StatusSelect';
import SkeletonFieldPreloader from 'shared/components/CoreForm/SkeletonFieldPreloader';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import FieldInline from 'shared/components/CoreNewUI/FormControlInline/FieldInline/FieldInline';
import FormControlInline from 'shared/components/CoreNewUI/FormControlInline/FormControlInline';
import SkeletonPreloader from 'shared/components/SkeletonPreloader';
import Tooltip from 'shared/components/Tooltip';
import {costImpactTypes, getTaskIssueImpactOptions, getTaskIssueTypeOptions} from 'shared/constants/statuses';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {IssueModel} from 'shared/models/task/issue';
import {TaskStatusType} from 'shared/models/task/taskStatus';
import {CompanyWorker} from 'shared/models/worker';

import {AVAILABLE_FIELDS_IN_READONLY_VIEW} from '../../../utils/constants';
import {FormValues} from '../../types';
import {ActivitiesList} from '../ActivitiesList';
import {ImageGallery} from '../ImageGallery/ImageGallery';

import s from './IssuePanelForm.module.scss';

type Props = {
  issue: IssueModel | null;
  loading: boolean;
  onDelete: () => void;
  projectId: string;
  updateActivities: (activiity: Activity[]) => void;
  updateTaskIds: (values: string[]) => void;
  workers: CompanyWorker[];
};

const IssuePanelForm = ({issue, loading, onDelete, projectId, updateActivities, updateTaskIds, workers}: Props) => {
  const {t} = useTranslation(['task', 'common']);
  const location = useLocation<TasksLocationState>();
  const {hasAnyAdminRole} = useCompanyWorkerRoles(projectId);
  const {errors, values, setFieldValue} = useFormikContext<FormValues>();
  const issueTypeOptions = getTaskIssueTypeOptions(t);
  const issueImpactOptions = getTaskIssueImpactOptions(t);
  const ref = useRef<HTMLDivElement>(null);

  const onChangeStartDate = (value) => {
    const nextDate = dayjs(value?.target?.value).toDate();
    setFieldValue('startDate', nextDate);
    if (values.endDate && values.endDate < nextDate) {
      setFieldValue('endDate', nextDate);
    }
  };

  const imageList = issue?.extChatEvents?.flatMap((curr) => {
    return curr.eventMediaUrls.map((url) => ({
      url,
    }));
  });

  useEffect(() => {
    if (ref.current && location.state?.taskId) {
      ref.current.scrollIntoView({behavior: 'instant'});
    }
  }, [location.state?.taskId]);

  return (
    <div className={cn(s.issuePanelForm)} ref={ref}>
      <div className={cn(s.issuePanelForm__item)}>
        <FormControlInline.Formik label={t('task:issue_panel.form.name.label', 'Issue Name')} labelIsHidden name="name">
          {({field}) => (
            <SkeletonPreloader when={loading} height={40}>
              <FieldInline
                disabled={!hasAnyAdminRole && !AVAILABLE_FIELDS_IN_READONLY_VIEW.has(field.name)}
                element="textarea"
                placeholder={t('task:issue_panel.form.name.placeholder', 'Enter Issue Name')}
                {...field}
              />
            </SkeletonPreloader>
          )}
        </FormControlInline.Formik>
      </div>
      <div className={s.issuePanelForm__headerActions}>
        <Tooltip text={t('task:task_form.tooltips.delete', 'Delete')} placement="bottom">
          <CtrlButton
            data-cy="panel-activity-info-delete-btn"
            iconOnly
            className={s.issuePanelForm__headerButton}
            color="second"
            size="s"
            shadow
            icon="delete"
            onClick={onDelete}
            disabled={!hasAnyAdminRole || loading}
          />
        </Tooltip>
      </div>
      <div className={cn(s.issuePanelForm__row)}>
        <div className={s.issuePanelForm__item_date}>
          <FormControl
            name="startDate"
            error={String(errors?.startDate || '')}
            label={t('task:issue_panel.form.startdate.label', 'Start Date')}
          >
            <SkeletonPreloader when={loading} height={40}>
              <CoreNativeDatePicker
                disabled={!hasAnyAdminRole}
                minDate={values.startDate}
                value={values.startDate}
                onChange={onChangeStartDate}
              />
            </SkeletonPreloader>
          </FormControl>
        </div>
        <div className={s.issuePanelForm__item_date}>
          <FormControl
            name="endDate"
            error={String(errors?.endDate || '')}
            label={t('task:issue_panel.form.enddate.label', 'End Date')}
          >
            <SkeletonPreloader when={loading} height={40}>
              <CoreNativeDatePicker
                disabled={!hasAnyAdminRole}
                minDate={values.startDate}
                value={values.endDate}
                onChange={(value) =>
                  setFieldValue('endDate', value?.target?.value && dayjs(value?.target?.value).toDate())
                }
              />
            </SkeletonPreloader>
          </FormControl>
        </div>
      </div>
      <div className={cn(s.issuePanelForm__item)}>
        <FormControl.Formik
          name="description"
          error={errors?.description}
          label={t('task:issue_panel.form.description.label', 'Description')}
        >
          {({field: {...fieldProps}}) => (
            <SkeletonPreloader when={loading} height={40}>
              <Field
                {...fieldProps}
                className="ctrl-textfield"
                as="textarea"
                placeholder={t('task:issue_panel.form.description.placeholder', 'Click here to add a description...')}
                disabled={!hasAnyAdminRole}
              />
            </SkeletonPreloader>
          )}
        </FormControl.Formik>
      </div>
      <div className={cn(s.issuePanelForm__item)}>
        <ImageGallery attachments={imageList} isMatrixImage displayGalleryCopy isLoading={loading} key={issue?.id} />
      </div>
      <div className={cn(s.issuePanelForm__item)}>
        <FormControl.Formik
          name="status"
          error={errors?.status}
          label={t('task:issue_panel.form.status.label', 'Status')}
        >
          {({field: {name, ...field}}) => (
            <SkeletonFieldPreloader when={loading}>
              <CoreStatusSelect.Task
                {...field}
                projectId={projectId}
                className="react-select--colored"
                placeholder={t('task:issue_panel.form.type.placeholder', 'Choose Status')}
                menuPosition="absolute"
                allowedStatuses={[TaskStatusType.inProgress, TaskStatusType.tba, TaskStatusType.closed]}
                onChange={(value: string) => setFieldValue('status', value)}
              />
            </SkeletonFieldPreloader>
          )}
        </FormControl.Formik>
      </div>
      <div className={cn(s.issuePanelForm__item)}>
        <FormControl
          error={errors?.responsible}
          name="responsible"
          label={t('task:issue_panel.form.responsible.label', 'Assignee')}
        >
          <SkeletonFieldPreloader when={loading}>
            <CoreSelect
              isDisabled={!hasAnyAdminRole}
              className="react-select"
              placeholder={t('task:issue_panel.form.responsible.placeholder', 'Unassigned')}
              classNamePrefix="react-select"
              menuPlacement="auto"
              value={values.responsible}
              options={
                workers?.map((w) => ({
                  label: w.workerFull.fullName || w.workerFull.email || w.workerFull.mobileNumber,
                  value: w.workerId,
                })) || []
              }
              onChange={(value) => setFieldValue('responsible', value)}
            />
          </SkeletonFieldPreloader>
        </FormControl>
      </div>
      <div className={cn(s.issuePanelForm__item)}>
        <FormControl.Formik
          name="responsibleOrgId"
          error={errors?.responsibleOrgId}
          label={t('task:issue_panel.form.responsible_org_id.label', 'Resolving Company')}
        >
          {({field: {value, name, ...fieldProps}}) => (
            <SkeletonFieldPreloader when={loading}>
              <AsyncProjectSubcontractorSelect
                {...fieldProps}
                isCreatable
                isDisabled={!hasAnyAdminRole}
                value={value}
                onChange={(value) => setFieldValue(name, value)}
                onAfterCreateOption={(newSub) => setFieldValue(name, newSub.id)}
                placeholder={t('task:issue_panel.form.subcontractor.placeholder', 'Choose Company')}
              />
            </SkeletonFieldPreloader>
          )}
        </FormControl.Formik>
      </div>
      <div className={cn(s.issuePanelForm__item)}>
        <FormControl.Formik
          name="culpableOrgId"
          error={errors?.culpableOrgId}
          label={t('task:issue_panel.form.culpable_sub.label', 'Liable Company')}
        >
          {({field: {value, name, ...fieldProps}}) => (
            <SkeletonFieldPreloader when={loading}>
              <AsyncProjectSubcontractorSelect
                {...fieldProps}
                isCreatable
                isDisabled={!hasAnyAdminRole}
                value={value}
                onChange={(value) => setFieldValue(name, value)}
                onAfterCreateOption={(newSub) => setFieldValue(name, newSub.id)}
                placeholder={t('task:issue_panel.form.subcontractor.placeholder', 'Choose Company')}
              />
            </SkeletonFieldPreloader>
          )}
        </FormControl.Formik>
      </div>
      <div className={cn(s.issuePanelForm__item)}>
        <FormControl error={errors?.issueType} label={t('task:issue_panel.form.issuetype.label', 'Issue Type')}>
          <SkeletonFieldPreloader when={loading}>
            <CoreSelect
              isDisabled={!hasAnyAdminRole}
              options={issueTypeOptions}
              value={values.issueType}
              onChange={(value) => setFieldValue('issueType', value)}
            />
          </SkeletonFieldPreloader>
        </FormControl>
      </div>
      <div className={cn(s.issuePanelForm__item)}>
        <FormControl error={errors?.impact} label={t('task:issue_panel.form.impact.label', 'Time Impact')}>
          <SkeletonFieldPreloader when={loading}>
            <CoreSelect
              isDisabled={!hasAnyAdminRole}
              options={issueImpactOptions}
              value={values.impact}
              onChange={(value) => setFieldValue('impact', value)}
            />
          </SkeletonFieldPreloader>
        </FormControl>
      </div>
      <div className={cn(s.issuePanelForm__item)}>
        <FormControl
          name="costImpact"
          error={errors?.costImpact}
          label={t('task:issue_panel.form.cost_impact.label', 'Cost Impact')}
        >
          <SkeletonFieldPreloader when={loading}>
            <CoreSelect
              isDisabled={!hasAnyAdminRole}
              className="react-select"
              placeholder={t('task:issue_panel.form.cost_impact.placeholder', 'Choose Cost Impact')}
              classNamePrefix="react-select"
              menuPlacement="auto"
              value={String(values.costImpact)}
              options={costImpactTypes}
              onChange={(value) => setFieldValue('costImpact', value)}
            />
          </SkeletonFieldPreloader>
        </FormControl>
      </div>
      <div className={cn(s.issuePanelForm__item)}>
        <ActivitiesList
          issue={issue}
          issueLoading={loading}
          projectId={projectId}
          updateActivities={updateActivities}
          updateTaskIds={updateTaskIds}
        />
      </div>
    </div>
  );
};

export default IssuePanelForm;
