import cn from 'classnames';
import {Children, cloneElement, isValidElement, ReactElement} from 'react';

import s from './CtrlSwitcher.module.scss';

type ButtonProps = {
  className?: string;
  [key: string]: any;
};

type CtrlSelectorProps = {
  className?: string;
  title?: string;
  children: ReactElement<ButtonProps>[] | ReactElement<ButtonProps>;
  view?: string;
  onChange?: (value: string) => void;
};

const CtrlSwitcher = ({className, title, children, view}: CtrlSelectorProps) => {
  return (
    <div className={cn(s.ctrlSelector, view && s[`ctrlSelector_view_${view}`], className)}>
      {title && <span className={s.ctrlSelector__title}>{title}</span>}
      <div className={s.ctrlSelector__container}>
        {Children.toArray(children).map((child) => {
          if (isValidElement<ButtonProps>(child)) {
            return cloneElement<ButtonProps>(child, {
              ...child.props,
              className: cn(s.ctrlSelector__button, child.props.className),
            });
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default CtrlSwitcher;
