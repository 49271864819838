import {Formik, FormikHelpers, FormikProps} from 'formik';
import {FC, useCallback, useRef, useState} from 'react';
import ReactPixel from 'react-facebook-pixel';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';
import {toast} from 'react-toastify';

import ContactApi from 'api/contact';
import FirebaseDefault from 'services/Firebase';
import {useAuth} from 'shared/components/AuthUserProvider';
import Icon from 'shared/components/Icon';
import MetaTags from 'shared/components/MetaTags';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {ContactUsSchema} from 'shared/helpers/validationSchemas';
import {useScrollToTopOnMount} from 'shared/hooks/core/useScrollToTopOnMount';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {BaseLayoutStylesContainer, Footer, Header} from 'shared/layout/base';
import {ContactRequest} from 'shared/models/contactForm';

import ContactForm from './ContactForm';
import {getContactContentByKey} from './contentHelper';
import HeroContactSection from './HeroSection';

export type FormValues = Pick<ContactRequest, 'companyName' | 'name' | 'emailAddress' | 'mobileNumber' | 'message'>;
type SubmitHandler = (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void | Promise<unknown>;

const defaultValues: FormValues = {
  companyName: '',
  name: '',
  emailAddress: '',
  mobileNumber: '',
  message: '',
};

const getPathnameWithoutLocale = (pathname: string) => {
  return pathname.replace(/\/(?:en|es|ja)\//, '/');
};

const Contact: FC = () => {
  const {useZoomInfo} = useAnalyticsService({publicPage: true});
  useZoomInfo();
  const {t} = useTranslation(['contacts', 'header']);
  const {user} = useAuth();
  const location = useLocation();
  const formik = useRef<FormikProps<FormValues>>(null);
  const {tag, title, metaDescription, metaKeywords} = getContactContentByKey(
    getPathnameWithoutLocale(location.pathname),
    t,
  );
  const [countryCode, setCountryCode] = useState<string>('+1');
  useScrollToTopOnMount();

  const onSubmit: SubmitHandler = useCallback(
    async (values: ContactRequest) => {
      try {
        if (!user) {
          await FirebaseDefault.signInAnonymously();
        }
        await ContactApi.sendContactRequest({...values, mobileNumber: countryCode + values.mobileNumber, tags: [tag]});
        if (tag === 'demo_request') {
          window.lintrk('track', {conversion_id: 4704652});
        }
        // eslint-disable-next-line import/no-named-as-default-member
        ReactPixel.trackCustom('Sales_Form', {});
        formik.current.resetForm();
        toast.success(
          t('contacts:toast.success', 'Thank you for reaching out. We’ll get back to you as soon as we can!'),
        );
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(extractAxiosError(error) as string);
        }
      }
    },
    [tag, user, countryCode],
  );

  return (
    <>
      <MetaTags title={title} description={metaDescription} keywords={metaKeywords} />
      <BaseLayoutStylesContainer>
        <Header />
        <HeroContactSection type={getPathnameWithoutLocale(location.pathname)} />
        <section className="contacts">
          <div className="box">
            <div className="contacts__container">
              <div className="contacts__form">
                <Formik<FormValues>
                  initialValues={defaultValues}
                  validationSchema={ContactUsSchema}
                  validateOnMount={false}
                  onSubmit={onSubmit}
                  innerRef={formik}
                >
                  <ContactForm
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    type={getPathnameWithoutLocale(location.pathname)}
                  />
                </Formik>
              </div>
              <div className="contacts-info contacts__info">
                <h2 className="contacts-info__title">{t('contacts:contacts_info.title', 'Reach Core')}</h2>
                <ul className="contacts-info__list">
                  <li className="contacts-info__list-item">
                    <a className="contacts-info__link" href="mailto:support@bycore.com">
                      {t('contacts:email', 'support@bycore.com')}
                    </a>
                  </li>
                  <li className="contacts-info__list-item">
                    <a className="contacts-info__link" href="tel:+18312924077">
                      {t('contacts:phone', '+1-831-292-4077')}
                    </a>
                  </li>
                </ul>
                <div className="contacts-info__description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'contacts:contacts_info.description',
                        'And don&apos;t forget to join us on social media for all the latest news and updates and great content!',
                      ),
                    }}
                  />
                </div>
                <div className="social contacts-info__social">
                  <a
                    className="ctrl-btn-clear social__item"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={process.env.REACT_APP_FACEBOOK_LINK}
                  >
                    <span className="social__name">{t('contacts:social.facebook', 'Follow us on Facebook')}</span>
                    <Icon name="social-facebook-line" colorFill className="ctrl-btn-clear__icon" size={24} />
                  </a>
                  <a
                    className="ctrl-btn-clear social__item"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={process.env.REACT_APP_TWITTER_LINK}
                  >
                    <span className="social__name">{t('contacts:social.twitter', 'Follow us on Twitter')}</span>
                    <Icon name="social-twitter-line" colorFill className="ctrl-btn-clear__icon" size={24} />
                  </a>
                  <a
                    className="ctrl-btn-clear social__item"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={process.env.REACT_APP_LINKEDIN_LINK}
                  >
                    <span className="social__name">{t('contacts:social.linkedin', 'Follow us on LinkedIn')}</span>
                    <Icon name="social-linkedin-line" colorFill className="ctrl-btn-clear__icon" size={24} />
                  </a>
                  <a
                    className="ctrl-btn-clear social__item"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={process.env.REACT_APP_INSTAGRAM_LINK}
                  >
                    <span className="social__name">{t('contacts:social.instagram', 'Follow us on Instagram')}</span>
                    <Icon name="social-instagram-line" colorFill className="ctrl-btn-clear__icon" size={24} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </BaseLayoutStylesContainer>
    </>
  );
};
export default Contact;
