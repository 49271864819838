import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useMemo} from 'react';

import ProjectsApi from 'api/projects';
import TasksApi from 'api/tasks';

import {useMount} from './core/useMount';

const BLACK_LIST = ['*'];

export interface UseWorkflowStatusesParams {
  getById: string;
  shared?: boolean;
  enabled?: boolean;
}

export function useWorkflowStatuses({getById, enabled = true, shared}: UseWorkflowStatusesParams) {
  const {data: workflow, isLoading} = useQuery({
    queryKey: [shared ? 'taskWorkflow' : 'workflow', getById],
    queryFn: () => (shared ? TasksApi.getSharedTaskWorkflow(getById) : ProjectsApi.getWorkflow(getById)),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!getById && enabled,
    staleTime: 5 * 60 * 1000,
  });
  const statuses = useMemo(
    () =>
      workflow
        ? Array.from(
            new Set(
              workflow.reduce((acc, cur) => {
                return acc.concat(cur.fromStatus).concat(cur.toStatus);
              }, []),
            ),
          ).filter((status) => !BLACK_LIST.includes(status as string))
        : [],
    [workflow],
  );
  return [statuses, isLoading] as const;
}

export function usePrefetchWorkFlowStatuses(projectId?: string) {
  const client = useQueryClient();
  useMount(() => {
    client.prefetchQuery({
      queryKey: ['workflow', projectId],
      queryFn: () => ProjectsApi.getWorkflow(projectId),
    });
  });
}
