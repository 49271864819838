import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import AsyncProjectSubcontractorSelect from 'shared/components/CoreForm/Select/AsyncProjectSubcontractorSelect';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useProjectWorkers} from 'shared/hooks/useProjectWorkers';
import {GroupMemberRole} from 'shared/models/task/const';

import {useAssigneesTabContext} from '../../AssigneesTab/components/AssigneesTabContext';
import {companyWorkerToTaskAssignee} from '../../AssigneesTab/utils/functions';

import s from './SelectGroup.module.scss';

const SelectGroup = () => {
  const [subcontractorId, setSubcontractorId] = useState('');
  const {watchers, responsible, updateWatchers, onMemberSelect} = useAssigneesTabContext();
  const {t} = useTranslation('task');
  const {projectWorkers: workers} = useProjectWorkers();
  const {projectId} = useParams<{projectId: string}>();
  const {viewMode} = useFilterContext();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});

  const groupToAdd = useMemo(() => {
    const filteredWorkers = workers?.filter((w) => {
      const isSelected = watchers && watchers.find((m) => m.memberId === w.workerId);
      return w.orgMappingIds?.includes(subcontractorId) && !isSelected;
    });
    return workers ? filteredWorkers : [];
  }, [workers, watchers, subcontractorId]);

  const onAddGroup = () => {
    mixpanel.track(mixpanel.events.task.buttons.selectAssigneesGroup);
    const selectedResponsible = responsible[0] ? [{...responsible[0], workerId: responsible[0].memberId}] : [];
    const preparedGroup = groupToAdd.map((w) => companyWorkerToTaskAssignee(w, GroupMemberRole.assignee));
    const preparedWatchers = watchers
      .filter((w) => w.memberId !== responsible[0]?.memberId)
      .map((w) => ({workerId: w.memberId, memberRole: GroupMemberRole.assignee}));

    updateWatchers([...watchers, ...preparedGroup]);
    onMemberSelect([...selectedResponsible, ...preparedGroup, ...preparedWatchers]);
    setSubcontractorId(null);
  };

  return (
    <div className={s.selectGroup}>
      <p className={s.selectGroup__label}>{t('assignees_tab.adding_watchers.select_group.label', 'Add from Group')}</p>
      <AsyncProjectSubcontractorSelect
        className="react-select"
        classNamePrefix="react-select"
        value={subcontractorId}
        placeholder={t('assignees_tab.adding_watchers.select_group.placeholder', 'Select subcontractor')}
        onChange={setSubcontractorId}
      />
      <div className={s.selectGroup__add}>
        <CtrlButton view="link" disabled={!subcontractorId} onClick={onAddGroup}>
          {t('assignees_tab.adding_watchers.select_group.button', '+ Add Group')}
        </CtrlButton>
      </div>
    </div>
  );
};

export default SelectGroup;
