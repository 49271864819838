import {useCallback, use} from 'react';

import {ConfirmationContext} from './ConfirmationContext';
import {ConfirmConfig} from './state';

let resolver;
export function useConfirm() {
  const {dispatch} = use(ConfirmationContext);

  const closeConfirm = useCallback(() => {
    dispatch({type: 'CLOSE_CONFIRM'});
  }, [dispatch]);

  const accept = useCallback(() => {
    closeConfirm();
    resolver(true);
  }, [closeConfirm]);

  const cancel = useCallback(() => {
    closeConfirm();
    resolver(false);
  }, [closeConfirm]);

  const confirm = (payload: string | ConfirmConfig) => {
    dispatch({type: 'OPEN_CONFIRM', payload: typeof payload === 'string' ? {description: payload} : payload});
    return new Promise((res) => {
      resolver = res;
    });
  };

  const confirmAction = async (
    payload: string | ConfirmConfig,
    cond: boolean,
    onAccept: () => void,
    onReject?: () => void,
  ) => {
    if (cond && (await confirm(payload))) {
      return onAccept();
    }

    return onReject && onReject();
  };
  const resolveValue = (value, closeAfter: boolean) => {
    if (closeAfter) {
      closeConfirm();
    }
    resolver(value);
  };

  return {confirm, accept, cancel, closeConfirm, confirmAction, resolveValue};
}
