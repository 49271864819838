import cn from 'classnames';
import {cloneElement, isValidElement, ReactElement} from 'react';

import s from './CtrlForm.module.scss';

type CtrlFormProps = {
  className?: string;
  field: ReactElement<{className?: string; id?: string}>;
  label: string;
  labelIsHidden: boolean;
  id: string;
};

const CtrlForm = ({className, field, label, labelIsHidden, id}: CtrlFormProps) => {
  return (
    <div className={cn(s.ctrlForm, labelIsHidden && s[`ctrlForm_labelIsHidden`], className)}>
      <label className={s.ctrlFrom__label} htmlFor={id}>
        {label}
      </label>
      <div className={s.ctrlForm__body}>
        {isValidElement(field) &&
          cloneElement(field, {
            ...field.props,
            className: cn(field.props.className, s.ctrlForm__field),
            id: id,
          })}
      </div>
    </div>
  );
};

export default CtrlForm;
