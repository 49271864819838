import {useEffect, useMemo, useRef} from 'react';
import {useLocation, useRouteMatch} from 'react-router';

import {parseUrlQuery, UrlParseSchema} from 'shared/helpers/queryParams';

type UseParsedQueryOptions<T extends Record<string, unknown>> = {
  defaultParams: Partial<T>;
  schema: UrlParseSchema<T>;
  exactMatch?: string;
};

export const useParsedQuery = <T extends Record<string, unknown>>({
  defaultParams,
  schema,
}: UseParsedQueryOptions<T>) => {
  const location = useLocation();

  return useMemo(() => {
    if (schema?.view) {
    }
    return Object.assign({}, defaultParams, parseUrlQuery(location.search, schema)) as T;
  }, [location.search, defaultParams, schema]);
};

export const useExactMatchParsedQuery = <T extends Record<string, unknown>>({
  defaultParams,
  schema,
  path,
  keepPreviousMatchParams = true,
}: UseParsedQueryOptions<T> & {path: string; keepPreviousMatchParams?: boolean}) => {
  const match = useRouteMatch(path);
  const queryParams = useParsedQuery<T>({defaultParams, schema});
  const exactMatchParams = useRef<T>(null);

  useEffect(() => {
    if (match?.isExact) {
      exactMatchParams.current = queryParams;
    }
  }, [match, queryParams]);

  return {
    params: match?.isExact
      ? queryParams
      : keepPreviousMatchParams && exactMatchParams.current
      ? exactMatchParams.current
      : defaultParams,
    match,
  };
};
