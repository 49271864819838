import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';
import {toast, ToastContent} from 'react-toastify';

import TasksApi from 'api/tasks';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {TaskChatEvent, AsyncJobChatHistoryStatus} from 'shared/models/task/chat';

import {isAsyncJobInProgress} from '../components/CommentsTab/utils/helpers';

const REFETCH_INTERVAL_MS = 2500;
const STALE_TIME = 5 * (60 * 1000); // 5 min

export function useLoadTaskChatHistory(taskId: string): {
  chatEvents: TaskChatEvent[];
  jobStatus: AsyncJobChatHistoryStatus;
} {
  const queryClient = useQueryClient();
  const {data: asyncUploadId} = useQuery({
    queryKey: QUERY_CACHE_KEYS.initAsyncJobChatHistory(taskId),

    queryFn: () => {
      return TasksApi.startAsyncChatHistory(taskId);
    },
    enabled: !!taskId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: STALE_TIME,
  });

  const {data: reqMatrixEvents, error} = useQuery({
    queryKey: QUERY_CACHE_KEYS.checkStatusAsyncJobChatHistory(asyncUploadId?.id),
    queryFn: () => {
      return TasksApi.checkAsyncStatusChatHistory(taskId, asyncUploadId.id);
    },
    enabled: !!asyncUploadId?.id,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: (query) => (isAsyncJobInProgress(query.state.data?.status) ? REFETCH_INTERVAL_MS : false),
    staleTime: STALE_TIME,
  });

  useEffect(() => {
    if (error) {
      toast(error as unknown as ToastContent);
    }
  }, [error]);

  return {chatEvents: reqMatrixEvents?.result?.events, jobStatus: reqMatrixEvents?.status};
}
