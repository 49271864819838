import {InfiniteData, useMutation, useQueryClient} from '@tanstack/react-query';
import equal from 'fast-deep-equal';
import {FormikProps} from 'formik';
import {RefObject} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import WorkersApi, {UpdateCompanyWorkerResponse} from 'api/workers';
import {extractAxiosError} from 'shared/helpers/axios';
import {useCompany} from 'shared/hooks/useCompany';
import {useProfile} from 'shared/hooks/useProfile';
import {useQueryCache} from 'shared/hooks/useQueryCache/useQueryCache';
import {CompanyModel} from 'shared/models/company';
import {CompanyWorker, CompanyWorkerRole, WorkerFormValues} from 'shared/models/worker';
import {updateCompanyOrgsList} from 'shared/utils/inviteWorker';
import {useRootDispatch} from 'store';
import {profileActions} from 'store/profile';

import {QUERY_CACHE_KEYS} from '../../shared/constants/queryCache';

type MutationParams = {values: Partial<WorkerFormValues>};

type UpdateWorkerProps = {
  values: Partial<WorkerFormValues>;
  companyWorker: CompanyWorker;
  projectId: string;
  updateMember: (payload) => Promise<UpdateCompanyWorkerResponse>;
};

type MutationToUpdateParams = UpdateWorkerProps;
type UpdateWorkerParams = UpdateWorkerProps & {company: CompanyModel};

export const useInviteWorker = (
  inviteMember: (payload: any, queryParams: any) => Promise<CompanyWorker>,
  formik?: RefObject<FormikProps<WorkerFormValues>>,
  afterSubmit?: () => void,
) => {
  const company = useCompany();
  const {cacheHelper} = useQueryCache();
  const queryClient = useQueryClient();
  const {t} = useTranslation('worker');
  const dispatch = useRootDispatch();

  return useMutation({
    mutationFn: ({values}: MutationParams) => {
      const payload = {
        workerPhoneNumber: values.phoneNumber ? values.phoneCode + values.phoneNumber : undefined,
        workerName: values.fullName?.trim(),
        workerEmail: values.workerEmail?.trim(),
        companyId: company.id,
        projectId: values.projectId,
        workerId: values.workerId,
        trade: values.trade,
        orgMappingIds: values.orgMappingIds?.length ? values.orgMappingIds : undefined,
      };
      const queryParams = {
        sendInviteSms: values.sendSms,
        sendInviteEmail: values.sendEmail,
        inviteAsAdmin: values.roles.some((role) => ['company_admin', 'project_admin'].includes(role)),
        inviteAsForeman: values.roles.includes('foreman'),
      };
      return inviteMember(payload, queryParams);
    },

    onSuccess: (worker) => {
      if (worker.projectId) {
        const projectWorkersQuery = cacheHelper.findRecentPagedQuery<CompanyWorker>(
          QUERY_CACHE_KEYS.projectWorkers(worker.projectId),
        );
        if (projectWorkersQuery) {
          queryClient.setQueryData<InfiniteData<CompanyWorker[]>>(projectWorkersQuery.queryKey, (data) =>
            data ? cacheHelper.prependOrUpdateCompanyWorkers(data, worker) : data,
          );
        }

        const projectWorkersWithPaginateQuery = cacheHelper.findRecentPagedQuery<CompanyWorker>(
          QUERY_CACHE_KEYS.projectWorkersWithPaginate(worker.projectId),
        );
        if (projectWorkersWithPaginateQuery) {
          queryClient.setQueryData<InfiniteData<CompanyWorker[]>>(projectWorkersWithPaginateQuery.queryKey, (data) =>
            data ? cacheHelper.prependOrUpdateCompanyWorkers(data, worker) : data,
          );
        }
      }

      const workersQuery = cacheHelper.findRecentPagedQuery<CompanyWorker>([QUERY_CACHE_KEYS.workers]);
      if (workersQuery) {
        queryClient.setQueryData<InfiniteData<CompanyWorker[]>>(workersQuery.queryKey, (data) =>
          data ? cacheHelper.prependOrUpdateCompanyWorkers(data, worker) : data,
        );
      }

      if (formik) {
        toast.success(t('toast.success.invite', 'New worker has been invited'));
        dispatch(
          profileActions.updateCompany({
            ...company,
            currentSeatCount: company.currentSeatCount + 1,
          }),
        );
        afterSubmit?.();
      }
    },

    onError: (error: any) => {
      const message = extractAxiosError(error);
      if (typeof message === 'string') {
        toast.error(message);
      } else if (formik?.current) {
        formik.current.setErrors(message);
      }
    },

    onSettled: (values) => {
      if (values?.orgMappingIds && formik) {
        updateCompanyOrgsList(values.orgMappingIds, queryClient);
      }
    },
  });
};

export const useRemoveCompanyWorker = () => {
  const company = useCompany();
  const {cacheHelper} = useQueryCache();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({id, workerId}: {id: string; workerId: string}) => {
      return WorkersApi.updateCompanyWorker({
        id,
        companyId: company.id,
        workerId,
        status: 'removed',
      });
    },

    onSuccess: (worker) => {
      const query = cacheHelper.findRecentPagedQuery<CompanyWorker>([QUERY_CACHE_KEYS.workers]);
      if (query) {
        queryClient.setQueryData<InfiniteData<CompanyWorker[]>>(query.queryKey, (data) =>
          data ? cacheHelper.updatePagedData(data, worker) : data,
        );
      }
    },
  });
};

function updateRoleAndOrgs(
  profile: CompanyWorker,
  roles: CompanyWorkerRole[],
  orgMappingIds: string[],
  projectId: string,
) {
  return WorkersApi.updateCompanyWorker({
    companyId: profile.companyId,
    workerId: profile.workerId,
    id: profile.id,
    roles,
    orgMappingIds: orgMappingIds || undefined,
    projectId,
  });
}

const prepareToUpdateRole = (values: Partial<WorkerFormValues>) => {
  const currentRoles = new Set(values.roles);
  if (!currentRoles.size) currentRoles.add('worker');
  return Array.from(currentRoles) as CompanyWorkerRole[];
};

const updateWorker = ({company, companyWorker, values, projectId, updateMember}: UpdateWorkerParams) => {
  const payload = {
    projectId: projectId,
    companyId: company.id,
    companyWorkerId: companyWorker.id,
    fullName: values.fullName?.trim() || undefined,
    mobileNumber: values.phoneNumber ? values.phoneCode + values.phoneNumber : undefined,
    trade: values.trade || undefined,
    email: values.workerEmail?.trim() || undefined,
    orgMappingIds: values.orgMappingIds || undefined,
    sendInviteEmail: values.sendEmail || undefined,
    sendInviteSms: values.sendSms || undefined,
  };
  return updateMember(payload);
};

export const useUpdateWorker = () => {
  const company = useCompany();
  const {cacheHelper} = useQueryCache();
  const profile = useProfile();
  const queryClient = useQueryClient();

  const updateWorkersCache = (response: CompanyWorker) => {
    const query = cacheHelper.findRecentPagedQuery<CompanyWorker>([QUERY_CACHE_KEYS.workers]);

    if (query) {
      queryClient.setQueryData<InfiniteData<CompanyWorker[]>>(query.queryKey, (oldData) => {
        if (!oldData) return oldData;
        return cacheHelper.updatePagedData(oldData, response);
      });
    }
  };

  return useMutation({
    mutationFn: async ({values, companyWorker, projectId, updateMember}: MutationToUpdateParams) => {
      const isInvited = companyWorker.workercard.status === 'invited';
      const roles = prepareToUpdateRole(values);
      const hasChangedRolesOrOrgs =
        !equal(companyWorker.roles, values.roles) || !equal(companyWorker.orgMappingIds, values.orgMappingIds || []);

      if (isInvited) {
        const requests: Promise<unknown>[] = [
          updateWorker({company, companyWorker, values, projectId, updateMember}),
          updateRoleAndOrgs(companyWorker, roles, values.orgMappingIds, projectId),
        ];

        return Promise.all(requests as [Promise<UpdateCompanyWorkerResponse>, Promise<CompanyWorker>]).then(
          ([invitedWorkerResponse, adminResponse]) => {
            return {
              ...companyWorker,
              ...adminResponse,
              workerFull: {...companyWorker.workerFull, ...invitedWorkerResponse},
            };
          },
        );
      }
      /* update only roles for active worker */
      if (profile.trade !== values.trade) {
        try {
          const {trade} = await updateWorker({company, companyWorker, values, projectId, updateMember});
          const updateCompanyWorkerResponse = {
            ...companyWorker,
            workerFull: {...companyWorker.workerFull, trade},
            workercard: {...companyWorker.workercard, trade},
          };
          updateWorkersCache(updateCompanyWorkerResponse);
          if (!hasChangedRolesOrOrgs) {
            return updateCompanyWorkerResponse;
          }
        } catch (error) {
          return Promise.reject(error);
        }
      }
      if (hasChangedRolesOrOrgs) {
        return updateRoleAndOrgs(companyWorker, roles, values.orgMappingIds, projectId);
      }
    },

    onSuccess: (response) => updateWorkersCache(response),
  });
};
