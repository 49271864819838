import {cloneElement, FC, FunctionComponent, PropsWithChildren, ReactElement} from 'react';

interface RadioProps {
  children: ReactElement<{className?: string; name?: string}>;
  name: string;
  label: string;
}

const Radio: FC<RadioProps> = ({children, name, label}) => {
  return (
    <label className="ctrl-radios__item">
      {cloneElement(children, {className: 'ctrl-radios__field', name})}
      <span className="ctrl-radios__visual" />
      <span className="ctrl-radios__label">{label}</span>
    </label>
  );
};

const Radios: FunctionComponent<PropsWithChildren> & {Field: typeof Radio} = ({children}) => {
  return (
    <div className="ctrl-radios">
      <div className="ctrl-radios__grid">{children}</div>
    </div>
  );
};
Radios.Field = Radio;

export default Radios;
