import {useFloating, offset, flip, shift} from '@floating-ui/react';
import {cloneElement, CSSProperties, FC, ForwardedRef, ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';

import ExportApi from 'api/export';
import {ExportToCSV} from 'services/ExportToCSV';
import CoreDropPanel from 'shared/components/DropPanel';
import {addStatusToFilters} from 'shared/helpers/tasks';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useProject} from 'shared/hooks/useProject';
import {useTasksUrlState} from 'shared/hooks/useTasksUrlState';
import {CompanyModel} from 'shared/models/company';
import {ProjectModel} from 'shared/models/project';

import TasksExportForm from './TasksExportForm';

interface ButtonProps {
  className: string;
  onClick: () => void;
}

interface ExtendedButtonProps extends ButtonProps {
  ref?: ForwardedRef<HTMLButtonElement>;
}

type TaskExportButtonProps = {
  status?: string;
  projects?: Record<string, ProjectModel>;
  company: CompanyModel;
  queryParams: Record<string, unknown>;
  button: ReactElement<Partial<ExtendedButtonProps>>;
  buttonStyle?: CSSProperties;
  dropPanelStyles?: CSSProperties;
  setIsExporting?: (boolean) => void;
  mixpanelEvents: {[name: string]: string};
};

const TaskExportButton: FC<TaskExportButtonProps> = ({
  queryParams,
  company,
  button,
  buttonStyle,
  dropPanelStyles,
  setIsExporting,
  mixpanelEvents,
}) => {
  const [showPopper, setShowPopper] = useState(false);
  const {t} = useTranslation('project');
  const {id} = useParams<{id: string}>();
  const {project} = useProject(id);
  const tasksState = useTasksUrlState();
  const {mixpanel} = useAnalyticsService();

  const {x, y, strategy, refs} = useFloating({
    placement: 'bottom',
    middleware: [offset(4), flip(), shift()],
  });

  const startExport = async (dateFormat: string) => {
    try {
      const res = await ExportApi.exportTasks(company.id, {
        dateFormat,
        params: addStatusToFilters(tasksState, queryParams),
      });
      setIsExporting?.(true);
      setShowPopper(false);

      const exportData = await ExportApi.pollExportTasksResults(company.id, res.data.id, 200);

      if (exportData.status === 'finished') {
        if (exportData.result.errors) {
          toast(exportData.result.errors[0].code);
        } else if (exportData.result.error) {
          toast(t('toast.error.unexpected', 'Unexpected server error'));
        } else {
          const csvExport = new ExportToCSV();
          csvExport.download(company.companyName + '_' + new Date().toLocaleString(), '', exportData.resultFileUrl);
        }
      }
    } finally {
      setIsExporting?.(false);
    }
  };

  const createButton = () => {
    return cloneElement(button, {
      ...button.props,
      onClick: () =>
        mixpanel.trackWithAction(() => setShowPopper(!showPopper), mixpanelEvents.button, {
          'Project Name': project?.name,
        }),
      ref: refs.setReference as ForwardedRef<HTMLButtonElement>,
    });
  };

  return (
    <div className="panel__button" style={buttonStyle}>
      {createButton()}
      {showPopper && (
        <CoreDropPanel
          dropPanelStyles={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            ...dropPanelStyles,
          }}
        >
          <TasksExportForm
            onSubmit={(dateFormat) =>
              mixpanel.trackWithAction(() => startExport(dateFormat), mixpanelEvents.startExport)
            }
          />
        </CoreDropPanel>
      )}
    </div>
  );
};

export default TaskExportButton;
