import cn from 'classnames';
import dayjs from 'dayjs';
import {camelizeKeys} from 'humps';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory, useParams} from 'react-router';

import WeatherApi from 'api/weather';
import {useProjectSubcontractorOptions} from 'modules/Tasks/components/ActionsBar/components/FilterDropdown/useProjectSubcontractorOptions';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import s from 'modules/Tasks/DailyRisk/DailyRisk.module.scss';
import {CoreSelect} from 'shared/components/CoreForm/Select/Select';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import Icon from 'shared/components/Icon';
import Loader from 'shared/components/Loader';
import {TaskLabelCategory} from 'shared/constants/dailyStatus';
import {RouteParams, useLocalizedRoutes} from 'shared/constants/routes';
import {
  populateOSKMap,
  getSafety,
  getQuality,
  getPlanning,
  getExternalFactors,
  getBlocked,
  Section,
  getLegend,
} from 'shared/helpers/reports';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useLabelCategories} from 'shared/hooks/useLabelCategories';
import {useProjectSelector} from 'shared/hooks/useProjectSelector';
import {toGanttTaskModel} from 'shared/mapping/task';
import {TaskObjectType} from 'shared/models/task/const';
import {TaskGanttModel} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';
import {WeatherReport} from 'shared/models/weather';
import {useRootDispatch} from 'store';
import {getProject} from 'store/projects/actions';

import {fetchBlockedTasks, fetchTasksByDate, loadIssues} from '../components/Gantt/utils/load';

const DailyRisk = () => {
  const {projectId} = useParams<RouteParams['tasks']>();
  const dispatch = useRootDispatch();
  const history = useHistory();
  const routes = useLocalizedRoutes();
  const project = useProjectSelector(projectId);
  const labelCategories = useLabelCategories();
  const [tasks, setTasks] = useState<TaskGanttModel[]>(null);
  const [blockedTasks, setBlockedTasks] = useState<TaskGanttModel[]>(null);
  const [issues, setIssues] = useState<TaskGanttModel[]>(null);
  const [weatherReport, setWeatherReport] = useState<WeatherReport>(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loadingTasks, setLoadingTasks] = useState(true);
  const dateChoices = useRef<CoreOptionType[]>([]);
  const oskMap = useRef<{[osk: string]: TaskGanttModel}>({});
  const subMap = useRef<{[ordId: string]: string}>({});
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, 'Project Name': project?.name}});
  const mixpanelEvents = mixpanel.events.risk;

  const {t, i18n} = useTranslation('gantt');
  const isJapanese = i18n.language === 'ja';

  const {data: subcontractorOptions, isLoading: isLoadingSubcontractors} = useProjectSubcontractorOptions(projectId);

  useEffect(() => {
    if (!isLoadingSubcontractors && subcontractorOptions) {
      subMap.current = subcontractorOptions.reduce((acc, opt) => {
        acc[opt.value] = opt.label;
        return acc;
      }, {});
    }
  }, [isLoadingSubcontractors, subcontractorOptions]);

  useEffect(() => {
    dispatch(getProject(projectId));

    let today = dayjs();
    const results = [];
    for (let count = 0; count < 3; count++) {
      results.push({value: today.toDate(), label: today.format('LL')});
      today = today.add(1, 'day');
    }
    dateChoices.current = results;
    setSelectedDate(results[0].value);
  }, []);

  useEffect(() => {
    if (project) {
      WeatherApi.getWeather(project.city, project.state, 3).then((report) => setWeatherReport(report));
    }
  }, [project]);

  useEffect(() => {
    if (!project || !selectedDate) {
      return;
    }

    const fetchIssues = async (): Promise<TaskGanttModel[]> => {
      const issues = await loadIssues({
        queryParams: {
          status: TaskStatusType.inProgress,
        },
        projectId,
        dataRange: undefined,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        done: () => {}, // no-op since we're using the return value directly
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setLoading: () => {}, // no-op if we don't need loading state
      });

      return issues.map((t) => toGanttTaskModel(camelizeKeys(t)));
    };

    const load = async () => {
      setLoadingTasks(true);
      const begin = dayjs(selectedDate).startOf('day');
      const end = begin.add(1, 'day').endOf('day');

      let tasksByDate = await fetchTasksByDate(projectId, begin, end);
      tasksByDate = populateOSKMap(tasksByDate, oskMap.current);
      setTasks(tasksByDate);

      let blockedResults = await fetchBlockedTasks(projectId);
      blockedResults = populateOSKMap(blockedResults, oskMap.current);
      setBlockedTasks(
        blockedResults
          .filter((task) => task.status === TaskStatusType.blocked)
          .filter((task) => task.object_type === TaskObjectType.activity)
          .map((t) => {
            return toGanttTaskModel(camelizeKeys(t)) as GanttTask;
          }),
      );

      let issuesResult = await fetchIssues();
      issuesResult = populateOSKMap(issuesResult, oskMap.current);
      setIssues(issuesResult);

      setLoadingTasks(false);
    };
    load();
  }, [project, projectId, selectedDate]);

  const publicURL = window.location.origin;
  const loading = isLoadingSubcontractors || loadingTasks || project === null || weatherReport === null;

  const selDayIdx = dateChoices.current?.map((c) => c.value).indexOf(selectedDate) || 0;

  const dayForecast = weatherReport?.forecast?.forecastday[Math.max(selDayIdx, 0)];
  const forecastIcon = dayForecast?.day.condition.icon;
  const chanceRain = dayForecast?.day.daily_chance_of_rain;
  const maxTempF = dayForecast?.day.maxtemp_f;
  const maxTempC = dayForecast?.day.maxtemp_c;

  const chanceOfRain =
    isJapanese && weatherReport && dayForecast
      ? t('risk.report.chance_pct', {value: chanceRain})
      : t('risk.report.pct_chance', {value: chanceRain});

  if (loading) {
    return <Loader />;
  }

  const safetyRows = getSafety(tasks, dayForecast, labelCategories, selectedDate, oskMap.current, subMap.current, t);
  const qualityRows = getQuality(tasks, dayForecast, labelCategories, selectedDate, oskMap.current, subMap.current, t);
  const planningRows = getPlanning(
    tasks,
    issues,
    dayForecast,
    labelCategories,
    selectedDate,
    oskMap.current,
    subMap.current,
    t,
  );
  const externalFactorRows = getExternalFactors(tasks, selectedDate, dayForecast, t);
  const blockedRows = getBlocked(blockedTasks, oskMap.current, subMap.current, t);

  const allRows = safetyRows.concat.apply([], [qualityRows, planningRows, externalFactorRows, blockedRows]);
  const hasCompanies = allRows.some((row) => row.sub);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={s.daily_status}>
          <CtrlButton
            className={s.back_btn}
            view="link"
            onClick={() => {
              mixpanel.trackWithAction(() => {
                history.push({
                  pathname: generatePath(routes.tasks, {projectId}),
                });
              }, mixpanelEvents.tasksButtonClicked);
            }}
          >
            &lt; Back to Project
          </CtrlButton>
          <CtrlButton
            className={cn(s.print_btn, s.print_btn_top)}
            color="default"
            view="selector"
            onClick={() => {
              mixpanel.trackWithAction(() => {
                window.print();
              }, mixpanelEvents.printButtonClicked);
            }}
          >
            <Icon colorFill size={24} name="print" /> {t('risk.controls.print', 'Print')}
          </CtrlButton>
          <div className={s.status_header}>
            <img className={s.logo} src={`${publicURL}/images/logo/logo-crews.png`} alt="" />
            <div className={s.title}>{t('risk.report.title', 'daily risk checklist')}</div>
            <div className={s.metasection}>
              <div className={s.metadata}>
                <div> Project: {project.name} </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div className={s.date_label}>Date:</div>
                  <CoreSelect
                    className={s.date_select}
                    value={selectedDate}
                    options={dateChoices.current}
                    onChange={(value) => {
                      let event = mixpanelEvents.todayButtonClicked;
                      const idx = dateChoices.current.findIndex((opt) => opt.value === value);
                      if (idx === 1) {
                        event = mixpanelEvents.tomorrowButtonClicked;
                      } else if (idx === 2) {
                        event = mixpanelEvents.nextDayButtonClicked;
                      }
                      mixpanel.trackWithAction(() => setSelectedDate(value), event);
                    }}
                    menuPlacement="auto"
                    styles={{
                      menu: (baseStyles, _state) => ({
                        ...baseStyles,
                        width: '300px',
                      }),
                    }}
                  />
                </div>
              </div>
              {weatherReport ? (
                <div className={s.weather}>
                  {forecastIcon && <img src={`http:${forecastIcon}`} />}
                  <span>{chanceOfRain}</span>
                  {project.country === 'USA' ? (
                    <span className={s.weather_text}>
                      {maxTempF}&deg;{t('risk.report.fahrenheit', ' F (max)')}
                    </span>
                  ) : (
                    <span className={s.weather_text}>
                      {maxTempC}&deg;{t('risk.report.celsius', ' C (max)')}
                    </span>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <Section
            title={t('risk.headers.safety', 'Safety')}
            showCompany={hasCompanies}
            rows={safetyRows}
            category={TaskLabelCategory.safety}
            t={t}
          />
          <Section title={t('risk.headers.quality', 'Quality')} showCompany={hasCompanies} rows={qualityRows} t={t} />
          <Section
            title={t('risk.headers.planning', 'Planning')}
            showCompany={hasCompanies}
            rows={planningRows}
            t={t}
          />
          <Section
            title={t('risk.headers.external_factors', 'External Factors')}
            showCompany={hasCompanies}
            rows={externalFactorRows}
            t={t}
          />
          <Section
            title={t('risk.headers.blocked', 'Blocked Tasks')}
            showCompany={hasCompanies}
            rows={blockedRows}
            t={t}
          />
          {getLegend(t)}
        </div>
      )}
    </>
  );
};

export default DailyRisk;
