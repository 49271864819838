import cn from 'classnames';
import {cloneElement, isValidElement, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import Avatar from 'shared/components/Avatar';
import {WorkerTrade} from 'shared/models/worker';

import s from './Member.module.scss';

type MemberProps = {
  className?: string;
  memberName: string;
  memberTrade?: WorkerTrade;
  memberOrgs?: string[];
  memberProfilePicUrl?: string;
  action?: ReactElement<{className?: string}>;
};

const Member = ({className, memberName, memberTrade, memberOrgs, memberProfilePicUrl = '', action}: MemberProps) => {
  const {t} = useTranslation('common');

  return (
    <article className={cn(s.Member, className)}>
      <header className={s.Member__header}>
        <Avatar src={memberProfilePicUrl} className={s.Member__avatar} />
        <div className={s.Member__info}>
          <h3 className={s.Member__name}>{memberName}</h3>
          {memberTrade && <div className={s.Member__trade}>{t(`worker_trades.${memberTrade}`) || memberTrade}</div>}
        </div>
        {action && (
          <div className={s.Member__actions}>
            {isValidElement(action) &&
              cloneElement(action, {
                ...action.props,
                className: cn(action.props.className),
              })}
          </div>
        )}
      </header>
      {!!memberOrgs?.length && (
        <div className={s.Member__body}>
          <div className={s.Member__orgWrap}>
            {memberOrgs.map((org) => (
              <div key={org} className={s.Member__org}>
                {org}
              </div>
            ))}
          </div>
        </div>
      )}
    </article>
  );
};

export default Member;
