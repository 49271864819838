import {useQuery} from '@tanstack/react-query';
import {GanttStatic} from 'dhtmlx-gantt';
import {useEffect, useCallback} from 'react';

import ProjectsApi from 'api/projects';
import {container} from 'IocContainer';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {IOC_TYPES} from 'shared/models/ioc';
import {TasksStoreType} from 'shared/stores/TasksStore';

import {GanttEventNameUnion} from '../utils/gantt';

export function useLoadRowNumbersAndLinks(gantt: GanttStatic, projectId: string) {
  const tasksStore = container.get<TasksStoreType>(IOC_TYPES.TasksStore);

  const {refetch: refetchRowNumbers} = useQuery({
    queryKey: QUERY_CACHE_KEYS.projectRowMap(projectId),
    queryFn: async () => ProjectsApi.getRowNumberMap(projectId),
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  });

  const refreshDepsForTaskIds = useCallback(
    async (updatedTaskIds: string[]) => {
      const res = await ProjectsApi.getTaskDependencies(projectId, updatedTaskIds);
      const tidSet = new Set(updatedTaskIds);
      // strip out any possibly-stale dependencies
      const nextDeps = tasksStore.dependencies.filter((dep) => {
        return !tidSet.has(dep.taskId);
      });
      // Append deps fetched for taskIds list
      nextDeps.push(...res.dependencies);
      tasksStore.setDependencies(nextDeps);
    },
    [projectId, tasksStore],
  );

  const {refetch: refetchDependencies} = useQuery({
    queryKey: QUERY_CACHE_KEYS.projectDependencies(projectId, gantt.name),
    queryFn: async () => {
      const tasks = gantt.getTaskByTime();
      // filter out placeholder task and new created tasks for which we don't know real id yet
      const tasksIds = tasks
        .filter(({type, id}) => type !== 'placeholder' && typeof id === 'string')
        .map((task) => task.id);
      if (tasksIds.length) {
        await refreshDepsForTaskIds(tasksIds);
      }
      return [];
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    structuralSharing: false,
  });

  const refetchData = useCallback(async () => {
    await refetchDependencies();
  }, [refetchDependencies]);

  const refetchDataForTaskIds = useCallback(
    async (taskIds: string[]) => {
      await refreshDepsForTaskIds(taskIds);
    },
    [refreshDepsForTaskIds],
  );

  useEffect(() => {
    const events = [];
    events.push(
      gantt.attachEvent(
        'DataLoaded' as GanttEventNameUnion,
        () => {
          refetchDependencies();
        },
        undefined,
      ),
    );

    events.push(
      gantt.attachEvent(
        'afterTasksImport' as GanttEventNameUnion,
        () => {
          refetchData();
        },
        undefined,
      ),
    );

    events.push(
      gantt.attachEvent(
        'afterTasksClone' as GanttEventNameUnion,
        (taskIds: string[]) => {
          refreshDepsForTaskIds(taskIds);
        },
        undefined,
      ),
    );

    return () => events.forEach((eventId) => gantt.detachEvent(eventId));
  }, [gantt, refetchData, refetchDependencies, refreshDepsForTaskIds, refetchRowNumbers]);

  return {
    refetchData,
    refetchDataForTaskIds,
  };
}
