import {Container} from 'inversify';
import {createContext, use, useMemo} from 'react';

const InversifyContext = createContext({container: null});

export const InjectionProvider = (props: {container: Container; children: React.ReactNode}) => {
  const container = useMemo(() => props.container, [props.container]);

  return <InversifyContext value={{container}}>{props.children}</InversifyContext>;
};

export function useInjectStore<T>(identifier: symbol): T {
  const {container} = use(InversifyContext);

  if (!container) {
    throw new Error('useInjection must be used within InjectionProvider');
  }

  const instance = useMemo(() => container.get(identifier), [container, identifier]);

  return instance;
}
