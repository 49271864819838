import cn from 'classnames';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {useHistory, useParams, useRouteMatch} from 'react-router';
import {generatePath} from 'react-router-dom';

import EmptyGridBubble from 'shared/components/EmptyGridBubble';
import ProjectLevelToolbar from 'shared/components/ProjectLevelToolbar';
import ProjectNameInlineEdit from 'shared/components/ProjectNameInlineEdit';
import Table from 'shared/components/Table';
import WorkspaceSwitcher from 'shared/components/WorkspaceSwitcher';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {sortAlphabetically} from 'shared/helpers/common';
import {filterDuplicatedCompanyWorkers} from 'shared/helpers/worker';
import {useMount} from 'shared/hooks/core/useMount';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useClassName} from 'shared/hooks/useClassName';
import {useCompany} from 'shared/hooks/useCompany';
import {useProjectSelector} from 'shared/hooks/useProjectSelector';
import {useProjectWorkers} from 'shared/hooks/useProjectWorkers';
import {useResponsibleOrgColors} from 'shared/hooks/useResponsibleOrgColors';
import {useSaveLastSelectedProjectId} from 'shared/hooks/useSaveLastSelectedProjectId';
import {Panel, ScreenGreed} from 'shared/layout/admin';
import {CompanyOrgs} from 'shared/models/company';

import {SubcontractorsContext} from './components/SubcontractorsContext';
import SubcontractorsPanel from './components/SubcontractorsPanel';
import {useGetSubcontractors} from './hooks/useGetSubcontractors';
import {useSubcontractorsColumns} from './hooks/useSubcontractorsColumns';
import s from './Subcontractors.module.scss';
import {CompanyOrgsExtends} from './types';
import {flatSubs} from './utils/utils';

const Subcontractors = () => {
  const {projectId} = useParams<{projectId: string}>();
  const project = useProjectSelector(projectId);
  const routes = useLocalizedRoutes();
  const company = useCompany();
  const history = useHistory();
  const subcontractorMatch = useRouteMatch(routes.subcontractor);
  const {lookaheadColors} = useResponsibleOrgColors();
  const columns = useSubcontractorsColumns(lookaheadColors);
  const [localSubs, setLocalSubs] = useState<CompanyOrgs[]>([]);
  const {t} = useTranslation(['gantt', 'workers', 'panel']);
  const {projectWorkers} = useProjectWorkers();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId}});
  const mixpanelEvents = mixpanel.events.projectOrgs;
  useSaveLastSelectedProjectId(routes.subcontractors);

  useMount(() => {
    mixpanel.track(mixpanelEvents.screen);
  });
  const preparedCompanyWorkers = useMemo(() => {
    if (!projectWorkers) return [];
    const result = {};
    filterDuplicatedCompanyWorkers(projectWorkers).forEach((cw) => {
      result[cw.workerId] = {memberName: cw.workerFull.fullName, memberImage: cw.workercard?.profilePicUrl || ''};
    });
    return result;
  }, [projectWorkers]);

  useClassName(document.querySelector('.screen'), 'screen--size-full');

  const {projectSubs, hasNextPage, fetchNextPage, isLoading, refetchSubs} = useGetSubcontractors(projectId);

  const closePanel = useCallback(() => {
    history.push(
      generatePath(routes.subcontractors, {
        projectId,
      }),
    );
  }, [history, projectId, routes.subcontractors]);

  const addSubcontractor = () => {
    mixpanel.track(mixpanelEvents.toolbar.createOrg);
    history.push(generatePath(routes.subcontractor, {id: 'new', projectId}), {
      from: history.location.pathname,
    });
  };

  const searchSubcontractor = (value: string) => {
    let filteredSubs = projectSubs?.pages?.flat();
    if (value) {
      filteredSubs = filteredSubs.filter(
        (sub) => sub?.group?.name && sub.group.name.toLocaleLowerCase().includes(value.trim().toLocaleLowerCase()),
      );
    }
    setLocalSubs(sortAlphabetically(filteredSubs, 'abbrev'));
  };

  useEffect(() => setLocalSubs(sortAlphabetically(flatSubs(projectSubs?.pages), 'abbrev')), [projectSubs]);

  const preparedSubs = useMemo<CompanyOrgsExtends[]>(() => {
    if (!localSubs) return [];
    return localSubs.map((s) => {
      return {...s, designatedPerson: preparedCompanyWorkers[s.contactWorkerId]};
    });
  }, [localSubs, projectWorkers]);

  const showNowData = !localSubs?.length && !isLoading && !!company?.id && !subcontractorMatch;

  const onCompanyClick = (company: CompanyOrgs) => {
    mixpanel.track(mixpanelEvents.editOrg);
    history.push(
      generatePath(routes.subcontractor, {
        id: company.id,
        projectId,
      }),
    );
  };

  const deleteOrgFromTable = useCallback(
    (id: string) => {
      setLocalSubs((localSubs) => localSubs.filter((sub) => id !== sub.id));
      closePanel();
      refetchSubs();
    },
    [refetchSubs, setLocalSubs, closePanel],
  );

  const getOrg = useCallback(
    (id: string) => {
      return preparedSubs.find((sub) => sub.id === id);
    },
    [preparedSubs],
  );

  return (
    <SubcontractorsContext value={{deleteOrgFromTable, getOrg}}>
      <Panel
        title={<ProjectNameInlineEdit project={project} />}
        actions={<WorkspaceSwitcher projectId={projectId} />}
      />
      <ProjectLevelToolbar
        handleAddItem={addSubcontractor}
        handleInputChange={searchSubcontractor}
        buttonTitle={t('gantt:buttons.add_subcontractor', 'Create Company')}
        buttonIcon="add_circle_2"
        onFocusInput={() => mixpanel.track(mixpanelEvents.toolbar.searchInput)}
      />
      <ScreenGreed
        content={
          <>
            {showNowData ? (
              <EmptyGridBubble
                text={t('panel:subcontractors.empty.title', 'No results found. Please try again.')}
                style={{width: '100%'}}
                t={t}
              />
            ) : (
              <div className={s.Subcontractors__grid}>
                <div className={s.Subcontractors__grid_content}>
                  <Table<CompanyOrgsExtends>
                    data={preparedSubs ?? []}
                    columns={columns}
                    isLoading={isLoading}
                    hasMore={hasNextPage}
                    next={fetchNextPage}
                    tableClassName={cn(
                      s.Subcontractors__table,
                      !!subcontractorMatch && s.Subcontractors__table_CustomContent,
                    )}
                    headerClassName={cn(
                      s.Subcontractors__table,
                      !!subcontractorMatch && s.Subcontractors__table_CustomHeader,
                    )}
                    skeletonLoader={<Skeleton style={{margin: '24px 0'}} count={6} height={32} />}
                    onRowClick={({original}) => onCompanyClick(original)}
                  />
                  {!!subcontractorMatch && (
                    <div className={cn('activity-details_wrap', s.Subcontractors__panelWrap)}>
                      <SubcontractorsPanel onClose={closePanel} className={s.Subcontractors__panel} />
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        }
      />
    </SubcontractorsContext>
  );
};

export default Subcontractors;
