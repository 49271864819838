import {CellContext, ColumnDef} from '@tanstack/react-table';
import {MouseEventHandler, ReactNode, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams, generatePath} from 'react-router';

import InfoMember, {InfoMemberProps} from 'shared/components/CoreNewUI/InfoMember/InfoMember';
import {useLocalizedRoutes, RouteParams} from 'shared/constants/routes';
import {LookaheadColors} from 'shared/hooks/useResponsibleOrgColors';

import SubcontractorInfo from '../components/SubcontractorInfo';
import {CompanyOrgsExtends} from '../types';

type SubcontractorValue = {
  value: CompanyOrgsExtends;
  color: string;
};

type WorkersCountValue = {
  nbWorkers: number;
  onClick: MouseEventHandler<HTMLDivElement>;
};

export const CenteredColumn = ({getValue}: CellContext<CompanyOrgsExtends, string | number | ReactNode>) => {
  const value = getValue();
  return <CenteredContainer>{value}</CenteredContainer>;
};

export const CenteredContainer: React.FC<React.HTMLProps<HTMLDivElement>> = ({children, ...props}) => {
  const centerStyles = {display: 'flex', alignItems: 'center', height: '100%'};
  return (
    <div style={centerStyles} {...props}>
      {children}
    </div>
  );
};

export const CenteredColumnSubcontractors = ({getValue}: CellContext<CompanyOrgsExtends, WorkersCountValue>) => {
  const {nbWorkers = 0, onClick} = getValue();
  return <CenteredContainer onClick={onClick}>{nbWorkers}</CenteredContainer>;
};

const InfoMemberCell = ({getValue}: CellContext<CompanyOrgsExtends, InfoMemberProps>) => {
  const {memberImage, memberName} = getValue();
  if (!memberImage && !memberName) return null;
  return <InfoMember memberName={memberName} memberImage={memberImage} />;
};

export const useSubcontractorsColumns = (lookaheadColors: LookaheadColors) => {
  const {t} = useTranslation('panel');
  const routes = useLocalizedRoutes();
  const history = useHistory();
  const {projectId} = useParams<RouteParams['tasks']>();

  const goToWorkers =
    (name: string): MouseEventHandler<HTMLDivElement> =>
    (e) => {
      e.stopPropagation();
      history.push({
        pathname: generatePath(routes.projectWorkers, {projectId}),
        search: `?orgList=${name}`,
      });
    };

  return useMemo((): ColumnDef<CompanyOrgsExtends>[] => {
    return [
      {
        header: t('subcontractor_table.column.subcontractor', 'Company'),
        id: 'subcontractor',
        accessorFn: (row: CompanyOrgsExtends): SubcontractorValue => ({
          value: row,
          color: lookaheadColors?.[row?.lookaheadColor].fill,
        }),
        cell: (props: CellContext<CompanyOrgsExtends, SubcontractorValue>) => <SubcontractorInfo {...props} />,
        minSize: 200,
        size: 200,
      },
      {
        header: t('subcontractor_table.column.designated_person', 'Designated Person'),
        id: 'contactWorkerId',
        accessorFn: (row: CompanyOrgsExtends): InfoMemberProps => ({
          memberName: row?.designatedPerson?.memberName,
          memberImage: row?.designatedPerson?.memberImage,
        }),
        cell: (props: CellContext<CompanyOrgsExtends, InfoMemberProps>) => <InfoMemberCell {...props} />,
        minSize: 200,
        size: 200,
      },
      {
        header: t('subcontractor_table.column.workers_count', 'Number of Workers'),
        id: 'nbWorkers',
        accessorFn: (row: CompanyOrgsExtends): WorkersCountValue => ({
          nbWorkers: row.nbWorkers,
          onClick: goToWorkers(row.group.name),
        }),
        cell: (props: CellContext<CompanyOrgsExtends, WorkersCountValue>) => (
          <CenteredColumnSubcontractors {...props} />
        ),
        minSize: 100,
        size: 100,
      },
    ];
  }, [lookaheadColors, t, routes.projectWorkers, projectId]);
};
