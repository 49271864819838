import {createContext, Dispatch, use} from 'react';

import {bindActionCreators} from 'shared/helpers/reducerHelpers';

import {actionCreators, getDefaultTaskImportContext, TasksImportState} from './TasksImportState';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TasksImportContext = TasksImportState & {dispatch: Dispatch<any>};

export const TasksImportContext = createContext<TasksImportContext>({
  ...getDefaultTaskImportContext(),
  dispatch: () => console.warn('Dispatch not provided'),
});

export function useTasksImportContext() {
  const {dispatch, ...state} = use(TasksImportContext);
  const actions = bindActionCreators(actionCreators, dispatch);
  const res = [state, actions, dispatch] as const;
  Object.assign(res, {
    state,
    actions,
    dispatch,
  });
  return res as typeof res & {state: typeof state; actions: typeof actions; dispatch: typeof dispatch};
}
