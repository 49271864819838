import {InfiniteData, useMutation, useQueryClient} from '@tanstack/react-query';
import {useParams, useRouteMatch} from 'react-router';
import {toast} from 'react-toastify';

import ProjectsApi from 'api/projects';
import {SubcontractorFormValues} from 'modules/Subcontractors/utils/utils';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {extractAxiosError} from 'shared/helpers/axios';
import {useQueryCache} from 'shared/hooks/useQueryCache/useQueryCache';
import {CompanyOrgs} from 'shared/models/company';

export const useCreateSubcontractor = () => {
  const {projectId} = useParams<{projectId: string}>();
  const {cacheHelper} = useQueryCache();
  const queryClient = useQueryClient();

  const updateSubcontractorsCache = (response: CompanyOrgs, projectId: string) => {
    const queryKey = QUERY_CACHE_KEYS.projectSubcontractorsWithPaginate(projectId);

    queryClient.setQueryData<InfiniteData<CompanyOrgs[]>>(queryKey, (data) =>
      data ? cacheHelper.prependToPagedData(data, response) : data,
    );
  };

  return useMutation({
    mutationFn: (values: SubcontractorFormValues) => {
      return ProjectsApi.createOrg(projectId, values);
    },

    onSuccess: (subcontractor) => updateSubcontractorsCache(subcontractor, projectId),

    onError: (error) => {
      const message = extractAxiosError(error);
      if (typeof error === 'string') toast.error(message as string);
    },
  });
};

export const useUpdateSubcontractor = () => {
  const {projectId} = useParams<{projectId: string}>();
  const {cacheHelper} = useQueryCache();
  const routes = useLocalizedRoutes();
  const match = useRouteMatch<{id: string}>(routes.subcontractor);
  const queryClient = useQueryClient();

  const updateSubcontractorsCache = (response: CompanyOrgs, projectId: string) => {
    const queryKey = QUERY_CACHE_KEYS.projectSubcontractorsWithPaginate(projectId);

    queryClient.setQueryData<InfiniteData<CompanyOrgs[]>>(queryKey, (oldData) =>
      oldData ? cacheHelper.updatePagedData(oldData, response) : oldData,
    );
  };

  return useMutation<CompanyOrgs, Error, Partial<CompanyOrgs>>({
    mutationFn: (values) => {
      return ProjectsApi.updateOrg(projectId, match?.params?.id, values);
    },
    onSuccess: (subcontractor) => updateSubcontractorsCache(subcontractor, projectId),
  });
};
