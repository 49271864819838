import {createContext, ReactNode, use} from 'react';

import {TasksObserver} from 'services/TasksObserver/TasksObserver';

const TasksObserverContext = createContext<TasksObserver>(null);

export function TasksObserverProvider({
  children,
  value = TasksObserver.getInstance(),
}: {
  children: ReactNode;
  value?: TasksObserver;
}) {
  return <TasksObserverContext value={value}>{children}</TasksObserverContext>;
}

export function useTasksObserver() {
  return use(TasksObserverContext);
}
