import axios, {CancelToken} from 'axios';
import {Effect} from 'effect';
import {InterruptedException, UnknownException} from 'effect/Cause';

import TasksApi from 'api/tasks';
import {SortOrder} from 'shared/constants/common';
import {SortField, TaskProjection} from 'shared/models/task/const';
import {TaskFilterQuery, TaskStatusQuery} from 'shared/models/task/filter';
import {IssueModelRawDTO, TaskModelRawDTO} from 'shared/models/task/task';

import {ApiResponse} from '../BatchLoader';
import {BadGatewayError, EffectError, HttpError} from '../errors';

export const createFetchIssuesBatch = ({
  projectId,
  queryParams,
  dataRange,
}: {
  projectId: string;
  queryParams: Partial<TaskFilterQuery>;
  dataRange: Date[];
}) => {
  return (
    offset: number,
    take: number,
    cancelToken: CancelToken,
  ): Effect.Effect<ApiResponse<IssueModelRawDTO>, EffectError, never> =>
    Effect.tryPromise({
      try: () =>
        TasksApi.getTaskIssues(projectId, {
          offset,
          limit: take,
          sortParams: {
            sortField: 'description',
            sortOrder: SortOrder.DESC,
          },
          filterParams: {
            ...queryParams,
            schedIntersect: dataRange?.map((date) => date.toISOString()) as [string, string],
          },
          cancelToken,
        }),
      catch: (error): EffectError => {
        if (axios.isCancel(error)) {
          return new InterruptedException('Request cancelled');
        }
        return handleAxiosError(error);
      },
    }).pipe(
      Effect.map((response) => ({
        data: response.data,
        headers: response.headers,
      })),
    );
};

export const createFetchDailiesIssuesBatch = ({
  projectId,
  queryParams,
}: {
  projectId: string;
  queryParams: Partial<TaskFilterQuery>;
}) => {
  return (
    offset: number,
    take: number,
    cancelToken: CancelToken,
  ): Effect.Effect<ApiResponse<IssueModelRawDTO>, EffectError, never> =>
    Effect.tryPromise({
      try: () =>
        TasksApi.getTaskIssues(projectId, {
          offset,
          limit: take,
          sortParams: {
            sortField: 'description',
            sortOrder: SortOrder.DESC,
          },
          filterParams: {
            ...queryParams,
            schedEndFirst: queryParams.schedEndFirst,
          },
          cancelToken,
        }),
      catch: (error): EffectError => {
        if (axios.isCancel(error)) {
          return new InterruptedException('Request cancelled');
        }
        return handleAxiosError(error);
      },
    }).pipe(
      Effect.map((response) => ({
        data: response.data,
        headers: response.headers,
      })),
    );
};

export const createFetchTaskDetailsBatch = ({
  filterParams,
}: {
  filterParams: Partial<TaskFilterQuery & TaskStatusQuery>;
}) => {
  return (
    offset: number,
    take: number,
    cancelToken: CancelToken,
  ): Effect.Effect<ApiResponse<TaskModelRawDTO>, EffectError, never> => {
    const batchTaskIds = filterParams.ids.slice(offset, offset + take);

    return Effect.tryPromise({
      try: () =>
        TasksApi.getProjectTasks({
          projection: TaskProjection.taskSmall,
          offset,
          limit: take,
          sortField: SortField.outlineSortKey,
          sortOrder: SortOrder.ASC,
          params: {ids: batchTaskIds, ...filterParams},
          cancelToken,
        }),
      catch: (error): EffectError => {
        if (axios.isCancel(error)) {
          return new InterruptedException('Request cancelled');
        }
        return handleAxiosError(error);
      },
    }).pipe(
      Effect.map((response) => ({
        data: response.data as TaskModelRawDTO[],
        headers: response.headers,
      })),
    );
  };
};

export const handleAxiosError = (error: unknown): EffectError => {
  if (axios.isAxiosError(error)) {
    switch (error.response?.status) {
      case 502:
        return new BadGatewayError({
          message: error.message,
          cause: error,
        });
      default:
        return new HttpError({
          statusCode: error.response?.status || 500,
          message: error.message,
          cause: error,
        });
    }
  }
  return new UnknownException({
    message: error instanceof Error ? error.message : 'Unknown error',
    cause: error instanceof Error ? error : undefined,
  });
};
