import classNames from 'classnames';
import {CSSProperties, FC, PropsWithChildren, useRef} from 'react';
import {createPortal} from 'react-dom';

import {useEventListener} from 'shared/hooks/core/useEventListener';
import {useOutsideClick} from 'shared/hooks/core/useOusideClick';
import {usePopupScrollHandler} from 'shared/hooks/usePopupScrollHandler';

import Button from '../Button';
import Icon from '../Icon';

export type PopupContentProps = {
  onClose?: () => void;
  containerClassName?: string;
  className?: string;
  closeOnOutsideClick?: boolean;
  containerStyle?: CSSProperties;
  ignore?: string;
  isCloseButtonInside?: boolean;
  showCloseIcon?: boolean;
  style?: CSSProperties;
};

const PopupContent: FC<PropsWithChildren<PopupContentProps>> = ({
  onClose,
  className,
  containerClassName,
  closeOnOutsideClick = true,
  showCloseIcon = true,
  children,
  containerStyle,
  isCloseButtonInside,
  style = null,
  ignore = '',
}) => {
  useEventListener('keydown', (e) => {
    if (e?.key?.toLocaleLowerCase() === 'escape') {
      onClose();
    }
  });
  const popupContainer = useRef<HTMLDivElement>(null);
  useOutsideClick({ref: popupContainer, callback: () => closeOnOutsideClick && onClose(), ignore});
  usePopupScrollHandler();
  return createPortal(
    <section className={classNames('popup', containerClassName)} style={containerStyle}>
      <div className={classNames('popup__item', className)} ref={popupContainer} style={style}>
        {children}
        {showCloseIcon && (
          <Button
            iconOnly
            icon={<Icon name="clear" size={24} colorFill />}
            className={!isCloseButtonInside ? 'popup__button-close' : ''}
            onClick={onClose}
            style={
              isCloseButtonInside && {
                position: 'absolute',
                top: '16px',
                right: '16px',
                color: 'rgba(250, 56, 13, 0.5)',
              }
            }
          />
        )}
      </div>
    </section>,
    document.body,
  );
};
export default PopupContent;
