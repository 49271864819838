import * as Sentry from '@sentry/browser';
import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import WorkerApi from 'api/worker';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {useDebounce} from 'shared/hooks/core/useDebounce';
import {usePrevious} from 'shared/hooks/core/usePrevious';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useProfile} from 'shared/hooks/useProfile';
import {TaskObjectType} from 'shared/models/task/const';

import {TasksViewMode} from '../../constants/common';
import Button from '../Button';
import Confirmation from '../Confirmation';
import SkeletonFieldPreloader from '../CoreForm/SkeletonFieldPreloader';
import Icon from '../Icon';

type ShareTaskProps = {
  taskId: string;
  projectId: string;
  viewMode: TasksViewMode;
  visible: boolean;
  onClose: () => void;
  objectType?: TaskObjectType;
};

const ShareTask: FC<ShareTaskProps> = ({
  taskId,
  visible,
  onClose,
  objectType = TaskObjectType.activity,
  projectId,
  viewMode,
}) => {
  const profile = useProfile();
  const [localSharedLink, setLocalSharedLink] = useState<string>(null);
  const prevShowLink = usePrevious(visible);
  const prevTaskId = usePrevious(taskId);
  const {t} = useTranslation(['gantt', 'lookahead', 'common']);
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});

  // we should clear local link
  // if we change task in activity details component
  useEffect(() => {
    if (prevTaskId && taskId !== prevTaskId) {
      setLocalSharedLink('');
    }
  }, [taskId]);

  useEffect(() => {
    if (visible) {
      getTaskShareLink().then((link) => {
        setLocalSharedLink(link);
      });
    } else if (!visible && prevShowLink) {
      clearSharedLink();
    }
  }, [visible, localSharedLink]);

  const getTaskShareLink = async () => {
    if (!localSharedLink) {
      try {
        const {url} = await WorkerApi.getSharedLoginToken(profile.id, taskId);
        return url;
      } catch (error) {
        Sentry.captureException(error);
        if (isAxiosError(error)) {
          toast.error(extractAxiosError(error) as string);
        }
      }
    }

    return localSharedLink;
  };

  const copySharedLink = () => {
    mixpanel.track(mixpanel.events.task.actions.copyTaskShareLink);
    navigator.clipboard
      .writeText(localSharedLink)
      .then(() => toast.success(t('gantt:toast.success.copy', 'Copied!')))
      .catch((err) => {
        Sentry.captureException(err);
        toast.error(t('gantt:toast.error.copy', 'Something went wrong while copying the link.'));
      });
  };

  const clearSharedLink = useDebounce(() => {
    setTimeout(() => setLocalSharedLink(''), 0);
  }, 60 * 1000);

  return (
    <>
      <Confirmation
        title={t('lookahead:share_task.confirmation.title', 'Share task')}
        visible={visible}
        onClose={onClose}
        acceptButton={{
          title: t('lookahead:share_task.buttons.accept', 'Done'),
          type: 'success',
        }}
        onAccept={copySharedLink}
        className="confirmation--type-share"
      >
        <div className="ctrl-share-task confirmation__share">
          <div className="ctrl-share-task__header">
            <h3 className="ctrl-share-task__title">
              {t('lookahead:share_task.title', 'Share this activity with your Crew', {
                objectType: `$t(common:${objectType})`,
              })}
            </h3>
          </div>
          <div className="ctrl-share-task__form">
            <SkeletonFieldPreloader when={!localSharedLink}>
              <div className="ctrl-share-task__item">
                <div className="ctrl-form ctrl-form--label-hidden">
                  <div className="ctrl-form__header">
                    <label className="ctrl-form__label">{t('lookahead:share_task.url_label', 'URL')}</label>
                  </div>
                  <div className="ctrl-form__body">
                    <input
                      className="ctrl-textfield"
                      type="text"
                      placeholder={!localSharedLink && t('lookahead:share_task.placeholder', 'Loading...')}
                      value={localSharedLink}
                      readOnly
                    />
                  </div>
                </div>
                <Button
                  className="ctrl-btn ctrl-btn--shadow ctrl-share-task__button-copy"
                  buttonSize="xs"
                  type="button"
                  onClick={copySharedLink}
                  icon={<Icon name="copy" colorFill fill={false} size={24} />}
                >
                  {t('lookahead:share_task.buttons.copy_link', 'Copy link')}
                </Button>
              </div>
            </SkeletonFieldPreloader>
          </div>
        </div>
      </Confirmation>
    </>
  );
};

export default ShareTask;
