import {FC, PropsWithChildren, createContext, use} from 'react';

interface ProgressReportPopupContext {
  updateCurrentTaskId: (taskId: string) => void;
  currentTaskId: string;
}

const ProgressReportPopupContext = createContext<ProgressReportPopupContext | undefined>(undefined);

const ProgressReportPopupProvider: FC<PropsWithChildren<ProgressReportPopupContext>> = ({children, ...props}) => (
  <ProgressReportPopupContext value={{...props}}>{children}</ProgressReportPopupContext>
);

export default ProgressReportPopupProvider;

export const useProgressReportPopupContext = () => {
  const context = use(ProgressReportPopupContext);
  if (!context) {
    throw new Error('useProgressReportPopupContext must be used within a ProgressReportProvider');
  }
  return context;
};
