import cn from 'classnames';
import {cloneElement, isValidElement, ReactElement} from 'react';

import s from './Review.module.scss';

type ReviewProps = {
  className?: string;
  text?: string;
  authorName?: string;
  authorPosition?: string;
  authorImage?: ReactElement<{className?: string}>;
};

const Review = ({
  className,
  text = 'Quote text',
  authorName = 'Author Name',
  authorPosition = 'Author Position',
  authorImage,
}: ReviewProps) => {
  return (
    <blockquote className={cn(s.review, className)}>
      <div className={s.review__text}>{text}</div>
      <div className={s.review__author}>
        {isValidElement(authorImage) &&
          cloneElement(authorImage, {
            ...authorImage.props,
            className: cn(className, s.review__photo),
          })}
        <div className={s.review__name}>{authorName}</div>
        <svg className={s.review__slash} width="20px" height="20px" fill="red" viewBox="0 0 20 20">
          <path d="M11 0h3L9 20H6l5-20z" />
        </svg>
        <div className={s.review__position}>{authorPosition}</div>
      </div>
    </blockquote>
  );
};

export default Review;
