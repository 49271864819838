import {useInfiniteQuery} from '@tanstack/react-query';

import ProjectsApi from 'api/projects';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';

export const useGetSubcontractors = (projectId: string) => {
  const {
    isLoading,
    data: projectSubs,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: QUERY_CACHE_KEYS.projectSubcontractorsWithPaginate(projectId),
    queryFn: () => ProjectsApi.getOrgs(projectId),
    enabled: !!projectId,
    refetchOnWindowFocus: false,
    // Required in v5
    initialPageParam: 0,
    getNextPageParam: () => undefined,
  });

  return {isLoading, projectSubs, hasNextPage, fetchNextPage, refetchSubs: refetch};
};
