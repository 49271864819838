import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import CompanyApi from 'api/company';
import ProjectsApi from 'api/projects';
import TasksApi from 'api/tasks';
import WorkersApi from 'api/workers';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import {SortOrder} from 'shared/constants/common';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {TaskProjection} from 'shared/models/task/const';

export const useCompanyWorker = (companyId: string, workerId: string) => {
  return useQuery({
    queryKey: ['companyworker', {id: workerId}],
    queryFn: () => WorkersApi.getCompanyWorker(companyId, workerId),
    enabled: !!companyId && !!workerId && workerId !== 'new',
  });
};

export const useCompanyOrgs = (companyId: string, enabled = true) => {
  const {data: orgs} = useQuery({
    queryKey: QUERY_CACHE_KEYS.companySubcontractors(companyId),
    queryFn: () => CompanyApi.getCompanyOrgs(companyId),
    enabled: !!companyId && enabled,
  });

  return useMemo(
    () => orgs?.map((org) => ({value: org.group.name, label: org.group.name, id: org.id} as CoreOptionType)) || [],
    [orgs],
  );
};

export const useProjectOrgs = (projectId: string) => {
  const {data: projectOrgs} = useQuery({
    queryKey: QUERY_CACHE_KEYS.projectSubcontractors(projectId),
    queryFn: () => ProjectsApi.getOrgs(projectId),
    enabled: !!projectId,
  });

  return useMemo(
    () =>
      projectOrgs?.map((org) => ({value: org.group.name, label: org.group.name, id: org.id} as CoreOptionType)) || [],
    [projectOrgs],
  );
};

export const useCompanyWorkerProjects = (companyId: string, workerId: string) => {
  return useQuery({
    queryKey: ['projects', {companyWorkerId: workerId}],
    queryFn: () => WorkersApi.getProjects(companyId, workerId).catch(() => []),
    enabled: !!companyId && !!workerId && workerId !== 'new',
  });
};

export const useProjectWorkerProject = (projectId: string) => {
  return useQuery({
    queryKey: ['project', projectId],

    queryFn: async () => {
      const res = await ProjectsApi.getProject(projectId);
      return [res];
    },

    enabled: !!projectId,
  });
};

export const useCompanyWorkerTasks = (companyId: string, workerId: string) => {
  return useQuery({
    queryKey: ['tasks', workerId, companyId],

    queryFn: async () => {
      const tasks = await WorkersApi.getTasks(companyId, workerId);
      return tasks;
    },

    enabled: !!companyId && !!workerId && workerId !== 'new',
    refetchOnWindowFocus: false,
  });
};

export const useProjectWorkerTasks = (projectId: string, workerId: string) => {
  return useQuery({
    queryKey: ['projects', workerId],

    queryFn: async () => {
      const res = await TasksApi.getProjectTasks({
        projection: TaskProjection.taskDetail,
        limit: 500,
        sortOrder: SortOrder.ASC,
        sortField: 'outline_sort_key',
        params: {
          projectId,
          objectTypeList: ['task', 'summary', 'activity'],
          responsible: workerId,
          deleted: false,
        },
      });
      return res.data;
    },

    enabled: !!projectId && !!workerId && workerId !== 'new',
  });
};
