import cn from 'classnames';
import {FieldHelperProps, FieldInputProps, FieldMetaProps, useField} from 'formik';
import {cloneElement, CSSProperties, isValidElement, ReactElement} from 'react';

import s from './FormControlInline.module.scss';

type FormControlInlineProps = {
  className?: string;
  label?: string;
  labelIsHidden?: boolean;
  labelSize?: 'm';
  children: ReactElement<{className?: string}>;
  error?: string;
  style?: CSSProperties;
  required?: boolean;
  value?: string | string[];
};

type FormikChildrenProps<T> = {
  field: FieldInputProps<T>;
  meta: FieldMetaProps<T>;
  form: FieldHelperProps<T>;
};

type FormControlInlineFormikProps<T> = Omit<FormControlInlineProps, 'children'> & {
  name: string;
  children: (props: FormikChildrenProps<T>) => ReactElement;
};

const FormControlInline = ({
  className,
  label,
  labelIsHidden,
  labelSize,
  error,
  style,
  children,
  required,
  value,
}: FormControlInlineProps) => {
  const hideErrorText = required && error ? !value || (Array.isArray(value) && !value.length) : false;

  return (
    <div
      style={style}
      className={cn(
        s.formControlInline,
        labelSize && s[`formControlInline_labelSize_${labelSize}`],
        labelIsHidden && s[`formControlInline_labelIsHidden`],
        !!error && s[`formControlInline_isError`],
        className,
      )}
    >
      <label className={s.formControlInline__label}>{label}</label>
      <div className={cn(s.formControlInline__body, {[s.formControlInline__body_error]: hideErrorText})}>
        {isValidElement(children) &&
          cloneElement(children, {
            ...children.props,
            className: cn(children.props.className),
          })}
      </div>
      {!!error && !hideErrorText && <div className={cn('ctrl-form__validation')}>{error}</div>}
    </div>
  );
};

const FormControlInlineFormik = <T extends string | number | readonly string[]>({
  name,
  children,
  ...props
}: FormControlInlineFormikProps<T>) => {
  const [field, meta, form] = useField(name);
  return (
    <FormControlInline {...props} error={meta.error}>
      {children({field, meta, form})}
    </FormControlInline>
  );
};

FormControlInline.Formik = FormControlInlineFormik;

export default FormControlInline;
