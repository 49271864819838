import {createAsyncThunk, createAction} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import {AuthCredential, ConfirmationResult, User} from 'firebase/auth';

import WorkerApi, {SupportedLoginTypes} from 'api/worker';
import FirebaseService from 'services/Firebase';
import errors from 'shared/constants/errors';
import {isFirebaseError} from 'shared/constants/firebase';
import {isAxiosError} from 'shared/helpers/axios';
import {Worker} from 'shared/models/worker';
import {AppDispatch, RootState} from 'store';
import {logoutUser} from 'store/actions';

import * as types from './types';

interface LoginViaPhoneCredentials {
  confirmationCode?: string;
  confirmationResult?: ConfirmationResult;
  deleteIfNotExist?: boolean;
  credentials?: AuthCredential;
}

interface LoginWithEmailAndPassword {
  email: string;
  password: string;
  deleteIfNotExist?: boolean;
}

// Action Creators
export const loginSuccessed = createAction<{token: string; workerId: string} | undefined>(types.LOGIN_SUCCESS);

export const loginProcess = createAction(types.LOGIN_INPROGRESS);

export const loginFailed = createAction<string>(types.LOGIN_FAILURE);

export const requestingPhoneCodeInProgress = createAction(types.REQUESTING_PHONE_CODE_INPROGRESS);

export const requestingPhoneCodeSuccess = createAction(types.REQUESTING_PHONE_CODE_SUCCESS);

export const requestingPhoneCodeFailed = createAction(types.REQUESTING_PHONE_CODE_FAILED);

export const fullLogout = createAsyncThunk<void, void, {dispatch: AppDispatch}>(
  'auth/fullLogout',
  async (_, {dispatch}) => {
    await FirebaseService.signOut();
    dispatch(logoutUser());
    ['body', 'html'].forEach((s) => (document.querySelector(s)!.scrollTop = 0));
  },
);

export const loginWorkerByPhone = createAsyncThunk<User, LoginViaPhoneCredentials, {state: RootState}>(
  'auth/loginWorkerByPhone',
  async ({confirmationCode, confirmationResult, credentials}) => {
    try {
      if (credentials) {
        await FirebaseService.signInWithCredential(credentials);
      } else {
        await FirebaseService.confirmSignInWithMobile(confirmationCode, confirmationResult);
      }
      return await FirebaseService.getCurrentUser();
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
);

export const loginWorkerByEmail = createAsyncThunk<
  Worker,
  LoginWithEmailAndPassword,
  {dispatch: AppDispatch; state: RootState}
>('auth/loginWorkerByEmail', async ({email, password, deleteIfNotExist = true}, {dispatch}) => {
  try {
    await FirebaseService.signInWithEmailAndPassword(email, password);
    const loggedUser = await FirebaseService.getCurrentUser();
    const checkSignUp = await WorkerApi.checkSignUp(loggedUser.uid);

    if (checkSignUp) {
      const tokenId = await FirebaseService.getUserIdToken();
      return (await WorkerApi.loginOrSignupWorker({
        user: loggedUser,
        idToken: tokenId,
        loginType: 'password' as SupportedLoginTypes,
      })) as Worker;
    } else {
      if (deleteIfNotExist) {
        await FirebaseService.deleteUser();
      }
      throw new Error(errors.auth.emailNotExist);
    }
  } catch (error) {
    Sentry.captureException(error);
    if (isAxiosError(error) || isFirebaseError(error)) {
      dispatch(loginFailed(error.message));
    }
    console.error(error);
    throw error;
  }
});

export const loginConfirmAuth = createAsyncThunk<
  Worker,
  LoginViaPhoneCredentials,
  {dispatch: AppDispatch; state: RootState}
>('auth/loginConfirmAuth', async (credentials, {dispatch}) => {
  try {
    const loggedInUser = await dispatch(loginWorkerByPhone(credentials)).unwrap();
    const checkSignUp = await WorkerApi.checkSignUp(loggedInUser.uid);

    if (checkSignUp) {
      const tokenId = await FirebaseService.getUserIdToken();
      return (await WorkerApi.loginOrSignupWorker({
        user: loggedInUser,
        idToken: tokenId,
      })) as Worker;
    } else {
      if (credentials.deleteIfNotExist) {
        await FirebaseService.deleteUser();
      }
      throw new Error(errors.auth.phoneNotExist);
    }
  } catch (error) {
    if (isAxiosError(error) || isFirebaseError(error)) {
      dispatch(loginFailed(error.message));
    }
    console.error(error);
    throw error;
  }
});
