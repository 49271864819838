import equal from 'fast-deep-equal';
import {Formik, FormikProps} from 'formik';
import {FC, memo, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import {useLocationsOptions} from 'modules/Tasks/components/ActionsBar/components/FilterDropdown/useLocationsOptions';
import {useProjectSubcontractorOptions} from 'modules/Tasks/components/ActionsBar/components/FilterDropdown/useProjectSubcontractorOptions';
import {useTaskStatusOptions} from 'modules/Tasks/components/ActionsBar/components/FilterDropdown/useTaskStatusOptions';
import SearchField from 'modules/Tasks/components/ActionsBar/components/SearchField/SearchField';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {CreatableSelect} from 'shared/components/CoreForm/Select/CreatableSelect/CreatableSelect';
import Autocomplete from 'shared/components/CoreNewUI/Autocomplete/Autocomplete';
import CtrlBtnOption from 'shared/components/CoreNewUI/CtrlBtnOption/CtrlBtnOption';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import Dropdown from 'shared/components/CoreNewUI/CtrlDrop/CtrlDrop';
import Icon from 'shared/components/Icon';
import {RouteParams} from 'shared/constants/routes';
import {getTaskIssueImpactOptions, getTaskIssueTypeOptions} from 'shared/constants/statuses';
import {useDebounce} from 'shared/hooks/core/useDebounce';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {useTasksUrlState} from 'shared/hooks/useTasksUrlState';
import {TaskFilterQuery} from 'shared/models/task/filter';
import {TaskStatusType} from 'shared/models/task/taskStatus';

import s from '../../ActionsBar/components/FilterDropdown/FilterDropdown.module.scss';
import {useResponsibleOptions} from '../../ActionsBar/components/FilterDropdown/useResponsibleOptions';

import {costImpactItems} from './constants';

type Props = {
  className?: string;
  active?: boolean;
  buttonIconOnly?: boolean;
  sideContentDirection?: 'left' | 'right';
};

const IssuesFilterDropdown: FC<Props> = ({className, active, sideContentDirection}: Props) => {
  const tasksState = useTasksUrlState();
  const {projectId} = useParams<RouteParams['tasks']>();
  const {t} = useTranslation(['filters', 'gantt']);
  const {updateSearchParams, reset, queryParams, taskState, filtersState} = useFilterContext();
  const {viewMode} = useFilterContext();
  const {isProjectAdmin} = useCompanyWorkerRoles(projectId);
  const taskStatusOptions = useTaskStatusOptions(queryParams.status, tasksState).filter((s) =>
    [TaskStatusType.inProgress, TaskStatusType.tba, TaskStatusType.closed].includes(s.value as TaskStatusType),
  );
  const preparedLocations = useLocationsOptions(projectId, queryParams.locations);
  const {data: subcontractorOptions, isLoading: isLoadingSubcontractors} = useProjectSubcontractorOptions(projectId);
  const impactOptions = useMemo(() => getTaskIssueImpactOptions(t), [t]);
  const typeOptions = useMemo(() => getTaskIssueTypeOptions(t), [t]);
  const trackingNumberOptions = queryParams.costTrackingNumber.map((n) => ({value: n, label: n}));
  const responsibleOptions = useResponsibleOptions(projectId);

  const {
    mixpanel: {
      events: {
        gantt: {filterDropdown: mixpanelEvents},
      },
      ...mixpanel
    },
  } = useAnalyticsService({extraMeta: {projectId, isReadOnlyMode: !isProjectAdmin, viewMode}});
  const formik = useRef<FormikProps<TaskFilterQuery>>(null);

  const syncFormikStateWithQuery = useDebounce(() => {
    if (!equal(formik.current?.values, queryParams)) {
      formik.current?.setValues(queryParams);
    }
  }, 500);

  useEffect(() => {
    syncFormikStateWithQuery();
  }, [queryParams]);

  const onSearchInputChange = (value: string) => {
    updateSearchParams({...queryParams, q: value});
  };

  const applyFilters = (values: TaskFilterQuery) => {
    if (!equal(queryParams, values)) {
      updateSearchParams({...queryParams, ...values});
    }
  };

  return (
    <Dropdown
      viewportPosition="right"
      className={className}
      onAfterToggleClick={(isActive) => {
        if (isActive) mixpanel.track(mixpanelEvents.openFilter);
      }}
      toggleElement={
        <SearchField
          name="filterSearch"
          onChange={onSearchInputChange}
          value={queryParams.q}
          active={active}
          className={s.filterDropdown__input}
          inputClassName={s.filterDropdown__input_size}
        />
      }
      toggleElementId="toolbarFiltersToggle"
      header={{
        title: t('filters:dropdown.header', 'Filters'),
        button: {
          title: t('filters:dropdown.reset', 'Reset all'),
          onClick: () => reset({exclude: ['q', 'schedWeeks', 'schedEndFirst', 'showPastDue']}),
        },
      }}
    >
      <Formik<TaskFilterQuery>
        innerRef={formik}
        onSubmit={applyFilters}
        initialValues={filtersState.current[viewMode][taskState]}
      >
        {({handleSubmit, values, setFieldValue}) => {
          const trackingNumbersOptions = trackingNumberOptions.concat(
            values.costTrackingNumber
              .filter((v) => !trackingNumberOptions.find((tno) => tno.value === v))
              .map((v) => ({
                label: v,
                value: v,
              })),
          );
          return (
            <>
              <CtrlBtnOption
                openOnHover
                title={t('filters:tasks.options.status', 'Status')}
                countSelected={values.statusList.length}
                iconRight={
                  values.statusList.length ? (
                    <Icon
                      name="clear"
                      colorFill
                      onClick={() => {
                        setFieldValue('statusList', []);
                      }}
                    />
                  ) : null
                }
                icon={<Icon colorFill name="status" />}
                nestedContentPlacement={sideContentDirection}
                nested={
                  <Autocomplete
                    isMulti
                    hideSearch
                    items={taskStatusOptions}
                    name="statusList"
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(() => setFieldValue(name, value), mixpanelEvents.status)
                    }
                    selected={values.statusList}
                  />
                }
              />
              <CtrlBtnOption
                openOnHover
                title={t('filters:tasks.options.issueType', 'Issue Type')}
                countSelected={values.issueType.length}
                iconRight={
                  values.issueType.length ? (
                    <Icon name="clear" colorFill onClick={() => setFieldValue('issueType', [])} />
                  ) : null
                }
                icon={<Icon colorFill name="active_type" />}
                nestedContentPlacement={sideContentDirection}
                nested={
                  <Autocomplete
                    isMulti
                    items={typeOptions}
                    name="issueType"
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(() => setFieldValue(name, value), mixpanelEvents.issueType)
                    }
                    selected={values.issueType}
                  />
                }
              />
              <CtrlBtnOption
                openOnHover
                title={t('filters:tasks.options.location', 'Location')}
                countSelected={values.locations.length}
                icon={<Icon colorFill name="location" />}
                iconRight={
                  values.locations.length ? (
                    <Icon name="clear" colorFill onClick={() => setFieldValue('locations', [])} />
                  ) : null
                }
                nestedContentPlacement={sideContentDirection}
                nested={
                  <Autocomplete
                    isMulti
                    items={preparedLocations}
                    name="locations"
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(() => setFieldValue(name, value), mixpanelEvents.locations)
                    }
                    selected={values.locations}
                  />
                }
              />
              <CtrlBtnOption
                openOnHover
                title={t('filters:tasks.options.responsibleOrgIds', 'Resolving Company')}
                countSelected={values.responsibleOrgIds.length}
                iconRight={
                  values.responsibleOrgIds.length ? (
                    <Icon name="clear" colorFill onClick={() => setFieldValue('responsibleOrgIds', [])} />
                  ) : null
                }
                icon={<Icon colorFill name="subcontractor" />}
                nestedContentPlacement={sideContentDirection}
                nested={
                  <Autocomplete
                    isMulti
                    name="responsibleOrgIds"
                    items={subcontractorOptions}
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(() => setFieldValue(name, value), mixpanelEvents.responsible)
                    }
                    selected={values.responsibleOrgIds}
                  />
                }
              />
              <CtrlBtnOption
                openOnHover
                title={t('filters:tasks.options.culpable_org', 'Liable company')}
                countSelected={values.culpableOrgId.length}
                icon={<Icon colorFill name="insurance" />}
                iconRight={values.culpableOrgId.length ? <Icon name="clear" colorFill /> : null}
                onClick={() => setFieldValue('culpableOrgId', [])}
                nestedContentPlacement={sideContentDirection}
                nested={
                  <Autocomplete
                    isMulti
                    items={subcontractorOptions}
                    name="culpableOrgId"
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(() => setFieldValue(name, value), mixpanelEvents.liableCompany)
                    }
                    selected={values.culpableOrgId}
                    disabled={isLoadingSubcontractors}
                  />
                }
              />

              <CtrlBtnOption
                openOnHover
                title={t('filters:tasks.options.impact', 'Time Impact')}
                countSelected={values.impact.length}
                iconRight={
                  values.impact.length ? (
                    <Icon name="clear" colorFill onClick={() => setFieldValue('impact', [])} />
                  ) : null
                }
                icon={<Icon colorFill name="clockdelay" />}
                nestedContentPlacement={sideContentDirection}
                nested={
                  <Autocomplete
                    isMulti
                    items={impactOptions}
                    name="impact"
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(() => setFieldValue(name, value), mixpanelEvents.impact)
                    }
                    selected={values.impact}
                  />
                }
              />
              <CtrlBtnOption
                openOnHover
                title={t('filters:tasks.options.tracking_number', 'Tracking Number')}
                countSelected={values.costTrackingNumber.length}
                icon={<Icon colorFill name="barcode" />}
                iconRight={
                  values.costTrackingNumber.length ? (
                    <Icon
                      name="clear"
                      colorFill
                      onClick={() => {
                        setFieldValue('costTrackingNumber', []);
                      }}
                    />
                  ) : null
                }
                nestedContentPlacement={sideContentDirection}
                nested={
                  <CreatableSelect
                    isClearable
                    formatCreateLabel={(input) => `Add search by ${input}`}
                    noOptionsMessage={() => 'Add one or more tracking numbers'}
                    placeholder={t('filters:tasks.options.tracking_number', 'Tracking Number')}
                    getOptionValue={(option) => option.value}
                    name="costTrackingNumber"
                    value={values.costTrackingNumber}
                    options={trackingNumbersOptions}
                    components={{DropdownIndicator: null}}
                    onChange={(values) =>
                      mixpanel.trackWithAction(
                        () => setFieldValue('costTrackingNumber', values),
                        mixpanelEvents.trackingNumber,
                      )
                    }
                  />
                }
              />
              <CtrlBtnOption
                openOnHover
                title={t('filters:tasks.options.responsible', 'Responsible')}
                countSelected={values.responsible ? 1 : 0}
                icon={<Icon colorFill name="assign" />}
                iconRight={
                  values.responsible ? (
                    <Icon
                      name="clear"
                      colorFill
                      onClick={() => {
                        setFieldValue('responsible', null);
                      }}
                    />
                  ) : null
                }
                nestedContentPlacement={sideContentDirection}
                nested={
                  <Autocomplete
                    hideSearch
                    items={responsibleOptions}
                    name="responsible"
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(() => setFieldValue(name, value), mixpanelEvents.responsible)
                    }
                    selected={[values.responsible]}
                  />
                }
              />

              <CtrlBtnOption
                openOnHover
                title={t('filters:tasks.options.cost_impact', 'Cost Impact')}
                countSelected={values.costImpact.length}
                icon={<Icon colorFill name="dollar" />}
                iconRight={
                  values.costImpact.length ? (
                    <Icon
                      name="clear"
                      colorFill
                      onClick={() => {
                        setFieldValue('costImpact', []);
                      }}
                    />
                  ) : null
                }
                nestedContentPlacement={sideContentDirection}
                nested={costImpactItems.map(({label, value}) => (
                  <CtrlBtnOption
                    selected={String(value) === String(values.costImpact?.[0])}
                    key={label}
                    title={label}
                    onClick={() => setFieldValue('costImpact', [value])}
                  />
                ))}
              />

              <CtrlButton
                style={{width: '100%', marginTop: '15px'}}
                type="submit"
                onClick={() => mixpanel.trackWithAction(handleSubmit, mixpanelEvents.applyButton)}
              >
                {t('filters:dropdown.apply', 'Apply')}
              </CtrlButton>
            </>
          );
        }}
      </Formik>
    </Dropdown>
  );
};
export default memo(IssuesFilterDropdown);
