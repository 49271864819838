import {QueryKey, useInfiniteQuery} from '@tanstack/react-query';
import {CellContext, ColumnDef, OnChangeFn, SortingState} from '@tanstack/react-table';
import {FunctionComponent, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {generatePath, useHistory, useLocation, useParams, useRouteMatch} from 'react-router';

import WorkersApi from 'api/workers';
import {TextArrayColumn} from 'modules/Workers/Columns/TextArrayColumn';
import EmptyGridBubble from 'shared/components/EmptyGridBubble';
import HelpModal from 'shared/components/HelpModal';
import ProjectLevelToolbar from 'shared/components/ProjectLevelToolbar';
import ProjectNameInlineEdit from 'shared/components/ProjectNameInlineEdit';
import Table from 'shared/components/Table';
import {CenteredColumn} from 'shared/components/Table/components/Columns';
import WorkspaceSwitcher from 'shared/components/WorkspaceSwitcher';
import {formatPhoneNumber} from 'shared/constants/common';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {prepareQueryString} from 'shared/helpers/queryParams';
import {getCompanyWorkerStatus} from 'shared/helpers/statuses';
import {
  getNormalizedWorkerRoles,
  getNormalizedWorkerTrade,
  mapOrgsIds,
  prepareWorkerFiltersForUrl,
} from 'shared/helpers/worker';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompany} from 'shared/hooks/useCompany';
import {useExactMatchParsedQuery} from 'shared/hooks/useParsedQuery';
import {useProjectSelector} from 'shared/hooks/useProjectSelector';
import {useSaveLastSelectedProjectId} from 'shared/hooks/useSaveLastSelectedProjectId';
import {Panel, ScreenGreed} from 'shared/layout/admin';
import {CompanyWorker, CompanyWorkerFilterParams, CompanyWorkerRole} from 'shared/models/worker';
import {getWorkerMixpanelLabel} from 'shared/utils/inviteWorker';

import {useWorkerPath} from '../Worker/hooks/useWorkerPath';
import {useProjectOrgs} from '../Worker/queries';

import {NameColumn, OrgsColumn, StatusColumn} from './Columns';
import FilterDropdown from './FilterDropdown';
import {DEFAULT_FILTER_PARAMS, DEFAULT_SORT_RULE} from './utils/constants';
import s from './Workers.module.scss';

const EXCLUDED_COLUMN_FOR_COMPANY_WORKERS = ['orgsMappingIds'];

const Workers: FunctionComponent = () => {
  const company = useCompany();
  const history = useHistory();
  const location = useLocation();
  const routes = useLocalizedRoutes();
  const [sortBy, setSortBy] = useState<SortingState>([]);
  const [pageSize] = useState(20);
  const queryKeyRef = useRef<QueryKey>(null);
  const {mixpanel} = useAnalyticsService();
  const {t} = useTranslation('workers');
  const match = useRouteMatch([routes.projectWorkers, routes.workers]);
  const {projectId} = useParams<{projectId: string}>();
  const project = useProjectSelector(projectId);
  const {workerPath} = useWorkerPath();
  const mixpanelEvents = mixpanel.events.workers;
  useSaveLastSelectedProjectId(routes.projectWorkers);
  const isProjectWorkers = match?.path === routes.projectWorkers;

  const {params: queryParams} = useExactMatchParsedQuery<CompanyWorkerFilterParams>({
    defaultParams: DEFAULT_FILTER_PARAMS,
    path: isProjectWorkers ? routes.projectWorkers : routes.workers,
    schema: {
      wildcard: 'string',
      trade: 'string',
      blendedStatus: 'string',
      orgList: 'string[]',
    },
  });

  useEffect(() => {
    mixpanel.track(mixpanelEvents.screen(getWorkerMixpanelLabel(isProjectWorkers)));
  }, [isProjectWorkers]);

  const addWorker = () => {
    mixpanel.track(mixpanelEvents.addWorkerBtn(getWorkerMixpanelLabel(!!projectId)));
    history.push(generatePath(workerPath, {id: 'new', projectId}), {
      from: history.location.pathname,
      queryKey: queryKeyRef.current,
    });
  };

  const updateSearchParams = (values: CompanyWorkerFilterParams) => {
    history.push({
      pathname: location.pathname,
      search: prepareQueryString({
        values,
        prepareFunction: prepareWorkerFiltersForUrl,
      }),
      // TODO: delete state within the task: https://journey-builders.atlassian.net/browse/CNA-3017
      state: location.state,
    });
  };

  const searchWorker = (value: string) => {
    updateSearchParams({...queryParams, wildcard: value});
  };

  const {data, hasNextPage, isFetching, fetchNextPage} = useInfiniteQuery({
    queryKey: ['workers', company?.id, projectId, queryParams, sortBy],
    queryFn: async ({pageParam, queryKey}) => {
      queryKeyRef.current = queryKey;
      const formattedSortFields = {};
      if (sortBy.length) {
        Object.assign(formattedSortFields, {
          sortField: sortBy[0]?.id,
          sortOrder: sortBy[0]?.desc ? 'DESC' : 'ASC',
        });
      }

      const res = await WorkersApi[isProjectWorkers ? 'getAllProjectWorkers' : 'getAllCompaniesWorkers'](
        isProjectWorkers ? projectId : company?.id,
        {
          filterParams: {...queryParams, companyLevel: true},
          offset: pageParam * pageSize,
          limit: pageSize,
          ...formattedSortFields,
        },
      );
      const indexMap: Record<string, number> = {};
      const uniqWorkers: CompanyWorker[] & {total?: number} = [];
      res
        .sort((workerA, workerB) => {
          const mainAdminRole = isProjectWorkers ? 'project_admin' : 'company_admin';
          if (workerA.workerId === workerB.workerId) {
            if (workerA.roles.includes(mainAdminRole)) return -1;
            if (workerB.roles.includes(mainAdminRole)) return 1;
          }
          return 0;
        })
        .forEach((worker) => {
          const workerId = worker.workerId;
          if (indexMap[workerId] !== undefined) {
            const existingWorker = uniqWorkers[indexMap[workerId]];
            existingWorker.roles = (existingWorker.roles || []).concat(worker.roles || []);
          } else {
            indexMap[workerId] = uniqWorkers.length;
            uniqWorkers.push({...worker});
          }
        });
      uniqWorkers.total = res.length;
      return uniqWorkers;
    },
    initialPageParam: 0, // Add this line for v5
    enabled: !!company?.id,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => (lastPage.total < pageSize ? undefined : allPages.length),
  });

  const orgsOptions = useProjectOrgs(projectId);

  const workers = useMemo(() => data?.pages.reduce((prev, cur) => prev.concat(cur), []) || [], [data]);

  const showNowData = !workers?.length && !isFetching && !!company?.id;

  const columns = useMemo(
    () =>
      [
        {
          header: t('columns.name', 'Name'),
          id: 'firstName',
          accessorKey: 'workerFull',
          cell: (props) => <NameColumn {...props} />,
          minSize: 200,
          size: 400,
        },
        {
          header: t('columns.trade', 'Trade'),
          id: 'trade',
          accessorFn: ({workerFull}) => getNormalizedWorkerTrade(workerFull.trade, t),
          cell: (props: CellContext<CompanyWorker, string>) => <CenteredColumn {...props} />,
          minSize: 100,
        },
        {
          header: t('columns.role', 'Role'),
          id: 'role',
          accessorFn: ({roles}) => getNormalizedWorkerRoles(roles, t),
          cell: (props: CellContext<CompanyWorker, CompanyWorkerRole[]>) => <TextArrayColumn {...props} />,
          enableSorting: false,
          minSize: 100,
        },
        {
          header: t('columns.organization', 'Organization'),
          id: 'orgsMappingIds',
          accessorFn: ({orgMappingIds}) => mapOrgsIds(orgMappingIds, orgsOptions),
          cell: (props: CellContext<CompanyWorker, CompanyWorkerRole[]>) => <OrgsColumn {...props} />,
          enableSorting: false,
          minSize: 100,
        },
        {
          header: t('columns.status', 'Status'),
          id: 'status',
          accessorKey: 'status',
          cell: (props) => <StatusColumn {...props} />,
          enableSorting: false,
          size: 80,
          minSize: 80,
        },
        {
          header: t('columns.phone', 'Phone'),
          id: 'phone',
          accessorFn: ({workerFull, status}) =>
            workerFull.mobileNumber && getCompanyWorkerStatus(status, workerFull.status) !== 'removed'
              ? formatPhoneNumber(workerFull.mobileNumber)
              : '',
          enableSorting: false,
          cell: (props: CellContext<CompanyWorker, CompanyWorkerRole[]>) => <CenteredColumn {...props} />,
          size: 300,
          minSize: 300,
        },
        {
          header: t('columns.email', 'Email'),
          id: 'email',
          accessorFn: ({workerFull, status}) =>
            getCompanyWorkerStatus(status, workerFull.status) !== 'removed' ? workerFull.email : '',
          enableSorting: false,
          cell: (props: CellContext<CompanyWorker, CompanyWorkerRole[]>) => <CenteredColumn {...props} />,
          size: 300,
          minSize: 300,
        },
      ] as ColumnDef<CompanyWorker>[],
    [orgsOptions, t],
  );

  const handleSort: OnChangeFn<SortingState> = (updater) => {
    setSortBy(typeof updater === 'function' ? updater(sortBy) : updater);
  };

  const tableInitialState = useMemo(() => {
    return {sorting: DEFAULT_SORT_RULE};
  }, []);

  const companyColumns = columns.filter((column) => !EXCLUDED_COLUMN_FOR_COMPANY_WORKERS.includes(column.id));
  const preparedColumns = isProjectWorkers ? columns : companyColumns;

  const downloadApp = () => {
    window.open('https://bycore.com/download?fromMobileApp=true', '_blank');
  };

  return (
    <>
      <Panel
        title={
          isProjectWorkers ? (
            <ProjectNameInlineEdit pretext={t('project_title', 'Project Workers for')} project={project} />
          ) : (
            t('title', 'Company Users')
          )
        }
        actions={isProjectWorkers && <WorkspaceSwitcher projectId={projectId} />}
      />
      <ProjectLevelToolbar
        handleAddItem={addWorker}
        handleInputChange={searchWorker}
        buttonTitle={t('buttons.add')}
        buttonIcon="worker_add"
        onFocusInput={() => mixpanel.track(mixpanelEvents.searchInput(getWorkerMixpanelLabel(isProjectWorkers)))}
      >
        <FilterDropdown queryParams={queryParams} isProjectWorkers={isProjectWorkers} />
      </ProjectLevelToolbar>
      <ScreenGreed
        content={
          <>
            {showNowData ? (
              <EmptyGridBubble text={t('empty.title', 'No results found. Please try again or reset filters.')} t={t} />
            ) : (
              <div className={s.workers__grid}>
                <Table<CompanyWorker>
                  data={workers}
                  columns={preparedColumns}
                  isLoading={isFetching}
                  hasMore={hasNextPage}
                  onSort={handleSort}
                  next={fetchNextPage}
                  initialState={tableInitialState}
                  onRowClick={({original}) =>
                    history.push(
                      generatePath(workerPath, {
                        id: original.id,
                        projectId,
                      }),
                      {
                        from: history.location.pathname,
                        queryKey: queryKeyRef.current,
                      },
                    )
                  }
                  skeletonLoader={<Skeleton style={{margin: '24px 0'}} count={6} height={32} />}
                />
              </div>
            )}
          </>
        }
      />
      <HelpModal
        title={t('help.title', 'Workers')}
        text={t(
          'help.text',
          'Workers that sign up using the text when the “Notify with SMS” toggle is turned to ON will be able to download the Crews by Core app to manage activities, chat with their Crew and update their status while at the job site!',
        )}
        buttonText={t('buttons.download', 'Download the App!')}
        buttonAction={() =>
          mixpanel.trackWithAction(downloadApp, mixpanelEvents.screen(getWorkerMixpanelLabel(isProjectWorkers)))
        }
        iconName="download_outlined"
      />
    </>
  );
};
export default Workers;
