import cn from 'classnames';
import {useFormikContext} from 'formik';
import {TFunction} from 'i18next';
import {use} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams, useRouteMatch} from 'react-router';
import {generatePath} from 'react-router-dom';
import {toast} from 'react-toastify';

import ProjectsApi from 'api/projects';
import {SubcontractorsContext} from 'modules/Subcontractors/components/SubcontractorsContext';
import {SubcontractorFormValues} from 'modules/Subcontractors/utils/utils';
import {useConfirm} from 'shared/components/Confirmation';
import AsyncCompanyWorkerSelect from 'shared/components/CoreForm/Select/AsyncCompanyWorkerSelect/AsyncCompanyWorkerSelect';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import {RouteParams, useLocalizedRoutes} from 'shared/constants/routes';
import {extractAxiosError} from 'shared/helpers/axios';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {CompanyWorker} from 'shared/models/worker';

import s from './SubcontractorMember.module.scss';

type SubcontractorMemberProps = {
  className?: string;
};

const confirmPayload = (t: TFunction, assignedCount: number) => ({
  title: t('tab.subcontractors.delete_company.confirm.title', 'Are you sure you want to delete this company?'),
  description: t(
    'tab.subcontractors.delete_company.confirm.description',
    `There are {{quantity}} user(s) assigned to this Company.`,
    {quantity: assignedCount},
  ),
  acceptButton: t('tab.subcontractors.delete_company.confirm.accept', 'Delete'),
  cancelButton: t('tab.subcontractors.delete_company.confirm.cancel', 'Cancel'),
});

const SubcontractorMember = ({className}: SubcontractorMemberProps) => {
  const routes = useLocalizedRoutes();
  const {confirm} = useConfirm();
  const history = useHistory();
  const {
    params: {id},
  } = useRouteMatch<{id: string}>();
  const {setFieldValue, values} = useFormikContext<SubcontractorFormValues>();
  const {t} = useTranslation('panel');
  const PLACEHOLDER = t('tab.subcontractors.designated_member.select_placeholder', 'Choose Member');
  const {projectId} = useParams<RouteParams['tasks']>();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId}});
  const mixpanelEvents = mixpanel.events.projectOrgs;
  const {deleteOrgFromTable, getOrg} = use(SubcontractorsContext);

  const onSelect = (companyWorker: CompanyWorker) => {
    if (companyWorker) {
      mixpanel.track(mixpanelEvents.selectResponsible);
      setFieldValue('contactWorkerId', companyWorker.workerId);
    }
  };

  const goToWorkers = () => {
    mixpanel.track(mixpanelEvents.goToWorkersPage);
    history.push({
      pathname: generatePath(routes.projectWorkers, {projectId}),
      search: `?orgList=${values.name}`,
    });
  };

  const deleteCompany = async () => {
    const currentOrg = getOrg(id);
    if (await confirm(confirmPayload(t, currentOrg.nbWorkers))) {
      mixpanel.track(mixpanelEvents.deleteOrg);
      try {
        await ProjectsApi.deleteOrg(projectId, id);
        deleteOrgFromTable(id);
        toast.success(t('tab.subcontractors.delete_company.success', 'Company deleted.'));
      } catch (err) {
        toast.error(extractAxiosError(err) as string);
      }
    }
  };

  return (
    <div className={cn(s.subcontractorMember, className)}>
      <FormControl label={t('tab.subcontractors.designated_member.label', 'Designated Member (Optional)')}>
        <AsyncCompanyWorkerSelect
          onSelectChange={onSelect}
          initialValueId={values?.contactWorkerId}
          placeholder={PLACEHOLDER}
        />
      </FormControl>
      {id !== 'new' && (
        <>
          <div className={s.subcontractorMember__description}>
            {t('tab.subcontractors.designated_member.description', '')}
          </div>
          <div className={s.subcontractorMember__actions}>
            <CtrlButton view="link" onClick={goToWorkers}>
              {t('tab.subcontractors.designated_member.button', 'Manage Members')}
            </CtrlButton>
            <CtrlButton
              view="selector"
              color="actionWarning"
              onClick={deleteCompany}
              className={s['subcontractorMember__actions__action--delete']}
              icon="remove_from_trash"
              iconSize={16}
            >
              {t('tab.subcontractors.delete_company.button', 'Delete Company')}
            </CtrlButton>
          </div>
        </>
      )}
    </div>
  );
};

export default SubcontractorMember;
