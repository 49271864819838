import * as Sentry from '@sentry/browser';
import {GanttStatic} from 'dhtmlx-gantt';
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';
import {GroupBase, SelectInstance} from 'react-select';
import {toast} from 'react-toastify';

import TasksApi from 'api/tasks';
import css from 'modules/Tasks/components/Gantt/components/Editors/ResponsibleEditor/index.module.scss';
import {useInlineSelectController} from 'modules/Tasks/components/Gantt/components/Editors/useInlineSelectController';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import InviteTaskResponsible from 'modules/Tasks/components/popups/InviteTaskResponsible';
import {refreshTask} from 'modules/Tasks/utils/functions';
import {ObserverActionSource, ObserverAction} from 'services/TasksObserver/const';
import {useTasksObserver} from 'services/TasksObserver/TasksObserverProvider';
import AsyncProjectWorkerSelect from 'shared/components/CoreForm/Select/AsyncProjectWorkerSelect';
import {ProjectWorkerSelectOption} from 'shared/components/CoreForm/Select/AsyncProjectWorkerSelect/AsyncProjectWorkerSelect';
import {switchTaskResponsible} from 'shared/helpers/task';
import {useMount} from 'shared/hooks/core/useMount';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {toGanttTaskModel} from 'shared/mapping/task';
import {TaskProjection, GroupMemberRole} from 'shared/models/task/const';
import {TaskDetailsModelDTO} from 'shared/models/task/task';
import {CompanyWorker} from 'shared/models/worker';

type Props = {
  gantt: GanttStatic;
  task: GanttTask;
  save(gantt: GanttStatic, task: GanttTask, responsible: CompanyWorker): Promise<TaskDetailsModelDTO>;
  invite(gantt: GanttStatic, task: GanttTask, responsible: CompanyWorker): Promise<TaskDetailsModelDTO>;
};

const ResponsibleEditor = ({gantt, task, save, invite}: Props) => {
  const selectRef = useRef<SelectInstance<ProjectWorkerSelectOption, true, GroupBase<ProjectWorkerSelectOption>>>(null);
  const {t} = useTranslation('gantt');
  const {focusOrHide, onKeyUp, onKeyDown, onClose} = useInlineSelectController(gantt);
  const location = useLocation();
  const observer = useTasksObserver();
  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.gantt.inlineEdit;

  useMount(() => {
    selectRef.current?.focus();
  });

  const updateResponsible = async (selectedResponsible: CompanyWorker[]) => {
    const [responsible] = selectedResponsible;
    if (task.responsible?.[0]?.member_id === responsible.workerId) {
      gantt.ext.inlineEditors.hide();
      return;
    }
    try {
      const updTask = await save(gantt, task, responsible);
      afterChangeResponsible(updTask);
      toast.success(t('gantt.toast.success.responsible_update', 'Responsible updated.'));
    } catch (err) {
      toast.error(t('gantt.toast.error.responsible_update', 'Something went wrong changing Responsible'));
      Sentry.captureException(err);
    }

    mixpanel.track(mixpanelEvents, {viewMode: gantt.name, column: 'responsible'});
    focusOrHide();
  };

  const afterChangeResponsible = (task: TaskDetailsModelDTO) => {
    refreshTask(gantt, task.id, toGanttTaskModel(task));
    observer.emit([{data: task}], {
      projectId: task.projectId,
      source: ObserverActionSource.gantt,
      projection: TaskProjection.taskDetail,
      action: ObserverAction.update,
      sourceName: gantt.name,
    });
  };

  if (location.state?.['inviteTaskResponsible']) {
    return (
      <InviteTaskResponsible
        gantt={gantt}
        task={task}
        afterChangeResponsible={afterChangeResponsible}
        invite={invite}
      />
    );
  }

  return (
    <AsyncProjectWorkerSelect
      withInviteButton
      className={`react-select--size-xs ${css['task-responsible-inline-editor']}`}
      selectRef={selectRef}
      onSelectChange={updateResponsible}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMenuClose={onClose}
      menuIsOpen={true}
      placeholder={t('bulk_assign.confirmation.select.placeholder', 'Select assignee')}
      loadingPlaceholder={t('bulk_assign.confirmation.select.loading_placeholder', 'Loading...')}
      projectId={task.project_id || task.projectId}
      taskId={task.id}
      t={t}
    />
  );
};

ResponsibleEditor.Task = (props: Omit<Props, 'save' | 'invite'>) => {
  return <ResponsibleEditor {...props} save={switchTaskResponsible} invite={switchTaskResponsible} />;
};

const save = (gantt, task, responsible) =>
  TasksApi.addIssueAssignee(task.projectId, task.id, {
    memberId: responsible.workerId,
    memberRole: GroupMemberRole.responsible,
  });
ResponsibleEditor.Issues = (props: Omit<Props, 'save' | 'invite'>) => {
  return <ResponsibleEditor {...props} save={save} invite={save} />;
};

export default ResponsibleEditor;
