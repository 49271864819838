import {GanttStatic} from 'dhtmlx-gantt';

import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import {costImpactTypes} from 'shared/constants/statuses';

import SelectEditor, {KeysOfType} from './SelectEditor';

type CostImpactEditorProps = {
  gantt: GanttStatic;
  task: GanttTask;
  name: KeysOfType<boolean>;
};

const CostImpactEditor = ({gantt, task, name}: CostImpactEditorProps) => {
  const options: CoreOptionType[] = costImpactTypes.map((type) => ({
    value: String(type.value),
    label: type.label,
  }));

  return (
    <SelectEditor<boolean>
      gantt={gantt}
      task={task}
      name={name}
      options={options}
      transformValue={(value) => (value === null || value === undefined ? '' : String(value))}
      transformBack={(value) => value === 'true'}
    />
  );
};

export default CostImpactEditor;
