import cn from 'classnames';
import {cloneElement, CSSProperties, isValidElement, PropsWithChildren, ReactElement, ReactNode} from 'react';

import Icon from 'shared/components/Icon';

import s from './CtrlCheck.module.scss';

type CtrlCheckProps = {
  fieldType: 'checkbox' | 'radio';
  className?: string;
  label?: ReactNode;
  labelIsHidden?: boolean;
  labelSize?: 's' | 'm';
  labelClassName?: string;
  description?: string;
  children: ReactElement<{className?: string}>;
  view?: 'circle' | 'square';
  disabled?: boolean;
  style?: CSSProperties;
  reversed?: boolean;
  iconHelp?: ReactNode;
  tooltip?: ReactNode;
  labelColor?: string;
  id?: string;
} & PropsWithChildren;

const CtrlCheck = ({
  className,
  label,
  labelIsHidden,
  labelSize,
  labelClassName,
  description,
  children,
  fieldType,
  disabled,
  view,
  style,
  reversed = false,
  iconHelp,
  tooltip,
  labelColor,
}: CtrlCheckProps) => {
  return (
    <label
      style={style}
      className={cn(
        s.CtrlCheck,
        s[`CtrlCheck_${fieldType}`],
        labelIsHidden && s[`CtrlCheck_labelIsHidden`],
        labelSize && s[`CtrlCheck_labelSize_${labelSize}`],
        disabled && s.CtrlCheck_disable,
        view && s[`CtrlCheck_view_${view}`],
        reversed && s.CtrlCheck_reverse,
        className,
      )}
    >
      {isValidElement(children) &&
        cloneElement(children, {
          ...children.props,
          className: cn(s.CtrlCheck__field, children.props.className),
        })}
      <span className={s.CtrlCheck__visualBox}>
        <span className={s.CtrlCheck__visual}>
          {fieldType === 'checkbox' && <Icon className={s.CtrlCheck__visualIcon} name="checkmark-outlined" colorFill />}
        </span>
      </span>
      <span className={s.CtrlCheck__body}>
        <span
          className={cn(s.CtrlCheck__label, disabled && s.CtrlCheck_disable, labelClassName)}
          style={labelColor ? {color: labelColor} : null}
        >
          {label}
          {iconHelp}
        </span>
        {description && (
          <span className={cn(s.CtrlCheck__description, disabled && s.CtrlCheck_disable)}>{description}</span>
        )}
      </span>
      {tooltip}
    </label>
  );
};

export default CtrlCheck;
