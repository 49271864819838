import cn from 'classnames';
import {Children, cloneElement, isValidElement, ReactElement, ReactNode, JSXElementConstructor} from 'react';

import s from './CtrlCheckGroup.module.scss';

type CtrlCheckGroupProps = {
  className?: string;
  titleClassName?: string;
  title: ReactNode;
  children: ReactNode[];
  direction?: 'line';
};

const CtrlCheckGroup = ({className, title, titleClassName, children, direction}: CtrlCheckGroupProps) => {
  return (
    <div className={cn(s.CtrlCheckGroup, direction && s[`CtrlCheckGroup_direction_${direction}`], className)}>
      <div className={cn(s.CtrlCheckGroup__title, titleClassName)}>{title}</div>
      <div className={s.CtrlCheckGroup__body}>
        <div className={s.CtrlCheckGroup__grid}>
          {Children.toArray(children).map((child) => {
            if (isValidElement<{className?: string}>(child)) {
              return cloneElement<{className?: string}>(child, {
                ...child.props,
                className: cn(s.CtrlCheckGroup__item, child.props.className),
              });
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default CtrlCheckGroup;
