import classNames from 'classnames';
// eslint-disable-next-line import/no-named-as-default
import {QRCodeSVG} from 'qrcode.react';
import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import Avatar from 'shared/components/Avatar';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import SkeletonPreloader from 'shared/components/SkeletonPreloader';
import {formatPhoneNumber} from 'shared/constants/common';
import {getCompanyWorkerStatus} from 'shared/helpers/statuses';
import {
  getNormalizedWorkerRoles,
  getNormalizedWorkerTrade,
  getWorkerFlyerPath,
  mapOrgsIds,
} from 'shared/helpers/worker';
import {useEffectOnce} from 'shared/hooks/core/useEffectOnce';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {ProjectModel} from 'shared/models/project';
import {CompanyWorker, WorkerBlendedStatus} from 'shared/models/worker';
import {getWorkerMixpanelLabel, needShowEmailContact} from 'shared/utils/inviteWorker';

import ProfileStatus from '../ProfileStatus';

type WorkerProfileProps = {
  profile: CompanyWorker;
  projects: ProjectModel[];
  options: CoreOptionType[];
  className?: string;
};

const WorkerProfile: FC<WorkerProfileProps> = ({profile, projects, className, options}) => {
  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.worker;
  const showEmail = useMemo(() => needShowEmailContact(profile), [profile]);
  const orgs = useMemo(() => mapOrgsIds(profile?.orgMappingIds, options), [profile?.orgMappingIds]);
  const {projectId} = useParams<{projectId: string}>();
  const {t} = useTranslation('worker');

  useEffectOnce(() => {
    mixpanel.track(mixpanelEvents.screen(getWorkerMixpanelLabel(!!projectId)));
  });

  return (
    <section className={classNames('profile-info', className)}>
      <div className="profile-info__container">
        <header className="profile-info__header">
          <SkeletonPreloader when={!profile} circle width={90} height={90} className="profile-info__avatar">
            <Avatar className="profile-info__avatar" src={profile?.workercard?.profilePicUrl} />
          </SkeletonPreloader>
          <div className="profile-info__data" style={{minWidth: 0}}>
            <div className="profile-info__phone" style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
              <SkeletonPreloader when={!profile}>
                <a
                  className="profile-info__phone-number"
                  href={showEmail ? `mailto:${profile?.workerFull?.email}` : `tel:${profile?.workerFull?.mobileNumber}`}
                >
                  {showEmail ? profile?.workerFull?.email : formatPhoneNumber(profile?.workerFull?.mobileNumber)}
                </a>
              </SkeletonPreloader>
            </div>
            <SkeletonPreloader when={!profile} width={66} height={24} style={{marginTop: 8}}>
              <ProfileStatus
                className="profile-info__status"
                status={getCompanyWorkerStatus(profile?.status, profile?.workercard?.status) as WorkerBlendedStatus}
              />
            </SkeletonPreloader>
          </div>
        </header>
        <div className="profile-info__body">
          <div className="profile-info__item">
            <h2 className="profile-info__name">
              <SkeletonPreloader when={!profile}>
                <>
                  <span className="profile-info__name-part">{profile?.workerFull?.fullName}</span>
                </>
              </SkeletonPreloader>
            </h2>
            <SkeletonPreloader when={!profile} width={66} height={24}>
              <div className="profile-info__trade">{getNormalizedWorkerTrade(profile?.workercard?.trade, t)}</div>
            </SkeletonPreloader>
            <SkeletonPreloader when={!profile} width={66} height={24}>
              <div className="profile-info__trade">{getNormalizedWorkerRoles(profile?.roles, t)?.join(', ')}</div>
            </SkeletonPreloader>
          </div>
          <div className="profile-info__item">
            <div className="profile-info__projects">
              <h3 className="profile-info__projects-title">{t('profile.title', 'Projects')}</h3>
              <ul className="profile-info__projects-list">
                <SkeletonPreloader count={2} width={200} style={{margin: '10px 0'}} when={!profile || !projects}>
                  {projects?.map((project) => {
                    return (
                      <li className="profile-info__projects-item" key={project?.id}>
                        <p className="profile-info__projects-name">{project?.name}</p>
                      </li>
                    );
                  })}
                </SkeletonPreloader>
              </ul>
            </div>
          </div>
          <div className="profile-info__item">
            <div className="profile-info__qr-box">
              <SkeletonPreloader width={96} height={96} when={!profile}>
                <a target="_blank" rel="noreferrer" href={getWorkerFlyerPath(profile?.workerId)}>
                  <QRCodeSVG value={getWorkerFlyerPath(profile?.workerId)} size={96} />
                </a>
              </SkeletonPreloader>
            </div>
          </div>
          <div className="profile-info__item">
            <div className="profile-info__orgs" style={{paddingTop: '30px'}}>
              <SkeletonPreloader when={!profile}>
                {orgs?.map((value, i) => (
                  <span className="te-organization profile-info__orgs-item" key={i}>
                    {value}
                  </span>
                ))}
              </SkeletonPreloader>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default WorkerProfile;
