import cn from 'classnames';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

import Icon from 'shared/components/Icon';
import ProgressBar from 'shared/components/ProgressBar';
import {CompletionUnits} from 'shared/constants/completionUnits';
import {IconsMap} from 'shared/constants/icons';
import {formatDate} from 'shared/helpers/dates';
import {TaskCompletionUnits, FeedbackByDateTask} from 'shared/models/task/task';
import {CompanyWorker} from 'shared/models/worker';

import {DailiesDisplayMode, ProcessedEntry} from '../types';
import {processReportsData, formatProgressUnits} from '../utils/formatting';

import ActivityIssues from './ActivityIssues';
import {DailiesReportCard} from './DailiesReportCard';
import {ReportType} from './DailyCardHeader';

export function DailiesCardReportContent({
  completionTarget,
  completionUnit,
  displayMode,
  feedbackByDate,
  handleRedirectToGanttView,
  projectWorkers,
  projectedLabor,
  taskId,
}: {
  completionTarget?: string;
  completionUnit?: TaskCompletionUnits;
  displayMode: DailiesDisplayMode;
  feedbackByDate?: FeedbackByDateTask[];
  handleRedirectToGanttView?: () => void;
  projectWorkers?: CompanyWorker[];
  projectedLabor?: string;
  taskId: string;
}) {
  const {t} = useTranslation('dailies');
  const processedReports = processReportsData(feedbackByDate);

  function submitterSection(
    entry: ProcessedEntry,
    completionAmountReporter: CompanyWorker,
    laborAmountReporter: CompanyWorker,
  ) {
    const reportSubmittedBy =
      completionAmountReporter?.workerFull?.fullName ?? laborAmountReporter?.workerFull?.fullName;
    const availableEntry = entry[ReportType.dailyLabor] ?? entry[ReportType.completionAmount];

    const formattedDate = availableEntry.timeCreated ? formatDate(availableEntry.timeCreated, 'H:mm A') : null;
    const submittedDate = availableEntry.timeCreated ? formatDate(availableEntry.timeCreated, 'MMM D, YYYY') : null;

    return (
      <div className="daily-report-card__report-metrics-submitter">
        <div className="daily-report-card__report-metrics-submitter--title">{t('dailies_report.submitted_by')}</div>
        <div>
          <span className="daily-report-card__report-metrics-submitter-name">{reportSubmittedBy}</span>
          {t('dailies_report.submission_info', {
            submittedDate,
            formattedDate,
          })}
          <button className="daily-report-card__report-cta" onClick={handleRedirectToGanttView}>
            <Icon name={IconsMap.history} />
          </button>
        </div>
      </div>
    );
  }

  function progressSection(entry: ProcessedEntry) {
    const progressComplete = entry?.completionAmount?.value;
    const {formattedProgressComplete, formattedCompletionTarget, unitLabel} = formatProgressUnits({
      progressComplete,
      completionTarget,
      completionUnit,
    });
    const delimiter = unitLabel === CompletionUnits.Percent ? '/' : 'of';

    const progressText = t('dailies_report.progress_info', {
      formattedProgressComplete,
      delimiter,
      formattedCompletionTarget,
      unitLabel,
    });

    return (
      <>
        <div className="daily-report-card__section--header">
          <span className="daily-report-card__label">{t('dailies_report.progress')}</span>
          <span className="daily-report-card__progress-text">{progressText}</span>
        </div>
        <div className="daily-report-card__progress-bar">
          <ProgressBar progress={Number(progressComplete)} />
        </div>
      </>
    );
  }

  function manPowerSection(entry: ProcessedEntry) {
    const reportedManPower = entry?.dailyLabor?.value ?? null;
    const lowManPower = Number(reportedManPower) < Number(projectedLabor);

    return (
      <div className="daily-report-card__crew-metrics">
        <div className="daily-report-card__crew-metrics--manpower">
          {t('dailies_report.manpower_reported')}
          <span
            className={cn({
              'daily-report-card__crew-metrics--amount': true,
              'daily-report-card__crew-metrics--amount--less-than-reported': lowManPower && reportedManPower,
            })}
          >
            <Icon name={IconsMap.worker} className="worker-icon" />
            {reportedManPower ? reportedManPower : t('dailies_report.no_manpower_reported')}
          </span>
        </div>
        <div className="daily-report-card__crew-metrics--crewsize">
          {t('dailies_report.crew_size')}
          <span className="daily-report-card__crewsize--amount">
            <Icon name={IconsMap.worker} className="worker-icon" />
            {projectedLabor}
          </span>
        </div>
      </div>
    );
  }

  function findReportSubmitterById(entry: ProcessedEntry, type: keyof typeof ReportType) {
    return projectWorkers.find((worker) => worker?.workerId === entry?.[type]?.workerId) ?? null;
  }

  return (<>
    <ActivityIssues displayMode={displayMode} taskId={taskId} />
    {processedReports?.map((entry: ProcessedEntry, idx) => {
      const dailyLaborWorker = findReportSubmitterById(entry, ReportType.dailyLabor);
      const completionAmountWorker = findReportSubmitterById(entry, ReportType.completionAmount);

      return (
        // use Fragment to avoid the key warning
        (<Fragment key={entry.date + idx}>
          <section className="daily-report-card">
            <div className="daily-report-card__section">
              {progressSection(entry)}

              {manPowerSection(entry)}
            </div>

            <div className="daily-report-card__section">
              {submitterSection(entry, completionAmountWorker, dailyLaborWorker)}
            </div>
          </section>
          <DailiesReportCard.Separator />
        </Fragment>)
      );
    })}
  </>);
}
