import * as Sentry from '@sentry/react';
import {InterruptedException} from 'effect/Cause';
import {inject, injectable} from 'inversify';
import {IObservableArray, makeObservable, observable, runInAction} from 'mobx';

import FeedbackService from 'api/feedback';
import ProjectsApi from 'api/projects';
import {loadAllTasks} from 'modules/Tasks/components/Gantt/utils/load';
import {extractAxiosError} from 'shared/helpers/axios';
import {DailyType, MessageTypeMap} from 'shared/models/feedback';
import {IOC_TYPES} from 'shared/models/ioc';
import {TaskModelRawDTO, FeedbackProjectModelDTO, FeedbackByDateTaskRaw} from 'shared/models/task/task';
import {TaskDependencyDto} from 'shared/models/TaskDependency';

type EnrichedTask = TaskModelRawDTO & {feedback: FeedbackProjectModelDTO[]};

import type {UIStoreType} from './UIStore';

export function nullsFirstOSKSort(a: TaskModelRawDTO, b: TaskModelRawDTO) {
  if (a.outline_sort_key === b.outline_sort_key) {
    return 0;
  }
  if (!a.outline_sort_key) {
    return -1;
  } else if (!b.outline_sort_key) {
    return 1;
  } else {
    return a.outline_sort_key.localeCompare(b.outline_sort_key);
  }
}

export type TasksStoreType = Pick<TasksStore, keyof Omit<TasksStore, 'uiStore'>>;

@injectable()
export class TasksStore implements TasksStoreType {
  @inject(IOC_TYPES.UIStore) private uiStore: UIStoreType;

  tasks: TaskModelRawDTO[] = [];
  dependencies: TaskDependencyDto[] = [];
  isLoading = false;
  projectId: string | null = null;
  tasksLoaded = false;
  error = null;
  loadDeleted = false;
  silent = false;
  projectFeedback: FeedbackProjectModelDTO[] = [];

  constructor() {
    // eslint-disable-next-line mobx/exhaustive-make-observable
    makeObservable(this, {
      tasks: observable.shallow,
      dependencies: observable.shallow,
      projectFeedback: observable.shallow,
      isLoading: observable,
      projectId: observable,
      tasksLoaded: observable,
      error: observable,
      loadDeleted: observable,
      silent: observable,
    });
  }

  async setLoadDeleted(loadDeleted: boolean) {
    if (this.loadDeleted !== loadDeleted) {
      this.loadDeleted = loadDeleted;
      return this.loadTasks();
    }
  }

  setTasks(tasks: TaskModelRawDTO[]) {
    tasks.sort(nullsFirstOSKSort);
    (this.tasks as IObservableArray).replace(tasks);
  }

  getSortedTasksCopy() {
    return JSON.parse(JSON.stringify(this.tasks)).sort(nullsFirstOSKSort);
  }

  setError(error: unknown) {
    this.error = error;
  }

  setDependencies(dependencies: TaskDependencyDto[]) {
    (this.dependencies as IObservableArray).replace(dependencies);
  }

  setIsLoading(loading: boolean) {
    this.isLoading = loading;
    this.uiStore.setLoading(loading);
  }

  setTasksLoaded(loaded: boolean) {
    this.tasksLoaded = loaded;
  }

  getTaskById(taskId: string) {
    return this.tasks.find((task) => task.id === taskId);
  }

  async setProjectId(projectId: string) {
    if (this.projectId !== projectId || !this.tasks.length) {
      this.projectId = projectId;
      await this.loadTasks();
    }
  }

  addTasks(addedTasks: TaskModelRawDTO[]) {
    addedTasks.sort(nullsFirstOSKSort);
    if (this.tasks.length) {
      for (const task of addedTasks) {
        // this.tasks is an array sorted by outline_sort_key that does not contain task.
        // Find the correct offset to insert task into this.tasks
        // The most common case where the new tasks outline sort key already exists is when
        // a subsequent updateTasks is anticipated that will move those out of the way, so
        // prefer inserting the newest task before any task with matching OSK, so >=.
        const offset = this.tasks.findIndex((t) => t.outline_sort_key >= task.outline_sort_key);
        if (offset === -1) {
          this.tasks.push(task);
        } else {
          this.tasks.splice(offset, 0, task);
        }
      }
    } else {
      this.tasks.push(...addedTasks);
    }
  }

  updateTasks(tasks: Partial<TaskModelRawDTO>[]) {
    // Find the task in the store matching the provided tasks and overwrite the attributes
    const updateMap = new Map(tasks.map((task) => [task.id, task]));
    // This double-pass looks lame, but it's getting around the fact that observe doesn't
    // support transactions.  Also, it isn't actually much more costly.
    for (let i = 0; i < this.tasks.length; i++) {
      if (updateMap.has(this.tasks[i].id)) {
        Object.assign(this.tasks[i], updateMap.get(this.tasks[i].id));
      }
    }

    this.silent = true;
    this.tasks.sort(nullsFirstOSKSort);
    this.silent = false;

    for (let i = 0; i < this.tasks.length; i++) {
      if (updateMap.has(this.tasks[i].id)) {
        // Object.assign, which would seem more natural here, doesn't fire the listener
        // Also, no need to be granular, I'm going to transform the entire task anyway
        this.tasks[i] = {...this.tasks[i]};
      }
    }
  }

  changeTaskId(oldId: number, newId: string) {
    const task = this.tasks.find((task: TaskModelRawDTO) => typeof task.id === 'number' && task.id === oldId);
    if (task) {
      task.id = newId;
    }
  }

  removeTasks(taskIds: string[]) {
    const taskIdsSet = new Set(taskIds);
    runInAction(() => {
      let startIdx = 0;
      let endIdx = 0;

      // This reduces calls to ganttStore.taskListChanged by removing tasks in ranges.
      while (endIdx < this.tasks.length) {
        if (taskIdsSet.has(this.tasks[endIdx].id)) {
          endIdx++;
        } else {
          // If the task ID isn't on set of IDs and our startIdx is different to endIdx, it removes the tasks in that range.
          // After that, we reset endIdx to startIdx to start a new range.
          if (startIdx !== endIdx) {
            this.tasks.splice(startIdx, endIdx - startIdx);
            endIdx = startIdx;
          } else {
            // If startIdx equals endIdx, increment both.
            startIdx++;
            endIdx++;
          }
        }
      }

      // If loop exited with startIdx !== endIdx, it removes remaining tasks in that range.
      if (startIdx !== endIdx) {
        this.tasks.splice(startIdx, endIdx - startIdx);
      }
    });
  }

  addProjectFeedback(newFeedback: FeedbackProjectModelDTO[]) {
    this.projectFeedback = (this.projectFeedback || []).concat(newFeedback);
  }

  deleteProjectFeedback(id: string) {
    const feedbackIndex = this.projectFeedback.findIndex((feedback) => feedback.id === id);
    if (feedbackIndex !== -1) {
      this.projectFeedback.splice(feedbackIndex, 1);
    }
  }

  private async loadProjectFeedback() {
    const feedback = await FeedbackService.getAllFeedBackForProject(this.projectId);
    this.projectFeedback = feedback;
  }

  private mergeTaskRelatedData(): TaskModelRawDTO[] {
    const feedbackByTask: Record<string, FeedbackProjectModelDTO[]> = {};
    const commentCountByTask: Record<string, number> = {};

    // Group feedback by task
    this.projectFeedback?.forEach((feedback) => {
      if (!feedbackByTask[feedback.taskId]) {
        feedbackByTask[feedback.taskId] = [];
      }
      feedbackByTask[feedback.taskId].push(feedback);

      // Count comments
      if (
        feedback.feedbackType === MessageTypeMap.message ||
        feedback.feedbackType === MessageTypeMap.image ||
        feedback.feedbackType === MessageTypeMap.extChatEvent
      ) {
        commentCountByTask[feedback.taskId] = (commentCountByTask[feedback.taskId] || 0) + 1;
      }
    });

    // Merge task related data into tasks
    return this.tasks.map((task) => {
      const taskFeedback = feedbackByTask[task.id] || [];
      const commentCount = commentCountByTask[task.id] || 0;

      const feedbackByDate: FeedbackByDateTaskRaw[] = [];

      taskFeedback.forEach((feedback) => {
        const feedbackField = feedback.feedbackType as DailyType;

        let feedbackByDateEntry = feedbackByDate.find((entry) => entry.field === feedbackField);

        if (!feedbackByDateEntry) {
          feedbackByDateEntry = {field: feedbackField, feedback_by_date: []};
          feedbackByDate.push(feedbackByDateEntry);
        }

        const {date, workerId, timeCreated} = feedback;
        const {valueInteger} = feedback.payload;

        const newFeedbackEntry = {
          date,
          time_created: timeCreated,
          value: valueInteger,
          worker_id: workerId,
        };

        // Check if there's already an entry for this date and time_created
        const existingEntryIndex = feedbackByDateEntry.feedback_by_date.findIndex(
          (entry) => entry.time_created === feedback.timeCreated && entry.date === feedback.date,
        );

        if (existingEntryIndex > -1) {
          // Update existing entry if found
          feedbackByDateEntry.feedback_by_date[existingEntryIndex] = newFeedbackEntry;
        } else {
          // Add new entry if not found
          feedbackByDateEntry.feedback_by_date.push(newFeedbackEntry);
        }
      });

      const enrichedTask = Object.assign({}, task) as EnrichedTask;
      enrichedTask.feedback = taskFeedback;
      enrichedTask.comment_count = commentCount;
      enrichedTask.feedback_by_date = feedbackByDate;

      return enrichedTask;
    });
  }

  async loadTasks() {
    const requestProjectId = this.projectId;

    try {
      this.setIsLoading(true);
      this.setError(null);
      this.setDependencies([]);
      this.setTasks([]);
      this.setTasksLoaded(false);
      const loadDeleted = this.loadDeleted;

      const results = await loadAllTasks(requestProjectId, loadDeleted);

      /**
       * If interrupted because project id changed, loadAllTasks
       * succeeds with an InterruptedException this is intentional
       * so that we continue processing the new batches that were created
       */
      if (results instanceof InterruptedException) {
        return;
      }

      runInAction(() => {
        this.setTasks(results);
      });
      const res = await ProjectsApi.getTaskDependencies(this.projectId, []);
      await this.loadProjectFeedback();
      const tasksWithFeedback = this.mergeTaskRelatedData();

      runInAction(() => {
        this.setTasks(tasksWithFeedback);
        this.setDependencies(res.dependencies);
        this.setIsLoading(false);
        this.setTasksLoaded(true);
      });
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          projectId: requestProjectId,
        },
      });
      extractAxiosError(error);
      runInAction(() => {
        this.setError(error);
        this.setIsLoading(false);
      });
    }
  }
}
