import {useQuery} from '@tanstack/react-query';
import {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router';
import {type ActionMeta, type SelectInstance} from 'react-select';

import ProjectsApi from 'api/projects';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';

import {CreatableSelect} from '../CreatableSelect/CreatableSelect';
import {CoreSelect} from '../Select';

interface BaseProps {
  name: string;
  loadingPlaceholder?: string;
  isFocusWhenLoading?: boolean;
  _projectId?: string;
  noOptionsMessage?: () => string;
  onChange?: (value: string | null, actionMeta?: ActionMeta<CoreOptionType>) => void;
  value?: string | null;
  placeholder?: string;
  isDisabled?: boolean;
}

interface CreatableProps extends BaseProps {
  isCreatable: true;
}

interface NonCreatableProps extends BaseProps {
  isCreatable?: false;
}

type Props = (CreatableProps | NonCreatableProps) & Omit<Parameters<typeof CoreSelect>[0], keyof BaseProps>;

const AsyncTaskTypeSelect = ({
  name,
  value,
  loadingPlaceholder = 'Loading...',
  placeholder,
  onChange,
  isCreatable = false,
  isDisabled,
  isFocusWhenLoading,
  _projectId,
  noOptionsMessage,
  ...props
}: Props) => {
  const urlParams = useParams<{status: string; id: string; projectId: string}>();
  const selectRef = useRef<SelectInstance<CoreOptionType, false>>(null);
  const [selected, setSelected] = useState<string | null>(null);
  const projectId = _projectId || urlParams.projectId;

  useEffect(() => {
    value && setSelected(value);
  }, [value]);

  const {
    data: taskTypes,
    isLoading,
    refetch: refetchTaskTypes,
  } = useQuery({
    queryKey: QUERY_CACHE_KEYS.taskTypes(projectId),
    queryFn: async () => {
      const options: CoreOptionType[] = [];
      const res = await ProjectsApi.getProjectTaskTypes(projectId);
      res.forEach((label) => {
        if (label.length) options.push({value: label, label: label});
      });
      return options;
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isFocusWhenLoading) selectRef.current?.focus();
  }, [taskTypes, isFocusWhenLoading]);

  const onSelectChange = (value: string, {action}: ActionMeta<CoreOptionType>) => {
    setSelected(value);
    onChange(value);
    if (action === 'create-option') {
      refetchTaskTypes();
    }
  };

  const commonProps = {
    isSearchable: true,
    isClearable: true,
    ref: selectRef,
    isDisabled: isLoading || isDisabled,
    isLoading,
    options: taskTypes || [],
    name,
    placeholder: isLoading ? loadingPlaceholder : placeholder,
    className: 'react-select',
    classNamePrefix: 'react-select',
    openMenuOnClick: !isLoading,
    onChange: onSelectChange,
    value: selected,
    noOptionsMessage: noOptionsMessage ? () => noOptionsMessage() : undefined,
    ...props,
  };

  if (isCreatable) {
    return <CreatableSelect {...commonProps} isValidNewOption={(val: string) => !!val.trim()} />;
  }

  return <CoreSelect {...commonProps} />;
};

export default AsyncTaskTypeSelect;
