import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useCallback, useEffect, useMemo} from 'react';

import ProjectsApi from 'api/projects';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {WakeCapCrews} from 'shared/models/task/task';

const useCrews = (projectId: string) => {
  const queryClient = useQueryClient();

  const fetchCrews = useCallback(() => ProjectsApi.getWakeCapCrews(projectId), [projectId]);

  const CREWS_CACHE_KEY = useMemo(() => QUERY_CACHE_KEYS.crews(projectId), [projectId]);

  useEffect(() => {
    if (!queryClient.getQueryData(CREWS_CACHE_KEY)) {
      queryClient.prefetchQuery({
        queryKey: CREWS_CACHE_KEY,
        queryFn: fetchCrews,
      });
    }
  }, [queryClient, CREWS_CACHE_KEY, fetchCrews]);

  const query = useQuery({
    queryKey: QUERY_CACHE_KEYS.crews(projectId),
    queryFn: fetchCrews,
    enabled: Boolean(projectId),
    select: (data) => data ?? [],
    initialData: () => {
      const cachedData = queryClient.getQueryData<WakeCapCrews[]>(CREWS_CACHE_KEY);
      return cachedData ?? [];
    },
  });

  return {
    ...query,
    data: query.data ?? [],
  };
};

export default useCrews;
