import * as Sentry from '@sentry/browser';
import {Field, FieldProps, Formik, FormikProps} from 'formik';
import {useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast, ToastContent} from 'react-toastify';
import {ValidationError} from 'yup';

import WorkerApi from 'api/worker';
import {useAuth} from 'shared/components/AuthUserProvider';
import Button from 'shared/components/Button';
import {useConfirm} from 'shared/components/Confirmation';
import {CoreSelect} from 'shared/components/CoreForm/Select/Select';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import AvatarCrop from 'shared/components/Cropper/Cropper';
import {useCropper} from 'shared/components/Cropper/useCropper';
import FormikChangeWatcher from 'shared/components/FormikChangeWatcher';
import Icon from 'shared/components/Icon';
import SkeletonPreloader from 'shared/components/SkeletonPreloader';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {WORKER_UNSPECIFIED_TRADE} from 'shared/constants/worker';
import {getConfirmSaveChangesPayload} from 'shared/helpers/common';
import {getCompanyWorkerStatus} from 'shared/helpers/statuses';
import {useMixpanel} from 'shared/hooks/analytics/useMixpanel';
import {useMount} from 'shared/hooks/core/useMount';
import {useCompany} from 'shared/hooks/useCompany';
import {useLoadWorkerTrades} from 'shared/hooks/useLoadWorkerTrades';
import {useProfile} from 'shared/hooks/useProfile';
import {useQueryCache} from 'shared/hooks/useQueryCache/useQueryCache';
import {SlidePanel} from 'shared/layout/admin';
import {ProfileFormValues} from 'shared/models/worker';
import {needShowEmailContact} from 'shared/utils/inviteWorker';
import {useRootDispatch, useRootSelector} from 'store';
import {profileActions, selectCompanyWorkerProfileSelector} from 'store/profile';

import {useCompanyWorker, useCompanyWorkerProjects} from '../queries';
import WorkerCard, {WorkerCardProps} from '../WorkerCard/WorkerCard';

import TwoFactorAuthSettings from './components/TwoFactor/TwoFactorAuthSettings';
import {getImageValidationSchema, getProfileDefaultSchema} from './validationSchema';

const Profile = () => {
  const company = useCompany();
  const companyWorkerProfile = useRootSelector(selectCompanyWorkerProfileSelector);
  const {localizedTrades} = useLoadWorkerTrades();
  const profile = useProfile();
  const dispatch = useRootDispatch();
  const {queryClient} = useQueryCache();
  const mixpanel = useMixpanel({});
  const {confirm} = useConfirm();
  const {t} = useTranslation(['common', 'profile', 'worker']);

  const {user: firebaseUser} = useAuth();
  const canUserConfigure2Fa = !firebaseUser?.phoneNumber && !firebaseUser.isAnonymous;

  const formik = useRef<FormikProps<ProfileFormValues>>(null);

  const {data: worker, refetch: refetchWorkerProfile} = useCompanyWorker(company?.id, companyWorkerProfile.id);
  const {data: projects} = useCompanyWorkerProjects(company?.id, companyWorkerProfile.id);

  // for WorkerCard component
  const [formikValues, setFormikValues] = useState<ProfileFormValues>(null);
  const [saving, setSaving] = useState(false);

  const cropper = useCropper({
    originalUrl: profile.profilePicUrl,
    previewUrl: profile.profilePicUrl,
    edit: true,
  });

  useMount(() => {
    const {events, track} = mixpanel;
    track(events.profile.open);

    return () => {
      track(events.profile.close);
    };
  });

  const getInitialValues = useMemo(() => {
    return {
      fullName: worker?.workerFull.fullName || '',
      email: worker?.workerFull.email || '',
      trade: worker?.workerFull.trade || WORKER_UNSPECIFIED_TRADE,
      avatar: null,
    };
  }, [worker]);

  const workerCardModel: WorkerCardProps['profile'] = useMemo(() => {
    return {
      profileImage: worker?.workerFull.profilePicUrl,
      status: worker ? getCompanyWorkerStatus(worker.status, worker.workercard.status) : '',
      fullName: formikValues?.fullName,
      trade: formikValues?.trade,
      workerId: worker?.id,
      roles: worker?.roles,
      workerEmail: formikValues?.email,
      showEmailAsContact: needShowEmailContact(worker),
    };
  }, [worker, formikValues]);

  const uploadWorkerAvatar = async (avatar: File): Promise<string> => {
    try {
      const updatedWorker = await WorkerApi.uploadWorkerImage(worker.workercard.id, avatar);
      return updatedWorker.profilePicUrl;
    } catch (e) {
      toast.error(t('profile:toast.error.upload_avatar'));
    }
  };

  const onSubmit = async (values: ProfileFormValues) => {
    setSaving(true);
    mixpanel.track(mixpanel.events.profile.saveChanges);
    let profilePicUrl = null;
    if (values.profilePic) {
      profilePicUrl = await uploadWorkerAvatar(values.profilePic);
    }
    try {
      const updatedWorker = await WorkerApi.updateWorker({
        id: worker.workercard.id,
        fullName: values.fullName,
        trade: values.trade,
        profilePicUrl: profilePicUrl,
      });
      dispatch(profileActions.setWorker({...updatedWorker}));
      formik.current?.resetForm({values});
      toast.success(t('profile:toast.success.profile'));
      refetchWorkerProfile();
      queryClient.resetQueries({
        queryKey: QUERY_CACHE_KEYS.workers,
      });
    } catch (e) {
      if (typeof e === 'object' && 'message' in e) {
        toast.error(e.message as ToastContent);
      }
      console.error(e);
      Sentry.captureException(e);
    } finally {
      setSaving(false);
    }
  };

  const onClose = async () => {
    if (formik.current?.dirty) {
      if (await confirm(getConfirmSaveChangesPayload(t))) {
        await formik.current?.handleSubmit();
      }
    }
    dispatch(profileActions.setMyProfileOpened(false));
  };

  const onAvatarChange = async (file: File) => {
    if (file) {
      try {
        await getImageValidationSchema(t).validate(file);
        cropper.setSelectedFile(file);
      } catch (e) {
        if (e instanceof ValidationError) {
          toast.error(e.errors[0]);
        }
      }
    } else {
      cropper.setSelectedFile(file);
      formik.current.setFieldValue('profilePic', null);
    }
  };

  return (
    <SlidePanel
      disablePaddings
      onClose={onClose}
      content={
        <>
          <SlidePanel.Header>
            {
              <Button
                type="submit"
                data-cy="editing_save_worker_bt"
                onClick={() => {
                  formik.current.handleSubmit();
                }}
                disabled={saving}
                className="slide-panel__button-main"
                icon={<Icon colorFill size={24} name="checkmark-outlined" />}
              >
                {t('worker:buttons.save', 'Save Info')}
              </Button>
            }
          </SlidePanel.Header>
          <SlidePanel.Body>
            <Formik<ProfileFormValues>
              innerRef={formik}
              enableReinitialize={true}
              validationSchema={getProfileDefaultSchema(t)}
              initialValues={getInitialValues}
              onSubmit={onSubmit}
            >
              {({setFieldValue}) => (
                <form className="form-default" style={{width: '100%'}}>
                  <FormikChangeWatcher debounce={200} onChange={setFormikValues} />
                  <div className="form-default__container">
                    <header className="form-default__header">
                      <h2 className="form-default__title">{t('profile:title', 'Edit Profile')}</h2>
                    </header>
                    <div className="form-default__grid">
                      <div className="form-default__item form-default__item--full">
                        <Field name="profilePic">
                          {() => {
                            return (
                              <AvatarCrop
                                classNames="form-edit-resume__item"
                                previewImg={cropper.preview}
                                image={cropper.selectedFileUrl}
                                fileType={cropper.selectedFileType}
                                onImageChange={onAvatarChange}
                                edit={cropper.edit}
                                onEdit={cropper.setEdit}
                                zoom={cropper.zoom}
                                rotate={cropper.rotate}
                                onCropped={(file) => {
                                  cropper.setCropperFile(file);
                                  setFieldValue('profilePic', file);
                                }}
                                onRotate={cropper.setRotate}
                                onZoom={cropper.setZoom}
                              />
                            );
                          }}
                        </Field>
                      </div>
                      <div className="form-default__item form-default__item--full">
                        <SkeletonPreloader when={!worker}>
                          <FormControl label={t('worker:form.name.label', 'Full Name')} name="fullName">
                            <Field
                              name="fullName"
                              className="ctrl-textfield"
                              placeholder={t('worker:form.name.placeholder', 'Full Name')}
                            />
                          </FormControl>
                        </SkeletonPreloader>
                      </div>
                      <div className="form-default__item form-default__item--full">
                        <SkeletonPreloader when={!worker}>
                          <FormControl label={t('worker:form.email.label', 'Email Address')} name="email">
                            <Field as="input" className="ctrl-textfield" disabled={true} />
                          </FormControl>
                        </SkeletonPreloader>
                      </div>
                      <div className="form-default__item form-default__item--full">
                        <SkeletonPreloader when={!worker}>
                          <FormControl label={t('worker:form.trade.label', 'Select Trade')} name="trade">
                            <Field>
                              {({field}: FieldProps) => (
                                <CoreSelect
                                  options={localizedTrades}
                                  isLoading={!localizedTrades}
                                  value={field.value}
                                  onChange={(value) => setFieldValue('trade', value)}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </SkeletonPreloader>
                      </div>
                      {canUserConfigure2Fa && (
                        <div className="form-default__item form-default__item--full">
                          <SkeletonPreloader when={!worker}>
                            <TwoFactorAuthSettings />
                          </SkeletonPreloader>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </SlidePanel.Body>
        </>
      }
      aside={<WorkerCard projects={projects} profile={workerCardModel} />}
    />
  );
};
export default Profile;
