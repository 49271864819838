import classNames from 'classnames';
import {CSSProperties, FC, isValidElement, PropsWithChildren} from 'react';

type Props = {
  fromParent?: boolean;
  dropPanelStyles?: CSSProperties;
};

const CoreDropPanel: FC<PropsWithChildren<Props>> = ({fromParent = true, children, dropPanelStyles}) => {
  return (
    <div className={classNames('drop-panel', fromParent && 'drop-panel--from-parent')} style={dropPanelStyles}>
      {isValidElement(children) ? children : null}
    </div>
  );
};

export default CoreDropPanel;
