import {TFunction} from 'i18next';
import {useHistory} from 'react-router';
import {components, OptionProps, SingleValueProps, MenuListProps, GroupBase, Props as SelectProps} from 'react-select';

import Avatar from 'shared/components/Avatar';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';

import {ProjectWorkerSelectOption} from './AsyncProjectWorkerSelect';

interface CustomProps {
  taskId?: string;
  t?: TFunction;
}

type CustomMenuListProps = MenuListProps<ProjectWorkerSelectOption, true, GroupBase<ProjectWorkerSelectOption>> & {
  selectProps: SelectProps<ProjectWorkerSelectOption, true, GroupBase<ProjectWorkerSelectOption>> & CustomProps;
};

export const CustomOption = (
  props: OptionProps<ProjectWorkerSelectOption, true, GroupBase<ProjectWorkerSelectOption>>,
) => (
  <components.Option {...props}>
    <div className="worker-accepted">
      <div className="worker-accepted__info">
        <Avatar
          className="avatar worker-accepted__info-part worker-accepted__info-part--avatar"
          src={props.data.value?.workercard?.profilePicUrl}
        />
        <div className="worker-accepted__info-part worker-accepted__info-part--text">
          <div className="worker-accepted__name">
            <span>{props.data.label}</span>
          </div>
          <div className="worker-accepted__trade">
            <span>{props.data.value?.workerFull?.trade || 'unspecified'}</span>
          </div>
        </div>
      </div>
    </div>
  </components.Option>
);

export const CustomOptionForMulti = (
  props: OptionProps<ProjectWorkerSelectOption, true, GroupBase<ProjectWorkerSelectOption>>,
) => (
  <components.Option {...props}>
    <div className="worker-accepted">
      <div className="worker-accepted__info">
        <Avatar
          className="avatar worker-accepted__info-part worker-accepted__info-part--avatar"
          src={props.data.value?.workercard?.profilePicUrl}
        />
        <div className="worker-accepted__info-part worker-accepted__info-part--text">
          <div className="worker-accepted__name">
            <span>{props.data.label}</span>
          </div>
          <div className="worker-accepted__trade">
            <span>{props.data.value?.workerFull?.trade || 'unspecified'}</span>
          </div>
        </div>
      </div>
    </div>
  </components.Option>
);

export const CustomSingleValue = (
  props: SingleValueProps<ProjectWorkerSelectOption, true, GroupBase<ProjectWorkerSelectOption>>,
) => (
  <components.SingleValue {...props}>
    <div className="person-preview">
      <Avatar className="person-preview__avatar" src={props.data.value?.workercard?.profilePicUrl} />
      <div className="person-preview__info">
        <div className="person-preview__name">{props.data.label}</div>
        <div className="person-preview__trade">{props.data.value?.workerFull?.trade || 'unspecified'}</div>
      </div>
    </div>
  </components.SingleValue>
);

export const CustomMenuList = (props: CustomMenuListProps) => {
  const history = useHistory();

  const openInviteForm = () => {
    history.replace({state: {inviteTaskResponsible: props.selectProps.taskId}});
  };

  return (
    <components.MenuList {...props}>
      {props.children}
      {!props.selectProps.isLoading && (
        <div className="react-select__option">
          <CtrlButton
            color="action"
            onClick={openInviteForm}
            dataCy="add_worker_bt"
            view="link"
            size="s"
            style={{padding: '0px 5px', width: '100%'}}
          >
            {props.selectProps.t?.('gantt:responsible.invite.button', '+ Invite')}
          </CtrlButton>
        </div>
      )}
    </components.MenuList>
  );
};
