import {useReducer} from 'react';
import {createPortal} from 'react-dom';

import {ConfirmationContext} from './ConfirmationContext';
import ConfirmationWrapper from './ConfirmationWrapper';
import {confirmReducer} from './state';

const ConformationProvider = ({children}) => {
  const [state, dispatch] = useReducer(confirmReducer, {show: false, config: null});

  return (
    <ConfirmationContext value={{dispatch}}>
      {children}
      {state.show && state.config && createPortal(<ConfirmationWrapper {...state.config} />, document.body)}
    </ConfirmationContext>
  );
};
export default ConformationProvider;
