import {CellContext} from '@tanstack/react-table';
import cn from 'classnames';

import {CompanyWorker} from 'shared/models/worker';

import s from './OrgsColumn.module.scss';

type OrgsColumnProps = CellContext<CompanyWorker, string[]>;

export const OrgsColumn = ({getValue}: OrgsColumnProps) => {
  const value = getValue();

  return (
    <div className={cn(s.orgsColumn)}>
      <div className={s.orgsColumn__grid}>
        {value?.map((org, index) => (
          <div className={s.orgsColumn__item} key={index}>
            {org}
          </div>
        ))}
      </div>
    </div>
  );
};
