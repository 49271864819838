import {useFloating, offset, shift, flip} from '@floating-ui/react';
import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';
import {camelize} from 'humps';
import {useCallback, useRef} from 'react';
import ReactDatePicker from 'react-datepicker';
import {createPortal} from 'react-dom';

import {useGanttContext} from 'modules/Tasks/components/Gantt/components/GanttContext';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import DatePicker from 'shared/components/CoreForm/DatePicker';
import {KEYCODE} from 'shared/constants/common';
import {addDays, safeParseDate} from 'shared/helpers/dates';
import {useMount} from 'shared/hooks/core/useMount';
import {useOutsideClick} from 'shared/hooks/core/useOusideClick';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {prepareDateForGantt} from 'shared/mapping/task';

import {GANTT_COLUMNS_NAMES} from '../../utils/constants';

const DATE_FORMAT = 'yyyy-MM-dd';

const getMinDate = (selectedTask: GanttTask, columnName: string): Date | null => {
  if (columnName === GANTT_COLUMNS_NAMES.actualEnd) {
    return safeParseDate(selectedTask.actual_end);
  }
  if (columnName === GANTT_COLUMNS_NAMES.endDate) {
    return safeParseDate(selectedTask.end_date);
  }
  return null;
};

export const DateEditor = ({gantt}: {gantt: GanttStatic}) => {
  const {selectedTask, selector, onStopEditAction} = useGanttContext();
  const datePickerRef = useRef<ReactDatePicker>(null);
  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.gantt.inlineEdit;

  const refEl = typeof selector === 'string' ? document.querySelector<HTMLDivElement>(selector) : null;
  const dateColumnName = refEl?.dataset?.columnName;

  const isEnd = dateColumnName === GANTT_COLUMNS_NAMES.actualEnd;
  const isSchedEnd = dateColumnName === GANTT_COLUMNS_NAMES.endDate;

  const {x, y, strategy, refs} = useFloating({
    elements: {
      reference: refEl,
    },
    placement: 'bottom-start',
    middleware: [offset({mainAxis: 10, crossAxis: 6}), flip(), shift()],
  });

  useMount(() => {
    datePickerRef?.current?.setOpen(true);
    const onEnterHandler = (e: KeyboardEvent) => {
      if (e.code === KEYCODE.ENTER) {
        onStopEditAction();
      }
    };
    gantt.$container.addEventListener('keydown', onEnterHandler);
    return () => {
      gantt.$container.removeEventListener('keydown', onEnterHandler);
    };
  });

  useOutsideClick({
    ref: refs.floating,
    callback: (e) => {
      if (!(e.target as HTMLDivElement).closest('.react-datepicker')) {
        onStopEditAction();
      }
    },
  });

  const updateTask = (date: Date | null) => {
    if (!date) return;

    mixpanel.track(mixpanelEvents, {viewMode: gantt.name, column: dateColumnName});

    let isStartDateAfterEndDate = false;
    let updatedDate = date;

    if (isSchedEnd || isEnd) {
      updatedDate = addDays(date, 1).toDate();
    }

    if (isSchedEnd) {
      if (dayjs(selectedTask.start_date).isAfter(updatedDate)) {
        selectedTask.lastChangedFields.startDate = {newValue: addDays(updatedDate, -1).toDate()};
        isStartDateAfterEndDate = true;
      }
    }

    if (!isStartDateAfterEndDate) {
      selectedTask.lastChangedFields[camelize(dateColumnName)] = {
        newValue: updatedDate,
      };
    }

    if (selectedTask.datesIsPristine) selectedTask.datesIsPristine = false;
    gantt.updateTask(selectedTask.id, selectedTask);
    gantt.dRender();
    onStopEditAction();
  };

  const showSelected = useCallback(() => {
    const date: string = selectedTask[dateColumnName];
    if (date === undefined) {
      return null;
    }

    return prepareDateForGantt(date);
  }, [dateColumnName, isEnd, isSchedEnd, selectedTask]);

  return refEl
    ? createPortal(
        <div
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            zIndex: 1000,
          }}
          className="react-datepicker-popper date-editor-popper"
        >
          <DatePicker
            className="bulk-input_gantt"
            dateFormat={DATE_FORMAT}
            selected={showSelected()}
            onChange={updateTask}
            placeholderText={''}
            showIcon={false}
            inline
            ref={datePickerRef}
            minDate={getMinDate(selectedTask, dateColumnName)}
            isClearable={false}
          />
        </div>,
        document.body,
      )
    : null;
};
