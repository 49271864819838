import {Row, ColumnDef} from '@tanstack/react-table';

import s from 'shared/components/Table/Table.module.scss';

import {TableColumn} from './TableColumns';

export interface TableRowProps<D extends Record<string, unknown>> {
  onClick?: (row: Row<D>) => void;
  row: Row<D>;
  cellClassName: string;
  cellRenderProps: Record<string, unknown>;
  showPreloader?: boolean;
  columns?: ColumnDef<D>[];
}

export const TableRow = <D extends Record<string, unknown>>({
  cellClassName,
  cellRenderProps,
  onClick,
  row,
}: TableRowProps<D>) => {
  // Get style properties from row.original if they exist
  const style = {
    minHeight: (row.original as any).minRowHeight,
    cursor: 'pointer',
    height: (row.original as any).fixedRowHeight,
  };

  return (
    <div className={s.tableWorkers__line} style={style} onClick={() => onClick?.(row)}>
      {row.getVisibleCells().map((cell, index) => (
        <TableColumn
          cell={cell}
          key={cell.id}
          columnId={cell.column.id}
          columnIndex={index}
          className={cellClassName}
          renderProps={cellRenderProps}
        />
      ))}
    </div>
  );
};
