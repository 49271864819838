import {FC, PropsWithChildren} from 'react';

type Props = {
  title: string;
  description: string;
  keywords: string;
  ogTitle?: string;
  ogDescription?: string;
  ogKeywords?: string;
  ogImage?: string;
  ogUrl?: string;
  ogType?: string;
};

const MetaTags: FC<Props & PropsWithChildren> = ({
  title,
  description,
  keywords,
  ogTitle = title,
  ogDescription = description,
  ogKeywords = keywords,
  ogUrl,
  ogImage,
  ogType,
  children,
}) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {ogTitle && <meta name="og:title" content={ogTitle} />}
      {ogDescription && <meta name="og:description" content={ogDescription} />}
      {ogKeywords && <meta name="og:keywords" content={ogKeywords} />}
      {ogUrl && <meta name="og:url" content={ogUrl} />}
      {ogImage && <meta name="og:image" content={ogImage} />}
      {ogType && <meta name="og:type" content={ogType} />}
      {children}
    </>
  );
};
export default MetaTags;
