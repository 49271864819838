import {useQuery} from '@tanstack/react-query';

import TasksApi from 'api/tasks';

type TaskLabelCategories = {[key: string]: string[]};

export type AppConstantsResponse = {
  locales: {
    uS: {
      lookaheadColors: {
        [key: string]: {fill: string; outline: string; userSelectable: boolean};
      };
      taskRiskLabelByCategory: TaskLabelCategories;
      matrix: {homeserver: string};
    };
  };
};

export function useAppConstants() {
  const {data} = useQuery({
    queryKey: ['responsibleOrgColors'],
    queryFn: async () => {
      return (await TasksApi.getAppConstants()) as AppConstantsResponse;
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  return data;
}
