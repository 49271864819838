import {FC, HTMLAttributes, useMemo, useRef, useEffect} from 'react';
import {IMaskInput} from 'react-imask';

import {PHONE_MASKS_BY_CODE, PhoneMask, PhoneMaskWithAlter} from 'shared/constants/common';

type PhoneInputProps = {
  value: string;
  countryCode?: string;
  disabled?: boolean;
  onManualChange?: (value: string) => void;
  placeholder?: string;
} & HTMLAttributes<HTMLInputElement>;

const PhoneInput: FC<PhoneInputProps> = ({value, countryCode, disabled, onManualChange, ...props}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const previousCodeRef = useRef(countryCode);

  const mask = useMemo(() => {
    const maskConfig = PHONE_MASKS_BY_CODE[countryCode];
    if (!maskConfig) return null;

    const hasAlterMask = (config: PhoneMask): config is PhoneMaskWithAlter =>
      'alterMask' in config && 'alterPredicate' in config;

    if (hasAlterMask(maskConfig) && value && maskConfig.alterPredicate(value.replace(/\D+/g, ''))) {
      return maskConfig.alterMask;
    }
    return maskConfig.mask;
  }, [countryCode, value]);

  // reset phone number when country code changes
  useEffect(() => {
    if (previousCodeRef.current !== countryCode) {
      // force reset both the input value and call onManualChange
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      onManualChange?.('');
      previousCodeRef.current = countryCode;
    }
  }, [countryCode]);

  return (
    <IMaskInput
      {...props}
      value={value}
      mask={mask}
      unmask={false}
      lazy={false}
      definitions={{
        '0': /[0-9]/,
        '?': /[0-9 ]/,
      }}
      onAccept={(value) => {
        if (inputRef.current && value !== inputRef.current.value) {
          onManualChange?.(value);
        }
      }}
      onBlur={(e) => {
        if (props.onBlur) {
          props.onBlur(e);
        }
        if (inputRef.current && inputRef.current.value) {
          onManualChange?.(inputRef.current.value);
        }
      }}
      prepare={(str) => str || ''}
      style={disabled ? {opacity: '.25'} : undefined}
      disabled={disabled}
      inputRef={(el) => {
        inputRef.current = el;
      }}
    />
  );
};

export default PhoneInput;
