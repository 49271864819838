/* eslint-disable @typescript-eslint/naming-convention */
import {useQuery} from '@tanstack/react-query';
import {ColumnDef, useReactTable, getCoreRowModel, flexRender} from '@tanstack/react-table';
import cn from 'classnames';
import {decamelize} from 'humps';
import {FC, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {TableVirtuoso} from 'react-virtuoso';

import CompanyApi from 'api/company';
import Button from 'shared/components/Button';
import {useConfirm} from 'shared/components/Confirmation';
import Icon from 'shared/components/Icon';
import Popup from 'shared/components/Popup/Popup';
import ProgressBar from 'shared/components/ProgressBar';
import s from 'shared/components/TasksImport/components/styles.module.scss';
import Tooltip from 'shared/components/Tooltip';
import {mixpanelEvents} from 'shared/constants/mixpanelEvents';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompany} from 'shared/hooks/useCompany';

import {useTasksImportContext} from '../TasksImportContext/TasksImportContext';
import {getTableCellClass, statusSuffix} from '../utils/constants';
import {TaskImportField, TaskImportObject, TaskImportResult} from '../utils/types';

import LegendCompare from './LegendCompare';

const TABLE_HEIGHT = 425;

type TableHeadProps = React.HTMLProps<HTMLTableSectionElement>;
type TableRowProps = React.HTMLProps<HTMLTableRowElement> & {
  'data-index': number;
};

type TasksImportPreviewProps = {
  fields: TaskImportField[];
  importPercentage: number;
  onBack: () => void;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  result: TaskImportResult;
};

const TasksImportPreviewTable: FC<TasksImportPreviewProps> = ({
  fields,
  importPercentage,
  onBack,
  onClose,
  onSubmit,
  result,
}) => {
  const {confirm} = useConfirm();
  const {t} = useTranslation('import');
  const {projectId} = useParams<{projectId: string}>();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId}});
  const [{config}] = useTasksImportContext();
  const company = useCompany();
  const [importInProcess, setImportInProcess] = useState(false);
  const [showOnlyErrors, setShowOnlyErrors] = useState(false);

  const handleSubmit = async () => {
    try {
      if (result.errors?.find((error) => error.code === 'missing_outline_parent')) {
        if (
          await confirm({
            description: t('import_preview.description'),
            cancelButton: t('import_preview.buttons.cancel'),
            acceptButton: t('import_preview.buttons.continue'),
          })
        ) {
          setImportInProcess(true);
          await onSubmit();
          setImportInProcess(false);
        } else {
          return onClose();
        }
      } else {
        setImportInProcess(true);
        await onSubmit();
        setImportInProcess(false);
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setImportInProcess(false);
    }
  };

  const whichMixPanelEvent = showOnlyErrors
    ? mixpanelEvents.tasks.import.showAllRows
    : mixpanelEvents.tasks.import.showMoreErrors;

  const toggleView = () => {
    mixpanel.trackWithAction(() => setShowOnlyErrors(!showOnlyErrors), whichMixPanelEvent);
  };

  const {data: subcontractorOptions = {}} = useQuery({
    queryKey: ['companySubcontractorsImport', company?.id],
    queryFn: async () => {
      const res = await CompanyApi.getCompanyOrgs(company.id);
      return res.reduce((acc, sub) => {
        acc[sub.id] = sub.group.name;
        return acc;
      }, {});
    },
    enabled: !!company?.id,
    refetchOnWindowFocus: false,
  });

  const taskResponses = useMemo(
    () => (showOnlyErrors ? result.taskResponses.filter((task) => !!task?.errors.length) : result.taskResponses),
    [result.taskResponses, showOnlyErrors],
  );

  const columns = useMemo<ColumnDef<TaskImportObject>[]>(() => {
    return fields.map((field) => ({
      header: field.columnTitle || field.label,
      id: field.key || '',
      cell: ({row}) => {
        const taskCompare = row.original;
        const isEdit = taskCompare.updatedFields.includes(decamelize(field.key || ''));
        const isError = taskCompare.errors.find((error) => error.field === decamelize(field.key || ''));
        const cellClassName = isError ? 'error' : isEdit ? 'edit' : '';

        let value;
        if (field.key === 'assignedNames') {
          value = taskCompare.nameResolutions;
        } else if (field.key === 'subcontractor') {
          value = subcontractorOptions[taskCompare.task.responsibleOrgId] || '';
        } else if (field.key) {
          value = taskCompare.task[field.key];
        }

        const displayValue =
          field.accessor && value !== undefined
            ? field.key === 'assignedNames'
              ? field.accessor(value, taskCompare.nameResolutions, config)
              : field.accessor(value, null, config)
            : value;

        return (
          <span
            className={cn(
              'aka-table__value',
              'aka-table__value--one-line',
              cellClassName ? `aka-table__value--${cellClassName}` : '',
              {
                'aka-table__value--id': field.key === 'uniqueId',
              },
            )}
          >
            {displayValue}
            {(isEdit || isError) && (
              <Tooltip
                text={isEdit ? t('import_preview.tooltip.text') : isError.messages.join(', ')}
                type={isEdit ? 'edit' : 'error'}
                placement="top"
              >
                <Button
                  className="aka-table__value-button"
                  iconOnly
                  icon={<Icon name={isEdit ? 'swap_horizontal' : isError && 'error'} colorFill />}
                >
                  {t('import_preview.buttons.show')}
                </Button>
              </Tooltip>
            )}
          </span>
        );
      },
    }));
  }, [fields, subcontractorOptions, config, t]);

  const table = useReactTable({
    data: taskResponses,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const headerContent = () => (
    <>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id} className="aka-table__tr">
          {headerGroup.headers.map((header) => (
            <th key={header.id} className={`aka-table__th ${getTableCellClass(header.id)}`}>
              <div className={s['aka-table__th-content--start']}>
                <span className="aka-table__value aka-table__value--one-line">
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </span>
              </div>
            </th>
          ))}
        </tr>
      ))}
    </>
  );

  const rowContent = (index: number) => {
    const row = table.getRowModel().rows[index];

    return (
      <>
        {row.getVisibleCells().map((cell) => (
          <td key={cell.id} className={getTableCellClass(cell.column.id)}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </>
    );
  };

  const TableHead = (props: TableHeadProps) => <thead {...props} className={s.tableHeader} />;

  const TableRow = (props: TableRowProps) => {
    const row = table.getRowModel().rows[props['data-index']];
    return (
      <tr
        {...props}
        style={{
          display: 'flex',
        }}
        className={cn('aka-table__tr', `aka-table__tr--${statusSuffix[row.original.status]}`)}
      />
    );
  };

  return (
    <>
      <Popup.Body className={s.tableScrollContainer}>
        <div className="aka-table aka-table--import">
          <div className={s.tableBody}>
            <TableVirtuoso
              itemContent={rowContent}
              fixedHeaderContent={headerContent}
              totalCount={table.getRowModel().rows.length}
              style={{height: TABLE_HEIGHT}}
              components={{TableHead, TableRow}}
            />
          </div>
        </div>
      </Popup.Body>
      <Popup.Footer>
        <Button
          className="ctrl-btn--color-light ctrl-btn--shadow popup__button popup__button--back"
          icon={<Icon colorFill name="arrow_backward" />}
          onClick={onBack}
        >
          {t('import_preview.buttons.back')}
        </Button>
        {importInProcess ? (
          <div className={s.progressbar__container}>
            <ProgressBar progress={importPercentage} />
          </div>
        ) : (
          <LegendCompare
            className="popup__legend"
            showErrors={showOnlyErrors}
            toggleErrors={toggleView}
            totals={{
              unChanged: result.unchangedCount,
              new: result.addedCount,
              edited: result.updatedCount,
              error: result.taskResponses.filter((taskResponse) => taskResponse.errors.length).length,
            }}
          />
        )}
        <Button
          data-cy="btnImportFinish"
          icon={importInProcess ? <Icon colorFill className="ctrl-btn__icon" name="autorenew" /> : null}
          className={`popup__button ${importInProcess ? 'is-processing' : 'ctrl-btn--color-success'}`}
          type="button"
          onClick={handleSubmit}
          disabled={importInProcess}
        >
          {importInProcess ? t('import_preview.buttons.importing') : t('import_preview.buttons.finish')}
        </Button>
      </Popup.Footer>
    </>
  );
};

export default TasksImportPreviewTable;
