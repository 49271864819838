import * as Sentry from '@sentry/browser';
import cs from 'classnames';
import {AuthCredential, AuthError, ConfirmationResult, PhoneAuthProvider} from 'firebase/auth';
import {Field, FieldProps, Formik, FormikErrors, FormikProps, FormikValues} from 'formik';
import {TFunction} from 'i18next';
import {ChangeEvent, FC, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IMaskInput} from 'react-imask';
import {batch} from 'react-redux';
import {generatePath, useHistory} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import WorkerApi, {SupportedLoginTypes} from 'api/worker';
import EventManager from 'services/EventManager';
import FirebaseService from 'services/Firebase/Firebase';
import Button from 'shared/components/Button';
import CountryCodeSelector from 'shared/components/CoreForm/CountryCodeSelector';
import EmailInput from 'shared/components/CoreForm/EmailInput';
import FormControl from 'shared/components/CoreForm/FormControl';
import {usePasswordVisibility} from 'shared/components/CoreForm/hooks/usePasswordVisibility';
import PhoneInput from 'shared/components/CoreForm/PhoneInput';
import ExternalLink from 'shared/components/ExternalLink';
import Icon from 'shared/components/Icon';
import ZetOnboardingContainer from 'shared/components/OnboardingLayout/ZetOnboardingContainer';
import env from 'shared/constants/env';
import {getHumanizedFbError, isFirebaseAuthError} from 'shared/constants/firebase';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {getLocalizedLink} from 'shared/helpers/common';
import useCountDown from 'shared/helpers/hooks/useCountDown';
import {useRecaptcha} from 'shared/helpers/hooks/useRecaptcha';
import {useEffectOnce} from 'shared/hooks/core/useEffectOnce';
import {useToggle} from 'shared/hooks/core/useToggle';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {AlternativeSignUpForm, FirstStepForm} from 'shared/models/onboardingForms';
import {Worker} from 'shared/models/worker';
import {useRootDispatch, useRootSelector} from 'store';
import {createCompany} from 'store/company/actions';
import {loginWorkerByPhone} from 'store/ducks/auth/actions';
import {setCompany, setIsLoading, setSignupInProgress, setWorker} from 'store/onboarding';
import {registerWorker, updateWorkerObject} from 'store/onboarding/actions';
import {profileActions} from 'store/profile';
import {loadWorkerCompanies, loadWorkerCompanyWorkers} from 'store/profile/actions';

const RECAPTCHA_CONTAINER_ID = 'onboarding-recaptcha-container';

const AlternativeSignUp: FC = () => {
  const {mixpanel, useZoomInfo} = useAnalyticsService({publicPage: true});
  useZoomInfo();
  const {
    t,
    i18n: {language},
  } = useTranslation(['signup', 'errors']);
  const dispatch = useRootDispatch();
  const history = useHistory();
  const routes = useLocalizedRoutes();
  const recaptcha = useRecaptcha();
  const mixpanelEvents = mixpanel.events.signup.altA;
  const {isLoading} = useRootSelector((state) => ({
    isLoading: state.onboarding.isLoading,
  }));
  const formik = useRef<FormikProps<AlternativeSignUpForm>>(null);
  const [countryCode, setCountryCode] = useState<string>('+1');

  // login states
  const [loginType, setLoginType] = useState<string>('password');
  const [isSentCode, setIsSentCode] = useState<boolean>(false);
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult>(null);
  const {counter, start, reset} = useCountDown(15);
  const verifyCodeInputRef = useRef(null);
  const passwordVisibility = usePasswordVisibility();
  const confirmVisibility = usePasswordVisibility();
  const {isToggled: sendCodeTooltipVisible, toggle: toggleSendCodeTooltipVisible} = useToggle();
  const [isConfirmedPhoneNumber, setIsConfirmedPhoneNumber] = useState(false);

  useEffectOnce(() => {
    mixpanel.track(mixpanelEvents.page);
  });

  const validationSchema = useMemo(() => {
    let blueprint = {
      fullName: Yup.string()
        .required(t('signup:validation.fullName.required', 'Full Name is required field.'))
        .min(3, t('signup:validation.fullName.min', 'At least 3 characters is required!'))
        .max(200, t('signup:validation.fullName.max', 'No more than 200 characters!')),
      companyName: Yup.string()
        .required(t('signup:validation.companyName.required', 'Company Name is required field.'))
        .min(1, t('signup:validation.companyName.min', 'At least 1 character is required!'))
        .max(200, t('signup:validation.companyName.max', 'No more than 200 characters!')),
    };

    if (loginType === 'phone') {
      blueprint = Object.assign(blueprint, {
        verificationCode: isSentCode
          ? Yup.string().required(t('signup:validation.verificationCode.required', 'This field is required'))
          : undefined,
        phoneNumber: Yup.string().required(t('signup:validation.phoneNumber.required', 'This field is required')),
      });
    } else {
      blueprint = Object.assign(blueprint, {
        email: Yup.string()
          .email(t('signup:validation.email.valid', 'Must be a valid email'))
          .required(t('signup:validation.email.required', 'This field is required')),
        password: Yup.string()
          .min(6, t('signup:validation.password.min', 'Must be at least 6 characters long'))
          .max(255, t('signup:validation.password.max', 'Must not exceed 255 characters long'))
          .required(t('signup:validation.password.required', 'This field is required')),
        passwordRepeat: Yup.string()
          .oneOf([Yup.ref('password'), null], t('signup:validation.password.match', 'Passwords must match'))
          .required(t('signup:validation.password.required', 'This field is required')),
      });
    }

    return Yup.object().shape(blueprint);
  }, [loginType, isSentCode, t]);

  const loadCompaniesAndWorkers = (workerId: string) => {
    return Promise.all([dispatch(loadWorkerCompanies(workerId)), dispatch(loadWorkerCompanyWorkers(workerId))]);
  };

  async function afterSubmit(worker: Worker) {
    await loadCompaniesAndWorkers(worker.id);

    batch(() => {
      dispatch(profileActions.setWorker(worker));
      dispatch(profileActions.showCongratsPopup());
      dispatch(setSignupInProgress(false));
    });

    putWorkerIdToLocalStorage(worker.id);
    mixpanel.track(mixpanelEvents.buttons.submit);
    EventManager.pushToGtmDataLayer({event: 'c4-onboarding-company'});

    history.push(generatePath(routes.project, {id: 'new'}));
    setIsLoading(false);
  }

  const createUserWithEmailAndPassword = async (values: FormikValues) => {
    const email = values.email;
    const password = values.password;
    try {
      dispatch(setIsLoading(true));
      await FirebaseService.createUserWithEmailAndPassword(email, password);
    } catch (error) {
      Sentry.captureException(error);
      if (isFirebaseAuthError(error) && error.code === 'auth/email-already-in-use') {
        toast(t('signup:errors.email.already-in-use', 'This email address is already in use. Please, login.'));
        dispatch(setIsLoading(false));
        history.push(routes.logIn);
      } else {
        toast(getHumanizedFbError(error, t as TFunction));
      }
      throw error;
    }
  };

  const submitForm = async (values: FormikValues) => {
    if (loginType === 'phone' && !isConfirmedPhoneNumber) {
      toast(t('signup:errors.phone.need-to-confirm', 'Please, confirm your phone number.'));
      return;
    }

    dispatch(setIsLoading(true));

    if (loginType !== 'phone') {
      try {
        await createUserWithEmailAndPassword(values);
        dispatch(setSignupInProgress(true));
      } catch (error) {
        return;
      }
    }

    const res = await dispatch(
      registerWorker({loginType: loginType as SupportedLoginTypes, phoneNumber: values.phoneNumber}),
    );
    if (registerWorker.fulfilled.match(res)) {
      const registeredWorker = res.payload as Worker;
      const model = {...formik.current.values};

      delete model.companyName;
      delete model.password;
      delete model.passwordRepeat;
      delete model.verificationCode;

      if (loginType === 'phone') {
        delete model.email;
      }

      const updatedWorker = await dispatch(
        updateWorkerObject({
          id: registeredWorker.id,
          workerObject: {...model},
        }),
      );
      if (updateWorkerObject.fulfilled.match(updatedWorker)) {
        dispatch(setWorker(updatedWorker.payload));
        EventManager.pushToGtmDataLayer({event: 'c4-onboarding-user'});

        const [, companyWorkers] = await loadCompaniesAndWorkers(updatedWorker.payload.id);
        if (loadWorkerCompanyWorkers.fulfilled.match(companyWorkers)) {
          if (companyWorkers.payload.length) {
            dispatch(setCompany(companyWorkers.payload[0]));
          }
        }

        trackAnalyticsEventsAfterSubmit();

        try {
          const companyCreateResult = await dispatch(
            createCompany({companyObject: {companyName: values.companyName}, workerId: updatedWorker.payload.id}),
          );
          if (createCompany.fulfilled.match(companyCreateResult)) {
            await afterSubmit(updatedWorker.payload);
            return;
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      } else {
        toast.warning(updatedWorker.error.message);
      }
    } else {
      toast(res.error.message);
    }
    dispatch(setIsLoading(false));
  };

  const sendVerificationCode = async (phoneNumber: string) => {
    dispatch(setIsLoading(true));
    const phoneNumberWithCountryCode = countryCode + phoneNumber;
    FirebaseService.toggleRecapchaAutoVerificationForTesting(phoneNumberWithCountryCode);
    const recaptchaVerifier = recaptcha.create(RECAPTCHA_CONTAINER_ID, phoneNumberWithCountryCode);
    try {
      const result = await FirebaseService.signInWithPhone(phoneNumberWithCountryCode, recaptchaVerifier);

      setConfirmationResult(result);
      dispatch(setIsLoading(false));

      // timer
      reset();
      start();

      setIsSentCode(true);
      mixpanel.track(mixpanelEvents.buttons.sendCode);

      verifyCodeInputRef.current.focus();
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      setIsSentCode(false);
      toast(getHumanizedFbError(error, t as TFunction));
    } finally {
      recaptcha.destroy(recaptchaVerifier, RECAPTCHA_CONTAINER_ID);
      dispatch(setIsLoading(false));
    }
  };

  const tryLinkWithPhoneCredential = async (confirmResult: ConfirmationResult, verificationCode: string) => {
    try {
      await FirebaseService.linkWithPhoneCredential(confirmResult, verificationCode);
      setIsConfirmedPhoneNumber(true);
    } catch (error) {
      const castedError = error as AuthError;
      Sentry.captureException(error);
      if (castedError.code === 'auth/credential-already-in-use') {
        await trySignInWithPhoneCredentials(PhoneAuthProvider.credentialFromError(castedError));
      } else {
        toast(getHumanizedFbError(error, t as TFunction));
        throw error;
      }
    }
  };

  const trySignInWithPhoneCredentials = async (credentials: AuthCredential) => {
    try {
      const loggedUser = await dispatch(loginWorkerByPhone({credentials})).unwrap();
      const isSignedUp = await WorkerApi.checkSignUp(loggedUser.uid);
      if (isSignedUp) {
        toast(t('signup:errors.phone.already-in-use', 'This phone number is already in use. Please, login.'));
        history.push(routes.logIn);
      } else {
        await FirebaseService.deleteUser();
      }
    } catch (error) {
      Sentry.captureException(error);
      toast(getHumanizedFbError(error, t as TFunction));
      throw error;
    }
  };

  const verifyCode = async (confirmationCode: string) => {
    try {
      dispatch(setIsLoading(true));
      await tryLinkWithPhoneCredential(confirmationResult, confirmationCode);
      setIsConfirmedPhoneNumber(true);
      dispatch(setSignupInProgress(true));
    } catch (error) {
      Sentry.captureException(error);

      // reset field validation message
      verifyCodeInputRef.current.blur();
      formik.current.setFieldValue('verificationCode', '');
      formik.current.setFieldTouched('verificationCode', false);

      setIsConfirmedPhoneNumber(false);
      setIsSentCode(false);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const onChangeAuthWay = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginType(event.currentTarget.value);
    const authWay = event.currentTarget.value;
    setLoginType(event.currentTarget.value);

    if (authWay === 'phone') {
      mixpanel.track(mixpanelEvents.buttons.togglePhone);
      formik.current.setFieldValue('email', '');
    } else {
      mixpanel.track(mixpanelEvents.buttons.toggleEmail);
      formik.current.setFieldValue('phoneNumber', '');
    }
  };

  const onFullNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {setFieldValue, values} = formik.current;
    // do not clear company name if full name is cleared
    if (event.currentTarget.value === '' && values.companyName === values.fullName + '`s Company') {
      setFieldValue('companyName', '');
    }
    setFieldValue('fullName', event.currentTarget.value);
    // do not change company name by full name if company name was manually changed
    if (
      event.currentTarget.value !== '' &&
      (!values.companyName || values.companyName === values.fullName + '`s Company')
    ) {
      setFieldValue('companyName', event.currentTarget.value + '`s Company');
    }
  };

  const onVerificationCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const value = event.currentTarget.value;

    if (value.length <= 6) {
      formik.current.setFieldValue('verificationCode', event.currentTarget.value);

      if (value.length === 6) {
        verifyCode(value);
      }
    }
  };

  const putWorkerIdToLocalStorage = (workerId: string) => {
    FirebaseService.setWorkerIdToLS(workerId);
  };

  const getFooter = () => {
    return (
      <footer className="form-onboarding-z__footer">
        <div className="form-onboarding-z__footer-text">
          {t('signup:footer.notice_agree.1', 'By clicking this button, I agree with')}{' '}
          <ExternalLink
            target="_blank"
            href={getLocalizedLink(env.legalPages.termsOfService, language)}
            text={t('signup:footer.notice_agree.2', 'Core Terms')}
          />{' '}
          {t('signup:footer.notice_agree.3', 'of Service and')}{' '}
          <ExternalLink
            target="_blank"
            href={getLocalizedLink(env.legalPages.privacyPolicy, language)}
            text={t('signup:footer.notice_agree.4', 'Privacy Policy →')}
          />
        </div>
        <div className="form-onboarding-z__footer-actions">
          <button
            data-cy="signup_step1_next_bt"
            className="ctrl-btn ctrl-btn--color-success form-onboarding-z__footer-button"
            onClick={() => formik.current?.submitForm()}
            type="button"
          >
            <span className="ctrl-btn__text">{t('signup:footer.submit_alternate_a', 'Submit')}</span>
          </button>
        </div>
      </footer>
    );
  };

  const trackAnalyticsEventsAfterSubmit = () => {
    if (loginType === 'phone') {
      mixpanel.track(mixpanelEvents.buttons.verifyCode);
    } else {
      mixpanel.track(mixpanelEvents.buttons.emailSubmit);
    }

    EventManager.pushToGtmDataLayer({event: 'c4-onboarding-phone'});
    EventManager.pushToGtmDataLayer({
      event: 'Crews_phone_verified',
      eventCategory: 'Registration',
      eventAction: 'Completion',
    });
  };

  const getEmailFields = () => {
    return (
      <>
        <div className="form-onboarding-z__item">
          <FormControl name="email" label={t('signup:fields.email.label', 'Email')}>
            <Field>
              {({field}) => (
                <EmailInput
                  data-cy="signup_email_input"
                  placeholder={t('signup:fields.email.placeholder', 'Enter email address')}
                  {...field}
                />
              )}
            </Field>
          </FormControl>
        </div>
        <div className="form-onboarding-z__item">
          <FormControl
            name="password"
            label={t('signup:fields.password.label', 'Password')}
            iconName={passwordVisibility.visible ? 'visible_outlined' : 'hidden_outlined'}
            onIconClick={passwordVisibility.toggleVisibility}
          >
            <Field
              data-cy="signup_password_input"
              name="password"
              type={passwordVisibility.visible ? 'text' : 'password'}
              className="ctrl-textfield"
              placeholder={t('signup:fields.password.placeholder', 'Enter password')}
            />
          </FormControl>
        </div>
        <div className="form-onboarding-z__item">
          <FormControl
            name="passwordRepeat"
            label={t('signup:fields.passwordRepeat.label', 'Repeat Password')}
            iconName={confirmVisibility.visible ? 'visible_outlined' : 'hidden_outlined'}
            onIconClick={confirmVisibility.toggleVisibility}
          >
            <Field
              data-cy="signup_password_repeat_input"
              name="passwordRepeat"
              type={confirmVisibility.visible ? 'text' : 'password'}
              className="ctrl-textfield"
              placeholder={t('signup:fields.passwordRepeat.placeholder', 'Repeat password')}
            />
          </FormControl>
        </div>
      </>
    );
  };

  const getPhoneFields = (values: FormikValues, errors: FormikErrors<FirstStepForm>) => {
    return (
      <>
        <div className="form-onboarding-z__item">
          <div className="ctrl-phone">
            <div className="ctrl-phone__item ctrl-phone__item--code">
              <FormControl
                name="countryCode"
                label={t('signup:fields.countryCode.label', 'Mobile Phone Number')}
                className={isConfirmedPhoneNumber && 'ctrl-form--success'}
              >
                <CountryCodeSelector
                  onChange={setCountryCode}
                  name="countryCode"
                  initialValue={countryCode}
                  disabled={isConfirmedPhoneNumber}
                />
              </FormControl>
            </div>
            <div className="ctrl-phone__item ctrl-phone__item--number">
              <FormControl
                className={cs('ctrl-form--button-link', isConfirmedPhoneNumber && 'ctrl-form--success ctrl-form--icon')}
                name="phoneNumber"
                label={t('signup:fields.phoneNumber.label', 'Phone Number')}
                iconHelpName="help"
                onHelpClick={() => toggleSendCodeTooltipVisible()}
                tooltip={sendCodeTooltip}
              >
                <Field name="phoneNumber">
                  {(props: FieldProps) => (
                    <PhoneInput
                      {...props.field}
                      style={isConfirmedPhoneNumber ? {pointerEvents: 'none', opacity: '.25'} : null}
                      onChange={!isConfirmedPhoneNumber && props.field.onChange}
                      countryCode={countryCode}
                      className="ctrl-textfield"
                      id="apply_onboarding_phone"
                      placeholder={t('signup:fields.phoneNumber.placeholder', 'Enter Phone Number')}
                    />
                  )}
                </Field>
                {isConfirmedPhoneNumber && (
                  <Icon
                    name="checkmark-circle-outline"
                    colorFill
                    className="ctrl-form__icon ctrl-form__icon--success"
                  />
                )}
              </FormControl>
            </div>
            <div className="ctrl-phone__item ctrl-phone__item--button">
              <Button
                className="ctrl-phone__button-send"
                onClick={(e) => sendOrResendCode(e, values.phoneNumber)}
                disabled={
                  isConfirmedPhoneNumber || !values.phoneNumber || !!errors.phoneNumber || (isSentCode && !!counter)
                }
                colorStyle={!!values.verifyCode ? 'success' : null}
              >
                {!isSentCode
                  ? t('signup:actions.confirmation.send', 'Send')
                  : !counter
                  ? t('signup:actions.confirmation.resend', 'Resend')
                  : `${t('signup:actions.confirmation.info', 'Resend in')} ${counter}${t(
                      'signup:actions.confirmation.seconds_acronim',
                      's',
                    )}`}
              </Button>
            </div>
          </div>
        </div>
        <div className="form-onboarding-z__item">
          <FormControl
            name="verificationCode"
            label={t('signup:fields.verificationCode.label', "Enter the code we've sent to your number")}
            className={cs('ctrl-form--button-big', {
              'ctrl-form--success': isConfirmedPhoneNumber,
              'ctrl-form--icon': isConfirmedPhoneNumber,
            })}
          >
            <Field name="verificationCode">
              {({field}: FieldProps) => (
                <>
                  <IMaskInput
                    {...field}
                    style={isConfirmedPhoneNumber || !isSentCode ? {pointerEvents: 'none', opacity: '.25'} : null}
                    className="ctrl-textfield"
                    mask="000000"
                    placeholder={t('signup:fields.verificationCode.placeholder', 'Enter the code')}
                    definitions={{
                      '0': /[0-9]/,
                    }}
                    ref={verifyCodeInputRef}
                    onAccept={(value, _maskRef) => {
                      onVerificationCodeChange({
                        target: {
                          value,
                          name: field.name,
                        },
                      } as React.ChangeEvent<HTMLInputElement>);
                    }}
                  />
                  {isConfirmedPhoneNumber && (
                    <Icon
                      name="checkmark-circle-outline"
                      colorFill
                      className="ctrl-form__icon ctrl-form__icon--success"
                    />
                  )}
                </>
              )}
            </Field>
          </FormControl>
        </div>
      </>
    );
  };

  const sendOrResendCode = async (event, phoneNumber: string) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isSentCode) {
      await sendVerificationCode(phoneNumber);
      return;
    }

    if (!counter && !isLoading) {
      mixpanel.trackWithAction(sendVerificationCode, mixpanelEvents.buttons.resendCode);
    }
  };

  const sendCodeTooltip = sendCodeTooltipVisible ? (
    <div
      className="react-popper react-popper--help react-popper--with-button-close"
      style={{position: 'absolute', bottom: '100%', right: 0}}
    >
      <div className="react-popper__title">{t('signup:verifyCode.tooltip.title', 'Verification code')}</div>
      <div className="react-popper__text">
        {t('signup:verifyCode.tooltip.text', "We'll send a verification code to your phone.")}
      </div>
      <Button
        className="react-popper__button-close"
        iconOnly
        icon={
          <Icon
            name="clear"
            onClick={toggleSendCodeTooltipVisible}
            className="ctrl-btn-clear__icon"
            colorFill
            size={24}
          />
        }
      >
        <span className="ctrl-btn-clear__text">{t('signup:actions.confirmation.close_tooltip', 'Close help')}</span>
      </Button>
    </div>
  ) : null;

  return (
    <ZetOnboardingContainer
      title={t('signup:alternate_a.header.title', 'Welcome to Crews by Core!')}
      description={t('signup:alternate_a.header.description', 'Enter your information to get started')}
      footer={getFooter()}
    >
      <Formik<AlternativeSignUpForm>
        initialValues={{
          fullName: '',
          companyName: '',
          phoneNumber: '',
          agreePrivacyPolicy: true,
          agreeDigestSms: true,
          verificationCode: '',
          email: '',
          password: '',
          passwordRepeat: '',
        }}
        onSubmit={submitForm}
        validationSchema={validationSchema}
        innerRef={formik}
      >
        {({values, errors}) => (
          <>
            <div className="form-onboarding-z__item">
              <FormControl name="fullName" label={t('signup:fields.fullName.label', 'Full Name')}>
                <Field
                  data-cy="signup_full_name_input"
                  name="fullName"
                  className="ctrl-textfield"
                  placeholder={t('signup:fields.fullName.placeholder', 'Full Name')}
                  onChange={onFullNameChange}
                />
              </FormControl>
            </div>
            <div className="form-onboarding-z__item">
              <FormControl name="companyName" label={t('signup:fields.companyName.label', 'Company Name')}>
                <Field
                  data-cy="signup_company_name"
                  name="companyName"
                  className="ctrl-textfield"
                  placeholder={t('signup:fields.companyName.placeholder', 'Enter Company Name')}
                />
              </FormControl>
            </div>
            <div className="form-onboarding-z__item form-onboarding-z__item--with-line">
              <div className="ctrl-radios">
                <div className="ctrl-radios__grid">
                  <label className="ctrl-radios__item">
                    <Field
                      data-cy="email_login_radio_bt"
                      className="ctrl-radios__field"
                      name="loginType"
                      type="radio"
                      value="password"
                      checked={loginType === 'password'}
                      onChange={onChangeAuthWay}
                      disabled={isConfirmedPhoneNumber}
                    />
                    <span className="ctrl-radios__visual" />
                    <span className="ctrl-radios__label">{t('signup:fields.auth_type.email', 'Email')}</span>
                  </label>
                  <label className="ctrl-radios__item">
                    <Field
                      data-cy="phone_login_radio_bt"
                      className="ctrl-radios__field"
                      name="loginType"
                      type="radio"
                      value="phone"
                      checked={loginType === 'phone'}
                      onChange={onChangeAuthWay}
                      disabled={isConfirmedPhoneNumber}
                    />
                    <span className="ctrl-radios__visual" />
                    <span className="ctrl-radios__label">{t('signup:fields.auth_type.phone', 'Phone Number')}</span>
                  </label>
                </div>
              </div>
            </div>
            {loginType === 'phone' ? getPhoneFields(values, errors) : getEmailFields()}
          </>
        )}
      </Formik>
    </ZetOnboardingContainer>
  );
};
export default AlternativeSignUp;
