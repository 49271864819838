import 'react-loading-skeleton/dist/skeleton.css';
import 'assets/stylesheets/common.css';
import 'cropperjs/dist/cropper.css';
import 'assets/stylesheets/tmp_import_preview.css';
import 'assets/scripts/modernizr/modernizr-custom.js';

import {Suspense} from 'react';
import {createRoot} from 'react-dom/client';

// breaks application if sort order is applied 😢
// eslint-disable-next-line import/order
import App from './App';

import smoothscroll from 'smoothscroll-polyfill';

import Loader from 'shared/components/Loader';

smoothscroll.polyfill();

const rootNode = document.getElementById('root');
const root = createRoot(rootNode);

root.render(
  <Suspense fallback={<Loader />}>
    <App />
  </Suspense>,
);
