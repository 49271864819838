import {GanttStatic} from 'dhtmlx-gantt';
import {useTranslation} from 'react-i18next';

import SelectEditor, {KeysOfType} from 'modules/Tasks/components/Gantt/components/Editors/SelectEditor';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {getTaskIssueTypeOptions} from 'shared/constants/statuses';

type IssueTypeEditorProps = {
  gantt: GanttStatic;
  task: GanttTask;
  name: KeysOfType<string>; // string since issue_type is a string
};

export function IssueTypeEditor(props: IssueTypeEditorProps) {
  const {t} = useTranslation(['task']);
  const options = getTaskIssueTypeOptions(t);

  return (
    <SelectEditor<string>
      {...props}
      options={options}
      transformValue={(value: string) => value}
      transformBack={(value: string) => value}
    />
  );
}
