import {useQuery} from '@tanstack/react-query';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Select, {ActionMeta, MultiValue, Options} from 'react-select';

import ProjectsApi from 'api/projects';
import {
  CustomOption,
  CustomSingleValue,
} from 'modules/Tasks/components/TasksActionsBar/bulkPopups/BulkWatcher/components';
import {
  deduplicationRemainingOptions,
  getWorkersOfSubcontractors,
  getWorkersWithoutSubcontractors,
  prepareSubcontractors,
  prepareWorkers,
} from 'modules/Tasks/components/TasksActionsBar/bulkPopups/BulkWatcher/utils';
import {useTasksBulkOperations} from 'modules/Tasks/components/TasksActionsBar/useTasksBulkOperations';
import {BulkProps} from 'modules/Tasks/utils/constants';
import Confirmation from 'shared/components/Confirmation';
import css from 'shared/components/CoreForm/Select/AsyncProjectWorkerSelect/index.module.scss';
import {bulkWatcherStyles} from 'shared/components/CoreForm/Select/styles';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {useDistinctProjectWorkers} from 'shared/hooks/useProjectWorkers';
import {useResponsibleOrgColors} from 'shared/hooks/useResponsibleOrgColors';

export enum OptionType {
  worker = 'worker',
  subcontractor = 'subcontractor',
}

export type BulkOption = {
  id: string;
  type: OptionType;
  label: string;
  value: string;
  orgMappingIds?: string[];
  profilePicUrl?: string;
  trade?: string;
  abbrev?: string;
  lookaheadColor?: string;
  nbWorkers?: number;
};

const BulkWatcher = ({onClose, gantt, projectId}: BulkProps) => {
  const [selectedSubcontractorIds, setSelectedSubcontractorIds] = useState<string[]>([]);
  const [selectedWatchers, setSelectedWatchers] = useState<Options<BulkOption>>([]);
  const [inputValue, setInputValue] = useState('');
  const {t} = useTranslation('gantt');
  const {assignWatcher} = useTasksBulkOperations(gantt);
  const loadingPlaceholder = t('bulk_assign.confirmation.select.loading_placeholder', 'Loading...');
  const placeholder = t('bulk_watcher.confirmation.select.placeholder', 'Select watchers');
  const {lookaheadColors} = useResponsibleOrgColors();
  const {projectWorkers, isLoading: isLoadingWorkers} = useDistinctProjectWorkers();

  const {data: subcontractors, isLoading: isLoadingSubcontractors} = useQuery({
    queryKey: QUERY_CACHE_KEYS.projectSubcontractors(projectId),
    queryFn: () => ProjectsApi.getOrgs(projectId),
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  });

  const mixedOptions = useMemo(() => {
    const preparedWorkers = prepareWorkers(projectWorkers);
    const preparedSubcontractors = prepareSubcontractors(subcontractors, lookaheadColors);
    return [...preparedWorkers, ...preparedSubcontractors];
  }, [projectWorkers, lookaheadColors, subcontractors]);

  const preparedSelectedValues = useMemo(() => {
    return deduplicationRemainingOptions(selectedSubcontractorIds, selectedWatchers as BulkOption[]);
  }, [selectedSubcontractorIds, selectedWatchers]);

  const preparedOptions = useMemo(() => {
    const deduplicatedOptions = deduplicationRemainingOptions(selectedSubcontractorIds, mixedOptions);
    return deduplicatedOptions.sort((a, b) => (a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1));
  }, [mixedOptions, selectedSubcontractorIds]);

  const preparedWatchersForAdd = useMemo(() => {
    const workersWithoutSubcontractors = getWorkersWithoutSubcontractors(selectedWatchers, projectWorkers);
    const workersOfSubcontractors = getWorkersOfSubcontractors(selectedSubcontractorIds, projectWorkers);
    return [...workersWithoutSubcontractors, ...workersOfSubcontractors];
  }, [projectWorkers, selectedWatchers, selectedSubcontractorIds]);

  const getNumberWatchers = useMemo(() => {
    if (preparedWatchersForAdd.length === 0) return '';
    return `${preparedWatchersForAdd.length} ${
      preparedWatchersForAdd.length === 1
        ? t('bulk_assign.confirmation.buttons.countWatchersText.one', 'Watcher')
        : t('bulk_assign.confirmation.buttons.countWatchersText.several', 'Watchers')
    }`;
  }, [preparedWatchersForAdd]);

  const updateInputValue = (value: string) => {
    setInputValue(value);
  };

  const handleChange = (newValue: BulkOption | MultiValue<BulkOption> | null, actionMeta: ActionMeta<BulkOption>) => {
    if (Array.isArray(newValue)) {
      setSelectedWatchers(newValue || []);
    } else if (newValue === null) {
      setSelectedWatchers([]);
    }

    if (actionMeta.action === 'select-option' && actionMeta.option.type === OptionType.subcontractor) {
      setSelectedSubcontractorIds((prev) => [...prev, actionMeta.option.id]);
    }
    if (actionMeta.action === 'remove-value' && actionMeta.removedValue.type === OptionType.subcontractor) {
      setSelectedSubcontractorIds((prev) => prev.filter((s) => s !== actionMeta.removedValue.id));
    }
  };

  return (
    <Confirmation
      visible
      title={t('bulk_watcher.confirmation.title', 'Watchers')}
      description={t('bulk_watcher.confirmation.description', 'Add watchers to selected activities')}
      cancelButton={t('bulk_assign.confirmation.buttons.cancel', 'Cancel')}
      acceptButton={`${t('bulk_watcher.confirmation.buttons.accept', 'Add')} ${getNumberWatchers}`}
      onAccept={() => assignWatcher(preparedWatchersForAdd)}
      onClose={onClose}
      isDisabledAcceptBtn={!selectedWatchers?.length}
    >
      <div className="confirmation__form">
        <div className="confirmation__form-item">
          <FormControl label="" name="">
            <Select
              isMulti
              closeMenuOnSelect={false}
              defaultInputValue=""
              isClearable={true}
              isDisabled={isLoadingWorkers && isLoadingSubcontractors}
              isLoading={isLoadingWorkers || isLoadingSubcontractors}
              options={preparedOptions}
              placeholder={isLoadingWorkers || isLoadingSubcontractors ? loadingPlaceholder : placeholder}
              className={`react-select ${css['task-responsible-select']}`}
              classNamePrefix="react-select"
              onChange={handleChange}
              inputValue={inputValue}
              onInputChange={updateInputValue}
              styles={bulkWatcherStyles}
              value={preparedSelectedValues}
              components={{Option: CustomOption, MultiValueLabel: CustomSingleValue}}
            />
          </FormControl>
        </div>
      </div>
    </Confirmation>
  );
};

export default BulkWatcher;
