import cn from 'classnames';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {CoreSelect} from 'shared/components/CoreForm/Select/Select';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import {DEPENDENCIES_MODES, DEPENDENCIES_TYPES} from 'shared/models/TaskDependency';

import s from './DependencyForm.module.scss';

type DependencyFormProps = {
  className?: string;
  disabled?: boolean;
};

const DependencyForm = ({className, disabled}: DependencyFormProps) => {
  const {t} = useTranslation('task');
  const types = useMemo(
    () =>
      DEPENDENCIES_TYPES.map((type) => {
        return {...type, label: t(`dependency.types.${type.value}`)};
      }),
    [t],
  );
  const modes = useMemo(
    () =>
      DEPENDENCIES_MODES.map((mode) => {
        return {...mode, label: t(`dependency.modes.${mode.value}`)};
      }),
    [t],
  );

  return (
    <div className={cn(s.dependencyForm, className)}>
      <div className={s.dependencyForm__body}>
        <div className={cn(s.dependencyForm__item, s.dependencyForm__item_type, s.dependencyForm__item_mb)}>
          <FormControl.Formik label={t('dependency.type.label', 'Dependency type')} name="depType">
            {({field, form}) => (
              <CoreSelect
                name={field.name}
                isDisabled={disabled}
                className="react-select"
                placeholder={t('dependency.type.placeholder', 'Select dependency')}
                value={field.value}
                onChange={(value) => form.setFieldValue(field.name, value)}
                classNamePrefix="react-select"
                options={types}
                onBlur={field.onBlur}
              />
            )}
          </FormControl.Formik>
        </div>
        <div className={`${s.dependencyForm__item} ${s.dependencyForm__item_mode}`}>
          <FormControl.Formik label={t('dependency.mode.label', 'Mode')} name="mode">
            {({field, form}) => (
              <CoreSelect
                name={field.name}
                isDisabled={disabled}
                className="react-select"
                placeholder={t('dependency.mode.placeholder', 'Choose Mode')}
                value={field.value}
                classNamePrefix="react-select"
                options={modes}
                onChange={(value) => form.setFieldValue(field.name, value)}
                onBlur={field.onBlur}
              />
            )}
          </FormControl.Formik>
        </div>
        <div className={`${s.dependencyForm__item} ${s.dependencyForm__item_time}`}>
          <FormControl.Formik label={t('dependency.time.label', 'Time (Days)')} name="lagDays">
            {({field}) => (
              <input
                type="number"
                disabled={disabled}
                max={100}
                min={0}
                step={1}
                placeholder={t('dependency.time.placeholder', 'delay')}
                {...field}
              />
            )}
          </FormControl.Formik>
        </div>
      </div>
    </div>
  );
};

export default DependencyForm;
