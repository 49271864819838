import {createContext, use} from 'react';

import {ReferenceType} from 'modules/Tasks/components/Gantt/hooks/useHideContextOnGanttScroll';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';

export interface GanttContext {
  selector: ReferenceType;
  onStopEditAction: () => void;
  selectedTask: GanttTask;
  updateSelectedTask: (task: GanttTask) => void;
}
export const GanttContext = createContext<GanttContext>({
  selector: null,
  onStopEditAction: () => null,
  selectedTask: null,
  updateSelectedTask: () => null,
});
export const useGanttContext = () => use(GanttContext);
