import {CellContext} from '@tanstack/react-table';

import {CenteredContainer} from 'shared/components/Table/components/Columns/Centered';
import {CompanyWorkerRole, CompanyWorker} from 'shared/models/worker';

type TextArrayColumnProps = CellContext<CompanyWorker, CompanyWorkerRole[]>;

export const TextArrayColumn = ({getValue}: TextArrayColumnProps) => {
  const value = getValue();

  return (
    <CenteredContainer>
      <span dangerouslySetInnerHTML={{__html: value?.join(', ')}} />
    </CenteredContainer>
  );
};
