import * as Sentry from '@sentry/browser';
import {FunctionComponent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router';
import {toast} from 'react-toastify';

import TasksApi from 'api/tasks';
import WorkerApi from 'api/worker';
import {useSharedTaskStatusOptions} from 'modules/SharedTask/hooks/useSharedTaskStatusOptions';
import FirebaseDefault from 'services/Firebase/Firebase';
import {useAuth} from 'shared/components/AuthUserProvider';
import Button from 'shared/components/Button';
import Confirmation from 'shared/components/Confirmation/ConfirmationPopup';
import SkeletonFieldPreloader from 'shared/components/CoreForm/SkeletonFieldPreloader';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import Icon from 'shared/components/Icon';
import Loader from 'shared/components/Loader';
import Logo from 'shared/components/Logo';
import MetaTags from 'shared/components/MetaTags';
import TaskStatusHistory from 'shared/components/TaskStatusHistory';
import {META_KEYWORDS} from 'shared/constants/common';
import env from 'shared/constants/env';
import {ROUTES} from 'shared/constants/routes';
import {useDebounce} from 'shared/hooks/core/useDebounce';
import {useMount} from 'shared/hooks/core/useMount';
import {useUnmount} from 'shared/hooks/core/useUnmount';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useClassName} from 'shared/hooks/useClassName';
import {useParsedQuery} from 'shared/hooks/useParsedQuery';
import {TaskDetailsModelDTO} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';

import AppCard from './AppCard';
import NotFound from './NotFound';
import {AssigneesPart, DownloadAppPart, TaskMainInfoPart} from './Parts';
import css from './SharedTask.module.scss';
import TaskStatusSelect from './TaskStatusSelect';

// localStorage key
const GUEST_NAME_LS_KEY = 'guestName';
const FIRST_CYRILLIC_LETTER = 1040;
const LAST_CYRILLIC_LETTER = 1103;

const SharedTask: FunctionComponent = () => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState({modal: false, header: false});
  const [task, setTask] = useState<TaskDetailsModelDTO>(null);
  const [prevStateTask, setPrevStateTask] = useState<TaskDetailsModelDTO>(null);
  const [fbToken, setFbToken] = useState<string>(null);
  const {id: taskId} = useParams<{id: string}>();
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [guestName, setGuestName] = useState('');
  const [showNotFound, setShowNotFound] = useState(false);
  const {user} = useAuth();
  const {mixpanel} = useAnalyticsService({publicPage: true});
  const mixpanelEvents = mixpanel.events.share;
  const [statusOptions, visibleOptions] = useSharedTaskStatusOptions(task?.id);
  const {token, ownerId} = useParsedQuery({
    defaultParams: {token: null, ownerId: null},
    schema: {
      token: 'string',
      ownerId: 'string',
    },
  });
  const history = useHistory();
  const inputGuestNameRef = useRef<HTMLInputElement>(null);
  const {t} = useTranslation('shared_task');

  useClassName(document.body, 'page');

  const debouncedAddGuestNameToLocalStorage = useDebounce(() => {
    addToLocalstorage(GUEST_NAME_LS_KEY, guestName);
  }, 1500);

  useMount(() => {
    if (getFromLocalstorage(GUEST_NAME_LS_KEY)) setGuestName(getFromLocalstorage(GUEST_NAME_LS_KEY));
    mixpanel.track(mixpanelEvents.userOpenedPage);
  });

  useMount(() => {
    addEventListener('storage', localStorageListener);

    return () => {
      removeEventListener('storage', localStorageListener);
    };
  });

  useEffect(() => {
    debouncedAddGuestNameToLocalStorage();
  }, [guestName]);

  useUnmount(async () => {
    await FirebaseDefault.turnOnPersistence();
  });

  const handleErrors = (error: Error) => {
    Sentry.captureException(error);
    toast.error(error.message);
  };

  const getFromLocalstorage = (key: string) => {
    return localStorage.getItem(key);
  };

  const addToLocalstorage = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };

  const localStorageListener = ({key, newValue}: StorageEvent) => {
    if (key === GUEST_NAME_LS_KEY) {
      setGuestName(newValue);
    }
  };

  const updateTask = useCallback(
    (payload: Partial<TaskDetailsModelDTO>, guestNameProp?: string) => {
      const config = {
        headers: {
          'x-journey-guestname': guestNameProp ? guestNameProp : guestName,
        },
      };
      setIsLoading(true);
      TasksApi.updateSharedTask(taskId, payload, config)
        .then((data) => {
          setTask(data);
          setPrevStateTask(data);
          toast.success(t('toast.success.update', 'Task info successfully updated.'));
        })
        .catch((err) => {
          handleErrors(err);
        })
        .finally(() => setIsLoading(false));
    },
    [taskId, guestName],
  );

  const debouncedUpdateTask = useDebounce((payload: Partial<TaskDetailsModelDTO>) => {
    const guestNameFromLS = getFromLocalstorage(GUEST_NAME_LS_KEY);

    if (!guestName && !guestNameFromLS) {
      setShowConfirmation(true);
      setTask({...task, ...payload});
    }
    if (!guestName && guestNameFromLS) {
      setGuestName(guestNameFromLS);
      updateTask(payload, guestNameFromLS);
    }
    if (guestName) updateTask(payload);
  }, 500);

  useEffect(() => {
    if (!fbToken && (!user || user.isAnonymous)) {
      WorkerApi.loginWithToken(`${ownerId}`, `${token}`)
        .then((data) => FirebaseDefault.signInWithCustomToken(data.firebaseToken))
        .then((res) => res.user.getIdToken())
        .then((token) => {
          setFbToken(token);
        })
        .catch((err) => {
          handleErrors(err);
          setIsInvalidToken(true);
        });
    }
  }, [task, token, ownerId, fbToken, user]);

  const getTask = () => {
    if (!task && (fbToken || user) && !hasLoaded) {
      TasksApi.getSharedTask(taskId)
        .then((data) => {
          setTask(data);
          setPrevStateTask(data);
          setHasLoaded(true);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setShowNotFound(true);
          } else handleErrors(err);
        });
    }
  };

  useEffect(() => {
    getTask();
  }, [fbToken, taskId, ownerId, user]);

  const onSave = () => {
    if (guestName) {
      updateTask(task);
      setIsError((err) => ({...err, ['header']: false}));
    }
  };

  const onCancel = () => {
    setTask(prevStateTask);
    setShowConfirmation(false);
  };

  const onClose = () => {
    setShowConfirmation(false);
  };

  const clonedTask = useMemo(() => {
    const punchlist = {items: task?.punchlist?.items?.map((item) => ({...item}))};
    return {...task, punchlist};
  }, [task]);

  const goToLoginPage = () => {
    history.push(ROUTES.logIn);
  };

  const validateGuestName = (value: number) => {
    return value < FIRST_CYRILLIC_LETTER || value > LAST_CYRILLIC_LETTER;
  };

  const handleInput = (e: React.KeyboardEvent<HTMLInputElement>, errorType: string) => {
    const charCode = e.charCode;
    const isValid = validateGuestName(charCode);

    !isValid && e.preventDefault();
    setIsError((err) => ({...err, [errorType]: !isValid}));
  };

  if (showNotFound) {
    return <NotFound />;
  }

  return (
    <>
      {isLoading && <Loader style={{position: 'fixed'}} />}
      <MetaTags
        title={
          task
            ? t('label.active', `{{companyName}} has shared a task with you.`, {companyName: task.companyName})
            : t('label.default', 'Crews by Core Pro')
        }
        description={t('description', 'Click this link to see the details and update progress for the team.')}
        keywords={META_KEYWORDS}
        ogUrl={window.location.origin + window.location.pathname}
      >
        <meta name="apple-itunes-app" content={`app-id=${env.appleStoreAppID}`} />
      </MetaTags>
      <div className="screen-app">
        <header className="app-header">
          <Logo className="app-header__logo" />
          <div className="app-actions app-header__actions">
            <SkeletonFieldPreloader when={!hasLoaded}>
              <div className="app-actions__form">
                <div className="ctrl-form ctrl-form--button">
                  <div className="ctrl-form__header">
                    <label className="ctrl-form__label">{t('form.label', 'Change activity status as:')}</label>
                  </div>
                  <div className="ctrl-form__body">
                    <FormControl
                      name="guestName"
                      errorClassName={css.form__validation}
                      error={
                        isError.header && t('form.guest_name_error_message', 'Field must contains only Latin letters')
                      }
                    >
                      <input
                        ref={inputGuestNameRef}
                        className="ctrl-textfield"
                        type="text"
                        placeholder={t('form.guest_name_placeholder', 'Guest Name')}
                        value={guestName}
                        onChange={(e) => setGuestName(e.currentTarget.value)}
                        onKeyPress={(e) => handleInput(e, 'header')}
                      />
                    </FormControl>
                    <Button
                      iconOnly
                      className="ctrl-form__button"
                      onClick={() => inputGuestNameRef.current.focus()}
                      icon={<Icon colorFill className="ctrl-btn-clear__icon" name="edit" />}
                    />
                  </div>
                </div>
              </div>
            </SkeletonFieldPreloader>
            <SkeletonFieldPreloader when={!hasLoaded}>
              <TaskStatusSelect
                status={task?.status}
                statusOptions={statusOptions}
                visibleOptions={visibleOptions}
                onSelect={(value: TaskStatusType) => {
                  mixpanel.trackWithAction(
                    () => debouncedUpdateTask({id: task.id, status: value}),
                    mixpanelEvents.updateTaskStatus,
                  );
                }}
              />
            </SkeletonFieldPreloader>
          </div>
        </header>
        <div className="app-task">
          <div className="app-task__grid">
            <div className="app-task__part app-task__part--main-info">
              <TaskMainInfoPart task={task} clonedTask={clonedTask} onUpdateTask={debouncedUpdateTask} />
            </div>
            <div className="app-task__part app-task__part--info">
              <AppCard title={t('activity_history.title', 'Activity History')}>
                <SkeletonFieldPreloader when={!hasLoaded}>
                  <TaskStatusHistory history={task?.statusHistory} />
                </SkeletonFieldPreloader>
              </AppCard>
              <AppCard title={t('assignees.title', 'Assignees')}>
                <SkeletonFieldPreloader when={!hasLoaded}>
                  <AssigneesPart assignees={task?.assignees} />
                </SkeletonFieldPreloader>
              </AppCard>
              <DownloadAppPart />
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        visible={showConfirmation}
        description={<>Enter Guest Name</>}
        field={
          <FormControl
            name="guestName"
            errorClassName={css.form__validation}
            error={isError.modal && t('form.guest_name_error_message', 'Field must contains only Latin letters')}
          >
            <input
              className="ctrl-textfield"
              type="text"
              value={guestName}
              onChange={(e) => setGuestName(e.currentTarget.value)}
              onKeyPress={(e) => handleInput(e, 'modal')}
            />
          </FormControl>
        }
        onClose={onClose}
        onAccept={onSave}
        isDisabledAcceptBtn={!guestName.trim() || isError.modal}
        onReject={onCancel}
      />
      <Confirmation
        visible={isInvalidToken}
        acceptButton={t('confirm.login_btn', 'Go to Log in')}
        onClose={goToLoginPage}
        onAccept={goToLoginPage}
        title="Sorry!"
        description={
          <>
            <span>{t('confirm.expired_link', 'Seems like the link has been expired.')}</span>
            <br />
            <span>{t('confirm.contact_to_active', 'Please contact to owner to get active.')}</span>
          </>
        }
      />
    </>
  );
};
export default SharedTask;
