import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
  SortingState,
  flexRender,
  Row,
  OnChangeFn,
} from '@tanstack/react-table';
import cn from 'classnames';
import {CSSProperties, ReactNode, Ref, useRef, UIEventHandler, ComponentType} from 'react';
import {Virtuoso, VirtuosoHandle} from 'react-virtuoso';

import {TableRow, TableRowProps} from './components/Columns/TableRow';
import {TableSortIndicator} from './components/TableSortIndicator';
import s from './Table.module.scss';

interface CoreTableProps<D extends Record<string, unknown>, TValue = unknown> {
  data: D[];
  columns: ColumnDef<D, TValue>[];
  onRowClick?: (row: Row<D>) => void;
  next?: () => void;
  onSort?: OnChangeFn<SortingState>;
  cellRenderProps?: Record<string, unknown>;
  cellClassName?: string;
  hasMore?: boolean;
  isLoading?: boolean;
  skeletonLoader?: ReactNode;
  scrollableTarget?: ReactNode;
  tableClassName?: string;
  queryKey?: string;
  fetchFinished?: boolean;
  bodyStyle?: CSSProperties;
  fixedRowHeight?: number;
  headerHeight?: number;
  onScroll?: UIEventHandler<'div'>;
  vListRef?: Ref<VirtuosoHandle>;
  isScrolling?: (isScrolling: boolean) => void;
  scrollerClassName?: string;
  headerClassName?: string;
  initialState?: {
    sorting?: SortingState;
  };
  rowComponent?: ComponentType<Partial<TableRowProps<D>>>;
}

const Table = <D extends Record<string, unknown>>({
  data,
  columns,
  onRowClick,
  next,
  hasMore,
  isLoading = true,
  skeletonLoader,
  cellRenderProps,
  cellClassName,
  onSort,
  tableClassName,
  rowComponent,
  fixedRowHeight,
  vListRef,
  isScrolling,
  scrollerClassName,
  headerClassName,
  initialState,
}: CoreTableProps<D>) => {
  const header = useRef<HTMLDivElement>(null);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    enableSorting: true,
    state: {
      sorting: initialState?.sorting || [],
    },
    onSortingChange: onSort,
  });

  const showSkeleton = isLoading && !data?.length;

  const loadMore = () => {
    if (hasMore) next?.();
  };

  const onScrollHandler: UIEventHandler<'div'> = (event) => {
    if (event.target instanceof HTMLDivElement) {
      header.current!.style.transform = `translateX(-${event.target.scrollLeft}px)`;
    }
  };

  return (
    <div className={cn(s.tableWorkers, tableClassName)}>
      <div className={cn(s.tableWorkers__table, headerClassName)}>
        {table.getHeaderGroups().map((headerGroup) => (
          <div key={headerGroup.id} className={cn(s.tableWorkers__line, s.tableWorkers__line_header)} ref={header}>
            {headerGroup.headers.map((header) => (
              <div
                key={header.id}
                className={`${s.tableWorkers__cell} ${s.tableWorkers__cell_th}`}
                style={{width: header.getSize()}}
              >
                {header.column.getCanSort() && (
                  <div
                    className={s.tableWorkers__thResizer}
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                  />
                )}
                <span
                  onClick={header.column.getToggleSortingHandler()}
                  title={`Sort by ${header.column.columnDef.header as string}`}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </span>
                {header.column.getIsSorted() && <TableSortIndicator column={header.column} />}
              </div>
            ))}
          </div>
        ))}
        {showSkeleton ? (
          skeletonLoader
        ) : (
          <Virtuoso
            ref={vListRef}
            className={scrollerClassName}
            data={table.getRowModel().rows}
            endReached={loadMore}
            fixedItemHeight={fixedRowHeight}
            isScrolling={isScrolling}
            itemContent={(_index, row) => {
              const RowComponent = rowComponent || TableRow;
              return (
                <RowComponent
                  onClick={() => onRowClick?.(row)}
                  row={row}
                  cellRenderProps={cellRenderProps}
                  cellClassName={cellClassName}
                  key={row.id}
                />
              );
            }}
            onScroll={onScrollHandler}
            components={{
              Footer() {
                return hasMore ? <h3 style={{textAlign: 'center'}}>Loading...</h3> : null;
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
