import {FunctionComponent, PropsWithChildren} from 'react';

import {useClassName} from 'shared/hooks/useClassName';
import {useLandingStyles} from 'shared/hooks/useLandingStyles';

const BaseLayoutStylesContainer: FunctionComponent<PropsWithChildren> = ({children}) => {
  useLandingStyles();
  useClassName(document.body, 's-page', {clearInitialClasses: false});
  return <>{children}</>;
};
export default BaseLayoutStylesContainer;
