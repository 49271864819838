import {forwardRef, Ref, useMemo} from 'react';
import {ActionMeta, GroupBase, PropsValue, SelectInstance} from 'react-select';
import CreatableReactSelect, {CreatableProps} from 'react-select/creatable';

import DropdownIndicator from '../DropdownIndicator/DropdownIndicator';
import {MenuPortal} from '../Select';
import {createGenericStyles} from '../styles';
import {CoreOptionType, SelectOption} from '../types';

interface CreatableBaseProps<Option extends SelectOption = CoreOptionType> {
  value?: string | null;
  onChange?: (value: string | null, actionMeta?: ActionMeta<Option>) => void;
  options?: readonly Option[];
  size?: 'xs';
  className?: string;
  [key: string]: any;
}

type CreatableSelectProps<Option extends SelectOption = CoreOptionType> = Omit<
  CreatableProps<Option, false, GroupBase<Option>>,
  'value' | 'onChange'
> &
  CreatableBaseProps<Option>;

type CreatableSelectRef<Option extends SelectOption = CoreOptionType> = SelectInstance<
  Option,
  false,
  GroupBase<Option>
>;

function isSelectOption<Option extends SelectOption>(option: Option | GroupBase<Option>): option is Option {
  return 'value' in option && 'label' in option;
}

export const CreatableSelect = forwardRef(
  <Option extends SelectOption = CoreOptionType>(
    {value, onChange, options, components, styles, className, size, ...selectProps}: CreatableSelectProps<Option>,
    ref: Ref<CreatableSelectRef<Option>>,
  ) => {
    const selected = useMemo(() => {
      if (!value) return null;

      const foundOption = options?.find((opt) => isSelectOption(opt) && opt.value === value);
      return (foundOption ??
        ({
          value,
          label: value,
        } as Option)) as PropsValue<Option>;
    }, [value, options]);

    const handleChange = (newValue: Option | null, actionMeta: ActionMeta<Option>) => {
      onChange?.(newValue?.value ?? null, actionMeta);
    };

    return (
      <CreatableReactSelect<Option, false, GroupBase<Option>>
        ref={ref}
        className={`${className ?? ''} ${size === 'xs' ? 'react-select--size-xs' : ''}`}
        classNamePrefix="react-select"
        options={options}
        value={selected}
        onChange={handleChange}
        components={{
          DropdownIndicator,
          MenuPortal,
          ...components,
        }}
        styles={styles || createGenericStyles<Option>()}
        {...selectProps}
      />
    );
  },
);

CreatableSelect.displayName = 'CreatableSelect';
