import cn from 'classnames';
import {FC, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory, useRouteMatch} from 'react-router';

import {ROUTES} from 'shared/constants/routes';
import {useOutsideClick} from 'shared/hooks/core/useOusideClick';

import Icon from '../Icon';

import {supportedLocales} from './constants';
import s from './LandingLngSwitcher.module.scss';

type Props = {
  className?: string;
  onBeforeChangeLanguage?: (language: string) => void;
};

const LandingLngSwitcher: FC<Props> = ({className, onBeforeChangeLanguage}: Props) => {
  const {
    i18n: {language, changeLanguage},
  } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch({
    path: [
      ROUTES.landing,
      ROUTES.signUpAltA,
      ROUTES.inviteSignup,
      ROUTES.logIn,
      ROUTES.forgotPassword,
      ROUTES.demoContact,
    ],
    exact: true,
  });
  const containerRef = useRef(null);
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);

  useOutsideClick({
    ref: containerRef,
    callback: () => {
      setIsOpenDropdown(false);
    },
  });

  return (
    <div className={cn(s.landingLangs, isOpenDropdown && s.landingLangs_isActive, className)} ref={containerRef}>
      <button
        className={cn(s.landingLangs__button, s.landingLangs__button_toggle)}
        onClick={() => setIsOpenDropdown((prev) => !prev)}
      >
        <Icon
          name="globe"
          className={cn(s.landingLangs__buttonIcon, s.landingLangs__buttonIcon_globe)}
          size={24}
          colorFill
        />
        <span className={s.landingLangs__buttonText}>{language?.toUpperCase()}</span>
        <Icon
          name={!isOpenDropdown ? 'chevron-bottom' : 'chevron-top'}
          className={cn(s.landingLangs__buttonIcon, s.landingLangs__buttonIcon_arrow)}
          size={24}
          colorFill
        />
      </button>
      {isOpenDropdown && (
        <div className={s.landingLangs__viewport}>
          <ul className={s.landingLangs__list}>
            {Object.keys(supportedLocales).map((locale) => (
              <li className="landing-langs__list-item" key={locale}>
                <button
                  className={cn(s.landingLangs__button, locale === language && s.landingLangs__button_current)}
                  onClick={() => {
                    if (typeof onBeforeChangeLanguage === 'function') onBeforeChangeLanguage(locale);
                    changeLanguage(locale);
                    history.replace(generatePath(match?.path, {...match?.params, locale}));
                    setIsOpenDropdown(false);
                  }}
                >
                  <span className={s.landingLangs__buttonText}>{supportedLocales[locale]}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
export default LandingLngSwitcher;
