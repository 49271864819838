import {TFunction} from 'i18next';
import {object, string} from 'yup';

import {DEFAULT_REQUIRED_MESSAGE} from 'shared/helpers/validationSchemas';
import {ProjectCustomFieldType} from 'shared/models/project';

const MIN_CHARS_LENGTH = 'Must be at least 3 characters';
const MAX_CHARS_LENGTH = 'Must be no more than 20 characters';

const OPTIONS_LABELS = {
  [ProjectCustomFieldType.multiselect]: 'Multiselect',
  [ProjectCustomFieldType.select]: 'Select',
  [ProjectCustomFieldType.string]: 'Text',
  [ProjectCustomFieldType.number]: 'Number',
  [ProjectCustomFieldType.date]: 'Date',
};

export const validationSchema = object().shape({
  fieldName: string().required(DEFAULT_REQUIRED_MESSAGE).min(3, MIN_CHARS_LENGTH).max(20, MAX_CHARS_LENGTH),
});

export const getFieldTypesOptions = (t: TFunction) => {
  return Object.values(ProjectCustomFieldType).map((fieldType) => {
    return {
      value: fieldType,
      label: t(`project:custom_field_popup.form_field.type.options.${fieldType}`, OPTIONS_LABELS[fieldType]),
    };
  });
};
