import './style.scss';
import {flip, offset, shift, useDismiss, useFloating, useInteractions, VirtualElement} from '@floating-ui/react';
import {isFulfilled} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import {GanttStatic} from 'dhtmlx-gantt';
import {CSSProperties, memo, useCallback, useMemo, useState} from 'react';
import {createPortal} from 'react-dom';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation, useParams} from 'react-router';
import {toast} from 'react-toastify';

import TasksApi from 'api/tasks';
import {container} from 'IocContainer';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {useGanttContext} from 'modules/Tasks/components/Gantt/components/GanttContext';
import {deselectAll, isPlaceholderTask} from 'modules/Tasks/components/Gantt/utils/gantt';
import {useTasksActions} from 'modules/Tasks/hooks/useTasksActions';
import {refreshTask} from 'modules/Tasks/utils/functions';
import {GanttSimpleLock} from 'modules/Tasks/utils/simpleLock';
import {ObserverActionSource, ObserverAction} from 'services/TasksObserver/const';
import {useTasksObserver} from 'services/TasksObserver/TasksObserverProvider';
import {useConfirm} from 'shared/components/Confirmation';
import CtrlColorPicker from 'shared/components/CoreNewUI/CtrlColorPicker/CtrlColorPicker';
import IssueUpdatesPopup from 'shared/components/CoreNewUI/IssueUpdatesPopup/IssueUpdatesPopup';
import Icon from 'shared/components/Icon';
import ShareTask from 'shared/components/ShareTask';
import TasksBulkAssignPopup from 'shared/components/TasksBulkAssignPopup';
import {TasksViewMode} from 'shared/constants/common';
import {GanttNames} from 'shared/constants/gantt';
import {RouteParams} from 'shared/constants/routes';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {toTitleCase} from 'shared/helpers/common';
import {safeParseDate} from 'shared/helpers/dates';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useResponsibleOrgColors} from 'shared/hooks/useResponsibleOrgColors';
import {toTaskModelRawDTO} from 'shared/mapping/task';
import {IOC_TYPES} from 'shared/models/ioc';
import {TaskObjectType, TaskActiveTab, TaskProjection} from 'shared/models/task/const';
import {TaskModelRawDTO, TaskRelativeDirection} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';
import {TasksStoreType} from 'shared/stores/TasksStore';
import {UIStoreType} from 'shared/stores/UIStore';
import {useRootDispatch} from 'store';
import {getTask, restoreTask} from 'store/tasks/actions';

import {getActivityName} from '../../utils/constants';

type ActionButtonType = {
  name: string;
  dataCy?: string;
  onClick: () => void;
  iconName: string;
  iconClick?: string;
  isDisabled?: boolean;
  hidden?: boolean;
  styles?: CSSProperties;
  group?: 'start' | 'end';
};

export interface GanttContextMenuProps {
  gantt: GanttStatic;
  setActiveEntityId?: (id: string) => void;
  activeEntityId: string;
  boundary: VirtualElement;
}

const TaskContextMenu = ({gantt, activeEntityId, setActiveEntityId, boundary}: GanttContextMenuProps) => {
  const {onStopEditAction} = useGanttContext();
  const simpleLock = GanttSimpleLock.getInstance(gantt);
  const selectedTask = gantt.contextMenu.context;
  const {t} = useTranslation(['gantt', 'task', 'common']);
  const dispatch = useRootDispatch();
  const history = useHistory();
  const location = useLocation();
  const {projectId} = useParams<RouteParams['tasks']>();
  const {confirm} = useConfirm();
  const {viewMode} = useFilterContext();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});
  const mixpanelEvents = mixpanel.events.tasks.contextMenu;
  const observer = useTasksObserver();

  const hasSelected = gantt.getSelectedTasks().length;
  const isMultiSelectedTasks = hasSelected > 1;

  const [showTaskShareLink, setShowTaskShareLink] = useState(false);
  const [isOpenBulkAssign, setIsOpenBulkAssign] = useState(false);

  const isArchived = selectedTask?.status === TaskStatusType.archived;
  const isDeleted = !!selectedTask?.time_removed;

  const openBulkAssign = () => setIsOpenBulkAssign(true);
  const closeBulkAssign = () => {
    setIsOpenBulkAssign(false);
    onClose();
  };

  const {indentSelectedTasks, outdentSelectedTasks, deleteSelectedTasks} = useTasksActions(gantt);
  const {lookaheadColors, mappedLookaheadColors} = useResponsibleOrgColors();

  const {x, y, strategy, refs, context} = useFloating({
    elements: {
      reference: boundary.contextElement,
    },
    placement: 'right-start',
    middleware: [offset(0), flip(), shift()],
    open: true,
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        onClose();
      }
    },
  });

  const dismiss = useDismiss(context, {
    outsidePress: true,
    referencePress: true,
    escapeKey: true,
  });

  const {getFloatingProps} = useInteractions([dismiss]);

  const onClose = () => {
    mixpanel.trackWithAction(onStopEditAction, mixpanelEvents.closeMenu);
    gantt.contextMenu.close();
  };

  const addActivity = () => {
    mixpanel.track(mixpanelEvents.addActivity);
    const nextId = gantt.getNextSibling(selectedTask.id);
    const isLast = !gantt.isTaskExists(nextId) || isPlaceholderTask(gantt, gantt.getTask(nextId));
    simpleLock.run(async () => {
      const sState = gantt.getScrollState();
      const model = {
        name: getActivityName(t),
        objectType: TaskObjectType.activity,
      };
      if (!isLast) {
        Object.assign(model, {
          relativeDir: TaskRelativeDirection.After,
          relativeToId: selectedTask.id,
        });
      }
      gantt.createTask(model, String(selectedTask.parent));
      gantt.scrollTo(0, sState.y);
    });
    onClose();
  };

  const addAction = () => {
    mixpanel.track(mixpanelEvents.addAction);
    history.replace({
      pathname: location.pathname,
      search: location.search,
      state: {newActionFocus: true},
    });
    setActiveEntityId(selectedTask.id);
    onClose();
  };

  const addIssue = () => {
    mixpanel.track(mixpanelEvents.addIssue);
    setActiveEntityId(selectedTask.id);
    // wait until new opened entity populated
    setTimeout(() => {
      history.replace({
        pathname: location.pathname,
        search: location.search,
        state: {newIssueFocus: true},
      });
    });
    onClose();
  };

  const addAssignees = () => {
    mixpanel.track(mixpanelEvents.addAssignees);
    setActiveEntityId(selectedTask.id);
    history.replace({
      pathname: location.pathname,
      search: location.search,
      state: {activeTab: TaskActiveTab.assigners},
    });
    onClose();
  };

  const openTask = () => {
    mixpanel.track(mixpanelEvents.taskDetails);
    setActiveEntityId(selectedTask.id);
    history.replace({
      pathname: location.pathname,
      search: location.search,
      state: {activeTab: TaskActiveTab.info},
    });
    onClose();
  };

  const hideTaskShareLink = useCallback(() => {
    setShowTaskShareLink(false);
  }, []);

  const deleteTask = async () => {
    mixpanel.track(mixpanelEvents.delete);

    const selectedTasks = gantt.getSelectedTasks();
    selectedTasks.forEach((task) => gantt.unselectTask(task.id));

    gantt.selectTask(selectedTask.id);

    const wasDeleted = await deleteSelectedTasks(projectId);

    if (wasDeleted) {
      if (selectedTask.id === activeEntityId) {
        setActiveEntityId(null);
      }
      onClose();
    }
  };

  const undeleteTask = async () => {
    const confirmTitle = t('task:confirmation.undelete.title', {activityName: selectedTask.name});
    const confirmText = t('task:confirmation.undelete.text', 'Are you sure you want to undelete the Activity?');
    if (await confirm({title: confirmTitle, description: confirmText, useNewLayout: true})) {
      const restoreRes = await dispatch(restoreTask(selectedTask.id));
      const fullTaskRes = await dispatch(getTask(selectedTask.id));
      if (restoreTask.fulfilled.match(restoreRes)) {
        if (isFulfilled(fullTaskRes)) {
          observer.emit([{data: fullTaskRes.payload}], {
            projection: TaskProjection.taskDetail,
            source: ObserverActionSource.gantt,
            sourceName: gantt.name,
            action: ObserverAction.create,
            projectId: fullTaskRes.payload.projectId,
          });
        }
        toast.success(t('gantt:toast.success.task_undelete', 'Activity undeleted'));
        onClose();
      } else {
        toast.error(restoreRes.error.message);
      }
    }
    onClose();
  };

  const indentWithLock = () => {
    simpleLock.run(() => {
      return indentSelectedTasks();
    });
    onClose();
  };

  const outdentWithLock = () => {
    simpleLock.run(() => {
      return outdentSelectedTasks();
    });
    onClose();
  };

  const copiedTaskIds = gantt.copyPlugin.getCopiedIds();

  const cloneTasks = async () => {
    const uiStore = container.get<UIStoreType>(IOC_TYPES.UIStore);
    const tasksStore = container.get<TasksStoreType>(IOC_TYPES.TasksStore);
    uiStore.setLoading(true);

    try {
      let relativeDir = TaskRelativeDirection.After;
      if (selectedTask.object_type == TaskObjectType.summary && selectedTask.$open) {
        relativeDir = TaskRelativeDirection.Into;
      }

      const res = await TasksApi.clone({
        relativeDir,
        taskIds: copiedTaskIds,
        relativeToId: selectedTask.id,
      });
      const tasks = res.tasks.filter((task) => task.objectType !== TaskObjectType.action);
      const affectedTasksIds = res.affectedTaskIds;

      const affectedTasks = (await TasksApi.getProjectTasks({
        projection: TaskProjection.task,
        limit: affectedTasksIds.length,
        params: {
          projectId: tasks[0].projectId,
          ids: affectedTasksIds,
        },
      }).then((res) => res.data)) as TaskModelRawDTO[];

      tasksStore.updateTasks(affectedTasks);

      const sState = gantt.getScrollState();

      tasksStore.addTasks(tasks.map(toTaskModelRawDTO));

      gantt.scrollTo(sState.x, sState.y);

      const tids = tasks.map((task) => task.id);

      deselectAll(gantt);

      gantt.callEvent('afterTasksClone', [tids]);
      toast.success(
        t('gantt:toast.success.activity_cloned', '$t(common:activity, {"count": {{count}} }) copied', {
          count: tasks.length,
        }),
      );
    } catch (e) {
      if (isAxiosError(e)) {
        toast.error(extractAxiosError(e) as string);
      }
    } finally {
      onClose();
      uiStore.setLoading(false);
    }
  };

  const bulkDelete = async () => {
    await deleteSelectedTasks(projectId);
    onClose();
  };

  const copyTasks = () => {
    const copiedIds = gantt.copyPlugin.copy(hasSelected ? undefined : [selectedTask?.id]);
    // values must be at root level for i18next-parser to extract correctly
    toast.success(t('gantt:floatMenu_successCopy', {defaultValue: '{{count}} item copied!', count: copiedIds.length}));
    onClose();
  };

  const getButtons = () => {
    const indentActions: ActionButtonType[] = [
      {
        name: t('gantt:contextMenu.buttons.indent', 'Indent'),
        iconName: 'indent',
        onClick: () => indentWithLock(),
        isDisabled: isDeleted || isArchived,
      },
      {
        name: t('gantt:contextMenu.buttons.outdent', 'Outdent'),
        iconName: 'outdent',
        onClick: () => outdentWithLock(),
        isDisabled: isDeleted || isArchived,
      },
    ];
    const contextTaskInSelection = gantt.getSelectedTasks().includes(selectedTask?.id);

    const pasteActions: ActionButtonType[] = copiedTaskIds.length
      ? [
          {
            name: t('gantt:contextMenu.buttons.paste_after', 'Paste After'),
            iconName: 'paste',
            onClick: () => cloneTasks(),
          },
        ]
      : [];

    const copyAction: ActionButtonType = {
      name: t('gantt:contextMenu.buttons.copy', 'Copy'),
      iconName: 'copy',
      onClick: copyTasks,
    };

    if (isMultiSelectedTasks) {
      return contextTaskInSelection
        ? [].concat(indentActions, copyAction, pasteActions).concat([
            {
              name: t('gantt:contextMenu.buttons.bulk_assign', 'Bulk Assign'),
              onClick: openBulkAssign,
              iconClick: 'link',
              iconName: 'user-add',
              hidden: !gantt
                .getSelectedTasks()
                .map((taskId) => gantt.getTask(taskId))
                .every((task) => task.object_type === TaskObjectType.milestone),
            },
            {
              name: t('gantt:contextMenu.buttons.bulk_delete', 'Bulk Delete'),
              onClick: bulkDelete,
              iconClick: 'link',
              iconName: 'remove_from_trash',
            },
          ])
        : [].concat(copyAction, pasteActions);
    }

    const singleActions: ActionButtonType[] = [
      {
        name: t('gantt:contextMenu.buttons.add_activity', 'Add Activity'),
        dataCy: 'AddActivity',
        onClick: addActivity,
        iconName: 'add-circle-outlined',
        isDisabled: isDeleted || isArchived,
      },
      {
        name: t('gantt:contextMenu.buttons.add_issue', 'Add Issue'),
        onClick: addIssue,
        dataCy: 'AddIssue',
        styles: {marginLeft: '2px'},
        iconName: 'clockdelay',
        isDisabled: isDeleted || isArchived,
        hidden: selectedTask.object_type !== TaskObjectType.activity,
      },
      {
        name: t('gantt:contextMenu.buttons.focus_annotation', 'Show annotation'),
        dataCy: 'ShowAnnot',
        onClick: () => {
          gantt.callEvent('focusAnnot', [selectedTask.id]);
          onClose();
        },
        iconName: 'show',
        hidden: gantt.name !== GanttNames.ganttVisual,
      },
      {
        name: t('gantt:contextMenu.buttons.add_action', 'Add Action'),
        onClick: addAction,
        styles: {margin: '2px 5px -2px 5px'},
        iconName: 'right_rotate_icon',
        isDisabled: isDeleted || isArchived,
        hidden: selectedTask.object_type !== TaskObjectType.activity,
      },
      {
        name: t('gantt:contextMenu.buttons.add_assignees', 'Add Assignees'),
        onClick: addAssignees,
        iconName: 'user-add',
        isDisabled: isDeleted || isArchived,
        hidden: selectedTask.object_type === TaskObjectType.milestone,
      },
      {
        name: t('gantt:contextMenu.buttons.open', 'Details'),
        onClick: openTask,
        iconName: 'info_outlined',
        isDisabled: false,
      },
      {
        name: isDeleted
          ? t('gantt:contextMenu.buttons.undelete', 'Undelete')
          : t('gantt:contextMenu.buttons.delete', 'Delete'),
        onClick: isDeleted ? undeleteTask : deleteTask,
        iconName: 'remove_from_trash',
        isDisabled: false,
      },
    ];

    return Array.from(
      new Set(
        singleActions
          .concat(!hasSelected || contextTaskInSelection ? indentActions : [])
          .concat(copyAction, pasteActions),
      ),
    );
  };

  const overridePerDateTaskColor = (abbrev: string, color: string) => {
    const date = boundary.contextElement.getAttribute('task-date');
    const newColor = color ? mappedLookaheadColors?.[color] : color;
    const prevValues = selectedTask.per_date_override[date];
    if (prevValues && newColor === prevValues.lookahead_color && abbrev === prevValues.abbrev) {
      return;
    }
    const updatedValue = {...selectedTask.per_date_override};
    if (!abbrev && !color) {
      delete updatedValue[date];
    } else {
      updatedValue[date] = {abbrev: abbrev || undefined, lookahead_color: newColor || undefined};
    }

    selectedTask.lastChangedFields.perDateOverride = {
      newValue: updatedValue,
      oldValue: selectedTask.per_date_override,
    };
    try {
      gantt.updateTask(selectedTask.id, {...selectedTask, per_date_override: updatedValue});
      refreshTask(gantt, selectedTask.id, {per_date_override: updatedValue});
      toast.success(
        t('task:notifications.updated', 'Activity updated', {
          type: `$t(common:${toTitleCase(selectedTask.object_type)})`,
        }),
      );
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
      Sentry.captureException(e);
    } finally {
      onClose();
    }
  };

  const resetOverrides = () => {
    overridePerDateTaskColor(null, null);
  };

  /* TODO: need to move placement logic related to gantt to separate hook
   *  currently placement logic repeated in each popper/editor
   */
  const mainContent = (
    <>
      <div className="ctrl-linklist">
        <div className="ctrl-linklist__grid">
          {getButtons().map(({name, dataCy, onClick, styles, iconName, isDisabled, hidden, group}) => (
            <div
              key={name}
              style={hidden ? {display: 'none'} : {}}
              className={cn('ctrl-linklist__item gantt__context-menu', {
                'gantt__context-menu_disable-item': isDisabled,
                [`gantt__context-menu_group-${group}`]: !!group,
              })}
            >
              <button
                style={{color: 'black'}}
                data-cy={`btnCtxMenu${dataCy}`}
                className="ctrl-linklist__link"
                type="button"
                onClick={onClick}
              >
                <Icon
                  name={iconName}
                  className="ctrl-linklist__icon"
                  style={styles ? styles : null}
                  colorFill
                  size={24}
                />
                <span className="ctrl-linklist__text">{name}</span>
              </button>
            </div>
          ))}
        </div>
      </div>
      <ShareTask
        projectId={projectId}
        viewMode={viewMode}
        taskId={selectedTask.id}
        visible={showTaskShareLink}
        onClose={hideTaskShareLink}
      />
      {/* TODO: make one global BulkAssignPopup?*/}
      {isOpenBulkAssign && <TasksBulkAssignPopup gantt={gantt} onClose={closeBulkAssign} />}
    </>
  );

  const getContent = useMemo(() => {
    if (boundary.contextElement.classList.contains('lookahead__badge')) {
      const cube = boundary.contextElement.closest('.lookahead_element');
      const date = cube.getAttribute('task-date');
      return (
        <IssueUpdatesPopup
          gantt={gantt}
          parent={selectedTask}
          date={safeParseDate(date, 'YYYY-MM-DD')}
          closePopup={onClose}
        />
      );
    }
    if (viewMode === TasksViewMode.lookahead && boundary.contextElement.closest('.gantt_data_area')) {
      const date = boundary.contextElement.getAttribute('task-date');
      const initialValues = {
        abbrev: selectedTask.per_date_override[date]?.abbrev ?? '',
        color: lookaheadColors ? lookaheadColors[selectedTask.per_date_override[date]?.lookahead_color]?.fill : '',
      };
      return (
        <CtrlColorPicker
          initialValues={initialValues}
          className="gantt__context-menu__override-date-square-color"
          colors={mappedLookaheadColors ? Object.keys(mappedLookaheadColors) : []}
          onReset={resetOverrides}
          onSave={overridePerDateTaskColor}
        />
      );
    }

    return mainContent;
  }, [viewMode, boundary]);

  return createPortal(
    <div
      {...getFloatingProps()}
      className={cn('ctrl-drop__viewport gantt__task-context-menu react-popper loader-container')}
      ref={refs.setFloating}
      style={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
        maxHeight: 'auto',
      }}
    >
      <div className="ctrl-drop__content">{getContent}</div>
    </div>,
    document.body,
  );
};

export default memo(TaskContextMenu);

TaskContextMenu.displayName = 'TaskContextMenu';
