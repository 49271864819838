import {GanttStatic} from 'dhtmlx-gantt';
import {TFunction} from 'i18next';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import {debounce} from 'shared/helpers/debounce';
import {TaskGanttLastChangedFields} from 'shared/models/task/task';
import {UndoRedoCommand, UndoRedoAction, UndoRedoActionType} from 'shared/models/undoRedo';

const removeActionFromStack = (stack: UndoRedoAction[], invalidCommand: UndoRedoCommand) => {
  const actionIndex = stack.findIndex((action) =>
    action.commands.some(
      (command) => command.entity === invalidCommand.entity && command.value.id === invalidCommand.value.id,
    ),
  );

  if (actionIndex !== -1) {
    const action = stack[actionIndex];
    if (action.commands.length > 1) {
      action.commands = action.commands.filter(
        (command) => !(command.entity === invalidCommand.entity && command.value.id === invalidCommand.value.id),
      );
    } else {
      stack.splice(actionIndex, 1);
    }
    return true;
  }
  return false;
};

const taskExistsForUndoRedo = (
  gantt: GanttStatic,
  command: UndoRedoCommand,
  actions: UndoRedoActionType[],
  t: TFunction,
) => {
  const {type, value} = command;
  if (actions.includes(type) && !gantt.isTaskExists(value.id)) {
    toast.warn(t('gantt:toast.error.task_deleted'));
    return false;
  }
  return true;
};
const redoActions: UndoRedoActionType[] = ['add', 'remove', 'update', 'move'];
const undoActions = redoActions.filter((type) => type !== 'add');

export function useUndo(gantt: GanttStatic) {
  const {t} = useTranslation('gantt');
  const [canRedo, setCanRedo] = useState(false);
  const [cantUndo, setCantUndo] = useState(false);
  useEffect(() => {
    const events = [];
    const stackChanged = debounce(() => {
      setCanRedo(!!gantt.getRedoStack().length);
      setCantUndo(!!gantt.getUndoStack().length);
    }, 100);
    events.push(
      gantt.attachEvent(
        'onBeforeUndoStack',
        (action: UndoRedoAction) => {
          for (const command of action.commands) {
            const {value, oldValue, entity} = command;
            if (entity === 'task') {
              if (!taskExistsForUndoRedo(gantt, command, undoActions, t)) {
                removeActionFromStack(gantt.getUndoStack(), command);
                stackChanged();
                return false;
              }
              const changes = value.lastChangedFields;
              if (changes) {
                oldValue.lastChangedFields = Object.entries<TaskGanttLastChangedFields[string]>(changes).reduce(
                  (acc, [key, value]) => {
                    return Object.assign(acc, {
                      [key]: {
                        oldValue: value.newValue,
                        newValue: value.oldValue,
                      },
                    });
                  },
                  {} as TaskGanttLastChangedFields,
                );
              }
            }
          }
          stackChanged();
          return true;
        },
        undefined,
      ),
    );
    events.push(
      gantt.attachEvent(
        'onBeforeRedoStack',
        (action: UndoRedoAction) => {
          for (const command of action.commands) {
            if (!taskExistsForUndoRedo(gantt, command, redoActions, t)) {
              removeActionFromStack(gantt.getUndoStack(), command);
              stackChanged();
              return false;
            }
          }
          stackChanged();
          return true;
        },
        undefined,
      ),
    );
    events.push(gantt.attachEvent('onAfterUndo', stackChanged, undefined));
    events.push(gantt.attachEvent('onAfterRedo', stackChanged, undefined));
    events.push(gantt.attachEvent('onGanttReady', stackChanged, undefined));
    return () => events.forEach((id) => gantt.detachEvent(id));
  }, [gantt, setCantUndo, setCanRedo, t]);

  return {
    canRedo,
    cantUndo,
  };
}
