import {ChangeEvent, FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Icon from 'shared/components/Icon';
import {useDebounce} from 'shared/hooks/core/useDebounce';

import FormControl, {CtrlFormProps} from '../FormControl/FormControl';

export type SearchInputProps = {
  value?: string;
  name: string;
  onChange: (newValue: string) => void;
  onFocus?: () => void;
  inputClassName?: string;
};

const SearchInput: FC<SearchInputProps & CtrlFormProps> = ({
  name,
  onChange,
  value = '',
  onFocus,
  inputClassName,
  ...formControlPros
}) => {
  const [currentValue, setCurrentValue] = useState<string>(value);
  const {t} = useTranslation('common');

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    debouncedUpdateSearchParams(currentValue);
  }, [currentValue]);

  const debouncedUpdateSearchParams = useDebounce((value: string) => {
    onChange(value.trim());
  }, 300);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCurrentValue(value);
  };

  const clear = () => {
    if (currentValue) {
      setCurrentValue('');
    }
  };

  return (
    <FormControl
      icon={<Icon colorFill name={currentValue.length ? 'clear' : 'search_2'} onClick={clear} />}
      {...formControlPros}
    >
      <input
        type="text"
        name={name}
        value={currentValue}
        onChange={onInputChange}
        onFocus={typeof onFocus === 'function' ? onFocus : undefined}
        placeholder={t('toolbar.search', 'Search...')}
        data-cy="worker-search"
        className={inputClassName}
      />
    </FormControl>
  );
};
export default SearchInput;
