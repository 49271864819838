import cn from 'classnames';
import {cloneElement, isValidElement, UIEvent, useRef} from 'react';

import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';

import s from './CtrlDates.module.scss';

type CtrlDatesProps = {
  prevButton: React.ReactElement<{className?: string}>;
  nextButton: React.ReactElement<{className?: string}>;
  text: string | React.ReactElement;
  className?: string;
  active?: boolean;
  onClick?: (e: UIEvent) => void;
};

const CtrlDates = ({prevButton, nextButton, text, className, active, onClick}: CtrlDatesProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  function onContainerClick(e: UIEvent) {
    if (buttonRef.current.contains(e.target as Node) && onClick) {
      onClick(e);
    }
  }
  return (
    <div className={cn(s.ctrlDates, className)} onClick={onContainerClick}>
      {isValidElement(prevButton) &&
        cloneElement(prevButton, {
          ...prevButton.props,
          className: cn(s.ctrlDates__button, s.ctrlDates__button_action),
        })}
      <CtrlButton
        ref={buttonRef}
        className={cn(s.ctrlDates__button, s.ctrlDates__button_text)}
        color="clear"
        size="xs"
        activeDot={active}
      >
        {text}
      </CtrlButton>
      {isValidElement(nextButton) &&
        cloneElement(nextButton, {
          ...nextButton.props,
          className: cn(s.ctrlDates__button, s.ctrlDates__button_action),
        })}
    </div>
  );
};

export default CtrlDates;
