import cn from 'classnames';
import {createRef, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {date, object, string, ValidationError} from 'yup';

import CtrlBtnOption from 'shared/components/CoreNewUI/CtrlBtnOption/CtrlBtnOption';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import CtrlCard from 'shared/components/CoreNewUI/CtrlCard/CtrlCard';
import CtrlCheck from 'shared/components/CoreNewUI/CtrlCheck/CtrlCheck';
import Dropdown from 'shared/components/CoreNewUI/CtrlDrop/CtrlDrop';
import FieldInline from 'shared/components/CoreNewUI/FormControlInline/FieldInline/FieldInline';
import FormControlInline from 'shared/components/CoreNewUI/FormControlInline/FormControlInline';
import Icon from 'shared/components/Icon';
import Loader from 'shared/components/Loader';
import {DEFAULT_REQUIRED_MESSAGE} from 'shared/helpers/validationSchemas';
import {useDebounce} from 'shared/hooks/core/useDebounce';
import {useEffectOnce} from 'shared/hooks/core/useEffectOnce';
import {usePrevious} from 'shared/hooks/core/usePrevious';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {IssueMinModel} from 'shared/models/task/issue';
import {TaskDetailsModelDTO} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';
import {CompanyWorker} from 'shared/models/worker';

import IssueForm from './IssueForm';

type IssueProps = {
  className?: string;
  data: IssueMinModel;
  disabled?: boolean;
  focus?: boolean;
  loading: boolean;
  onChange: (data: IssueMinModel) => Promise<void>;
  onDelete: () => Promise<void> | void;
  onOpenIssue: () => void;
  parent?: TaskDetailsModelDTO;
  projectId: string;
  projectName: string;
  workers: CompanyWorker[];
};

export type IssueFormErrors = {
  description?: string;
  endDate?: string;
  impact?: string;
  issueType?: string;
  name?: string;
  responsible?: string;
  startDate?: string;
};

const Issue = ({
  className,
  data,
  disabled,
  focus,
  loading,
  onChange,
  onDelete,
  onOpenIssue,
  parent,
  projectId,
  projectName,
  workers,
}: IssueProps) => {
  const [issue, setIssue] = useState(data);
  const prevIssue = usePrevious(issue);
  const container = useRef<HTMLDivElement>(null);
  const observer = useRef<MutationObserver | null>(null);
  const inputNameRef = createRef<HTMLInputElement>();
  const {t} = useTranslation(['task']);
  const [errors, setErrors] = useState<IssueFormErrors>({});
  const [name, setName] = useState(issue.name);
  const [clickActionLoading, setClickActionLoading] = useState(false);
  const isNewIssue = issue.id === 'new';
  const {mixpanel} = useAnalyticsService({extraMeta: {'Project Name': projectName, projectId}});
  const mixpanelEvents = mixpanel.events.tasks.sidePanel;

  const schema = object({
    description: string().required(DEFAULT_REQUIRED_MESSAGE),
    startDate: date().required(DEFAULT_REQUIRED_MESSAGE),
    endDate: date().nullable(),
    name: string().required(DEFAULT_REQUIRED_MESSAGE),
    impact: string().required(DEFAULT_REQUIRED_MESSAGE),
    issueType: string().required(DEFAULT_REQUIRED_MESSAGE),
  });

  const {hasAnyAdminRole} = useCompanyWorkerRoles(data?.projectId);

  // Scroll to show newly created issue after it's been added
  useEffectOnce(
    () => {
      const handleFocusAndScroll = () => {
        // Give browser a chance to finish any ongoing animations/updates
        requestAnimationFrame(() => {
          if (!container.current) return;

          requestAnimationFrame(() => {
            container.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          });
        });

        if (isNewIssue && inputNameRef.current) {
          inputNameRef.current.select();
        }

        if (observer.current) {
          observer.current.disconnect();
        }
      };

      observer.current = new MutationObserver((mutations) => {
        const isFullyRendered = mutations.some(
          (mutation) => mutation.type === 'childList' && mutation.target.contains(container.current),
        );

        if (isFullyRendered) {
          handleFocusAndScroll();
        }
      });

      observer.current.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true,
        characterData: true,
      });

      handleFocusAndScroll();

      return () => {
        if (observer.current) {
          observer.current.disconnect();
          observer.current = null;
        }
      };
    },
    [focus, isNewIssue],
    !!focus,
  );

  // apply schema on edit
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = (issue: IssueMinModel): Record<string, string> => {
    try {
      schema.validateSync(issue, {abortEarly: false});
      setErrors(null);
      return null;
    } catch (e) {
      if (e instanceof ValidationError && e.inner) {
        const errors = (e as ValidationError).inner.reduce((acc, cur) => {
          return Object.assign(acc, {[cur.path]: cur.errors});
        }, {});
        setErrors(errors);
        return errors;
      }
      return {};
    }
  };

  // Propagate form edits back up to IssuesList with 500ms debounce
  const onUpdate = useDebounce(async (changeModel: IssueMinModel) => {
    await onChange(changeModel);
  }, 500);
  useEffect(() => {
    if (issue && prevIssue !== issue && issue !== data) {
      if (!validate(issue)) {
        onUpdate(issue);
      }
    }
  }, [issue, data, prevIssue, onUpdate, validate]);

  // use loader while some long-running operation is going on, such as delete
  async function withClickActionLoader(cb: () => Promise<void> | void) {
    setClickActionLoading(true);
    try {
      await cb();
    } finally {
      setClickActionLoading(false);
    }
  }

  const onFieldChange = async (model: Partial<IssueMinModel>) => {
    if ('name' in model) {
      setName(model.name);
    }
    setIssue((prev) => {
      return {...prev, ...model};
    });
  };

  useEffect(() => {
    setIssue(data);
  }, [data]);

  const onCheck = (checked: boolean) => {
    mixpanel.track(mixpanelEvents[checked ? 'doneIssue' : 'reopenIssue']);
    onFieldChange({status: checked ? TaskStatusType.closed : TaskStatusType.inProgress});
  };

  return (
    <CtrlCard
      ref={container}
      className={cn(className, 'loader-container')}
      check={
        <CtrlCheck
          disabled={disabled}
          fieldType="checkbox"
          label={t('task:activity_issues.issue.checked.label', 'Check Issue')}
          labelIsHidden
          view="square"
        >
          <input
            type="checkbox"
            disabled={disabled}
            checked={issue.status === TaskStatusType.closed}
            onChange={(e) => onCheck(e.target.checked)}
          />
        </CtrlCheck>
      }
      titleField={
        <FormControlInline
          error={errors?.name}
          value={name}
          required
          label={t('task:activity_issues.issue.name.label', 'Issue name')}
          labelIsHidden
        >
          <FieldInline
            disabled={disabled || (!hasAnyAdminRole && !isNewIssue)}
            value={name}
            inputRef={inputNameRef}
            onChange={(e) => onFieldChange({name: e.target.value})}
            placeholder={t('task:activity_issues.issue.name.placeholder', 'Name')}
          />
        </FormControlInline>
      }
      actions={
        (!hasAnyAdminRole && !isNewIssue) || disabled ? null : (
          <Dropdown
            menuWidth={120}
            viewportPosition="right"
            toggleElement={
              <CtrlButton color="tertiary" icon="more_vertical" iconOnly size="s">
                Settings
              </CtrlButton>
            }
          >
            <CtrlBtnOption
              size="s"
              onClick={onOpenIssue}
              title={t('task:activity_actions.menu.buttons.details', 'Details')}
              icon={<Icon name="edit" colorFill size={24} />}
            />
            <CtrlBtnOption
              onClick={() => withClickActionLoader(onDelete)}
              size="s"
              title={t('task:activity_issues.menu.buttons.delete', 'Delete')}
              icon={<Icon name="remove_from_trash" colorFill size={24} />}
            />
          </Dropdown>
        )
      }
    >
      <IssueForm
        data={issue}
        disabled={disabled || (!hasAnyAdminRole && !isNewIssue)}
        errors={errors}
        hasAdminRole={hasAnyAdminRole}
        loading={clickActionLoading || loading}
        onChange={onFieldChange}
        parent={parent}
        workers={workers}
      />
      {loading && <Loader />}
    </CtrlCard>
  );
};

export default Issue;
