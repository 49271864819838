import cn from 'classnames';
import {forwardRef, ReactNode, Ref, use} from 'react';

import {ToolbarContext} from './Toolbar';
import s from './Toolbar.module.scss';

export interface ToolbarSectionProps {
  children: ReactNode;
  className?: string;
  contentClassName?: string;
  index?: number;
  showSeparatorBefore?: boolean;
}

const ToolbarSectionRaw = (
  {children, className, contentClassName, index, showSeparatorBefore = false}: ToolbarSectionProps,
  ref: Ref<HTMLDivElement>,
) => {
  const {hiddenSeparatorIndices} = use(ToolbarContext);
  const isSeparatorVisible = !hiddenSeparatorIndices.has(index);
  const shouldShowSeparator = index !== undefined && isSeparatorVisible;

  const sectionStyles = cn(
    {
      [s.section]: true,
    },
    className,
  );

  const contentStyles = cn(
    {
      [s.separator]: shouldShowSeparator && !showSeparatorBefore,
      [s['separator--before']]: shouldShowSeparator && showSeparatorBefore,
      [s.content]: true,
    },
    contentClassName,
  );

  return (
    <div ref={ref} className={sectionStyles}>
      <div className={contentStyles}>{children}</div>
    </div>
  );
};

export const ToolbarSection = forwardRef(ToolbarSectionRaw);
ToolbarSection.displayName = 'Toolbar.Section';
