import {KeyboardEvent, forwardRef, useCallback, useMemo} from 'react';
import {type SelectInstance, components, type SingleValueProps, Props as SelectProps, ActionMeta} from 'react-select';
import type {FilterOptionOption as ReactSelectFilterOptionOption} from 'react-select/dist/declarations/src/filters';

import {taskStatusClassNameOverride} from 'shared/constants/statuses';
import {useTaskStatusOptions} from 'shared/hooks/UseTaskStatusOptions';
import {TaskStatusType} from 'shared/models/task/taskStatus';

import TaskStatusComponent from '../../../TaskStatus';
import {CoreSelect} from '../Select';
import {CoreOptionType} from '../types';

const STATUS_HEIGHT = 28;

const SingleValue = ({children, ...props}: SingleValueProps<CoreOptionType>) => {
  return (
    <components.SingleValue {...props}>
      <TaskStatusComponent value={props.data.value}>{children}</TaskStatusComponent>
    </components.SingleValue>
  );
};

type Props = {
  projectId?: string;
  allowedStatuses?: TaskStatusType[];
  value: string;
  onChange?: (value: string, actionMeta?: ActionMeta<CoreOptionType>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLDivElement>) => void;
  onMenuClose?: () => void;
  className?: string;
  size?: 'xs';
} & Omit<SelectProps<CoreOptionType, false>, 'value' | 'onChange'>;

export const TaskStatusSelect = forwardRef<SelectInstance<CoreOptionType, false>, Props>(
  ({className, allowedStatuses, filterOption, projectId, ...selectProps}, ref) => {
    const selectedValue = selectProps.value as string;
    const {workflowStatuses, isLoading, options} = useTaskStatusOptions({getById: projectId});

    const filterOptions = useCallback(
      (option: ReactSelectFilterOptionOption<CoreOptionType>, inputValue: string) => {
        return (
          (filterOption ? filterOption(option, inputValue) : true) &&
          (projectId ? workflowStatuses?.includes(option.data.value) : true)
        );
      },
      [filterOption, projectId, workflowStatuses],
    );

    const allowedOptions = useMemo(() => {
      const mutableOptions = options.map((opt) => ({...opt}));
      return allowedStatuses?.length
        ? mutableOptions.filter((op) => allowedStatuses.includes(op.value as TaskStatusType))
        : mutableOptions;
    }, [allowedStatuses, options]);

    const minMenuHeight = options.length * STATUS_HEIGHT;
    return (
      <CoreSelect
        className={`react-select react-select--status react-select--status-${
          taskStatusClassNameOverride[selectedValue] || selectedValue
        } ${className || ''}`}
        menuPlacement="auto"
        menuPosition="absolute"
        ref={ref}
        minMenuHeight={minMenuHeight}
        classNamePrefix="react-select"
        hideSelectedOptions
        isSearchable={false}
        filterOption={filterOptions}
        formatOptionLabel={({label, value}, {context}) =>
          context === 'menu' ? <TaskStatusComponent value={value} /> : <>{label}</>
        }
        options={allowedOptions}
        isLoading={isLoading}
        components={{SingleValue}}
        {...selectProps}
      />
    );
  },
);

TaskStatusSelect.displayName = 'TaskStatusSelect';
