// eslint-disable-next-line import/no-named-as-default
import {QRCodeSVG} from 'qrcode.react';
import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory} from 'react-router';

import Avatar from 'shared/components/Avatar';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {getNormalizedWorkerRoles, getNormalizedWorkerTrade, getWorkerFlyerPath} from 'shared/helpers/worker';
import {ProjectModel} from 'shared/models/project';
import {WorkerBlendedStatus} from 'shared/models/worker';
import {useAppDispatch} from 'store';
import {profileActions} from 'store/profile';

import ProfileStatus from '../ProfileStatus';

import s from './WorkerCard.module.scss';

export type WorkerCardProps = {
  projects: ProjectModel[];
  profile: {
    profileImage?: string;
    status?: string | WorkerBlendedStatus;
    mobileNumber?: string;
    fullName?: string;
    trade?: string;
    orgMappingIds?: string[];
    workerId?: string;
    roles?: string[];
    workerEmail?: string;
    showEmailAsContact: boolean;
  };
};

const WorkerCard: FC<WorkerCardProps> = ({projects, profile}) => {
  const isNewWorker = profile.workerId === 'new';
  const {t} = useTranslation('worker');
  const routes = useLocalizedRoutes();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const qrCodeLink = useMemo(() => {
    return isNewWorker ? window.location.href : getWorkerFlyerPath(profile.workerId);
  }, [isNewWorker, profile.workerId]);

  const goToTasks = (id) => {
    dispatch(profileActions.setMyProfileOpened(false));
    history.push(generatePath(routes.tasks, {projectId: id}));
  };

  return (
    <div>
      <section className="profile-info profile-info--view-summary">
        <div className="profile-info__container">
          <header className="profile-info__header">
            <Avatar src={profile.profileImage} className="profile-info__avatar" />
            <div className="profile-info__data" style={{minWidth: 0}}>
              <div className="profile-info__phone" style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                <a
                  className="profile-info__phone-number"
                  href={profile.showEmailAsContact ? `mailto:${profile.workerEmail}` : `tel:${profile.mobileNumber}`}
                >
                  {profile.showEmailAsContact ? profile.workerEmail : profile.mobileNumber}
                </a>
              </div>
              <ProfileStatus className="profile-info__status" status={profile.status as WorkerBlendedStatus} />
            </div>
          </header>
          <div className="profile-info__body">
            <div className="profile-info__item">
              <h2 className="profile-info__name">{profile.fullName}</h2>
              <div className="profile-info__trade">{getNormalizedWorkerTrade(profile?.trade, t)}</div>
              <div className="profile-info__trade">{getNormalizedWorkerRoles(profile?.roles, t)?.join(', ')}</div>
            </div>
            <div className="profile-info__item">
              <div className="profile-info__projects">
                <h3 className="profile-info__projects-title">{t('card.title', 'Projects')}</h3>
                <ul className="profile-info__projects-list">
                  {projects?.map((project) => {
                    return (
                      <li key={project?.id} className="profile-info__projects-item">
                        <button className={s.workerCard__button} type="button" onClick={() => goToTasks(project.id)}>
                          {project?.name}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="profile-info__item">
              <div className="profile-info__qr-box">
                <QRCodeSVG
                  value={qrCodeLink}
                  size={96}
                  style={isNewWorker || profile?.status === 'invited' ? {filter: 'blur(3px)'} : {}}
                />
              </div>
            </div>
            <div className="profile-info__item">
              <div className="profile-info__orgs">
                {profile.orgMappingIds?.map((org, i) => (
                  <span className="te-organization profile-info__orgs-item" key={i}>
                    {org}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default WorkerCard;
