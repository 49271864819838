import {GanttStatic} from 'dhtmlx-gantt';
import {useRef} from 'react';

import {useInlineSelectController} from 'modules/Tasks/components/Gantt/components/Editors/useInlineSelectController';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {CreatableSelect} from 'shared/components/CoreForm/Select/CreatableSelect/CreatableSelect';
import {useMount} from 'shared/hooks/core/useMount';
import {useOptionsForSelect} from 'shared/hooks/useOptionsForSelect';

type Props = {
  gantt: GanttStatic;
  task: GanttTask;
};

const LocationEditor = ({gantt, task}: Props) => {
  const selectRef = useRef(null);
  const {updateValue, onKeyUp, onKeyDown, onClose} = useInlineSelectController(gantt);
  const {locationOptions} = useOptionsForSelect(task.projectId);
  const options = locationOptions.filter((option) => option.value !== task.location);

  useMount(() => {
    selectRef.current.focus();
  });

  const onChange = async (value: string | null) => {
    if (task.location !== value) {
      updateValue(task, 'location', value);
    }
  };

  return (
    <CreatableSelect
      isClearable
      ref={selectRef}
      value={task.location}
      options={options}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMenuClose={onClose}
      menuIsOpen={true}
      closeMenuOnSelect={false}
      tabSelectsValue={false}
    />
  );
};

export default LocationEditor;
