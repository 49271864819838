import {CellContext} from '@tanstack/react-table';
import cn from 'classnames';

import {CompanyOrgs} from 'shared/models/company';

import CtrlColorPreview from '../CtrlColorPreview';

import s from './SubcontractorInfo.module.scss';
import {CompanyOrgsExtends} from 'modules/Subcontractors/types';

export type SubcontractorValue = {
  value: CompanyOrgs;
  color: string;
};

export type SubcontractorInfoProps = CellContext<CompanyOrgsExtends, SubcontractorValue>;

const SubcontractorInfo = ({getValue}: SubcontractorInfoProps) => {
  const {value, color} = getValue();

  return (
    <div className={cn(s.infoSubcontractor)}>
      <CtrlColorPreview color={color} abbr={value.abbrev} size="s" />
      <span className={s.infoSubcontractor__title}>{value?.group?.name}</span>
    </div>
  );
};

export default SubcontractorInfo;
