import {useFloating, offset, flip, shift} from '@floating-ui/react';
import cn from 'classnames';
import dayjs from 'dayjs';
import {FC, memo, useEffect, useMemo, useState} from 'react';
import {createPortal} from 'react-dom';

import CompletionAmountSlider from 'shared/components/CompletionAmountSlider/CompletionAmountSlider';
import DatePicker from 'shared/components/CoreForm/DatePicker';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import {useOutsideClick} from 'shared/hooks/core/useOusideClick';

import CtrlDates from '../../../CtrlDates/CtrlDates';
import s from '../../../DateDropdown/DateDropdown.module.scss';
import {ActiveControl} from '../Baseline/Baseline';

import style from './DateControl.module.scss';

type Props = {
  selectedDate: Date;
  onChangeDateRangeValue: (date: Date) => void;
  projectStartDate?: string;
  activeControl: ActiveControl;
};

const DateControl: FC<Props> = ({selectedDate, onChangeDateRangeValue, projectStartDate, activeControl}: Props) => {
  const today = useMemo(() => new Date(), []);
  const [sliderValue, setSliderValue] = useState(null);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [popperElement] = useState<HTMLDivElement>(null);

  useEffect(() => {
    if (sliderValue !== projectDays) setSliderValue(projectDays);
  }, [projectStartDate]);

  const projectDays = useMemo(() => {
    const days = dayjs(today).startOf('day').diff(dayjs(projectStartDate).startOf('day'), 'day');
    if (days === 0) {
      return 1;
    }
    return days || 0;
  }, [projectStartDate, today]);

  const calcSubPointsNumbers = (date: string) => {
    const isProjectStartThisMonth = dayjs(projectStartDate).isSame(today, 'month');
    const incompleteMonthLength = dayjs(today).subtract(1, 'month').isBefore(date);

    if (isProjectStartThisMonth) {
      const currentDay = dayjs(today).date();
      const startDay = dayjs(projectStartDate).date();
      return currentDay - startDay;
    }
    if (incompleteMonthLength) {
      return dayjs(today).startOf('day').diff(dayjs(date).startOf('day'), 'day');
    }
    return dayjs(date).add(1, 'month').diff(dayjs(date), 'day');
  };

  const getLabel = (date: string) => {
    return dayjs(date).format("MMM 'DD");
  };

  const preparedDate = (date: string) => ({
    label: calcSubPointsNumbers(date) > 10 ? getLabel(date) : '',
    subPointsNumbers: calcSubPointsNumbers(date),
  });

  const dates = useMemo(() => {
    const start = preparedDate(projectStartDate);
    const months = dayjs(today).diff(projectStartDate, 'month');
    const preparedToday = {
      label: 'Today',
      subPointsNumbers: 0,
    };
    const points = new Array(months).fill(months).map((_, index) => {
      const incrementedMonth = dayjs(projectStartDate).add(index + 1, 'month');
      return preparedDate(String(incrementedMonth));
    });
    if (projectDays === 1 && dayjs(projectStartDate).isSame(today, 'day')) return [preparedToday];
    return [start, ...points, preparedToday];
  }, [projectStartDate, today, projectDays]);

  const disabledNextButton = useMemo(() => {
    const nextDate = dayjs(selectedDate).startOf('day').add(1, 'day');
    return !dayjs(nextDate).isBefore(today);
  }, [selectedDate, today]);

  const disabledPrevButton = useMemo(() => {
    const prevDate = dayjs(selectedDate).subtract(1, 'day');
    return dayjs(prevDate).isBefore(projectStartDate);
  }, [selectedDate, projectStartDate]);

  const onPrevDay = () => {
    const prevDate = dayjs(selectedDate).subtract(1, 'day');
    onChangeDateRangeValue(prevDate.toDate());
    setSliderValue(sliderValue - 1);
  };

  const onNextDay = () => {
    const nextDate = dayjs(selectedDate).add(1, 'day');
    onChangeDateRangeValue(nextDate.toDate());
    setSliderValue(sliderValue + 1);
  };

  const onChangeDate = (date: Date) => {
    onChangeDateRangeValue(date);
    const difference = dayjs(date).diff(projectStartDate, 'day');
    setSliderValue(difference);
  };

  const onChangeSlider = (value: number) => {
    if (dayjs(projectStartDate).isSame(today, 'day')) return;
    setSliderValue(value);
    const calculatedDate = dayjs(projectStartDate).add(value, 'day').toDate();
    onChangeDateRangeValue(calculatedDate);
  };

  const onClickDate = () => {
    setShowDatepicker((prev) => !prev);
  };

  const {x, y, strategy, refs} = useFloating({
    placement: 'top-start',
    middleware: [offset({mainAxis: -10, crossAxis: 160}), flip(), shift()],
  });

  useOutsideClick({
    ref: popperElement ? {current: popperElement} : undefined,
    callback: () => setShowDatepicker(false),
  });

  const getTextComponent = () => {
    return (
      <>
        <div onClick={onClickDate}>{dayjs(selectedDate).format('ll')}</div>
        {showDatepicker &&
          createPortal(
            <div
              ref={refs.setFloating}
              className={s.dateDropdown}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 1100,
              }}
            >
              <div className={style.dateControl__datepicker}>
                <DatePicker
                  inline
                  isClearable={false}
                  dateFormat="dd/MM/yy"
                  maxDate={today}
                  minDate={dayjs(projectStartDate).toDate()}
                  onChange={onChangeDate}
                  selected={selectedDate}
                />
              </div>
            </div>,
            document.body,
          )}
      </>
    );
  };

  return (
    <div
      ref={refs.setReference}
      className={cn(style.dateControl, {
        [style.dateControl__disable]: activeControl !== ActiveControl.datePicker,
      })}
    >
      <CtrlDates
        prevButton={
          <CtrlButton
            color="clear"
            size="xs"
            icon="chevron-left"
            iconOnly={true}
            onClick={onPrevDay}
            disabled={disabledPrevButton}
          >
            Prev
          </CtrlButton>
        }
        nextButton={
          <CtrlButton
            color="clear"
            size="xs"
            icon="chevron-right"
            iconOnly={true}
            onClick={onNextDay}
            disabled={disabledNextButton}
          >
            Next
          </CtrlButton>
        }
        text={getTextComponent()}
      />
      <CompletionAmountSlider
        onChange={onChangeSlider}
        value={sliderValue}
        max={projectDays}
        min={0}
        step={1}
        points={dates}
      />
    </div>
  );
};

export default memo(DateControl);
