import * as Sentry from '@sentry/browser';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import dayjs from 'dayjs';
import {FieldProps, Form, Formik, FormikHelpers} from 'formik';
import {Fragment, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';

import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import Icon from 'shared/components/Icon';
import {Pill} from 'shared/components/Pill/Pill';
import Popup from 'shared/components/Popup/Popup';
import {useInitializeMatrixSession} from 'shared/components/ProgressReport/hooks/useInitializeMatrixSession';
import Tooltip from 'shared/components/Tooltip';
import {IconsMap} from 'shared/constants/icons';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {formatDate} from 'shared/helpers/dates';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {MessageTypeMap} from 'shared/models/feedback';

import {commentFormValidationSchema} from './utils/commentFormValidationSchema';
import {GeneralCommentUpdates, handlePostGeneralComments} from './utils/handlePostGeneralComments';

const initialValues = {
  comment: '',
  images: [] as File[],
};

type CommentFormValues = typeof initialValues;

export function DailiesCommentForm() {
  const {t} = useTranslation(['dailies', 'comments']);
  const [showForm, setShowForm] = useState(false);
  const {projectId} = useParams<{projectId: string}>();
  const imageInput = useRef<HTMLInputElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const {data} = useInitializeMatrixSession();
  const queryClient = useQueryClient();
  const {queryParams} = useFilterContext();
  const {
    mixpanel: {events, trackWithAction, track},
  } = useAnalyticsService();

  const handleOpenForm = () => {
    trackWithAction(() => setShowForm(!showForm), events.dailies.clickAddGeneralComments);
  };

  const {mutate: onSubmitComments, isPending} = useMutation({
    mutationFn: (updates: GeneralCommentUpdates) => handlePostGeneralComments(updates, data.accessToken, projectId),
  });

  const onSubmit = (values: CommentFormValues, helpers: FormikHelpers<CommentFormValues>) => {
    track(events.dailies.dailiesGeneralCommentsSubmitButton);
    const {setTouched, resetForm, setErrors, setSubmitting} = helpers;
    const formData: GeneralCommentUpdates = {
      comment: {
        comment: values.comment,
        dateTag: dayjs(queryParams.schedEndFirst).toDate(),
        feedbackType: MessageTypeMap.message,
        flagAsPotentialIssue: false,
      },
      image: {
        images: Array.from(values.images),
        dateTag: dayjs(queryParams.schedEndFirst).toDate(),
        feedbackType: MessageTypeMap.image,
      },
    };
    onSubmitComments(formData, {
      onSuccess: () => {
        setTouched({
          comment: false,
          images: [],
        });
        resetForm();
        setErrors({});
        setSubmitting(false);
        setShowForm(false);
        queryClient.invalidateQueries({
          queryKey: [QUERY_CACHE_KEYS.dailiesGeneralComments],
        });
        toast.success(t('dailies:dailies_report.general_comments_form.success'));
      },
      onError: (err: unknown) => {
        Sentry.captureException(err, {tags: {feature: 'dailies-general-comment-form'}});
        toast.error(t('dailies:dailies_report.general_comments_form.failed'));
      },
    });
  };

  return (
    <>
      <Tooltip text={t('dailies:dailies_report.general_comments_form.tooltip')} placement="top">
        <div className="daily-report__add-comment-btn-wrapper">
          <CtrlButton
            className="daily-report__add-comment-btn"
            icon={IconsMap.plus}
            onClick={handleOpenForm}
            size="xs"
            type="button"
          >
            {t('dailies:dailies_report.general_comments_form.button')}
          </CtrlButton>
        </div>
      </Tooltip>
      <Popup className="generalCommentPopup" isCloseButtonInside onClose={handleOpenForm} visible={showForm}>
        <Popup.Header>
          <h3>
            {t('dailies:dailies_report.general_comments.title', {
              date: formatDate(queryParams.schedEndFirst, 'LL'),
            })}
          </h3>
        </Popup.Header>
        <Popup.Body isLoading={isPending}>
          <Formik<CommentFormValues>
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={commentFormValidationSchema(t)}
          >
            {({isSubmitting, setValues, setFieldValue, setFieldError, values, errors}) => {
              const oversizedFileNames = values.images?.reduce((acc, file, index) => {
                if (errors.images && errors.images[index]) {
                  // Checking if there's a size error for this file
                  acc.push(file.name); // Add the file name to the accumulator
                }
                return acc;
              }, []);
              return (
                <Form>
                  <div>
                    <div className="generalCommentsInputContainer">
                      <FormControl.Formik
                        name="images"
                        label={t('comments:activity_panel.comment_form.images.label')}
                        errorClassName="error-message-hidden"
                      >
                        {({field: {name, value}}: FieldProps<File[]>) => (
                          <>
                            <input
                              accept="image/jpeg, image/jpg, image/png"
                              hidden
                              multiple
                              name={name}
                              onChange={(event) => {
                                const newFiles = Array.from(event.currentTarget.files as FileList);
                                // Filter out duplicate files
                                const existingFileNames = new Set<string>(value.map((file) => file.name));
                                const uniqueFiles = newFiles.filter((file) => !existingFileNames.has(file.name));
                                setFieldValue(name, [...value, ...uniqueFiles]);
                                track(events.dailies.dailiesGeneralCommentsAttachPhoto);
                                // Reset the file input value or you will never be able to select another file 💀
                                event.currentTarget.value = '';
                              }}
                              ref={imageInput}
                              type="file"
                            />
                            <CtrlButton
                              type="button"
                              className="imageUploadBtn"
                              color="second"
                              size="s"
                              shadow
                              icon={IconsMap.image}
                              onClick={() => {
                                imageInput.current?.click();
                              }}
                              disabled={isSubmitting}
                            >
                              {t('comments:activity_panel.comment_form.images.cta')}
                            </CtrlButton>
                          </>
                        )}
                      </FormControl.Formik>
                      <div className="fileListContainer">
                        {values.images?.map((file, index) => {
                          const hasError = oversizedFileNames.includes(file.name);
                          const pillColor = hasError ? 'red' : null;

                          return (
                            <Fragment key={file.name + index}>
                              <Pill
                                color={pillColor}
                                onClick={() => {
                                  setValues({
                                    ...values,
                                    images: values.images.filter((_, i) => i !== index),
                                  });
                                  setFieldError(`files[${index}]`, undefined);
                                }}
                                icon={<Icon name={IconsMap.delete} />}
                              >
                                {file.name}
                              </Pill>
                            </Fragment>
                          );
                        })}
                      </div>
                      <FormControl.Formik
                        name="comment"
                        label={t('comments:activity_panel.comment_form.comment.label')}
                        errorClassName="error-message"
                      >
                        {({
                          field: {name, value, onChange, ...fieldProps},
                          form: {setFieldTouched},
                        }: FieldProps<string>) => (
                          <textarea
                            {...fieldProps}
                            ref={textAreaRef}
                            className="generalCommentFormInput"
                            onKeyDown={() => setFieldTouched(name, true)}
                            onChange={(e) => {
                              onChange(e);
                              // forces the text area to grow as a new line of text is added
                              textAreaRef.current.style.height = '16px';
                              textAreaRef.current.style.height = `${e.target.scrollHeight}px`;
                            }}
                            name={name}
                            value={value}
                          />
                        )}
                      </FormControl.Formik>
                      {errors['comment-or-files-required'] ? (
                        <span className="error-message">{errors['comment-or-files-required']}</span>
                      ) : null}
                    </div>
                    <Tooltip text={t('comments:activity_panel.comment_form.form_submit.cta')}>
                      <CtrlButton
                        type="submit"
                        aira-label="Submit"
                        className="commentSubmitFormBtn"
                        size="s"
                        shadow
                        icon={IconsMap.submit}
                        disabled={isSubmitting}
                      >
                        {t('dailies:dailies_report.general_comments_form.button')}
                      </CtrlButton>
                    </Tooltip>
                  </div>
                  {oversizedFileNames?.length > 0 ? (
                    <span className="error-message">
                      {t('comments:activity_panel.comment_form.validation.file_size_error', {
                        files: oversizedFileNames.join(', '),
                      })}
                    </span>
                  ) : null}
                </Form>
              );
            }}
          </Formik>
        </Popup.Body>
      </Popup>
    </>
  );
}
