import cn from 'classnames';
import {GanttStatic} from 'dhtmlx-gantt';
import {useTranslation} from 'react-i18next';

import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import WbsSection from 'modules/Tasks/components/SidebarPanel/components/WbsSection/WbsSection';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import FieldInline from 'shared/components/CoreNewUI/FormControlInline/FieldInline/FieldInline';
import FormControlInline from 'shared/components/CoreNewUI/FormControlInline/FormControlInline';
import SkeletonPreloader from 'shared/components/SkeletonPreloader';
import Tooltip from 'shared/components/Tooltip';
import {toTitleCase} from 'shared/helpers/common';
import {useMount} from 'shared/hooks/core/useMount';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {ProjectModel} from 'shared/models/project';
import {TaskObjectType} from 'shared/models/task/const';
import {TaskDetailsModelDTO} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';

import {AVAILABLE_FIELDS_IN_READONLY_VIEW} from '../../utils/constants';
import ActionsList from '../ActionsList/ActionsList';
import ActivityForm from '../ActivityForm/ActivityForm';
import DependenciesList from '../DependenciesList/DependenciesList';
import HistoryList from '../HistoryList/HistoryList';
import IssuesList from '../IssuesList/IssuesList';
import PanelSection from '../PanelSection/PanelSection';

import s from './PanelInfo.module.scss';

type PanelInfoProps = {
  gantt: GanttStatic;
  className?: string;
  activity?: TaskDetailsModelDTO;
  parent?: TaskDetailsModelDTO;
  onDelete: () => void;
  onRestore: () => void;
  onShare: () => void;
  onOpenAction: (id: string) => void;
  loading?: boolean;
  project?: ProjectModel;
  onOpenIssue: (id: string) => void;
};

const PanelInfo = ({
  gantt,
  className,
  onShare,
  onRestore,
  onDelete,
  loading,
  activity,
  parent,
  project,
  onOpenAction,
  onOpenIssue,
}: PanelInfoProps) => {
  const deleted = !!activity?.timeRemoved;
  const archived = activity?.status === TaskStatusType.archived;
  const disabled = deleted || archived || loading;

  const {t} = useTranslation(['task', 'common']);
  const isActivity = [TaskObjectType.activity, TaskObjectType.task].includes(activity?.objectType);
  const isMilestone = activity?.objectType === TaskObjectType.milestone;

  const {viewMode} = useFilterContext();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId: project.id, viewMode}});
  const mixpanelEvents = mixpanel.events.task;

  useMount(() => {
    mixpanel.track(mixpanelEvents.actions.taskDetailsTabLand, {taskType: activity?.objectType});
  });

  const {hasAnyAdminRole} = useCompanyWorkerRoles(activity?.projectId);

  return (
    <section className={cn(s.panelInfo, className)}>
      <header className={s.panelInfo__header}>
        <FormControlInline.Formik
          className={s.panelInfo__title}
          label={t('task:task_form.form.name.label', 'Activity Name')}
          labelIsHidden
          name="name"
        >
          {({field}) => (
            <SkeletonPreloader when={loading}>
              <FieldInline
                disabled={!hasAnyAdminRole && !AVAILABLE_FIELDS_IN_READONLY_VIEW.has(field.name)}
                element="textarea"
                placeholder={t('task:task_form.form.name.placeholder', 'Enter Activity Name')}
                {...field}
              />
            </SkeletonPreloader>
          )}
        </FormControlInline.Formik>

        <div className={s.panelInfo__headerActions}>
          <Tooltip text={t('task:task_form.tooltips.share', 'Share')} placement="bottom">
            <CtrlButton
              iconOnly
              className={s.panelInfo__headerButton}
              color="second"
              size="s"
              shadow
              icon="share"
              onClick={onShare}
              disabled={!hasAnyAdminRole || loading || !activity}
            />
          </Tooltip>
          {!deleted ? (
            <Tooltip text={t('task:task_form.tooltips.delete', 'Delete')} placement="bottom">
              <CtrlButton
                data-cy="panel-activity-info-delete-btn"
                iconOnly
                className={s.panelInfo__headerButton}
                color="second"
                size="s"
                shadow
                icon="delete"
                onClick={onDelete}
                disabled={!hasAnyAdminRole || loading || !activity}
              />
            </Tooltip>
          ) : (
            <Tooltip text={t('task:task_form.tooltips.undelete', 'Undelete')} placement="bottom">
              <CtrlButton
                iconOnly
                className={s.panelInfo__headerButton}
                color="second"
                size="s"
                shadow
                icon="restore"
                onClick={onRestore}
                disabled={!hasAnyAdminRole || loading}
              />
            </Tooltip>
          )}
        </div>
      </header>
      <div className={s.panelInfo__body}>
        <ActivityForm
          taskId={activity?.id}
          loading={loading}
          deleted={deleted}
          archived={archived}
          isWbs={activity?.objectType === TaskObjectType.summary}
        />
        {parent && <WbsSection wbs={parent} loading={!parent} />}
        {(isActivity || isMilestone) && (
          <DependenciesList taskId={activity?.id} disabled={!hasAnyAdminRole || disabled} projectId={project?.id} />
        )}
        {isActivity && (hasAnyAdminRole || !!activity?.subtaskCount) && (
          <ActionsList
            parent={activity}
            projectId={activity?.projectId}
            onOpenAction={onOpenAction}
            disabled={!hasAnyAdminRole || disabled}
          />
        )}
        {isActivity && (
          <IssuesList
            key={activity?.id}
            gantt={gantt}
            parent={activity as TaskDetailsModelDTO}
            projectId={activity?.projectId}
            onOpenIssue={onOpenIssue}
            disabled={disabled}
          />
        )}
        <PanelSection
          title={t('task:task_form.history', `{{type}} history`, {
            type: toTitleCase(t(`common:${activity?.objectType || TaskObjectType.activity}`)),
          })}
          description={!activity?.statusHistory?.length && 'History is empty for now.'}
        >
          <HistoryList history={activity?.statusHistory} timezone={project?.timezone} />
        </PanelSection>
      </div>
    </section>
  );
};

export default PanelInfo;
