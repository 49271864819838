import {type VirtualElement} from '@floating-ui/core';
import {GanttStatic} from 'dhtmlx-gantt';
import {useEffect} from 'react';

export type ReferenceType = string | HTMLElement | VirtualElement;

export function useHideContextOnGanttScroll(reference: ReferenceType, gantt: GanttStatic, callback: () => void) {
  useEffect(() => {
    let id;
    const referenceElement = typeof reference !== 'string' ? reference : document.querySelector(reference);
    if (reference && referenceElement) {
      const onScroll = (left: number, top: number) => {
        if (referenceElement && gantt.getScrollState().y !== top) {
          callback();
        }
      };
      id = gantt.attachEvent('onGanttScroll', onScroll, undefined);
    }
    return () => {
      id && gantt.detachEvent(id);
    };
  }, [reference, callback, gantt]);
}
