import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';

import {CompanyWorker} from 'shared/models/worker';
import {RootState} from 'store';
import {logoutUser} from 'store/actions';

import {getCompanyWorkers} from './actions';

type StoredCompanyWorker = Omit<CompanyWorker, 'id'> & {id: string};

const adapter = createEntityAdapter<StoredCompanyWorker>();

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'workers',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanyWorkers.fulfilled, (state, {payload}) => {
      const storedWorkers = payload.filter((worker): worker is StoredCompanyWorker => {
        return worker.id !== undefined;
      });

      const res = adapter.upsertMany(state, storedWorkers);
      state.entities = res.entities;
      state.ids = res.ids;
    });
    builder.addCase(logoutUser, () => adapter.getInitialState());
  },
});

export default slice.reducer;
export const {selectAll: selectAllWorkers, selectEntities: selectWorkersEntities} = adapter.getSelectors<RootState>(
  (state) => state.workers,
);
