import {Action, configureStore, Middleware, ThunkAction} from '@reduxjs/toolkit';
import {useDispatch, TypedUseSelectorHook, useSelector} from 'react-redux';
import {createLogger} from 'redux-logger';

import reducers from './ducks';
import onboardingReducer from './onboarding';
import profileReducer from './profile';
import projectsReducer from './projects';
import tasksReducer from './tasks';
import workersReducer from './workers';

const logger = createLogger({
  collapsed: true,
}) as Middleware;

const store = configureStore({
  reducer: {
    ...reducers,
    tasks: tasksReducer,
    workers: workersReducer,
    projects: projectsReducer,
    profile: profileReducer,
    onboarding: onboardingReducer,
  },
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'production') {
      return getDefaultMiddleware();
    }
    return getDefaultMiddleware().concat(logger);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// backward compatibility
export type RootDispatch = AppDispatch;
export const useRootDispatch = useAppDispatch;
export const useRootSelector = useAppSelector;

export default store;
