import {keepPreviousData, useQuery} from '@tanstack/react-query';
import dayjs from 'dayjs';

import FeedbackService from 'api/feedback';
import TasksApi from 'api/tasks';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {SortOrder} from 'shared/constants/common';
import {GC_TIME, QUERY_CACHE_KEYS, STALE_TIME} from 'shared/constants/queryCache';
import {useProjectWorkers} from 'shared/hooks/useProjectWorkers';
import {TaskObjectType, TaskProjection, SortField} from 'shared/models/task/const';
import {FeedbackProjectModelDTO, TaskDetailsModelDTO} from 'shared/models/task/task';

import {TasksWithComments} from './useCurrentDateReports';
import {mergeFeedbackIntoTask} from './utils/mergeFeedback';

export function useFetchDailiesTasks(projectId: string) {
  const {queryParams} = useFilterContext();
  const {projectWorkers} = useProjectWorkers();
  const dateForReport = dayjs(queryParams.schedEndFirst);
  const startOfDay = dateForReport.startOf('day');
  const endOfDay = dateForReport.endOf('day');
  const schedIntersect: [string, string] = [startOfDay.toISOString(), endOfDay.toISOString()];

  const tasksQuery = useQuery<TaskDetailsModelDTO[]>({
    queryKey: QUERY_CACHE_KEYS.dailiesReport(projectId, queryParams),
    queryFn: async () => {
      const objectTypeList = [TaskObjectType.activity, TaskObjectType.task];
      const response = await TasksApi.getProjectTasks({
        projection: TaskProjection.taskDetail,
        includeSummaryTasks: true,
        params: {
          ...queryParams,
          projectId,
          objectTypeList,
          schedIntersect,
        },
        sortOrder: SortOrder.ASC,
        sortField: SortField.outlineSortKey,
      });

      return response.data as TaskDetailsModelDTO[];
    },
    placeholderData: keepPreviousData,
    // overwrite default cache time
    staleTime: STALE_TIME,
    gcTime: GC_TIME,
  });

  // Fetch all feedback for the project
  const feedbackQuery = useQuery<FeedbackProjectModelDTO[]>({
    queryKey: QUERY_CACHE_KEYS.projectFeedback(projectId),
    queryFn: () => FeedbackService.getAllFeedBackForProject(projectId, startOfDay.toDate()),
    enabled: !!tasksQuery.data,
    // overwrite default cache time
    staleTime: STALE_TIME,
    gcTime: GC_TIME,
  });

  // Determine if both queries have completed
  const isLoading = tasksQuery.isLoading || feedbackQuery.isLoading;

  // Combine tasks with their feedback once all data has been fetched
  const tasksWithFeedback = tasksQuery.data?.map((task) => {
    if (feedbackQuery.isSuccess && feedbackQuery.data) {
      // Filter feedback for this specific task
      const taskFeedback = feedbackQuery.data.filter((feedback) => feedback.taskId === task.id);

      if (taskFeedback.length > 0) {
        const {feedbackByDate, comments} = mergeFeedbackIntoTask(task, taskFeedback, projectWorkers, schedIntersect);
        return {
          ...task,
          feedbackByDate,
          comments,
        };
      }
    }
    return task; // Return task as is if no feedback available
  });

  return {
    ...tasksQuery,
    isLoading,
    data: isLoading ? undefined : (tasksWithFeedback as TasksWithComments[]),
  };
}
