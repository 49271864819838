import cn from 'classnames';
import {Children, cloneElement, CSSProperties, FC, isValidElement, PropsWithChildren, ReactElement} from 'react';

import s from './Toolbar.module.scss';

type ToolbarPartProps = {
  withBorder?: boolean;
  isSearchInput?: boolean;
  abyss?: boolean;
};

const ToolbarPart: FC<ToolbarPartProps> = ({
  withBorder = true,
  isSearchInput = false,
  abyss = false,
  children,
}: PropsWithChildren<ToolbarPartProps>) => {
  return (
    <div
      className={cn(s.toolbar__part, {
        [s.toolbar__part_without_border]: !withBorder,
        [s.toolbar__part_abyss]: abyss,
        [s.toolbar__part_search]: isSearchInput,
      })}
    >
      {Children.toArray(children).map<ReactElement>(
        (child) =>
          isValidElement<{className?: string}>(child) &&
          cloneElement(child, {...child.props, className: cn(s.toolbar__item, child.props.className)}),
      )}
    </div>
  );
};

type ToolbarProps = {
  className?: string;
  style?: CSSProperties;
  spaceBetweenParts?: boolean;
};

const Toolbar: FC<PropsWithChildren<ToolbarProps>> & {
  Part: FC<PropsWithChildren<ToolbarPartProps>>;
} = ({children, className, spaceBetweenParts = true}) => {
  return (
    <div className={cn(s.toolbar, className)} style={spaceBetweenParts ? {justifyContent: 'space-between'} : null}>
      {children}
    </div>
  );
};

Toolbar.Part = ToolbarPart;

export default Toolbar;
