import classNames from 'classnames';
import {FC, PropsWithChildren, useEffect, useState} from 'react';

import {useTabsContext} from './TabsContext';
import {TabProps} from './types';

const Tab: FC<PropsWithChildren<TabProps>> = ({index, className, children, onClick, height, disabled}) => {
  const {activeTab} = useTabsContext();
  const [initialized, setInitialized] = useState(false);
  const isCurrentTab = activeTab === index;
  useEffect(() => {
    if (!initialized && !disabled) {
      setInitialized(activeTab === index);
    }
  }, [initialized, disabled, setInitialized, index, activeTab]);

  return isCurrentTab && initialized ? (
    <div
      className={classNames('slide-panel__tab', className)}
      style={isCurrentTab ? {height: height} : {display: 'none'}}
      onClick={() => onClick && onClick()}
    >
      {children}
    </div>
  ) : null;
};
export default Tab;
