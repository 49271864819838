import {useQueryClient} from '@tanstack/react-query';
import {useRef} from 'react';

import {QueryCacheHelper} from './QueryCacheHelper';

export function useQueryCache() {
  const queryClient = useQueryClient();
  const queryCacheHelper = useRef(new QueryCacheHelper(queryClient));
  return {queryClient, cacheHelper: queryCacheHelper.current};
}
