import {CellContext} from '@tanstack/react-table';
import cn from 'classnames';

import Avatar from 'shared/components/Avatar';
import {CompanyWorker, Worker} from 'shared/models/worker';

import s from './NameColumn.module.scss';

type NameColumnProps = CellContext<CompanyWorker, Worker>;

export const NameColumn = ({getValue}: NameColumnProps) => {
  const value = getValue();

  return (
    <div className={cn(s.nameColumn)}>
      <Avatar className={s.nameColumn__photo} src={value?.profilePicUrl} />
      <div className={s.nameColumn__name}>{value?.fullName || value?.email}</div>
    </div>
  );
};
