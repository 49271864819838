import cn from 'classnames';
import {FC, PropsWithChildren, ReactElement, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

import {ActionButtonConfig} from 'shared/components/Confirmation/useConfirm/state';
import {usePopupScrollHandler} from 'shared/hooks/usePopupScrollHandler';

import Button from '../Button';
import Icon from '../Icon';

import ActionButton from './components/ActionButton';
import Description from './components/Description';

export type ConfirmationProps = {
  title?: string;
  description?: ReactNode;
  onAccept?: () => void;
  onReject?: () => void;
  onClose?: () => void;
  acceptButton?: string | ActionButtonConfig;
  cancelButton?: string | ActionButtonConfig;
  field?: ReactElement;
  isDisabledAcceptBtn?: boolean;
  className?: string;
  iconName?: string;
};

const getActionButtonProps = (config: string | ActionButtonConfig) => {
  return typeof config === 'string' ? {title: config} : config;
};

const Confirmation: FC<PropsWithChildren<ConfirmationProps>> = ({
  acceptButton,
  cancelButton,
  children,
  className,
  description,
  field = null,
  iconName,
  isDisabledAcceptBtn = false,
  onAccept,
  onClose,
  onReject,
  title,
}) => {
  usePopupScrollHandler();
  const {t} = useTranslation('common');
  const cb = cancelButton || t('confirm.cancel', 'Cancel');
  const ab = acceptButton || t('confirm.accept', 'OK');

  const handleAccept = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onAccept?.();
  };

  const handleReject = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onReject?.();
  };

  return (
    <section className={cn('confirmation', className)} onMouseDown={(event) => event.stopPropagation()}>
      <div className="confirmation__item">
        <header className="confirmation__header">
          {iconName ? <Icon name={iconName} size={36} /> : null}
          {!!title && <h2 className="confirmation__title">{title}</h2>}
          {!!onClose && (
            <Button
              data-cy="btnConfirmClose"
              iconOnly
              className="confirmation__button-close"
              type="button"
              onClick={onClose}
              icon={<Icon name="clear" colorFill className="ctrl-btn-clear__icon" size={24} />}
            >
              Close
            </Button>
          )}
          {!!description && <Description description={description} />}
        </header>
        {field && (
          <div className="confirmation__form">
            <div className="confirmation__form-item">
              <div className="ctrl-form">
                <div className="ctrl-form__body">{field}</div>
              </div>
            </div>
          </div>
        )}
        {children}
        <footer className="confirmation__footer">
          {!!(cb && onReject) ? (
            <ActionButton
              onClick={handleReject}
              className="ctrl-btn--color-back ctrl-btn--shadow confirmation__button"
              dataCy="btnConfirmCancel"
              {...getActionButtonProps(cb)}
            />
          ) : null}
          {!!(ab && onAccept) ? (
            <ActionButton
              onClick={handleAccept}
              isDisabled={isDisabledAcceptBtn}
              className="confirmation__button"
              dataCy="btnConfirmOk"
              {...getActionButtonProps(ab)}
            />
          ) : null}
        </footer>
      </div>
    </section>
  );
};
export default Confirmation;
