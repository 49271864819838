import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ExtractRouteParams} from 'react-router';

const RAW_ROUTES = {
  collaboration: '/collaboration/:collabId/:workerId',
  reports: '/reports',
  demoContact: '/demo-contact',
  enterpriseContact: '/enterprise-contact',
  landing: '/',
  logIn: '/login',
  project: '/projects/:id',
  projects: '/projects',
  companyProfile: '/company-profile',
  profile: '/profile',
  signIn: '/signin',
  signUpAltA: '/signup/alt-a',
  getStarted: '/get-started',
  task: '/projects/:projectId/tasks/:id',
  tasks: '/projects/:projectId/tasks',
  dailyRisk: '/projects/:projectId/dailyRisk',
  worker: '/workers/:id',
  workers: '/workers',
  subcontractors: '/projects/:projectId/subcontractors',
  subcontractor: '/projects/:projectId/subcontractors/:id',
  projectWorkers: '/projects/:projectId/project-workers',
  projectWorker: '/projects/:projectId/project-workers/:id',
  ganttDemoTask: '/projects/:projectId/gantt-demo/tasks/:id',
  sharedTask: '/sharedtask/:id',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  applyAuthCode: '/auth/applycode',
  inviteSignup: '/invite_signup',
  oauth: '/oauth',
  mobileAccountComplete: '/account-complete',
  computerStart: '/computer-start',
} as const;

type RoutesKey = keyof typeof RAW_ROUTES;
type Language = 'en' | 'es' | 'pt' | 'ja';

function prefixRoutes<P extends string>(prefix: P) {
  return Object.entries(RAW_ROUTES).reduce((acc, [key, value]) => {
    return Object.assign(acc, {[key]: `${prefix}${value}`});
  }, {} as {[key in RoutesKey]: `${typeof prefix}${(typeof RAW_ROUTES)[key]}`});
}

export const ROUTES = prefixRoutes('/:locale(es|pt|ja)?');

export const useLocalizedRoutes = () => {
  const {
    i18n: {language},
  } = useTranslation();

  const normalizedLang = language.split('-')[0] as Language; // Converts 'en-US' → 'en'

  return useMemo(() => {
    const languagePref = normalizedLang === 'en' ? '' : `/${normalizedLang}`;
    return prefixRoutes(languagePref);
  }, [normalizedLang]);
};

export type RouteParams = {
  tasks: ExtractRouteParams<(typeof ROUTES)['tasks'], string>;
};
