import {GanttStatic} from 'dhtmlx-gantt';
import {FC, useRef} from 'react';

import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {CoreSelect} from 'shared/components/CoreForm/Select/Select';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import {getProjectCustomField} from 'shared/helpers/project';
import {useMount} from 'shared/hooks/core/useMount';
import {useProject} from 'shared/hooks/useProject';
import {ProjectCustomFieldType} from 'shared/models/project';

import {updateTaskCustomFieldValue} from '../../helpers';
import {useInlineSelectController} from '../../useInlineSelectController';

import s from './CustomFieldSelectorEditor.module.scss';

type Props = {
  gantt: GanttStatic;
  task: GanttTask;
  fieldName: string;
};

export const CustomFieldSelectEditor: FC<Props> = ({gantt, task, fieldName}: Props) => {
  const {onKeyUp, onKeyDown, onClose, focusOrHide} = useInlineSelectController(gantt);
  const {project} = useProject(task.projectId);
  const selectRef = useRef(null);

  const projectField = getProjectCustomField(project, fieldName);
  const isMulti = projectField?.fieldType === ProjectCustomFieldType.multiselect;
  const taskField = task.custom_fields.find(({internal_field_name}) => internal_field_name === fieldName);

  useMount(() => {
    selectRef.current?.focus();
  });

  const getSelectOptions = (): CoreOptionType[] => {
    if (!projectField?.fieldData) return [];
    return JSON.parse(projectField.fieldData).map((value: string) => ({
      value,
      label: value,
    }));
  };

  const handleChange = async (value: string | string[]) => {
    const prepared = JSON.stringify(Array.isArray(value) ? value : [value]);
    updateTaskCustomFieldValue(task, {field: fieldName, value: prepared});
    gantt.updateTask(task.id);
    focusOrHide();
  };

  const getValue = () => {
    if (!taskField?.value) return isMulti ? [] : '';
    const value = JSON.parse(taskField.value);
    return isMulti ? value : value[0] || '';
  };

  const commonProps = {
    className: s.customFieldSelector,
    isClearable: true,
    ref: selectRef,
    options: getSelectOptions(),
    onKeyDown,
    onKeyUp,
    onMenuClose: onClose,
    menuIsOpen: true,
    closeMenuOnSelect: false,
    tabSelectsValue: false,
  };

  return <CoreSelect {...commonProps} value={getValue()} onChange={handleChange} />;
};
