import {offset, arrow, flip, shift, useFloating, Placement} from '@floating-ui/react';
import cn from 'classnames';
import {
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {createPortal} from 'react-dom';

import classes from './tooltip.module.scss';

type TooltipProps<T extends HTMLElement = HTMLElement> = {
  title?: string;
  text: ReactNode;
  children: ReactElement<{ref: Ref<T>}>;
  placement?: Placement;
  type?: 'error' | 'edit' | 'help';
  isHide?: boolean;
  showAfterMs?: number;
};

const Tooltip = <T extends HTMLElement>({
  text,
  title,
  placement = 'top',
  type,
  children,
  isHide,
  showAfterMs = 0,
}: TooltipProps<T>) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const arrowRef = useRef<HTMLDivElement | null>(null);

  const {
    x,
    y,
    strategy,
    middlewareData: {arrow: arrowData},
    refs,
  } = useFloating({
    placement,
    middleware: [
      offset(5),
      flip(),
      shift((state) => ({
        padding: state.rects.reference.width,
      })),
      arrow((state) => ({
        element: arrowRef,
        padding: state.rects.reference.width,
      })),
    ],
  });

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleShow = useCallback(() => {
    if (!showAfterMs) {
      setShowTooltip(true);
      return;
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setShowTooltip(true);
    }, showAfterMs);
  }, [showAfterMs]);

  const handleHide = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setShowTooltip(false);
  };

  useEffect(() => {
    const refEl = refs.reference.current;
    if (refEl instanceof HTMLElement) {
      refEl.addEventListener('mouseenter', handleShow);
      refEl.addEventListener('mouseleave', handleHide);
    }

    return () => {
      if (refEl instanceof HTMLElement) {
        refEl.removeEventListener('mouseenter', handleShow);
        refEl.removeEventListener('mouseleave', handleHide);
      }
    };
  }, [showAfterMs, refs.reference, handleShow]);

  if (isHide) return children;

  return (
    <>
      {isValidElement(children) && cloneElement(children, {ref: refs.setReference})}

      {showTooltip &&
        createPortal(
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              zIndex: 9999,
            }}
            className={cn('react-tooltip', classes['react-tooltip'], classes[`react-tooltip--${type}`])}
            data-popper-placement={placement} // Ensures arrow placement styles are applied
          >
            {title && <div className="react-tooltip__title">{title}</div>}
            <div className="react-tooltip__text">{text}</div>
            <div
              ref={arrowRef}
              className="react-tooltip__arrow"
              style={{
                position: 'absolute',
                left: arrowData?.x != null ? `${arrowData.x}px` : '',
                top: arrowData?.y != null ? `${arrowData.y}px` : '',
                transform: 'rotate(45deg)',
              }}
            />
          </div>,
          document.body,
        )}
    </>
  );
};

export default Tooltip;
