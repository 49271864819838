import {useQuery} from '@tanstack/react-query';
import {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router';
import Select from 'react-select';

import ProjectsApi from 'api/projects';
import {filterDuplicatedCompanyWorkers} from 'shared/helpers/worker';
import {useCompany} from 'shared/hooks/useCompany';
import {CompanyWorker} from 'shared/models/worker';

import {QUERY_CACHE_KEYS} from '../../../../constants/queryCache';

import {MemberOption, SingleMemberOption} from './components';
import s from './index.module.scss';

const ENTITY_LIMIT = 1000;
const SORT_PARAMS = {
  field: 'fullName',
  order: 'ASC',
};

export type MemberOptionType = {
  label: string;
  value: CompanyWorker;
};

type Props = {
  onSelectChange: (value: CompanyWorker) => void;
  initialValueId: string;
  placeholder: string;
};

const SubcontractorMember = ({onSelectChange, initialValueId, placeholder}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const company = useCompany();
  const [selected, setSelected] = useState<MemberOptionType>(null);

  const {data: companyWorkers, isLoading} = useQuery({
    queryKey: QUERY_CACHE_KEYS.projectWorkers(projectId),

    queryFn: ({pageParam = 0}) => {
      const formattedSortFields = {};
      return ProjectsApi.getProjectWorkers(
        projectId,
        {
          filterParams: {
            blendedStatus: ['active', 'invited'],
          },
          sortField: SORT_PARAMS.field,
          sortOrder: SORT_PARAMS.order,
          offset: Number(pageParam) * ENTITY_LIMIT,
          limit: ENTITY_LIMIT,
          ...formattedSortFields,
        },
        'data',
      );
    },
    enabled: !!company?.id,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (companyWorkers) {
      const selectedWorker = companyWorkers.find((cw) => cw.workerId === initialValueId);
      setSelected(selectedWorker ? {label: selectedWorker.workerFull.fullName, value: selectedWorker} : null);
    }
  }, [companyWorkers, initialValueId]);

  const onChange = (option: MemberOptionType) => {
    setSelected(option);
    onSelectChange(option?.value);
  };

  const options = useMemo<MemberOptionType[]>(() => {
    return filterDuplicatedCompanyWorkers(companyWorkers || []).map((cw) => ({
      label: cw.workerFull.fullName,
      value: cw,
    }));
  }, [companyWorkers]);

  return (
    <Select
      isClearable={true}
      isSearchable={true}
      isDisabled={isLoading}
      isLoading={isLoading}
      placeholder={placeholder}
      onChange={onChange}
      value={selected}
      options={options}
      classNamePrefix="react-select"
      className={`${s['company-worker-select']}`}
      menuPortalTarget={document.body}
      menuPlacement="top"
      components={{Option: MemberOption, SingleValue: SingleMemberOption}}
    />
  );
};

export default SubcontractorMember;
