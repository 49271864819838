import {TFunction} from 'i18next';

import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import {TaskIssueType} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';

export const getPrettyTaskStatuses = (t: TFunction): Record<TaskStatusType, string> => ({
  tba: t('common:statuses.tba', 'TBA'),
  assigned: t('common:statuses.assigned', 'Assigned'),
  in_progress: t('common:statuses.in_progress', 'In Progress'),
  done: t('common:statuses.done', 'Done'),
  rework: t('common:statuses.rework', 'Rework'),
  verified: t('common:statuses.verified', 'Verified'),
  approved: t('common:statuses.approved', 'Approved'),
  closed: t('common:statuses.closed', 'Closed'),
  blocked: t('common:statuses.blocked', 'Blocked'),
  archived: t('common:statuses.archived', 'Archived'),
  unblocked: t('common:statuses.unblocked', 'Unblocked'),
  not_done: t('common:statuses.notDone', 'Not Done'),
});

export const prettyGoodStatuses = {
  verified: 'Verified',
  approved: 'Approved',
  done: 'Done',
};

export const taskStatusClassNameOverride = {
  in_progress: 'in-progress',
  rework: 're-work',
  not_done: 're-work',
};

export const taskStatusIcons: Record<TaskStatusType, string> = {
  in_progress: 'in-progress',
  tba: 'tba',
  done: 'done',
  rework: 'rework',
  approved: 'approved',
  closed: 'closed',
  blocked: 'blocked',
  archived: 'archived',
  assigned: 'assigned',
  verified: 'verified',
  not_done: 'rework',
  unblocked: 'unblocked',
};

export const sortedTasksStatuses: TaskStatusType[] = [
  TaskStatusType.tba,
  TaskStatusType.assigned,
  TaskStatusType.inProgress,
  TaskStatusType.blocked,
  TaskStatusType.rework,
  TaskStatusType.unblocked,
  TaskStatusType.done,
  TaskStatusType.notDone,
  TaskStatusType.verified,
  TaskStatusType.approved,
  TaskStatusType.closed,
  TaskStatusType.archived,
];

export const taskPastDueStatuses = [
  TaskStatusType.tba,
  TaskStatusType.assigned,
  TaskStatusType.inProgress,
  TaskStatusType.rework,
  TaskStatusType.blocked,
];

export const getCompanyWorkersStatuses = (t: TFunction): CoreOptionType[] => [
  {value: 'invited', label: t(`common:worker_status.invited`)},
  {value: 'active', label: t(`common:worker_status.active`)},
  {value: 'removed', label: t(`common:worker_status.removed`)},
  {value: 'closed', label: t(`common:worker_status.closed`)},
];

export const getTaskIssueTypeOptions = (t: TFunction): CoreOptionType[] => {
  return [
    {
      value: TaskIssueType.DesignEngineering,
      label: t('common:issue_type.design_engineering', 'Design and Engineering'),
    },
    {value: TaskIssueType.DrawingDiscrepancies, label: t('common:issue_type.drawing_discrepancies')},
    {value: TaskIssueType.LaborWorkforce, label: t('common:issue_type.labor_workforce', 'Labor and Workforce')},
    {value: TaskIssueType.LackofPlanning, label: t('common:issue_type.lack_of_planning')},
    {value: TaskIssueType.Manufacturing, label: t('common:issue_type.manufacturing')},
    {
      value: TaskIssueType.MaterialEquipment,
      label: t('common:issue_type.material_equipment', 'Material and Equipment'),
    },
    {value: TaskIssueType.TradeCoordination, label: t('common:issue_type.trade_coordination', 'Trade Coordination')},
    {
      value: TaskIssueType.SiteConditionsAccess,
      label: t('common:issue_type.site_conditions_access', 'Site Conditions and Access'),
    },
    {value: TaskIssueType.Quality, label: t('common:issue_type.quality')},
    {value: TaskIssueType.Weather, label: t('common:issue_type.weather', 'Weather')},
    {value: TaskIssueType.Safety, label: t('common:issue_type.safety', 'Safety')},
    {value: TaskIssueType.Other, label: t('common:issue_type.other', 'Other')},
  ];
};

export const getTaskIssueImpactOptions = (t: TFunction): CoreOptionType[] => {
  return [
    {value: 'none', label: t('common:issue_impact.none', 'Low - No Impact')},
    {value: 'partial', label: t('common:issue_impact.partial', 'Medium - Partial')},
    {value: 'day_for_day', label: t('common:issue_impact.day_for_day', 'High - Day for day')},
  ];
};

export const costImpactTypes = [
  {value: String(true), label: 'Yes'},
  {value: String(false), label: 'No'},
];
