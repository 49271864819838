import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useEffect, useMemo, useCallback} from 'react';

import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {useAppConstants} from 'shared/hooks/useAppConstants';
import {useProfile} from 'shared/hooks/useProfile';

import {getMatrixConnectionToken} from '../utils/apiHelpers';

export function useInitializeMatrixSession() {
  const queryClient = useQueryClient();
  const worker = useProfile();
  const appConstants = useAppConstants();
  const homeServer = appConstants?.locales?.uS.matrix.homeserver;

  const fetchMatrixToken = useCallback(
    () => getMatrixConnectionToken({workerId: worker.id, homeServer}),
    [homeServer, worker.id],
  );

  const MATRIX_TOKEN_CACHE = useMemo(() => [QUERY_CACHE_KEYS.matrixToken, worker.id], [worker.id]);

  useEffect(() => {
    if (!queryClient.getQueryData(MATRIX_TOKEN_CACHE)) {
      queryClient.prefetchQuery({
        queryKey: [MATRIX_TOKEN_CACHE],
        queryFn: fetchMatrixToken,
      });
    }
  }, [queryClient, MATRIX_TOKEN_CACHE, fetchMatrixToken]);

  const query = useQuery({
    queryKey: MATRIX_TOKEN_CACHE,
    queryFn: fetchMatrixToken,
  });

  return query;
}
