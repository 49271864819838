import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';
import {useParams} from 'react-router';

import ProjectsApi from 'api/projects';
import {filterDuplicatedProjectWorkers} from 'shared/helpers/worker';

import {QUERY_CACHE_KEYS} from '../constants/queryCache';

const ENTITY_LIMIT = 1000;
const SORT_PARAMS = {
  field: 'fullName',
  order: 'ASC',
};

export const useProjectWorkers = (projectId?: string) => {
  const {projectId: routeProjectId} = useParams<{projectId: string}>();
  const currentProjectId = routeProjectId || projectId;

  const {data: projectWorkers, isLoading} = useQuery({
    queryKey: QUERY_CACHE_KEYS.projectWorkers(currentProjectId),
    queryFn: ({pageParam = 0}) => {
      const formattedSortFields = {};
      return ProjectsApi.getProjectWorkers(
        currentProjectId,
        {
          filterParams: {
            blendedStatus: ['active', 'invited'],
          },
          sortField: SORT_PARAMS.field,
          sortOrder: SORT_PARAMS.order,
          offset: Number(pageParam) * ENTITY_LIMIT,
          limit: ENTITY_LIMIT,
          ...formattedSortFields,
        },
        'data',
      );
    },
    enabled: !!currentProjectId,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  return {projectWorkers, isLoading};
};

export const useDistinctProjectWorkers = (projectId?: string) => {
  const {projectWorkers, isLoading} = useProjectWorkers(projectId);
  const uniqueWorkers = useMemo(() => {
    if (projectWorkers?.length) {
      return filterDuplicatedProjectWorkers(projectWorkers);
    }
    return [];
  }, [projectWorkers]);
  return {projectWorkers: uniqueWorkers, isLoading};
};
