import {GanttStatic} from 'dhtmlx-gantt';
import {useRef} from 'react';
import {useParams} from 'react-router';
import type {SelectInstance} from 'react-select';

import {useInlineSelectController} from 'modules/Tasks/components/Gantt/components/Editors/useInlineSelectController';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {CoreSelect} from 'shared/components/CoreForm/Select/Select';
import {type CoreOptionType} from 'shared/components/CoreForm/Select/types';
import {useMount} from 'shared/hooks/core/useMount';

export type KeysOfType<T> = {
  [K in keyof GanttTask]: GanttTask[K] extends T ? K : never;
}[keyof GanttTask];

interface SelectEditorProps<T> {
  gantt: GanttStatic;
  task: GanttTask;
  options: CoreOptionType[];
  name: KeysOfType<T>;
  transformValue: (value: T) => string;
  transformBack: (value: string) => T;
}

const SelectEditor = <T,>({gantt, task, options, name, transformValue, transformBack}: SelectEditorProps<T>) => {
  const selectRef = useRef<SelectInstance<CoreOptionType, false>>(null);
  const {projectId} = useParams<{projectId: string}>();
  const {updateValue, onKeyUp, onKeyDown, onClose} = useInlineSelectController(gantt);

  useMount(() => {
    selectRef.current?.focus();
  });

  const onChange = async (value: string) => {
    const transformedValue = transformBack(value);
    const currentValue = task[name] as T;
    if (currentValue !== transformedValue) {
      updateValue(task, name, transformedValue as string);
    }
  };

  const getValue = (): string => {
    const value = task[name] as T;
    return transformValue(value);
  };

  return (
    <CoreSelect
      projectId={projectId || task.projectId || task.project_id}
      value={getValue()}
      options={options}
      className="react-select"
      ref={selectRef}
      menuPlacement="auto"
      size="xs"
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMenuClose={onClose}
      menuIsOpen={true}
      closeMenuOnSelect={false}
      tabSelectsValue={false}
    />
  );
};

export default SelectEditor;
