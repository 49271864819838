import {Column} from '@tanstack/react-table';
import classnames from 'classnames';

import Icon from 'shared/components/Icon';

interface TableSortIndicatorProps<TData> {
  column: Column<TData, unknown>;
}

export function TableSortIndicator<TData>({column}: TableSortIndicatorProps<TData>) {
  return (
    <div className="table-sorter table-main__sorter">
      <Icon
        colorFill
        name="chevron-top"
        className={classnames('table-sorter__icon table-sorter__icon--top', {
          'is-active': column.getIsSorted() === 'asc',
        })}
        size={24}
      />
      <Icon
        colorFill
        name="chevron-bottom"
        className={classnames('table-sorter__icon table-sorter__icon--bottom', {
          'is-active': column.getIsSorted() === 'desc',
        })}
        size={24}
      />
    </div>
  );
}
