// eslint-disable-next-line import/no-named-as-default
import * as Sentry from '@sentry/browser';
import {TFunction} from 'i18next';
import {FC, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory, useParams} from 'react-router';
import {toast} from 'react-toastify';

import ImportApi from 'api/import';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import Popup from 'shared/components/Popup/Popup';
import TasksImportPreviewTable from 'shared/components/TasksImport/components/TaskImportPreviewTable';
import TaskImportResult from 'shared/components/TasksImport/components/TaskImportResult';
import TasksImportMajorError from 'shared/components/TasksImport/components/TasksImportMajorError';
import TasksImportMapping, {
  ImportSubmitValues,
} from 'shared/components/TasksImport/components/TasksImportMapping/TasksImportMapping';
import TasksImportSource from 'shared/components/TasksImport/components/TasksImportSource/TasksImportSource';
import {useTasksImportContext} from 'shared/components/TasksImport/TasksImportContext/TasksImportContext';
import {TasksImportProvider} from 'shared/components/TasksImport/TasksImportContext/TasksImportProvider';
import {getImportFields, TaskImportStep} from 'shared/components/TasksImport/utils/constants';
import {filterEmptyTasks, getTasksSortFuncByMapping, isMppFile} from 'shared/components/TasksImport/utils/functions';
import {
  TaskImportField,
  TaskImportObject,
  TaskImportProjectCustomFieldDef,
} from 'shared/components/TasksImport/utils/types';
import {TasksViewMode} from 'shared/constants/common';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {deleteNullOrEmptyFieldsRecursive, moveArrayElement} from 'shared/helpers/common';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompany} from 'shared/hooks/useCompany';
import {useProject} from 'shared/hooks/useProject';
import {ProjectCustomFieldType} from 'shared/models/project';
import {useRootSelector} from 'store';
import {selectAllProjects} from 'store/projects';

type TasksImportPopupProps = {
  defaultProjectId?: string;
  onClose: () => void;
  onFinish: (importedTasks?: TaskImportObject['task'][]) => Promise<void>;
  viewMode?: TasksViewMode;
  visible: boolean;
};

const TasksImportPopup: FC<TasksImportPopupProps> = ({defaultProjectId, onClose, onFinish, viewMode, visible}) => {
  const profile = useRootSelector((state) => state.profile.worker);
  const company = useCompany();
  // TODO: refactor main routes to be consistent
  // depending on the route project id can be named id or projectId
  const {id, projectId: maybeProjectId} = useParams<{id: string; projectId: string}>();
  const projectId = id || maybeProjectId;
  const [selectProjectId, setSelectProjectId] = useState(projectId);
  const {project} = useProject(selectProjectId);
  const projects = useRootSelector(selectAllProjects);
  const history = useHistory();
  const routes = useLocalizedRoutes();

  const [
    {
      currentStep,
      parseResult,
      importErrorCode,
      csv,
      config,
      compareResult,
      showResult,
      asyncUploadId,
      completePercentage,
      file,
    },
    actions,
  ] = useTasksImportContext();

  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});
  const mixpanelEvents = mixpanel.events.tasks.import;
  const {t} = useTranslation(['import']);

  const fileHeaders = useMemo(
    () =>
      parseResult?.result.headers
        ? parseResult?.result.headers.reduce<CoreOptionType[]>((acc, field) => {
            if (field === 'outline_code') {
              if (isMppFile(file?.name)) return acc;
              acc.push({label: t('import:fields.outline.label'), value: field});
              return acc;
            }
            acc.push({label: field, value: field});
            return acc;
          }, [])
        : [],
    [file?.name, parseResult?.result.headers, t],
  );

  const importFields = getImportFields(t);

  const selectedProject = useMemo(
    () => projects.find((proj) => proj.id === selectProjectId),
    [projects, selectProjectId],
  );

  const getCustomColumns = useCallback((): TaskImportProjectCustomFieldDef[] => {
    if (!selectedProject || !fileHeaders) {
      return [];
    }

    let importSettings: {
      mapping?: Record<string, string>;
      dateFormat?: string;
      customFieldsMapping: Record<string, string>;
    };
    try {
      importSettings = JSON.parse(selectedProject.importSettings || '{"customFieldsMapping": {}}');
    } catch (e) {
      importSettings = {customFieldsMapping: {}};
    }

    const availableHeaders = fileHeaders.map((header) => header.value);
    const customFieldsMapping = importSettings.customFieldsMapping || {};
    const customFieldDef = selectedProject.customFieldDef.map<TaskImportProjectCustomFieldDef>((field) => {
      const savedMapping = customFieldsMapping[field.internalFieldName] || null;
      const validMapping = savedMapping && availableHeaders.includes(savedMapping) ? savedMapping : null;
      const parsedFieldData = field.fieldData ? JSON.parse(field.fieldData) : '';
      return {
        fieldData: Array.isArray(parsedFieldData) ? parsedFieldData.join('\n') : '',
        fieldName: field.fieldName,
        fieldType: field.fieldType || ProjectCustomFieldType.string,
        internalFieldName: field.internalFieldName,
        mappedTo: validMapping,
      };
    });

    customFieldDef.sort((a, b) => a.fieldName.localeCompare(b.fieldName));
    return customFieldDef ?? [];
  }, [fileHeaders, selectedProject]);

  const customColumns = getCustomColumns();

  const onSubmit = async ({asyncUploadId, importedCustomFields, values}: ImportSubmitValues) => {
    try {
      const customFields: Record<string, string> = importedCustomFields
        .filter((field) => {
          return field.mappedTo && field.internalFieldName;
        })
        .reduce(
          (acc, curr) => ({
            ...acc,
            [curr.internalFieldName]: curr.mappedTo,
          }),
          {},
        );

      const mapping = deleteNullOrEmptyFieldsRecursive({...values.mapping, customFields});

      await ImportApi.dryRun(values.defaultProject, asyncUploadId, {
        dateFormat: values.dateFormat,
        mapping,
      });

      const dryRunData = await ImportApi.pollDryDunResults(
        values.defaultProject,
        asyncUploadId,
        60,
        actions.setPercentage,
      );

      if (dryRunData.status === 'finished') {
        if (dryRunData.result.errors?.[0]?.code === 'runtime_error') {
          const error = dryRunData.result.errors?.[0]?.code;
          Sentry.captureException(error, {
            tags: {context: 'Import dry run failure', name: file?.name || 'Unknown file'},
          });
          throw new Error(error);
        }

        const preparedTasks = filterEmptyTasks(dryRunData.result.taskResponses);

        preparedTasks.sort(getTasksSortFuncByMapping(mapping));

        const updatedPreparedTasks = preparedTasks.map((data) => {
          const {task} = data;
          if (!task?.customFields) {
            return data;
          }

          const {customFields, ...restOfTask} = task;
          const mergedCustomFields = customFields.reduce((acc, field) => {
            if (!field?.internalFieldName) {
              return acc;
            }
            acc[field.internalFieldName] = field.value;
            return acc;
          }, {});

          return {
            ...data,
            task: {
              ...restOfTask,
              ...mergedCustomFields,
            },
          };
        });

        actions.setCompareResult({
          ...dryRunData.result,
          taskResponses: updatedPreparedTasks,
        });

        const mergedMapping = {...values.mapping, ...customFields};
        const newValues = {
          ...values,
          customFields: importedCustomFields,
          mapping: mergedMapping,
        };
        actions.setConfig(newValues);
        actions.setCurrentStep(TaskImportStep.Confirm);
        actions.setPercentage(0);
      }

      if (dryRunData.status === 'failed') {
        console.error('Dry run failed:', dryRunData);
        if (dryRunData.result.errors) {
          actions.setErrorCode(dryRunData.result.errors[0].code);
        } else if (dryRunData.result.error) {
          actions.setErrorCode('500');
        }
      }
    } catch (error) {
      Sentry.captureException(error, {tags: {context: 'Import onSubmit failure', name: file?.name || 'Unknown file'}});
      toast.error(t('import:errors.messages.server_error'));
      actions.setErrorCode('500');
    }
  };

  const getTabs = (t: TFunction) => [
    {id: TaskImportStep.SelectSource, title: t('tabs.upload.title', 'Upload File')},
    {id: TaskImportStep.Mapping, title: t('tabs.map_file_columns.title', 'Map File Columns')},
    {id: TaskImportStep.Confirm, title: t('tabs.confirm.title', 'Confirm List')},
  ];

  const tabs = getTabs(t);

  const previewFields = useMemo(() => {
    const customFields: TaskImportField[] = customColumns.map((col) => ({
      label: col.fieldName,
      columnTitle: col.fieldName,
      key: col.internalFieldName as TaskImportField['key'],
      accessor: (value) => {
        if (
          typeof value === 'string' &&
          [ProjectCustomFieldType.multiselect, ProjectCustomFieldType.select].includes(col.fieldType)
        ) {
          try {
            const data = JSON.parse(value);
            if (Array.isArray(data)) {
              return data.join(', ');
            }
          } catch (error) {
            return value;
          }
        }
        return value;
      },
    }));

    const mergedFields = importFields.concat(customFields);

    if (config) {
      const clearedImportFields = mergedFields.filter((field) => !!config.mapping[field.key]);
      return config.mapping.outlineCode
        ? moveArrayElement(
            mergedFields,
            clearedImportFields.findIndex((el) => el.key === 'outlineCode'),
            1,
          )
        : mergedFields;
    }
    return mergedFields;
  }, [config, customColumns, importFields]);

  const startImport = async () => {
    const customFields: Record<string, string> = config.customFields
      .filter((item) => !!item.internalFieldName)
      .reduce(
        (acc, curr) => ({
          ...acc,
          [curr.internalFieldName]: curr.mappedTo,
        }),
        {},
      );

    const mapping = deleteNullOrEmptyFieldsRecursive({...config.mapping, customFields});
    await ImportApi.commit(config.defaultProject, asyncUploadId, {
      mapping: {...mapping, assignedNames: mapping.assignedNames, archiveTask: mapping.archived},
      dateFormat: config.dateFormat,
    });
    const commitData = await ImportApi.pollCommitResults(
      config.defaultProject,
      asyncUploadId,
      4_800, // 80 minutes for large imports 😢
      actions.setPercentage,
    );

    if (commitData.status === 'finished') {
      const preparedTasks = commitData.result.taskResponses.filter(
        (taskResponse) => !!taskResponse.task && taskResponse.status !== 'failed',
      );
      preparedTasks.sort(getTasksSortFuncByMapping(mapping));

      // TODO: finish up with csv data passing to result
      actions.setCsv(commitData.result.export);
      actions.setCompareResult({
        ...commitData.result,
        taskResponses: preparedTasks,
      });
      actions.setShowResult(true);
    } else if (commitData.status === 'failed') {
      if (commitData.result.errors) {
        actions.setErrorCode(commitData.result.errors[0].code);
      } else if (commitData.result.error) {
        actions.setErrorCode('500');
      }
    }
  };

  async function finishAndRedirect() {
    await onFinish(compareResult.taskResponses.map((res) => res.task));
    history.push({
      pathname: generatePath(routes.tasks, {projectId: config.defaultProject}),
    });
  }

  const handleClose = () => {
    if (showResult) {
      finishAndRedirect();
    } else {
      onClose();
    }
  };

  function getStepContent() {
    switch (currentStep) {
      case TaskImportStep.SelectSource:
        return <TasksImportSource viewMode={viewMode} mixpanelEvents={mixpanelEvents} />;
      case TaskImportStep.Mapping:
        return (
          <TasksImportMapping
            customColumns={customColumns}
            defaultProjectId={defaultProjectId}
            fields={importFields}
            fileHeaders={fileHeaders}
            onProjectChange={setSelectProjectId}
            importPercentage={completePercentage}
            mixpanelEvents={mixpanelEvents}
            onSubmit={onSubmit}
          />
        );
      case TaskImportStep.Confirm:
        return (
          <TasksImportPreviewTable
            result={compareResult}
            fields={previewFields}
            onBack={() => mixpanel.trackWithAction(() => actions.setCurrentStep(0), mixpanelEvents.backToMapping)}
            onSubmit={startImport}
            onClose={onFinish}
            importPercentage={completePercentage}
          />
        );
      default:
        return null;
    }
  }

  function getPopupBody() {
    if (importErrorCode)
      return (
        <TasksImportMajorError
          tryAgain={() => {
            actions.setErrorCode('');
            actions.setCurrentStep(TaskImportStep.SelectSource);
          }}
          errorCode={importErrorCode}
          companyName={company.companyName}
          profile={profile}
          textToShow={t('import:errors.messages.default')}
        />
      );
    if (showResult) {
      return (
        <TaskImportResult
          onFinish={finishAndRedirect}
          totals={{
            newTasks: compareResult.addedCount,
            edited: compareResult.updatedCount,
            error: compareResult.taskResponses.filter((taskResponse) => taskResponse.errors.length).length,
            unChanged: compareResult.unchangedCount,
          }}
          csv={csv}
        />
      );
    }
    return getStepContent();
  }

  return (
    <Popup
      ignore="[data-popup-role='confirmation']"
      isCloseButtonInside
      visible={visible}
      onClose={() => {
        mixpanel.trackWithAction(
          handleClose,
          mixpanelEvents.exitCompleteScreen,
          {'Project Name:': project?.name},
          showResult,
        );
      }}
    >
      <Popup.Header>
        <div className="tabs-nav popup__nav">
          {tabs.map((tab, index) => (
            <button
              key={tab.id}
              style={{marginTop: '20px'}}
              className={`tabs-nav__button ${currentStep === tab.id ? 'is-active' : ''}`}
              type="button"
            >
              {index + 1}. {tab.title}
            </button>
          ))}
        </div>
      </Popup.Header>
      {getPopupBody()}
    </Popup>
  );
};

function TaskImportPopupWrapper(props: TasksImportPopupProps) {
  if (!props.visible) {
    return null;
  }

  return (
    <TasksImportProvider>
      <TasksImportPopup {...props} />
    </TasksImportProvider>
  );
}
export default TaskImportPopupWrapper;
