import {useQuery} from '@tanstack/react-query';

import ProjectsApi from 'api/projects';

export const useGetSubcontractor = (projectId: string, subId: string) => {
  const {isLoading, data: projectSub} = useQuery({
    queryKey: ['projectorgs', projectId, subId],

    queryFn: async () => {
      const res = await ProjectsApi.getProjectSub(projectId, subId);
      return res;
    },

    enabled: !!projectId && !!subId && subId !== 'new',
    refetchOnWindowFocus: false,
  });

  return {isLoading, projectSub};
};
