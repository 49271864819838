import {gantt, GanttStatic} from 'dhtmlx-gantt';

import {GanttApiResponse, GanttTask} from 'modules/Tasks/components/Gantt/types';
import {
  deselectAll,
  isPlaceholderTask,
  KeyNavigationEventName,
  OSKMap,
  prepareTaskGanttChangedFields,
  traverseTasks,
} from 'modules/Tasks/components/Gantt/utils/gantt';
import {useTasksActions} from 'modules/Tasks/hooks/useTasksActions';
import {safeParseJSON} from 'modules/Tasks/utils/utils';
import {safeFormatDate, safeParseDate, subtract, toShortIso} from 'shared/helpers/dates';
import {getProjectCustomField} from 'shared/helpers/project';
import {parseUrlQuery} from 'shared/helpers/queryParams';
import {GanttTaskColors} from 'shared/helpers/task';
import {
  getPunchListCountModel,
  prepareAssignees,
  prepareDateForGantt,
  prepareEndDateForGantt,
} from 'shared/mapping/task';
import {ProjectCustomFieldType, ProjectModel, WorkDaysEnum} from 'shared/models/project';
import {TaskObjectType, TaskObjectSubType} from 'shared/models/task/const';
import {
  TaskModelRawDTO,
  TaskDetailsModelDTO,
  TaskGanttModelDTO,
  TaskGanttModel,
  TaskHistoryRawType,
  FeedbackProjectModelDTO,
} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';

import {GANTT_COLUMNS_NAMES, NON_HIDING_COLUMNS} from './constants';
import {EventStore} from './eventStore';

interface TransformDataConfig {
  data: GanttApiResponse;
  projectId: string;
  colors?: GanttTaskColors;
  flatList?: boolean;
  inclusiveEndDate?: boolean;
}

type TransformLookaheadDataConfig = Omit<TransformDataConfig, 'data'> & {
  tasks: TaskModelRawDTO[];
  oskMap?: OSKMap;
  collapsed?: Set<string>;
};

export type GetOneDayFilterOptions = () => {filterDate: Date | null; enableOneDayFilter: boolean};

export const getOneDayFilterOptions: GetOneDayFilterOptions = () => {
  const {schedEndFirst, schedWeeks} = parseUrlQuery<{schedEndFirst?: Date; schedWeeks?: number}>(
    window.location.search,
    {
      schedEndFirst: 'date',
      schedWeeks: 'number',
    },
  );
  const enableOneDayFilter = schedEndFirst && schedWeeks === 0;

  return {filterDate: schedEndFirst, enableOneDayFilter};
};

export const setProjectCalendarExceptions = (gantt: GanttStatic, project: ProjectModel) => {
  const calendar = gantt.getCalendar(project.id);
  const ganttDateConverter = gantt.date.str_to_date('%Y-%m-%d');
  project.calendar.exceptions.forEach((ex) => {
    calendar.setWorkTime({
      date: ganttDateConverter(ex.date),
      hours: ex.working ? [`${project.defaultTaskStart}-${project.defaultTaskEnd}`] : false,
    });
  });
};

// Gantt library return any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setHolidays = (projectWorkDays: WorkDaysEnum[], calendar: any) => {
  const workDays = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };
  projectWorkDays.forEach((day) => {
    if (workDays[day]) delete workDays[day];
  });
  for (const key of Object.keys(workDays)) {
    calendar.setWorkTime({day: workDays[key], hours: false});
  }
};

export const transformLookaheadData = ({
  tasks,
  projectId,
  flatList,
  oskMap: initialOskMap,
  collapsed,
}: TransformLookaheadDataConfig) => {
  const oskMap = traverseTasks(tasks, initialOskMap);

  return {
    tasks: tasks.map((taskModel: TaskModelRawDTO & {row_height?: number}) => {
      const children = oskMap.get(taskModel.outline_sort_key)?.children_count;
      const parentOSK = oskMap.get(taskModel.outline_sort_key)?.parent;
      const parent = parentOSK ? oskMap.get(parentOSK).id : '0';
      const model = mapTaskProjectionToTaskGanttModel({
        taskModel,
        projectId,
        flatList: flatList,
        inclusiveEndDate: true,
      });
      model.subtask_count = children;
      model.has_child = children;
      model.$has_child = children;
      model.parent = parent;
      model.open = !collapsed?.has(taskModel.id);
      model.isPending = false;
      return model;
    }),
    links: [],
  };
};

export const transformIssuesData = ({
  tasks,
  projectId,
  flatList,
  feedbackMap = new Map(),
}: TransformLookaheadDataConfig & {
  feedbackMap: Map<string, FeedbackProjectModelDTO[] | readonly []>;
}) => {
  return {
    tasks: tasks.map((taskModel: TaskModelRawDTO & {row_height?: number}) => {
      const model = mapTaskProjectionToTaskGanttModel({
        taskModel,
        projectId,
        flatList: flatList,
        inclusiveEndDate: true,
      });
      model.unscheduled = true;
      // Add additional safety check when accessing the map
      const feedback = feedbackMap.get(taskModel.id);
      // Inject comment count for display in gantt column
      model.comment_count = feedback ? feedback.length : 0;
      return model;
    }),
    links: [],
  };
};

/*
 * We transform dates to inclusive format after get them from server,
 * and convert them back to exclusive when save to server
 * be careful with this converting
 * */
export const transformData = ({data, projectId, flatList, inclusiveEndDate}: TransformDataConfig) => {
  return {
    tasks: data.tasks.map((taskModel) =>
      mapGanttProjectionToTaskGanttModel({
        taskModel: taskModel,
        projectId: projectId,
        flatList: flatList,
        inclusiveEndDate: inclusiveEndDate,
      }),
    ),
    links: data.links.map((link) => Object.assign({}, link, {type: gantt.config.links[link.type]})),
  };
};

export const prepareTaskChangedFieldsForUpdate = (task: GanttTask): Partial<TaskDetailsModelDTO> => {
  const lastChangedFields = prepareTaskGanttChangedFields(task);
  const fieldsToSave = {} as Partial<TaskDetailsModelDTO>;
  const prepareDate = (date: string) => safeParseDate(date);
  const prepareInclusiveDate = (date: string) => subtract(prepareDate(date), 1).toDate();
  if (lastChangedFields?.actualStart) {
    fieldsToSave.actualStart = toShortIso(prepareDate(lastChangedFields?.actualStart as string));
  }

  if (lastChangedFields.hasOwnProperty('estLaborHours')) {
    if (lastChangedFields.estLaborHours === '' || lastChangedFields.estLaborHours === undefined) {
      fieldsToSave.estLaborHours = null;
    } else {
      fieldsToSave.estLaborHours = parseFloat(lastChangedFields.estLaborHours as string);
    }
  }

  if (lastChangedFields?.estimatedLaborAbbr) {
    fieldsToSave.projectedLabor = lastChangedFields.estimatedLaborAbbr as string;
  }

  if (lastChangedFields?.actualEnd) {
    fieldsToSave.actualEnd = toShortIso(prepareInclusiveDate(lastChangedFields?.actualEnd as string));
  }
  // sent startDate + duration if user changing either start_date or duration
  // otherwise  if user changing end data sent pair of start and end dates
  if (lastChangedFields?.taskDuration || lastChangedFields?.startDate) {
    fieldsToSave.duration = Math.abs(parseInt(String(lastChangedFields.taskDuration || task.taskDuration)));
    delete lastChangedFields['taskDuration'];
    fieldsToSave.startDate = toShortIso(
      lastChangedFields?.startDate ? prepareDate(lastChangedFields?.startDate as string) : task.start_date,
    );
  } else if (lastChangedFields?.endDate) {
    fieldsToSave.endDate = toShortIso(prepareInclusiveDate(lastChangedFields.endDate as string));
    fieldsToSave.startDate = toShortIso(
      lastChangedFields?.startDate ? prepareDate(lastChangedFields?.startDate as string) : task.start_date,
    );
  }
  if (task.object_type === TaskObjectType.milestone && (lastChangedFields?.startDate || lastChangedFields?.endDate)) {
    const date = lastChangedFields?.startDate ? lastChangedFields?.startDate : lastChangedFields?.endDate;
    fieldsToSave.startDate = toShortIso(prepareInclusiveDate(date as string));
    fieldsToSave.endDate = undefined;
    fieldsToSave.duration = 0;
  }
  if ('taskType' in lastChangedFields) {
    fieldsToSave.type = lastChangedFields.taskType as string;
    delete lastChangedFields.taskType;
  }
  if ('costImpact' in lastChangedFields) {
    fieldsToSave.costImpact = lastChangedFields.costImpact as boolean;
    delete lastChangedFields.costImpact;
  }
  if ('location' in lastChangedFields && task.location === null) {
    fieldsToSave.location = '';
    delete lastChangedFields.location;
  }

  return {id: task.id, projectId: task.projectId, ...lastChangedFields, ...fieldsToSave};
};

export const prepareTaskForCreate = (task: GanttTask): Partial<TaskDetailsModelDTO> => {
  const model: Partial<TaskDetailsModelDTO> & {parentId?: string} = {
    id: task.id,
    uniqueId: task?.unique_id,
    name: task?.name,
    projectId: task?.projectId,
    status: task?.taskStatus as TaskStatusType,
    completionAmount: task.completion_amount || '',
    completionTarget: task.completion_target,
    completionUnit: task.completion_unit,
    objectType: task.object_type,
    responsible: prepareAssignees(task.responsible),
    responsibleOrgId: task.responsible_org_id,
    type: task.taskType,
    location: task.location || '',
    startDate: toShortIso(task.start_date),
    actualEndDate: task.actual_start && toShortIso(task.actual_start),
    actualEnd: task.actual_end && toShortIso(subtract(task.end_date, 1)),
  };
  const hasDuration = Math.abs(parseInt(String(task?.taskDuration))) >= 0;
  const duration = Math.abs(parseInt(String(task?.taskDuration))) || undefined;
  if (hasDuration) {
    model.duration = duration;
    if (duration == 0) {
      model.startDate = toShortIso(task.end_date);
    }
  } else {
    model.endDate = toShortIso(subtract(task.end_date, 1));
  }

  if (task?.parent !== gantt.config.root_id) {
    model.parentId = String(task?.parent);
  }
  if (task?.lastChangedFields && Object.keys(task.lastChangedFields).length) {
    Object.assign(model, {...prepareTaskChangedFieldsForUpdate(task)});
  }
  if (task?.relativeToId) {
    Object.assign(model, {relativeToId: task.relativeToId, relativeDir: task.relativeDir});
  }
  return model;
};

interface MapToTaskGanttModelParams<T> {
  taskModel: T;
  projectId: string;
  flatList?: boolean;
  inclusiveEndDate?: boolean;
}

function mapGanttProjectionToTaskGanttModel({
  taskModel,
  flatList,
  inclusiveEndDate = true,
}: MapToTaskGanttModelParams<TaskGanttModelDTO>) {
  const prepareEndDateFn = inclusiveEndDate ? prepareEndDateForGantt : prepareDateForGantt;
  const result: TaskGanttModel = {
    ...taskModel,
    activities: taskModel.activities,
    estimated_labor: taskModel.estimatedLabor,
    punchlist: taskModel.punchlist,
    issue_task_ids: taskModel.issue_task_ids,
    assignment_count: taskModel.assignment_count,
    projectId: taskModel.project_id,
    parent: taskModel.parent || (gantt.config.root_id as string),
    meta: {loading: false},
    responsible: taskModel.responsible,
    start_date: prepareDateForGantt(taskModel.start_date),
    end_date: prepareEndDateFn(taskModel.end_date),
    actual_start: prepareDateForGantt(taskModel.actual_start),
    actual_end: prepareEndDateFn(taskModel.actual_end),
    date_list: taskModel.date_list,
    taskStatus: taskModel.status,
    taskDuration: taskModel.duration,
    punchList: getPunchListCountModel(taskModel.punchlist),
    timeRemoved: taskModel.time_removed,
    lastChangedFields: {},
    feedback_by_date: taskModel.feedback_by_date,
    feedback_rollup: taskModel.feedback_rollup,
    est_labor_hours: taskModel.est_labor_hours,
    // injected to keep a tally of non-system generated comments
    comment_count: taskModel.comment_count,
  };

  if (flatList) {
    result.parent = gantt.config.root_id as string;
  }

  return result;
}

function getStatusDate(history: TaskHistoryRawType[], status: TaskStatusType): string | undefined {
  return history.find((statusChange) => statusChange.status === status)?.time_updated;
}

export function mapTaskProjectionToTaskGanttModel({
  taskModel,
  flatList,
  inclusiveEndDate = true,
}: MapToTaskGanttModelParams<TaskModelRawDTO>) {
  const prepareEndDateFn = inclusiveEndDate ? prepareEndDateForGantt : prepareDateForGantt;
  const startDate = prepareDateForGantt(taskModel.start_date);
  const endDate = prepareEndDateFn(taskModel.end_date);
  const result: TaskGanttModel = {
    ...taskModel,
    estimated_labor: taskModel.projected_labor,
    projectId: taskModel.project_id,
    parent: gantt.config.root_id as string,
    meta: {loading: false},
    issue_task_ids: taskModel.issue_task_ids,
    status_issue_task_ids_pairs: taskModel.status_issue_task_ids_pairs,
    actual_start: prepareDateForGantt(taskModel.actual_start),
    actual_end: prepareEndDateFn(taskModel.actual_end),
    start_date: startDate,
    start_date_real: startDate,
    end_date: endDate,
    end_date_real: endDate,
    date_list: taskModel.date_list,
    task_ids: taskModel.task_ids,
    taskStatus: taskModel.status,
    taskDuration: taskModel.duration,
    taskType: taskModel.type,
    punchList: getPunchListCountModel(taskModel.punchlist),
    timeRemoved: taskModel.time_removed,
    type: taskModel.object_type === TaskObjectType.milestone ? taskModel.object_type : undefined,
    lastChangedFields: {},
    has_child: 0, // need to be calculated
    $has_child: 0, // need to be calculated
    subtask_count: 0, // need to be calculated
    comment_count: taskModel.comment_count,
  };

  if (taskModel.status_history) {
    // status history is provided newest to oldest, but looking for first in-progress
    result.inprogressDate = prepareDateForGantt(
      getStatusDate(taskModel.status_history.slice().reverse(), TaskStatusType.inProgress),
    );
    result.doneDate = prepareDateForGantt(getStatusDate(taskModel.status_history, TaskStatusType.done));
  }

  if (taskModel.object_type === TaskObjectType.milestone && taskModel.object_subtype === TaskObjectSubType.end) {
    result.start_date = result.end_date;
  }

  if (flatList) {
    result.parent = gantt.config.root_id as string;
  }

  return result;
}
export function registerCustomKeyNavMapping(
  eventStore: EventStore<KeyNavigationEventName>,
  gantt: GanttStatic,
  tasksActions: ReturnType<typeof useTasksActions>,
) {
  const mapping = {
    init: function (inlineEditors) {
      eventStore.attach('onBeforeFocus', function (node) {
        if (
          document.activeElement &&
          ['input', 'textarea'].includes(document.activeElement.nodeName.toLowerCase()) &&
          !document.activeElement.closest('.gantt-container')
        ) {
          return false;
        }
        const activeCell = inlineEditors.locateCell(node);
        if (activeCell) {
          // TODO: need to investigate why inline editor closes after changing a row
          if (inlineEditors.isVisible()) {
            return false;
          }
        }

        return true;
      });
      eventStore.attach('onFocus', function (node) {
        const activeCell = inlineEditors.locateCell(node);
        const state = inlineEditors.getState();

        if (activeCell && !(activeCell.id == state.id && activeCell.columnName == state.columnName)) {
          if (inlineEditors.isVisible()) {
            inlineEditors.save();
            return false;
          }
        }

        return true;
      });
      eventStore.attach('onKeyDown', function (_command, e: KeyboardEvent) {
        if (e.target instanceof Element && !e.target.closest('#foxit-container')) {
          const activeCell = inlineEditors.locateCell(e.target);
          const hasEditor = activeCell ? inlineEditors.getEditorConfig(activeCell.columnName) : false;
          const state = inlineEditors.getState();
          const keyboard = gantt.constants.KEY_CODES;
          const keyCode = e.keyCode;
          let preventKeyNav = false;

          if (e.target instanceof Element && !e.target.closest('#foxit-container')) {
            const keyCode = e.code;

            const CustomKeyNavMapping = {
              BracketLeft: 'BracketLeft',
              BracketRight: 'BracketRight',
            } as const;

            if (keyCode === CustomKeyNavMapping.BracketLeft && e.ctrlKey) {
              tasksActions.outdentSelectedTasks();
              e.preventDefault();
              e.stopPropagation();
              preventKeyNav = true;
              return;
            } else if (keyCode === CustomKeyNavMapping.BracketRight && e.ctrlKey) {
              tasksActions.indentSelectedTasks();
              e.preventDefault();
              e.stopPropagation();
              preventKeyNav = true;
              return;
            }
          }

          switch (keyCode) {
            case keyboard.ENTER:
              if (inlineEditors.isVisible()) {
                const nextTaskId = gantt.getNext(state.id);
                inlineEditors.save();
                if (nextTaskId) {
                  const nextTask = gantt.getTask(nextTaskId);
                  inlineEditors.startEdit(
                    nextTaskId,
                    isPlaceholderTask(gantt, nextTask) ? GANTT_COLUMNS_NAMES.name : state.columnName,
                  );
                } else {
                  const placeholderTask = gantt.getTaskBy((task) => isPlaceholderTask(gantt, task))?.[0];
                  if (placeholderTask) {
                    inlineEditors.startEdit(placeholderTask.id, GANTT_COLUMNS_NAMES.name);
                  }
                }
                e.preventDefault();
                e.stopPropagation();
                preventKeyNav = true;
              } else if (hasEditor && !(e.ctrlKey || e.metaKey || e.shiftKey)) {
                inlineEditors.startEdit(activeCell.id, activeCell.columnName);
                e.preventDefault();
                preventKeyNav = true;
              }
              break;

            case keyboard.ESC:
              if (inlineEditors.isVisible()) {
                inlineEditors.hide();
                e.preventDefault();
                preventKeyNav = true;
              } else {
                deselectAll(gantt);
              }

              break;

            case keyboard.UP:
            case keyboard.DOWN:
              if (inlineEditors.isVisible()) {
                preventKeyNav = true;
              }
              break;

            case keyboard.LEFT:
            case keyboard.RIGHT:
              if (hasEditor && inlineEditors.isVisible()) {
                preventKeyNav = true;
              }
              break;

            case keyboard.SPACE:
              if (inlineEditors.isVisible()) {
                preventKeyNav = true;
              }

              if ((hasEditor && !inlineEditors.isVisible()) || state.editorType === 'date') {
                inlineEditors.startEdit(activeCell.id, activeCell.columnName);
                e.preventDefault();
                preventKeyNav = true;
              }
              break;

            case keyboard.DELETE:
              preventKeyNav = true;
              break;

            case keyboard.TAB:
              if (inlineEditors.isVisible()) {
                e.preventDefault();
                preventKeyNav = true;

                const task = gantt.getTask(state.id);
                if (isPlaceholderTask(gantt, task)) {
                  if (state.columnName === GANTT_COLUMNS_NAMES.name) {
                    const value = state.editor.get_value(task.id, state.columnName, state.placeholder);
                    if (e.shiftKey) {
                      if (value) {
                        inlineEditors.moveRow(-1);
                        const cell = inlineEditors.getLastCell();
                        if (cell) {
                          task.focus = cell;
                        }
                      }
                      inlineEditors.editPrevCell(true);
                      break;
                    } else if (!value) {
                      inlineEditors.hide();
                      break;
                    }
                  }

                  const previous = gantt.getPrev(task.id);
                  const nextColumnName = inlineEditors.getNextCell(e.shiftKey ? -1 : 1);
                  if (nextColumnName) {
                    task.focus = nextColumnName;
                    inlineEditors.save();
                    const nextTask = gantt.getNext(previous);
                    inlineEditors.startEdit(nextTask, nextColumnName);
                  }
                } else {
                  if (e.shiftKey) {
                    inlineEditors.editPrevCell(true);
                  } else {
                    inlineEditors.editNextCell(true);
                  }
                }

                e.preventDefault();
                preventKeyNav = true;
              }
              break;

            default:
              if (inlineEditors.isVisible()) {
                preventKeyNav = true;
              }
              break;
          }

          return !preventKeyNav;
        }
        return false;
      });
    },

    onShow: function () {
      return;
    },
    onHide: function () {
      gantt.focus();
    },
  };
  gantt.ext.inlineEditors.setMapping(mapping);
}

export function resetFrozenColumnsOffset() {
  document.documentElement.style.setProperty('--gantt-frozen-column-scroll-left', 0 + 'px');
}
export function registerFrozenColumnsHandler(gantt: GanttStatic, columns: number): () => void {
  const frozenClass = `gantt_grid__frozen-${columns}`;
  let scrollContainer: HTMLElement;
  const events: string[] = [];
  const calcOffset = () =>
    document.documentElement.style.setProperty('--gantt-frozen-column-scroll-left', scrollContainer.scrollLeft + 'px');
  const onScrollHandler = () => {
    gantt.$grid.classList.add(frozenClass);
    gantt.$grid.dataset.frozenColumns = String(columns);
    calcOffset();
  };

  events.push(
    gantt.attachEvent(
      'onGanttReady',
      () => {
        scrollContainer = document.querySelector<HTMLElement>('.gridScroll_cell .gantt_hor_scroll');
        if (scrollContainer) {
          scrollContainer.addEventListener('scroll', onScrollHandler);
        }
      },
      null,
    ),
  );

  events.push(
    gantt.attachEvent(
      'onGridResizeEnd',
      () => {
        setTimeout(calcOffset);
      },
      null,
    ),
  );

  return () => {
    events.forEach((eventId) => gantt.detachEvent(eventId));
    gantt.$grid.classList.remove(frozenClass);
    delete gantt.$grid.dataset.frozenColumns;
    scrollContainer?.removeEventListener('scroll', onScrollHandler);
  };
}

export function isNonHidingColumn(colName: string): boolean {
  return NON_HIDING_COLUMNS.includes(colName as GANTT_COLUMNS_NAMES);
}

export function getCustomColumnCellTemplate(project: ProjectModel, task: GanttTask, columnName: string): string {
  if (isPlaceholderTask(gantt, task)) return '';
  const columnValue =
    // eslint-disable-next-line @typescript-eslint/naming-convention
    task.custom_fields?.find(({internal_field_name}) => internal_field_name === columnName)?.value ?? '';
  if (!columnValue) return '';
  const columnType = getProjectCustomField(project, columnName)?.fieldType;
  switch (columnType) {
    case ProjectCustomFieldType.date:
      return safeFormatDate(columnValue) ?? 'Not Parsed';
    case ProjectCustomFieldType.select:
    case ProjectCustomFieldType.multiselect: {
      if (!columnValue) return '';
      const parseResult = safeParseJSON<string[]>(columnValue);
      if (!parseResult.success) {
        return columnValue;
      }
      return Array.isArray(parseResult.data) ? parseResult.data.join(', ') : parseResult.data;
    }
    default: {
      return columnValue;
    }
  }
}

export function getCustomColumnWidthByFieldType(fieldType: ProjectCustomFieldType): number {
  return fieldType === ProjectCustomFieldType.date ? 125 : 100;
}

export function getInlineEditorTypeForCustomColumn(fieldType: ProjectCustomFieldType): {
  type: string;
  map_to: GANTT_COLUMNS_NAMES;
} {
  const mapTo = GANTT_COLUMNS_NAMES.customFields;
  const defaultEditor = {type: 'generalEditorForCustomField', map_to: mapTo};
  switch (fieldType) {
    case ProjectCustomFieldType.number:
      return {type: 'generalNumberEditor', map_to: mapTo};
    case ProjectCustomFieldType.date:
      return {type: 'customFieldDateEditor', map_to: mapTo};
    case ProjectCustomFieldType.select:
    case ProjectCustomFieldType.multiselect:
      return {type: 'customFieldDropdownEditor', map_to: mapTo};
    default: {
      return defaultEditor;
    }
  }
}
