import {type Props as ReactSelectProps} from 'react-select';

import {TaskStatusSelect} from 'shared/components/CoreForm/Select/TaskStatusSelect/TaskStatusSelect';
import {WorkerStatusSelect} from 'shared/components/CoreForm/Select/WorkerStatusSelect';

import {CoreSelect} from './Select';
import {CoreOptionType} from './types';

interface CoreStatusSelectProps extends Omit<ReactSelectProps<CoreOptionType, false>, 'value' | 'onChange'> {
  value: string;
  onChange: (value: string) => void;
}

const CoreStatusSelectField: React.FC<CoreStatusSelectProps> & {
  Task: typeof TaskStatusSelect;
  Worker: typeof WorkerStatusSelect;
} = (props) => {
  return <CoreSelect {...props} />;
};

CoreStatusSelectField.Task = TaskStatusSelect;
CoreStatusSelectField.Worker = WorkerStatusSelect;

export default CoreStatusSelectField;
