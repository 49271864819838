import {useQueries, useQuery, UseQueryResult} from '@tanstack/react-query';
import cn from 'classnames';
import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import TasksApi from 'api/tasks';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import PanelSection from 'modules/Tasks/components/SidebarPanel/components/PanelSection/PanelSection';
import {useConfirm} from 'shared/components/Confirmation';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import {GanttNames} from 'shared/constants/gantt';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {withGantt} from 'shared/helpers/gantt';
import {useQueryCache} from 'shared/hooks/useQueryCache/useQueryCache';
import Gantt from 'shared/models/Gantt';
import {TaskDetailsModelDTO} from 'shared/models/task/task';
import {DependencyMode, TaskDependencyDto} from 'shared/models/TaskDependency';

import s from './DependenciesList.module.scss';
import Dependency from './Dependency/Dependency';
import {TaskExtendedDependency} from './types';

type DependenciesListProps = {
  className?: string;
  taskId: string;
  disabled?: boolean;
  projectId?: string;
};

const DependenciesList = ({className, taskId, disabled, projectId}: DependenciesListProps) => {
  const {viewMode} = useFilterContext();
  const {t} = useTranslation();
  const {data: dependencies = [], refetch} = useQuery({
    queryKey: [taskId, 'dependencies'],
    queryFn: () => TasksApi.getDependencies(taskId),
    refetchOnWindowFocus: false,
    enabled: !!taskId,
  });
  const [selected, setSelected] = useState<TaskExtendedDependency | 'new'>();
  const {confirm} = useConfirm();
  const [createNew, setCreateNew] = useState(false);
  const {cacheHelper} = useQueryCache();

  const dependentTasks = useQueries({
    queries:
      dependencies?.map((dep) => ({
        queryKey: ['dependency', dep.predTaskId],
        queryFn: () => TasksApi.getTask(dep.predTaskId),
        refetchOnWindowFocus: false,
      })) ?? [],
  });

  const extendedDependencies = useMemo(() => {
    if (!dependencies?.length || !dependentTasks.length) return [];
    return dependencies.map((dep) => {
      const dependentTaskQuery: UseQueryResult<TaskDetailsModelDTO> = dependentTasks.find(
        (dependent) => dep.depTaskId === (dependent.data as TaskDetailsModelDTO)?.id,
      );
      const dependentTask = dependentTaskQuery?.data;
      return {
        id: dep.id,
        depTaskId: dep.depTaskId,
        delay: dep.delay,
        delayUnit: dep.delayUnit,
        depType: dep.depType,
        lagDays: dep.lagDays,
        taskId: dep.taskId,
        predTaskId: dep.depTaskId,
        title: dependentTask?.name,
        uniqId: dependentTask?.uniqueId,
        loading: dependentTaskQuery?.isLoading ?? true,
      } as TaskExtendedDependency;
    });
  }, [dependencies, dependentTasks.some((query) => query.isFetching), createNew]);

  async function createDependency(updates: TaskDependencyDto) {
    try {
      const res = await TasksApi.saveDependency(taskId, updates);
      cacheHelper.prependItem(QUERY_CACHE_KEYS.projectDependencies(projectId, viewMode), {
        ...res.dependency,
      });
      setCreateNew(null);
      await refetch();
      toast.success(t('task:dependencies.notifications.created', 'Dependency created.'));
    } catch (e) {
      if (isAxiosError(e)) {
        toast.error(extractAxiosError(e) as string);
      }
    }
  }

  async function updateDependency(id: string, updates: TaskDependencyDto) {
    try {
      const res = await TasksApi.updateDependency(taskId, {
        id,
        lagDays: updates.lagDays,
        predTaskId: updates.predTaskId,
        depType: updates.depType,
      });
      cacheHelper.updateItem(QUERY_CACHE_KEYS.projectDependencies(projectId, viewMode), {
        ...res.dependency,
      });
      toast.success(t('task:dependencies.notifications.updated', 'Dependency updated.'));
      await refetch();
    } catch (e) {
      if (isAxiosError(e)) {
        toast.error(extractAxiosError(e) as string);
      }
    }
  }

  const onSubmit = async ({id, updates}: {id: string; updates: TaskDependencyDto}) => {
    if (!id) {
      await createDependency(updates);
    } else {
      await updateDependency(id, updates);
    }
  };

  const deleteDep = useCallback(
    async (id: string) => {
      async function afterDelete() {
        if (selected !== 'new' && id === selected?.id) {
          setSelected(null);
        }
        await refetch();
        withGantt(
          Gantt.getInstance(GanttNames.gantt),
          (gantt) => {
            try {
              if (gantt.isLinkExists(id)) {
                const link = gantt.getLink(id);
                link.deletedAt = +new Date();
                gantt.deleteLink(id);
              }
            } catch (e) {
              toast.info(t('task:dependencies.notifications.not_exist', 'Dependency with id: {{id}} not exists', {id}));
            }
          },
          false,
        );
        cacheHelper.removeItem(QUERY_CACHE_KEYS.projectDependencies(projectId, viewMode), id);
      }

      if (
        await confirm(
          t('task:dependencies.confirmations.delete', 'Are you sure you want to delete this Activity Dependency?'),
        )
      ) {
        try {
          await TasksApi.deleteDependency(taskId, id);
          await afterDelete();
          toast.success(t('task:dependencies.notifications.deleted', 'Dependency deleted.'));
        } catch (e) {
          toast.error(extractAxiosError(e) as string);
        }
      }
    },
    [taskId, dependencies, extendedDependencies, selected],
  );

  const excludeList = dependencies.map((dep) => dep.predTaskId).concat(taskId);

  return (
    <PanelSection
      className={s.panelActivityInfo__item}
      title={t('task:dependencies.title', 'Dependencies')}
      width="narrow"
      headerAction={
        <CtrlButton disabled={disabled} view="link" onClick={() => setCreateNew(true)}>
          {t('task:dependencies.actions.create', 'Create Dependency')}
        </CtrlButton>
      }
      description={
        !dependencies?.length &&
        t(
          'task:dependencies.empty_desc',
          "You don't have any task set as dependent yet. Create a dependency to better organize your tasks.",
        )
      }
      // moreAction={<CtrlButton view="link">Show more</CtrlButton>} TODO
    >
      <div className={cn(s.dependenciesList, className)}>
        {extendedDependencies?.map((dep) => (
          <Dependency
            disabled={disabled}
            onDelete={() => deleteDep(dep.id)}
            dependency={dep}
            key={dep.id}
            className={s.dependenciesList__item}
            excludeTasks={excludeList}
            onChange={(values) =>
              onSubmit({
                id: dep.id,
                updates: {
                  depTaskId: values.predTask.value,
                  depType: values.depType,
                  delay: values.lagDays,
                  delayUnit: dep.delayUnit || 'days',
                  id: dep.id,
                  lagDays:
                    DependencyMode.LeadTime === values.mode ? Math.abs(values.lagDays) * -1 : Math.abs(values.lagDays),
                  taskId: dep.taskId,
                  predTaskId: values.predTask.value,
                },
              })
            }
          />
        ))}
        {createNew && (
          <Dependency
            className={s.dependenciesList__item}
            onDelete={() => setCreateNew(false)}
            excludeTasks={excludeList}
            onChange={(values) =>
              onSubmit({
                id: undefined,
                updates: {
                  depTaskId: values.predTask.value,
                  depType: values.depType,
                  lagDays:
                    DependencyMode.LeadTime === values.mode ? Math.abs(values.lagDays) * -1 : Math.abs(values.lagDays),
                  delayUnit: 'days',
                } as TaskDependencyDto,
              })
            }
          />
        )}
      </div>
    </PanelSection>
  );
};

export default DependenciesList;
