import {useRef} from 'react';
import {useTranslation} from 'react-i18next';

import CtrlBtnOption from 'shared/components/CoreNewUI/CtrlBtnOption/CtrlBtnOption';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import CtrlDrop, {CtrlDropdownActions} from 'shared/components/CoreNewUI/CtrlDrop/CtrlDrop';
import Icon from 'shared/components/Icon';

export enum SplitStyle {
  chart = 'chart',
  table = 'table',
  center = 'center',
}

type GanttSplitDropownProps = {
  onChange: (splitStyle: SplitStyle) => void;
  className?: string;
};

export const GanttSplitDropdown = ({onChange, className, ...props}: GanttSplitDropownProps) => {
  const splitToggleRef = useRef<CtrlDropdownActions>(null);
  const {t} = useTranslation('gantt');

  return (
    <CtrlDrop
      {...props}
      className={className}
      ref={splitToggleRef}
      key="togglesplit"
      toggleElement={
        <CtrlButton iconOnly size="s" icon="split-center" color="clear">
          {t('toolbar.actions.togglesplit', 'Toggle Split')}
        </CtrlButton>
      }
    >
      <CtrlBtnOption
        key="chartkey"
        size="s"
        onClick={() => {
          onChange(SplitStyle.chart);
          splitToggleRef.current.close();
        }}
        title="Chart View"
        icon={<Icon name="split-left" colorFill size={24} />}
      />
      <CtrlBtnOption
        key="splitkey"
        size="s"
        onClick={() => {
          onChange(SplitStyle.center);
          splitToggleRef.current.close();
        }}
        title="Split Center"
        icon={<Icon name="split-center" colorFill size={24} />}
      />
      <CtrlBtnOption
        key="tablekey"
        size="s"
        onClick={() => {
          onChange(SplitStyle.table);
          splitToggleRef.current.close();
        }}
        title="Table View"
        icon={<Icon name="split-right" colorFill size={24} />}
      />
    </CtrlDrop>
  );
};
