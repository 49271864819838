import {components, type OptionProps, type SingleValueProps, type GroupBase} from 'react-select';

import InfoMember from 'shared/components/CoreNewUI/InfoMember/InfoMember';

import {MemberOptionType} from './AsyncCompanyWorkerSelect';

export const MemberOption = (props: OptionProps<MemberOptionType, false, GroupBase<MemberOptionType>>) => {
  return (
    <components.Option {...props}>
      <InfoMember
        memberName={props.data.value.workerFull.fullName}
        memberImage={props.data.value.workercard?.profilePicUrl}
        size="m"
      />
    </components.Option>
  );
};

export const SingleMemberOption = (props: SingleValueProps<MemberOptionType, false, GroupBase<MemberOptionType>>) => {
  return (
    <components.SingleValue {...props}>
      <InfoMember
        memberName={props.data.value.workerFull.fullName}
        memberImage={props.data.value.workercard?.profilePicUrl}
        size="m"
      />
    </components.SingleValue>
  );
};
