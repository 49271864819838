import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {CoreOptionType} from 'shared/components/CoreForm/Select/types';

import WorkerApi from '../../api/worker';
import {toTitleCase} from '../helpers/common';
import {getNormalizedWorkerTrade} from '../helpers/worker';

type TradeOption = CoreOptionType;

export function useLoadWorkerTrades() {
  const {t} = useTranslation(['worker', 'common']);

  const {data: trades} = useQuery<TradeOption[]>({
    queryKey: ['workerTrades'],
    queryFn: async () => {
      const res = await WorkerApi.getTradeOptions();
      return res.map(
        (label): TradeOption => ({
          value: label,
          label: label.toLowerCase() === 'hvac' ? label.toUpperCase() : toTitleCase(label),
        }),
      );
    },
  });

  const localizedTrades: CoreOptionType[] = useMemo(
    () =>
      trades?.map(
        (trade): CoreOptionType => ({
          value: trade.value,
          label: getNormalizedWorkerTrade(trade.value, t),
        }),
      ) || [],
    [trades, t],
  );

  return {
    localizedTrades,
    trades: trades || [],
  };
}
