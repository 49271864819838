import {useQuery} from '@tanstack/react-query';
import cn from 'classnames';
import {FocusEventHandler, forwardRef, useCallback, useEffect, useMemo, useState} from 'react';
import Select, {ActionMeta, components, GroupBase, OptionProps, SelectInstance} from 'react-select';

import TasksApi from 'api/tasks';
import {taskSelectStyles} from 'shared/components/CoreForm/Select/styles';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import {SortOrder} from 'shared/constants/common';
import {useDebounce} from 'shared/hooks/core/useDebounce';
import {TaskObjectType} from 'shared/models/task/const';
import {TaskParams} from 'shared/models/task/filter';

export interface TaskOptionType extends CoreOptionType {
  uniqId: string;
}

function CustomOption(props: OptionProps<TaskOptionType, false, GroupBase<TaskOptionType>>) {
  return (
    <components.Option {...props}>
      <>
        <span className="task-info__id">{props.data.uniqId}</span>
        <span className="task-info__name">{props.label}</span>
      </>
    </components.Option>
  );
}

interface TaskAsyncSelectProps {
  name: string;
  className?: string;
  projectId: string;
  companyId: string;
  onChange: (option: TaskOptionType | null, action: ActionMeta<TaskOptionType>) => void;
  exclude?: string[];
  value: TaskOptionType | null;
  placeholder?: string;
  loadingPlaceholder?: string;
  onBlur?: FocusEventHandler;
  disabled?: boolean;
}

const TaskAsyncSelect = forwardRef<SelectInstance<TaskOptionType, false>, TaskAsyncSelectProps>(
  (
    {
      name,
      exclude,
      className,
      companyId,
      onChange,
      placeholder = 'Type activity id or name',
      loadingPlaceholder = 'Loading...',
      projectId,
      value,
      onBlur,
      disabled,
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState('');
    const [params, setParams] = useState<TaskParams>({
      params: {
        projectId,
        q: inputValue,
        deleted: false,
        objectTypeList: [TaskObjectType.activity, TaskObjectType.task, TaskObjectType.milestone],
      },
      sortField: 'outline_sort_key',
      sortOrder: SortOrder.ASC,
      offset: 0,
      limit: 20,
    });

    const {data: options, isFetching} = useQuery({
      queryKey: ['tasks', companyId, params],

      queryFn: () => {
        return TasksApi.getProjectTasks(params).then((res) => {
          return res.data.map((task) => ({value: task.id, label: task.name, uniqId: task.uniqueId} as TaskOptionType));
        });
      },

      enabled: !!(projectId && companyId),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    });

    const filteredOptions = useMemo(
      () => options?.filter((t) => !(exclude || []).includes(t.value)) || [],
      [options, exclude],
    );

    const onInputChange = useDebounce((value: string) => {
      setParams((prev) => ({...prev, params: {...prev.params, q: value?.trim()}}));
    }, 100);

    useEffect(() => {
      if (onInputChange) {
        onInputChange(inputValue);
      }
    }, [onInputChange, inputValue]);

    const loadingMessage = useCallback(() => {
      return loadingPlaceholder;
    }, [loadingPlaceholder]);

    return (
      <Select<TaskOptionType, false, GroupBase<TaskOptionType>>
        ref={ref}
        name={name}
        inputId={name}
        options={filteredOptions}
        placeholder={placeholder}
        isLoading={isFetching}
        loadingMessage={loadingMessage}
        className={cn('react-select', className)}
        classNamePrefix="react-select"
        onChange={onChange}
        menuPlacement="top"
        menuPortalTarget={document.body}
        components={{Option: CustomOption}}
        styles={taskSelectStyles}
        value={value}
        inputValue={inputValue}
        onInputChange={setInputValue}
        onBlur={onBlur}
        isDisabled={disabled}
      />
    );
  },
);

TaskAsyncSelect.displayName = 'TaskAsyncSelect';

export default TaskAsyncSelect;
