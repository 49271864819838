import {createAction, createAsyncThunk, SerializedError} from '@reduxjs/toolkit';

import ProjectsApi from 'api/projects';
import {deleteNullOrEmptyFields} from 'shared/helpers/common';
import {ProjectModel} from 'shared/models/project';
import {SnapshotPayload} from 'shared/models/task/task';
import {serializeError} from 'store/utils/serializeError';

type CreateProjectPayload = {
  companyId: string;
  model: ProjectModel;
};

export const getAllProjects = createAsyncThunk<ProjectModel[], string>('projects/getAll', async (companyId) => {
  const projects = await ProjectsApi.getProjects(companyId);
  return projects.map((project) => {
    if (!project.id) {
      throw new Error('Project must have an ID');
    }
    return project as ProjectModel;
  });
});

export const createProject = createAsyncThunk<ProjectModel, CreateProjectPayload, {rejectValue: SerializedError}>(
  'projects/createProject',
  async ({companyId, model}) => {
    const clearedPayload = Object.assign({}, model);
    deleteNullOrEmptyFields(clearedPayload);

    const newProject = await ProjectsApi.createProject(companyId, clearedPayload);

    if (!newProject.id) {
      throw new Error('Created project must have an ID');
    }

    return newProject as ProjectModel;
  },
  {serializeError},
);

export const updateCurrentProject = createAction<SnapshotPayload>('projects/updateCurrentProject');

export const updateProject = createAsyncThunk<ProjectModel, ProjectModel, {rejectValue: SerializedError}>(
  'projects/updateProject',
  async (model) => {
    const updatedProject = await ProjectsApi.updateProject({
      ...model,
      country: model.country === null ? '' : model.country,
    });

    if (!updatedProject.id) {
      throw new Error('Updated project must have an ID');
    }

    return updatedProject as ProjectModel;
  },
  {serializeError},
);

export const deleteProject = createAsyncThunk(
  'projects/deleteProject',
  async (projectId: string) => {
    return ProjectsApi.deleteProject(projectId);
  },
  {serializeError},
);

export const getProject = createAsyncThunk<ProjectModel, string>(
  'projects/getProject',
  async (projectId) => {
    const project = await ProjectsApi.getProject(projectId);
    if (!project.id) {
      throw new Error('Project must have an ID');
    }
    return project as ProjectModel;
  },
  {
    serializeError,
  },
);

export const getAllProjectsForWorker = createAsyncThunk(
  'projects/getAllProjectsForWorker',
  async (workerId: string) => {
    return await ProjectsApi.getAllProjectsForWorker(workerId);
  },
);
