import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import {useTasksBulkOperations} from 'modules/Tasks/components/TasksActionsBar/useTasksBulkOperations';
import {BulkProps} from 'modules/Tasks/utils/constants';
import Confirmation from 'shared/components/Confirmation';
import {CreatableSelect} from 'shared/components/CoreForm/Select/CreatableSelect/CreatableSelect';
import {RouteParams} from 'shared/constants/routes';
import {useOptionsForSelect} from 'shared/hooks/useOptionsForSelect';

const BulkLocation = ({onClose, gantt}: BulkProps) => {
  const {projectId} = useParams<RouteParams['tasks']>();
  const [selectedLocation, setSelectedLocation] = useState('');
  const {t} = useTranslation(['gantt', 'task']);
  const {bulkUpdate} = useTasksBulkOperations(gantt);
  const {locationOptions} = useOptionsForSelect(projectId);

  return (
    <Confirmation
      visible
      title={t('gantt:bulk_location.confirmation.title', 'Location')}
      description={t('gantt:bulk_location.confirmation.description', 'Set location for multiple activities')}
      cancelButton={t('gantt:bulk_assign.confirmation.buttons.cancel', 'Cancel')}
      acceptButton={t('gantt:bulk_assign.confirmation.buttons.accept', 'Submit')}
      onAccept={() => bulkUpdate({location: selectedLocation})}
      onClose={onClose}
      isDisabledAcceptBtn={!selectedLocation}
      field={
        <CreatableSelect
          isClearable
          value={selectedLocation}
          placeholder={t('task:task_form.form.location.placeholder', 'Set Location')}
          options={locationOptions}
          onChange={setSelectedLocation}
        />
      }
    />
  );
};

export default BulkLocation;
