import {FC, PropsWithChildren, useCallback, useState} from 'react';

import {TabsContext} from './TabsContext';
import {TabProps} from './types';

type TabsProps = {
  activeTab?: number;
  onTabChanged: (tab: number) => void;
};

const Tabs: FC<PropsWithChildren<TabsProps>> = ({activeTab = 0, onTabChanged, children}) => {
  const [tabs, setTabs] = useState<TabProps[]>([]);
  const addTabs = useCallback(
    (tabs: TabProps[]) => {
      setTabs(tabs);
    },
    [setTabs],
  );
  return <TabsContext value={{activeTab, setActiveTab: onTabChanged, tabs, setTabs: addTabs}}>{children}</TabsContext>;
};
export default Tabs;
