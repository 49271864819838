import {FormikProps} from 'formik';
import {FC, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router';

import ProjectsApi from 'api/projects';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import Loader from 'shared/components/Loader';
import css from 'shared/components/TaskAssigneersFilter/index.module.scss';
import {useMount} from 'shared/hooks/core/useMount';
import {usePrevious} from 'shared/hooks/core/usePrevious';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {TaskAssigneeWorker, TaskAssignees} from 'shared/models/task/member';
import {TaskDetailsModelDTO} from 'shared/models/task/task';
import {CompanyWorker, WorkerFormValues} from 'shared/models/worker';

import MemberInvite from '../MemberInvite/MemberInvite';

import {AssigneesTabContext} from './components/AssigneesTabContext';
import ResponsibleSection from './components/ResponsibleSection/ResponsibleSection';
import WatchersSection from './components/WatchersSection/WatchersSection';
import {AssignmentState} from './utils/constants';

type Props = {
  isNewTask: boolean;
  task: TaskDetailsModelDTO;
  loading: boolean;
  members: TaskAssigneeWorker[];
  onMemberSelect: (members: TaskAssigneeWorker[]) => void;
  disabled?: boolean;
};

export type TaskAssigneesExtended = TaskAssignees & {orgs?: string[]};

const AssigneesTab: FC<Props> = ({disabled, isNewTask, task, loading, members, onMemberSelect}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const [responsible, setSelectedResponsible] = useState<TaskAssigneesExtended[]>(task?.responsible || []);
  const [watchers, setSelectedWatchers] = useState<TaskAssigneesExtended[]>(task?.assignees || []);
  const [idsHiddenWatchers, setIdsHiddenWatchers] = useState([]);
  const [assignmentState, setAssignmentState] = useState<AssignmentState>(AssignmentState.empty);
  const prevAssignmentState = usePrevious<AssignmentState>(assignmentState);

  const {viewMode} = useFilterContext();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});

  useMount(() => {
    if (task) {
      mixpanel.track(mixpanel.events.task.actions.assigneeTabLand, {
        taskType: task.objectType,
      });
    }
  });

  const formik = useRef<FormikProps<WorkerFormValues>>(null);

  const addCompanyIdToWatchersAndResponsible = (
    workers: CompanyWorker[],
    responsible: TaskAssigneesExtended[],
    assignees: TaskAssigneesExtended[],
  ) => {
    const updatedWatchers = assignees.map((w) => {
      const orgs = workers.find((companyWorkers) => companyWorkers.workerId === w.memberId)?.orgs;
      return {...w, orgs};
    });
    if (responsible.length) {
      const orgs = workers.find((companyWorkers) => companyWorkers.workerId === responsible[0]?.memberId).orgs;
      setSelectedResponsible([{...responsible[0], orgs}]);
    }
    setSelectedWatchers(updatedWatchers);
  };

  const getWorkers = (
    workerIdList: string[],
    responsible: TaskAssigneesExtended[],
    assignees: TaskAssigneesExtended[],
  ) => {
    ProjectsApi.getProjectWorkers(
      projectId,
      {
        filterParams: {blendedStatus: ['active', 'invited'], workerIdList},
        sortOrder: 'ASC',
        sortField: 'fullName',
        offset: 0,
        limit: 1000,
      },
      'response',
    ).then((res) => {
      addCompanyIdToWatchersAndResponsible(res.data, responsible, assignees);
    });
  };

  const getWatchersAndResponsibleIds = () => {
    const result = task?.assignees.map((a) => a.memberId);
    if (responsible.length) result.push(responsible[0]?.memberId);
    return result;
  };

  useEffect(() => {
    if (task?.responsible && task?.assignees) {
      setSelectedResponsible(task?.responsible);
      setSelectedWatchers(task?.assignees);
      getWorkers(getWatchersAndResponsibleIds(), task?.responsible, task?.assignees);
    }
    setIdsHiddenWatchers([]);
  }, [task]);

  useEffect(() => {
    if (isNewTask) {
      setSelectedResponsible([]);
      setSelectedWatchers([]);
      setIdsHiddenWatchers([]);
    }
  }, [isNewTask]);

  return (
    <AssigneesTabContext
      value={{
        members,
        responsible,
        updateResponsible: setSelectedResponsible,
        watchers,
        updateWatchers: setSelectedWatchers,
        onMemberSelect,
        idsHiddenWatchers,
        setIdsHiddenWatchers,
        setAssignmentState,
        prevAssignmentState,
      }}
    >
      <>
        {!loading && ![AssignmentState.addWatcher, AssignmentState.inviteMember].includes(assignmentState) && (
          <ResponsibleSection disabled={disabled} assignmentState={assignmentState} />
        )}
        {!loading && ![AssignmentState.assignResponsible, AssignmentState.inviteMember].includes(assignmentState) && (
          <WatchersSection disabled={disabled} assignmentState={assignmentState} />
        )}
        {loading && (
          <div className="loader-container">
            <Loader className={css.assigneesListLoader} />
          </div>
        )}
        {!loading && assignmentState === AssignmentState.inviteMember && <MemberInvite ref={formik} />}
      </>
    </AssigneesTabContext>
  );
};

export default AssigneesTab;
