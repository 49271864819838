import {useQuery} from '@tanstack/react-query';

import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';

import {loadInitialData} from '../utils/apiHelpers';

export function useProgressReport(id: string) {
  return useQuery({
    queryKey: QUERY_CACHE_KEYS.task(id),
    queryFn: () => loadInitialData(id),
    enabled: !!id,
    // overwrite default cache time
    gcTime: 0,
    staleTime: 0,
  });
}
